import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';

import styles from './DueDiligenceQuestionnaireConfigurationRow.module.css';

export interface QuestionnaireConfigurationRowProps {
    eventKey?: string;
    level: 0 | 1 | 2 | 3;
    accessibilityTitle?: string;
    children?: React.ReactNode;
}

// TODO TPRM: This is now being used in the IRQ as well. Either rename, or use two different components.
export const DueDiligenceQuestionnaireConfigurationRow: React.FC<QuestionnaireConfigurationRowProps> = ({ eventKey, level, accessibilityTitle, children }) => {
    const getStyleClassArrow = (): string => {
        switch (level) {
            case 0:
                return styles.inlineStylesLevel0;
            case 1:
                return styles.inlineStylesLevel1;
            case 2:
                return styles.inlineStylesLevel2;
            case 3:
                return styles.inlineStylesLevel3;
            default:
                return '';
        }
    };

    const getStyleClassNoArrow = (): string => {
        switch (level) {
            case 0:
                return styles.inlineStylesNoArrowLevel0;
            case 1:
                return styles.inlineStylesNoArrowLevel1;
            case 2:
                return styles.inlineStylesNoArrowLevel2;
            case 3:
                return styles.inlineStylesNoArrowLevel3;
            default:
                return '';
        }
    };

    if (eventKey && accessibilityTitle) {
        return (
            <AccordionItem eventKey={eventKey}>
                <div className={`${styles.row} ${getStyleClassArrow()}`}>
                    <AccordionToggle eventKey={eventKey} ariaLabelSuffix={accessibilityTitle} />
                    {children}
                </div>
                <hr className={styles.hrModifier} />
            </AccordionItem>
        );
    }

    return (
        <>
            <div className={`${styles.row} ${getStyleClassNoArrow()}`}>{children}</div>
            <hr className={styles.hrModifier} />
        </>
    );
};
