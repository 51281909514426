import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

export interface CircularProgressIndicatorProps {
    percent: number;
}

export const CircularProgressIndicator = (props: CircularProgressIndicatorProps): JSX.Element => {
    return (
        <CircularProgressbarWithChildren
            value={props.percent}
            styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: 'var(--hps-dark-green)',
                trailColor: 'var(--hps-gray)',
            })}
        />
    );
};
