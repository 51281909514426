import { Skeleton } from '@mui/material';

import styles from './TPRMServiceCallouts.module.css';

/**
 * @param count - The number to display in the callout.
 * @param countHighlight - If this is `true` and the count is greater than zero, the count will be highlighted.
 * @param onclick - The function to call when the callout is clicked.
 */
export interface TPRMServiceCalloutsProps {
    count?: number;
    countHighlight?: boolean;
    onclick?: () => void;
}

export const TPRMServiceCallouts = (props: TPRMServiceCalloutsProps): JSX.Element => {
    return (
        <div onClick={props.onclick} className={styles.container}>
            {props.count !== undefined ? (
                <div className={props.countHighlight && props.count > 0 ? styles.countHighlight : styles.count}>{props.count}</div>
            ) : (
                <div className={styles.skeleton}>
                    <Skeleton>
                        <div className={styles.count}>---</div>
                    </Skeleton>
                </div>
            )}
        </div>
    );
};
