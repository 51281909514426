import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Link } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { ControlTable } from 'Components/ControlTable/ControlTable';
import { ExceptionHistoryTable, ExceptionHistoryTableProps } from 'Components/Exceptions/ExceptionHistoryTable/ExceptionHistoryTable';
import { AssociatedControlsModal } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { ThirdPartySummary } from 'Components/ThirdPartySummary/ThirdPartySummary';
import { EXCEPTIONS, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUpdateExceptionUrl } from 'Helpers/URLBuilder/URLBuilder';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlExceptionHistoryResponse, ExceptionHistoryResponse, ExceptionResponse, ExceptionStatus, ThirdPartyExceptionHistoryResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import styles from 'Styles/DetailsPage.module.css';

import { ExceptionDetailsSnapshot } from '../Components/ExceptionDetailsSnapshot/ExceptionDetailsSnapshot';
import { ExceptionHistoryModal } from '../Components/ExceptionHistoryModal/ExceptionHistoryModal';

enum ExceptionTab {
    CONTROLS = 'Controls',
    THIRD_PARTY = 'Third Party',
    HISTORY = 'History',
}

interface UrlParams {
    exceptionId: string;
}

interface ExceptionDetailsOperationalControlsProps {
    type: IssuesExceptionsModule.CONTROLS;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi?: never; // Typed like this so that `tprmApi` can be used unconditionally as a `useEffect` dependency.
}

interface ExceptionDetailsTprmProps {
    type: IssuesExceptionsModule.TPRM;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

export type ExceptionDetailsProps = ExceptionDetailsOperationalControlsProps | ExceptionDetailsTprmProps;
/**
 * Renders a page of detailed information for a specific exception.
 * This includes: current details; a tab containing a table of historical snapshots; and, depending on the exception type, a tab containing either a table of impacted controls or a summary of the impacted third party.
 */
export const ExceptionDetails = (props: ExceptionDetailsProps) => {
    const cachedData = useCachedData();
    const { exceptionId } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [exception, setException] = useState<ExceptionResponse>();
    const [thirdPartyIdToThirdPartyMap, setThirdPartyIdToThirdPartyMap] = useState<Map<string, ThirdPartyResponseWithServices>>(); // This isn't actually used when the exception belongs to the Operational Controls module.
    const [exceptionHistory, setExceptionHistory] = useState<ExceptionHistoryResponse[]>();
    const [exceptionHistoryToShowInModal, setExceptionHistoryToShowInModal] = useState<ExceptionHistoryResponse>();
    const [impactedControlsToShow, setImpactedControlsToShow] = useState<OperationalControl[]>();

    useEffect(() => {
        const getExceptionDetails = async (exceptionId: string): Promise<void> => {
            try {
                const getExceptionDetailsResponse = await props.exceptionsApi.getException(exceptionId);
                const exception = getExceptionDetailsResponse.data;
                setException(exception);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getExceptionDetails(exceptionId);
    }, [exceptionId, props.exceptionsApi]);

    useEffect(() => {
        const getExceptionHistory = async (exceptionId: string): Promise<void> => {
            try {
                const response = await props.exceptionsApi.getExceptionHistory(exceptionId);
                setExceptionHistory(response.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getExceptionHistory(exceptionId);
    }, [exceptionId, props.exceptionsApi]);

    useEffect(() => {
        const getThirdParties = async (): Promise<void> => {
            if (props.type !== IssuesExceptionsModule.TPRM) {
                setThirdPartyIdToThirdPartyMap(new Map());
                return;
            }

            try {
                // TODO: `getThirdPartyDetails` should be used if/when `ThirdPartyResponseWithServices` and `ThirdPartyResponse` are consolidated.
                const response = await props.tprmApi.getThirdParties();
                setThirdPartyIdToThirdPartyMap(new Map(response.data.map((thirdParty) => [thirdParty.id, thirdParty])));
            } catch (error) {
                handleRequestError(error);
            }
        };

        getThirdParties();
    }, [props.tprmApi, props.type]);

    const handleRequestError = (error: Error): void => setErrorMessage(error.message);

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    }

    if (!(exception && exceptionHistory && thirdPartyIdToThirdPartyMap)) {
        return <Placeholder />;
    }

    const buttons: JSX.Element = (() => {
        switch (exception.status) {
            case ExceptionStatus.DRAFT_OPEN:
                return (
                    <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                        MANAGE EXCEPTION
                    </Link>
                );
            case ExceptionStatus.APPROVED:
                return (
                    <>
                        <Link variant="secondaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                            MANAGE EXCEPTION
                        </Link>
                        <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, true)} fontAwesomeImage={faCheck}>
                            DRAFT CLOSURE
                        </Link>
                    </>
                );
            case ExceptionStatus.DRAFT_CLOSE:
                return (
                    <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                        MANAGE EXCEPTION
                    </Link>
                );
            case ExceptionStatus.CLOSED:
                return <></>;
        }
    })();

    const getThirdPartyName = (thirdPartyId: string) => thirdPartyIdToThirdPartyMap.get(thirdPartyId)!.name;

    const exceptionHistoryTableProps: ExceptionHistoryTableProps = (() => {
        switch (exception.type) {
            case IssuesExceptionsModule.CONTROLS:
                return { type: IssuesExceptionsModule.CONTROLS, histories: exceptionHistory as ControlExceptionHistoryResponse[], displayMappedControlsModal: setImpactedControlsToShow, onSelectHistory: (history: ExceptionHistoryResponse) => setExceptionHistoryToShowInModal(history) };
            case IssuesExceptionsModule.TPRM:
                return { type: IssuesExceptionsModule.TPRM, histories: exceptionHistory as ThirdPartyExceptionHistoryResponse[], getThirdPartyName: getThirdPartyName, onSelectHistory: (history: ExceptionHistoryResponse) => setExceptionHistoryToShowInModal(history) };
        }
    })();

    return (
        <>
            {exceptionHistoryToShowInModal && <ExceptionHistoryModal history={exceptionHistoryToShowInModal} documentApi={props.documentApi} hideModal={() => setExceptionHistoryToShowInModal(undefined)} />}
            {impactedControlsToShow && <AssociatedControlsModal associatedControls={impactedControlsToShow} hideModal={() => setImpactedControlsToShow(undefined)} />}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${exception.type === IssuesExceptionsModule.TPRM ? `${TPRM}/` : ''}${ISSUES_EXCEPTIONS}#${EXCEPTIONS}`}>Exceptions</BreadcrumbLink>
                        <BreadcrumbText>{exception.title}</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={exception.title}
                headerButtons={buttons}
                headerDescription={exception.status === ExceptionStatus.CLOSED ? `Closed: ${iso8601ToUsDateShort(exception.closed_timestamp)} by ${getUserNameFromSubject(exception.closed_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}` : `Last updated: ${iso8601ToUsDateShort(exception.last_updated_timestamp)} by ${getUserNameFromSubject(exception.last_updated_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}`}
                body={[
                    {
                        title: 'Exception Details',
                        content: <ExceptionDetailsSnapshot exception={exception} documentApi={props.documentApi} />,
                    },
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab={exception.type === IssuesExceptionsModule.CONTROLS ? ExceptionTab.CONTROLS : ExceptionTab.THIRD_PARTY} removePadding transparent>
                                {exception.type === IssuesExceptionsModule.CONTROLS ? (
                                    <Tab eventKey={ExceptionTab.CONTROLS} title={ExceptionTab.CONTROLS}>
                                        <div className={styles.tabContent}>
                                            <ControlTable controls={exception.impacted_controls} />
                                        </div>
                                    </Tab>
                                ) : (
                                    <Tab eventKey={ExceptionTab.THIRD_PARTY} title={ExceptionTab.THIRD_PARTY}>
                                        <div className={styles.tabContent}>
                                            <Text variant="Header2" color="darkBlue" noStyles>
                                                {thirdPartyIdToThirdPartyMap.get(exception.impacted_vendor)!.name}
                                            </Text>
                                            <hr />
                                            <ThirdPartySummary third_party={thirdPartyIdToThirdPartyMap.get(exception.impacted_vendor)!} />
                                        </div>
                                    </Tab>
                                )}
                                <Tab eventKey={ExceptionTab.HISTORY} title={ExceptionTab.HISTORY}>
                                    <div className={styles.tabContent} data-testid="exceptionHistoryTable">
                                        {exception.status !== ExceptionStatus.DRAFT_OPEN ? <ExceptionHistoryTable {...exceptionHistoryTableProps} /> : <Text>History will not be recorded until the exception is approved.</Text>}
                                    </div>
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        </>
    );
};
