import { useEffect, useState } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { TprmThirdPartyRiskScores } from 'Models/ExternalIntegrations';

export const useThirdPartyMonitoringMetricValues = (thirdPartyId: string, externalIntegrationsApi: ExternalIntegrationsApi): [TprmThirdPartyRiskScores[] | undefined, Error | undefined] => {
    const [thirdPartyRiskScores, setThirdPartyRiskScores] = useState<TprmThirdPartyRiskScores[] | undefined>(undefined);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const getThirdPartyMonitoringMetricValues = async () => {
            try {
                const getThirdPartyMonitoringMetricValuesResponse = await externalIntegrationsApi.getThirdPartyMonitoringMetricValues(thirdPartyId);
                setThirdPartyRiskScores(getThirdPartyMonitoringMetricValuesResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        getThirdPartyMonitoringMetricValues();
    }, [externalIntegrationsApi, thirdPartyId]);

    return [thirdPartyRiskScores, error];
};
