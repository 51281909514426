import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';

import styles from './ControlSelectorRow.module.css';

export interface ControlSelectorRowProps {
    eventKey?: string;
    level: number;
    'aria-label': string;
    children?: React.ReactNode;
}

export const ControlSelectorRow = (props: ControlSelectorRowProps): JSX.Element => {
    const getStyleClassArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.inlineStylesLevel0;
            case 2:
                return styles.inlineStylesLevel1;
            case 3:
                return styles.inlineStylesLevel2;
            case 4:
                return styles.inlineStylesLevel3;
            default:
                return styles.inlineStylesLevel4;
        }
    };

    const getStyleClassNoArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.inlineStylesLevel1;
            case 2:
                return styles.inlineStylesLevel2;
            case 3:
                return styles.inlineStylesLevel3;
            case 4:
                return styles.inlineStylesLevel4;
            default:
                return styles.inlineStylesLevel5;
        }
    };

    if (props.eventKey) {
        return (
            <AccordionItem eventKey={props.eventKey}>
                <div className={`${styles.rowText} ${getStyleClassArrow()}`}>
                    <AccordionToggle eventKey={props.eventKey} ariaLabelSuffix={props['aria-label']} />
                    {props.children}
                </div>
                <hr className={styles.hrModifier} />
            </AccordionItem>
        );
    }
    return (
        <>
            <div className={`${styles.rowText} ${getStyleClassNoArrow()}`}>{props.children}</div>
            <hr className={styles.hrModifier} />
        </>
    );
};
