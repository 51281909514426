/**
 * Alerts that were generated within the past this many days will be queried/shown in the UI. If you change this, update the variable of the same name in the API code.
 */
export const _NUMBER_OF_DAYS_OF_ALERTS = 14;

export interface AlertResponse {
    title: string;
    url: string;
    entity_id: string; // The canonical identifier for the entity that the Alert applies to. For example, "CIS CSC (v8.0)", "CIS CSC (v8.0)#1", or "CIS CSC (v8.0)#1#1.1"
    created_time: string;
    alert_limit: string;
    value: string;
}

export interface AlertRequest {
    limit?: number;
    entity_id?: string;
}
