import { Fragment, useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { DeleteLimitModal, DeleteLimitModalProps } from 'Components/Limits/DeleteLimitModal/DeleteLimitModal';
import { ModifyEffectivenessLimitModal, ModifyEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/ModifyEffectivenessLimitModal/ModifyEffectivenessLimitModal';
import { LimitsTables, LimitsTablesProps } from 'Components/Limits/LimitsTables/LimitsTables';
import { ModifyMetricLimitModal, ModifyMetricLimitModalProps } from 'Components/Limits/Metric/ModifyMetricLimitModal/ModifyMetricLimitModal';
import { EffectivenessLimit, Limit, LimitResponse, LimitType, MetricLimit } from 'Models/Limits';

export interface ManageLimitsTabProps {
    limitResponseList: LimitResponse[];
    alertsLimitsApi: AlertsLimitsApi;
    updateLimitListing: () => void;
}

export enum Modals {
    DeleteLimitModal,
    ModifyEffectivenessLimitModal,
    ModifyMetricLimitModal,
    None,
}

export const ManageLimitsTab: React.FC<ManageLimitsTabProps> = (props) => {
    const [deleteLimitId, setDeleteLimitId] = useState<string | undefined>();
    const [deleteLimitTitle, setDeleteLimitTitle] = useState<string | undefined>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [modifyLimit, setModifyLimit] = useState<Limit | undefined>();

    const displayDeleteLimitModal = (deleteLimitId: string, deleteLimitTitle: string): void => {
        setDeleteLimitId(deleteLimitId);
        setDeleteLimitTitle(deleteLimitTitle);
        displayModal(Modals.DeleteLimitModal);
    };

    const displayModifyLimitModal = (modifyLimit: Limit): void => {
        setModifyLimit(modifyLimit);
        if (modifyLimit._type === LimitType.EFFECTIVENESS) {
            displayModal(Modals.ModifyEffectivenessLimitModal);
        } else if (modifyLimit._type === LimitType.METRIC) {
            displayModal(Modals.ModifyMetricLimitModal);
        }
    };

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const limitsTableProps: LimitsTablesProps = {
        limitResponseList: props.limitResponseList,
        displayDeleteLimitModal: displayDeleteLimitModal,
        displayModifyLimitModal: displayModifyLimitModal,
        alertsLimitsApi: props.alertsLimitsApi,
    };

    const deleteLimitModalProps: DeleteLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        deleteLimitId: deleteLimitId!,
        deleteLimitTitle: deleteLimitTitle!,
        hideModal: () => displayModal(Modals.None),
        updateLimitListing: props.updateLimitListing,
    };

    const modifyEffectivenessLimitModalProps: ModifyEffectivenessLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        hideModal: () => displayModal(Modals.None),
        limit: modifyLimit! as EffectivenessLimit,
        onLimitModified: props.updateLimitListing,
    };

    const modifyMetricLimitModalProps: ModifyMetricLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        hideModal: () => displayModal(Modals.None),
        metricLimit: modifyLimit! as MetricLimit,
        onLimitModified: props.updateLimitListing,
    };

    return (
        <Fragment>
            {displayedModal === Modals.DeleteLimitModal && <DeleteLimitModal {...deleteLimitModalProps} />}
            {displayedModal === Modals.ModifyEffectivenessLimitModal && <ModifyEffectivenessLimitModal {...modifyEffectivenessLimitModalProps} />}
            {displayedModal === Modals.ModifyMetricLimitModal && <ModifyMetricLimitModal {...modifyMetricLimitModalProps} />}
            <LimitsTables {...limitsTableProps} />
        </Fragment>
    );
};
