import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ExceptionsSortFilterOptions } from 'Models/Exceptions';
import { ColorTheme, Filter, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

export interface ExceptionsMultiOptionFilterProps {
    filterOptions: GroupedOptions[];
    selectedFilterOptions: (selectedFilterOptions: Filter | Filter[]) => void;
    defaultSelectedOptions: GroupOptionType[];

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

/**
 * Renders a filter drop-down that facilitates filtering exceptions by priority and/or status.
 */
export const ExceptionsMultiOptionFilter = (props: ExceptionsMultiOptionFilterProps) => {
    const handleSelectChange = (value: GroupOptionType[]): void => {
        const priorityFilterValues: (string | number)[] = [];
        const statusFilterValues: (string | number)[] = [];

        value.forEach((filter) => {
            if (filter.groupId === ExceptionsSortFilterOptions.RISK_SCORE) {
                priorityFilterValues.push(filter.value);
            }
            if (filter.groupId === ExceptionsSortFilterOptions.STATUS) {
                statusFilterValues.push(filter.value);
            }
        });

        const bothFilters: Filter[] = [
            {
                key: 'risk_score',
                value: priorityFilterValues,
            },
            {
                key: 'status',
                value: statusFilterValues,
            },
        ];
        props.selectedFilterOptions(bothFilters);
    };
    return <FormFieldMultiOptionSelect colorTheme={props.colorTheme} defaultSelectedOptions={props.defaultSelectedOptions} options={props.filterOptions} formFieldLabel="Filter by Risk Score or Status" handleChange={handleSelectChange} accessibilityLabel="Exceptions Filter" />;
};
