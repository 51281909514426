import { Table as MaterialTable, TableBody as MaterialTableBody, TableCell as MaterialTableCell, TableRow as MaterialTableRow } from '@mui/material';
import { ReactNode } from 'react';

import { Link } from 'Components/Buttons/Buttons';

import styles from './Table.module.css';

export interface TableComponentWithChildren {
    children: ReactNode;
    className?: string;
}

/**
 * A simple table. Must have TableHead or TableBody as child.
 */
export function Table({ children }: TableComponentWithChildren): JSX.Element {
    return (
        <div className={styles.tableWrapper}>
            <MaterialTable>{children}</MaterialTable>
        </div>
    );
}

/**
 * A simple body for a table. Must have TableRow as child and Table as a parent.
 */
export function TableBody({ children }: TableComponentWithChildren): JSX.Element {
    return <MaterialTableBody sx={{ borderTop: 'none' }}>{children}</MaterialTableBody>;
}

/**
 * A simple row for a table. Must have a Table Cell as child and TableBody or TableHeader as a parent.
 */
export function TableRow({ children }: TableComponentWithChildren): JSX.Element {
    return <MaterialTableRow sx={{ lineHeight: '12px' }}>{children}</MaterialTableRow>;
}

const defaultTableCellStyle = { lineHeight: '12px', verticalAlign: 'center', padding: '.75rem' } as const;

/**
 * A simple cell for a Table. Must have TableRow as a parent. Anything can be its child.
 * @param {string?} className Can pass CSS from parent component to child component. CSS must be declared in Parent module.
 */
export function TableCell({ className, children }: { className?: string; children?: React.ReactNode }): JSX.Element {
    return (
        <MaterialTableCell className={className && className} sx={!className ? defaultTableCellStyle : {}}>
            {children}
        </MaterialTableCell>
    );
}

/**
 * A cell only meant to wrap an OverflowMenu Component. Use TableCell for any other component.
 */
export function TableOverflowCell({ className, children }: TableComponentWithChildren): JSX.Element {
    return (
        <MaterialTableCell className={className} sx={{ padding: '.25rem 0rem', verticalAlign: 'center' }}>
            {children}
        </MaterialTableCell>
    );
}

/**
 * A cell that will contain the default text for a cell. If not using the default styles use TableCell.
 */
export function TableCellDefaultText({ children }: TableComponentWithChildren): JSX.Element {
    return <MaterialTableCell sx={defaultTableCellStyle}>{children}</MaterialTableCell>;
}

/**
 * A cell that will contain a link to another location. If creating a link for a modal, use TableCell.
 * @param {string} link The location in the webpage the user will navigate to on click.
 * @param {string} children The text of the link.
 */
export function TableCellLink({ link, children }: { link: string; children: string }): JSX.Element {
    return (
        <MaterialTableCell sx={defaultTableCellStyle}>
            <Link to={link} size="sm">
                {children}
            </Link>
        </MaterialTableCell>
    );
}
