import { useState } from 'react';

/**
 * A custom hook to be used in conjunction with the FileDragAndDrop component. The return values can be passed directly to the component.
 * In most cases, this hook will suffice. But if a parent component is manually managing state for many child FileDragAndDrop components,
 * you can instead make use of only the helper functions below.
 *
 * Use this hook when multiple files can be selected. Otherwise, use `useFileDragAndDropSingleMode`.
 *
 * @returns The following:
 * * An alphabetized list of files
 * * A function to add files to the list
 * * A function to remove a file from the list
 * * A function to clear all files from the list
 */
export const useFileDragAndDrop = (): readonly [File[], (droppedFiles: File[]) => void, (file: File) => void, () => void] => {
    const [files, setFiles] = useState<File[]>([]);

    const onAddFiles = (files: File[]) => {
        setFiles((currentFiles) => addFiles(currentFiles, files));
    };

    const onRemoveFile = (file: File) => {
        setFiles((currentFiles) => removeFiles(currentFiles, file));
    };

    const clearFiles = () => {
        setFiles([]);
    };

    return [files, onAddFiles, onRemoveFile, clearFiles] as const;
};

/**
 * A custom hook to be used in conjunction with the FileDragAndDrop component. The return values can be passed directly to the component.
 * In most cases, this hook will suffice. But if a parent component is manually managing state for many child FileDragAndDrop components,
 * you can instead make use of only the helper functions below.
 *
 * Use this hook when only a single file can be selected. Otherwise, use `useFileDragAndDrop`.
 *
 * @returns The following:
 * * An alphabetized list of files
 * * A function to select a new file
 * * A function to remove the selected file
 */
export const useFileDragAndDropSingleMode = (): readonly [File | undefined, (selectedFile: File) => void, () => void] => {
    const [file, setFile] = useState<File>();
    return [file, setFile, () => setFile(undefined)] as const;
};

/**
 * Adds files to a (new) array, ignoring non-unique additions, and returns a sorted version of the new array.
 * Use this function only when the useFileDragAndDrop hook does not suffice.
 *
 * @param files An existing array of files.
 * @param newFiles The files to be added to the existing array of files.
 * @returns A new array of files.
 */
export const addFiles = (files: File[], newFiles: File[]): File[] => {
    const uniqueFiles = [...new Set(files.concat(newFiles))];
    return uniqueFiles.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
};

/**
 * Removes a file, based on filename, from an existing array of files.
 * Use this function only when the useFileDragAndDrop hook does not suffice.
 *
 * @param files An existing array of files.
 * @param fileToRemove The file to be removed from the existing array of files.
 * @returns A new array without the file included.
 */
export const removeFiles = (files: File[], fileToRemove: File): File[] => {
    return files.filter((file) => file.name !== fileToRemove.name);
};
