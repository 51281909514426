import { useEffect, useMemo, useState } from 'react';

import { TagsApi } from 'Api/Tags/TagsApi';
import { DisplayableTag, TagCategoryRequest, tagsComparator } from 'Models/Tags';
import { GroupedOptions } from 'Models/Types/GlobalType';

/**
 * A generic type that represents the three possible states of a network request.
 * Note: We have discussed implementing a "global" type like this that can be used across the app, but introducing it outside of this file is outside the scope of current work. If such a global type is implemented, use that, and remove this.
 */
export type FetchState<Data> = { type: 'loading' } | { type: 'success'; data: Data } | { type: 'failure'; message: string };

/**
 * Fetches tag categories (and tags) from the backend, then sorts them alphabetically.
 * @param tagsApi Something that can fetch tags from the backend.
 * @returns A `FetchState` that, when successful, contains a sorted array of tag categories and tags.
 */
export const useSortedCategorizedTags = (tagsApi: TagsApi) => {
    const [categoriesState, setCategoriesState] = useState<FetchState<Required<TagCategoryRequest>[]>>({ type: 'loading' });

    useEffect(() => {
        const getCategories = async (): Promise<void> => {
            try {
                const response = await tagsApi.getTags();
                const categories = response.data.category_set;
                categories.sort((a, b) => (a.title > b.title ? 1 : -1));
                categories.forEach((category) => category.tags.sort((a, b) => (a.title > b.title ? 1 : -1)));
                setCategoriesState({ type: 'success', data: categories });
            } catch (error) {
                setCategoriesState({ type: 'failure', message: error.message });
            }
        };
        getCategories();
    }, [tagsApi]);

    return categoriesState;
};

/**
 * Fetches tag categories (and tags) from the backend, sorts them alphabetically, then converts them to `GroupedOptions` that can be displayed in a drop-down.
 * @param tagsApi Something that can fetch tags from the backend.
 * @returns A `FetchState` that, when successful, contains a sorted array of `GroupedOptions` for tag categories and tags.
 */
export const useSortedCategorizedTagsOptions = (tagsApi: TagsApi): FetchState<GroupedOptions[]> => {
    const categoriesState = useSortedCategorizedTags(tagsApi);

    const optionsFetchState: FetchState<GroupedOptions[]> = useMemo(() => {
        switch (categoriesState.type) {
            case 'loading':
                return { type: 'loading' };
            case 'failure':
                return { type: 'failure', message: categoriesState.message };
            case 'success':
                const sortedCategoriesAndTags = categoriesState.data.map((category) => ({
                    label: category.title,
                    options: category.tags.map((tag) => ({
                        groupId: category.title,
                        label: tag.title,
                        value: tag.id!, // TODO: `!` should not be needed.
                    })),
                }));
                return { type: 'success', data: sortedCategoriesAndTags };
        }
    }, [categoriesState]);

    return optionsFetchState;
};

/**
 * Fetches tags from the backend, then returns a function that can convert a given list of `tagIds` to `DisplayableTag`s, which have all required information for displaying tags to the user.
 * Use this hook when you have an object with a list of IDs for tags that need to be shown to the user (usually via the `Tag` component).
 *
 * Note: It is possible (if there is a bug in the frontend or backend code, or if database data has gotten out of sync) that information for a given tag ID cannot be found. If this case occurs, an "UNKNOWN TAG" will be returned from the conversion/lookup function.
 *
 * @param tagsApi Something that can fetch tags from the backend.
 * @returns A `FetchState` that, when successful, contains the function for converting `tagIds` to `DisplayableTag`s.
 */
export const useDisplayableTagsLookup = (tagsApi: TagsApi): FetchState<(tagIds: string[]) => DisplayableTag[]> => {
    const categoriesState = useSortedCategorizedTags(tagsApi);

    const getDisplayableTags: FetchState<(tagIds: string[]) => DisplayableTag[]> = useMemo(() => {
        switch (categoriesState.type) {
            case 'loading':
                return { type: 'loading' };
            case 'failure':
                return { type: 'failure', message: categoriesState.message };
            case 'success':
                const tags: DisplayableTag[] = categoriesState.data.map((category) => category.tags.map((tag) => ({ id: tag.id!, title: tag.title, category_color: category.color, category_title: category.title }))).flat();
                const tagsById = new Map(tags.map((tag) => [tag.id, tag]));
                return {
                    type: 'success',
                    data: (tagIds: string[]) =>
                        tagIds
                            .map(
                                (id, index) =>
                                    tagsById.get(id) ?? {
                                        id: index.toString(),
                                        title: 'UNKNOWN TAG',
                                        category_color: '#000000',
                                        category_title: 'UNKNOWN CATEGORY',
                                    }
                            )
                            .sort(tagsComparator),
                };
        }
    }, [categoriesState]);

    return getDisplayableTags;
};
