import { faEdit, faFolder, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Text } from 'Components/Text/Text';
import { ISSUES_EXCEPTIONS, SERVICES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import { ManageTPRMServicesRouteState } from 'Pages/TPRM/ManageTPRMServices/ManageTPRMServices';

import styles from './ThirdPartyCard.module.css';
import { ThirdPartyListingServiceListing } from '../ThirdPartyListingServiceListing/ThirdPartyListingServiceListing';

export interface ThirdPartyCardProps {
    thirdParty: ThirdPartyResponseWithServices;
    selectedDeleteThirdParty: (thirdPartyId: string) => void;
    selectedModifyThirdParty: (thirdPartyId: string) => void;
    selectedCreateServiceForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewFolders: (thirdPartyId: string) => void;
}

export const ThirdPartyCard = (props: ThirdPartyCardProps): JSX.Element => {
    const cachedData = useCachedData();

    const overflowMenuProps: OverflowMenuProps = {
        overflowItems: [
            {
                text: 'Create a Service',
                onClickAction: () => props.selectedCreateServiceForThirdParty(props.thirdParty),
                icon: faPlus,
            },
            {
                text: 'Update Third Party',
                onClickAction: () => props.selectedModifyThirdParty(props.thirdParty.id),
                icon: faEdit,
            },
            {
                text: 'Manage Documents',
                onClickAction: () => props.selectedViewFolders(props.thirdParty.id),
                icon: faFolder,
            },
            {
                text: 'Delete Third Party',
                onClickAction: () => props.selectedDeleteThirdParty(props.thirdParty.id),
                icon: faTrash,
            },
        ],
        accessibilityTitle: `${props.thirdParty.name} Menu`,
    };

    const hasServices = props.thirdParty.services.length > 0;
    const state: ManageTPRMServicesRouteState | undefined = hasServices ? undefined : { createServiceForThirdParty: props.thirdParty };
    const numberOfOpenIssues = countOpenIssues(props.thirdParty.issues);
    const numberOfOpenExceptions = countOpenExceptions(props.thirdParty.exceptions);

    return (
        <Card className={styles.card}>
            <div className={styles.cardHeader}>
                <Text variant="Header2" noStyles>
                    {props.thirdParty.name}
                </Text>
                <div className={styles.overflowMenu}>
                    <OverflowMenu {...overflowMenuProps} />
                </div>
            </div>
            <div className={styles.cardHeader}>
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    {props.thirdParty.website}
                </Text>
            </div>
            <div className={styles.horizontalLine} />
            <div className={styles.cardSection}>
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    {hasServices ? `Services (${props.thirdParty.services.length} total)` : 'No services created yet'}
                </Text>
                <ThirdPartyListingServiceListing thirdPartyId={props.thirdParty.id} services={props.thirdParty.services} />
            </div>
            <div className={styles.horizontalLinePushDown} />
            <div className={styles.cardSection}>
                <div className={styles.row}>
                    <div>
                        <div>
                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                Date Created
                            </Text>
                        </div>
                        <Text variant="Text3" noStyles>
                            {iso8601ToUsDateLong(props.thirdParty.created_time)}
                        </Text>
                    </div>
                    <div>
                        <div>
                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                Third-Party Manager
                            </Text>
                        </div>
                        <Text variant="Text3" noStyles>
                            {getUserNameFromSubject(props.thirdParty.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}
                        </Text>
                    </div>
                </div>
            </div>
            <div className={styles.horizontalLine} />
            <div className={styles.cardSection}>
                <div className={styles.row}>
                    <div>
                        <Text variant="Text4" color="darkGray" noStyles toUppercase>
                            Issues & Exceptions
                        </Text>
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${props.thirdParty.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                    </div>
                    <div>
                        <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${props.thirdParty.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                    </div>
                </div>
            </div>
            <div className={styles.horizontalLine} />
            <div className={styles.cardFooter}>
                <Link
                    size="lg"
                    to={{
                        pathname: `/${TPRM}/${SERVICES}`,
                        search: hasServices ? `thirdPartyId=${props.thirdParty.id}` : '',
                    }}
                    state={state}
                >
                    {hasServices ? 'VIEW SERVICES' : 'CREATE A SERVICE'}
                </Link>
            </div>
        </Card>
    );
};
