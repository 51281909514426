import { faX } from '@fortawesome/free-solid-svg-icons';
import { Slider } from '@mui/material';
import { useEffect, useState } from 'react';

import { CSSColors } from 'Components/Colors';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { SortDirection, SortableTableHeader } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';
import { RiskRating, riskRatingAsString } from 'Models/TPRM';

import styles from './InherentRiskQuestionnareConfigurationThresholds.module.css';

// TODO TPRM: Standardize/DRY these somewhere. This specific ordering of colors is used both in TPRM and in Operational Controls.
const COLORS = [CSSColors.DARK_GREEN, CSSColors.LIGHT_GREEN, CSSColors.YELLOW, CSSColors.ORANGE, CSSColors.RED];
const getRiskVariantColor = (index: number): IndicatorVariant => {
    switch (index) {
        case 0:
            return IndicatorVariant.DARK_GREEN;
        case 1:
            return IndicatorVariant.LIGHT_GREEN;
        case 2:
            return IndicatorVariant.YELLOW;
        case 3:
            return IndicatorVariant.ORANGE;
        default:
            return IndicatorVariant.RED;
    }
};

// These are extracted from the component in anticipation of the user being able to apply custom names to risk tiers in the near future.
const RISK_TIER_NAMES_WITHOUT_HIGHEST = [riskRatingAsString(RiskRating.LOW), riskRatingAsString(RiskRating.LOW_MODERATE), riskRatingAsString(RiskRating.MODERATE), riskRatingAsString(RiskRating.MODERATE_HIGH)];
const RISK_TIER_NAME_HIGHEST = riskRatingAsString(RiskRating.HIGH);

export interface InherentRiskQuestionnareConfigurationThresholdsProps {
    minimum: number;
    maximum: number;
    defaultValues: number[];
    onNewValidValues: (values: number[]) => void;
}

interface TableState {
    /**
     * The values displayed in the thresholds table.
     */
    values: string[];
    /**
     * Each error corresponds with one of the `values` at the same index.
     * The entire array is undefined if there are no errors.
     */
    errors?: (string | undefined)[];
}

interface SliderState {
    /**
     * The slider values displayed when the user is not dragging a slider.
     * Also serves as a cached array of values to revert to if the user tries to release a slider on top of another.
     */
    valuesWhileIdle: number[];
    /**
     * The slider values displayed when the user is dragging a slider.
     * Undefined if the user is not currently dragging a slider.
     */
    valuesWhileDragging?: number[];
    /**
     * The bottom of the range for sliders. This differs from the minimum in the table only when the table has errors, which causes the sliders to de-sync from the table.
     */
    rangeMinimum: number;
    /**
     * The top of the range for sliders. This differs from the maxmimum in the table only when the table has errors, which causes the sliders to de-sync from the table.
     */
    rangeMaximum: number;
}

/**
 * Given a set of table values, returns `undefined` if there are no validation errors, or an array of error messages otherwise.
 */
const calculateTableErrors = (tableValues: string[], minimumValue: number, maximumValue: number): (string | undefined)[] | undefined => {
    const tableValuesAsNumbers = tableValues.map((value) => (isNaN(Number(value)) ? undefined : Number(value)));

    const errors = tableValuesAsNumbers.map((value, idx) => {
        if (value === undefined) {
            return 'Value must be a number';
        } else if (isNaN(Number(value))) {
            return 'Value must be a number';
        } else if (idx > 0 && tableValuesAsNumbers[idx - 1] !== undefined && value <= tableValuesAsNumbers[idx - 1]!) {
            return `Value must be greater than ${tableValuesAsNumbers[idx - 1]}`;
        } else if (idx < tableValuesAsNumbers.length - 1 && tableValuesAsNumbers[idx + 1] !== undefined && value >= tableValuesAsNumbers[idx + 1]!) {
            return `Value must be less than ${tableValuesAsNumbers[idx + 1]}`;
        } else if (idx === 0 && value < minimumValue) {
            return `Value must be at least ${minimumValue}`;
        } else if (idx === tableValuesAsNumbers.length - 1 && value > maximumValue) {
            return `Value must be at most ${maximumValue}`;
        }
        return undefined;
    });

    if (errors.some((error) => error !== undefined)) {
        return errors;
    } else {
        return undefined;
    }
};

/**
 * Renders sliders and a table, which the user can use to select the thresholds (i.e., a set of point ranges corresponding to the set of all risk tiers) for the Inherent Risk Questionnaire Configuration.
 * * Note: MUI calls the circles the user can drag "thumbs", but I'm going to refer to the collective `Slider` component as "sliders", and an individual circle as a "slider".
 * * The table effectively functions as free-form input, and so may contain errors. The sliders, on the other hand, always display valid data, though when errors are present, that data may be stale. (See below.)
 *
 * The minimum and maximum provided by the parent are calculated based on the highest and lowest possible points that can be arrived at via answering questions in the questionnaire.
 * Most of the time, the range for the sliders will correspond with these given values. But it is possible for the table and sliders to become out of sync. (See below.)
 *
 * The parent component is notified of new values only when those values are valid.
 * Values are considered invalid if they are not numbers, if they do not fall within the minimum and maximum, or if they are not in ascending order (e.g., the maximum value for "Low" is higher than the maximum value for "High")).
 * Whenever errors are present, the sliders and the table become out of sync with each other until the issues are resolved. An additional error indicator on the screen warns the user when this is the case.
 * * Errors can be resolved via the sliders or via the table. (Or even via changing the minimum and maximum, by changing answers' point values in the parent component.)
 * * Why the de-sync? Because while the table is "abstract", in the sense that the user can type anything there, the sliders are a visual represenation of the data. For example, we can't actually show the situation where all of the sliders have higher values than the maximum.
 * * While the sliders are out of sync with the table, they exist within their own universe. They operate within the min/max range that was most recently valid, and they can be dragged around freely.
 */
export const InherentRiskQuestionnareConfigurationThresholds = (props: InherentRiskQuestionnareConfigurationThresholdsProps): JSX.Element => {
    const { minimum: minimumValue, maximum: maximumValue, onNewValidValues: onChange } = props;

    const [tableState, setTableState] = useState<TableState>({ values: props.defaultValues.map((value) => value.toString()) });
    const [sliderState, setSliderState] = useState<SliderState>({ valuesWhileIdle: props.defaultValues, rangeMinimum: props.minimum, rangeMaximum: props.maximum });

    const sliderIsStale = tableState.errors !== undefined;
    const sliderValuesDiplayed = sliderState.valuesWhileDragging ?? sliderState.valuesWhileIdle;

    /**
     * This useEffect serves two purposes:
     * 1. To notify the parent component whenever the user has selected new thresholds, and those thresholds are valid.
     * 2. To recalculate errors whenever the parent component passes down new minimum or maximum values.
     */
    useEffect(() => {
        const errors = calculateTableErrors(tableState.values, minimumValue, maximumValue);
        setTableState({ values: tableState.values, errors });

        if (errors === undefined) {
            setSliderState({
                valuesWhileIdle: tableState.values.map((value) => Number(value)),
                rangeMinimum: minimumValue,
                rangeMaximum: maximumValue,
            });

            onChange(tableState.values.map((value) => Number(value)));
        }
    }, [minimumValue, maximumValue, tableState.values, onChange]);

    const handleTableInputChanged = (event: React.FormEvent<HTMLInputElement>, index: number) => {
        const values = [...tableState.values];
        values[index] = event.currentTarget.value;
        setTableState({ values, errors: tableState.errors });
    };

    /**
     * Whenever a slider is being dragged, `valuesWhileDragging` will be defined and used as the array of values to display on the screen.
     */
    const handleSliderDragged = (newValues: number[]) => setSliderState({ ...sliderState, valuesWhileDragging: newValues });

    /**
     * Whenver a slider being dragged is released, `valuesWhileDragging` will become undefined, and `valuesWhileIdle` will be used as the values to display on the screen.
     *
     * The table will be updated with the new sliders values only if doing so would resolve all current errors. There will be current errors only if they were previously introduced by input changing elsewhere on the page.
     * In other words, the sliders and table remain out of sync while there are errors, until input to one of them will produce an errorless state.
     */
    const handleSliderReleased = (newValues: number[]) => {
        const sliderWasDraggedOnTopOfAnother = new Set(newValues).size !== newValues.length;

        if (sliderWasDraggedOnTopOfAnother) {
            setSliderState({ ...sliderState, valuesWhileIdle: sliderState.valuesWhileIdle, valuesWhileDragging: undefined });
        } else {
            setSliderState({ ...sliderState, valuesWhileIdle: newValues });

            const potentialNewTableValues = newValues.map((value) => value.toString());
            const errors = calculateTableErrors(potentialNewTableValues, minimumValue, maximumValue);
            if (errors === undefined) {
                setTableState({ values: potentialNewTableValues });
            }
        }
    };

    /**
     * Calculates a gradient of colors to apply to the sliders, so that ranges are visually distinct and align with our standard colors for risk tiers.
     */
    const sliderStyles = (() => {
        const range = sliderState.rangeMaximum - sliderState.rangeMinimum;
        const percentages = sliderValuesDiplayed.map((value) => ((value - sliderState.rangeMinimum) / range) * 100);

        let gradient = `${COLORS[0]} 0% ${percentages[0]}%, `;

        for (let i = 0; i < percentages.length - 1; i++) {
            gradient += `${COLORS[i + 1]} ${percentages[i]}% ${percentages[i + 1]}%, `;
        }
        gradient += `${COLORS[percentages.length]} ${percentages[percentages.length - 1]}% 100%`;

        return `linear-gradient(to right, ${gradient})`;
    })();

    /**
     * The minimums displayed in the table are actually based on the slider values, because the slider values can never be invalid (within the context of their own rannge, which may differ from the range provided by the parent).
     * This can make the table a little confusing when there are errors (and thus, the sliders and table are out of sync), but that's the price to pay for always showing the sliders as a visual representation of data (even if that data is nonsensical).
     */
    const tableMinimums = [
        // Commenting just to force the linter to allow newlines.
        props.minimum,
        sliderState.valuesWhileIdle[0] + 1,
        sliderState.valuesWhileIdle[1] + 1,
        sliderState.valuesWhileIdle[2] + 1,
        sliderState.valuesWhileIdle[3] === props.maximum ? props.maximum : sliderState.valuesWhileIdle[3] + 1,
    ];

    return (
        <div className={styles.topSection}>
            <div className={styles.sliderAndTableContainer}>
                <div className={styles.sliderContainer} data-testid="thresholdsSelectorSlider">
                    <Slider
                        track={false}
                        value={sliderValuesDiplayed}
                        valueLabelDisplay="on"
                        onChange={(_event, newValues) => handleSliderDragged(newValues as number[])}
                        onChangeCommitted={(_event, newValues) => handleSliderReleased(newValues as number[])}
                        min={sliderState.rangeMinimum}
                        max={sliderState.rangeMaximum}
                        sx={{
                            '& .MuiSlider-rail': {
                                opacity: 1,
                                background: sliderStyles,
                            },
                            // Styling the thumbs via child index is kind of a hack, but it leverages the fact that the thumb elements appear predictably in the DOM. There doesn't seem to be another way to do this.
                            '& .MuiSlider-thumb': {
                                '&:nth-of-type(3)': {
                                    color: `${COLORS[0]} !important`,
                                },
                                '&:nth-of-type(4)': {
                                    color: `${COLORS[1]} !important`,
                                },
                                '&:nth-of-type(5)': {
                                    color: `${COLORS[2]} !important`,
                                },
                                '&:nth-of-type(6)': {
                                    color: `${COLORS[3]} !important`,
                                },
                                '&:nth-of-type(7)': {
                                    color: `${COLORS[4]} !important`,
                                },
                            },
                        }}
                    />
                </div>
                <div data-testid="thresholdsSelectorTable">
                    <Table>
                        <SortableTableHeader
                            headers={[
                                {
                                    dataKey: 'RATING',
                                    label: 'RATING',
                                    disableSort: true,
                                },
                                {
                                    dataKey: 'MINIMUM',
                                    label: 'MINIMUM POINTS',
                                    disableSort: true,
                                },
                                {
                                    dataKey: 'MAXIMUM',
                                    label: 'MAXIMUM POINTS',
                                    disableSort: true,
                                },
                            ]}
                            applySorting={() => void 0}
                            currentSort="RATING"
                            currentSortDirection={SortDirection.ASC}
                            // The error indicators for rows are used where an overflow menu normally would be.
                            tableIncludesOverflowMenu={true}
                        />
                        <TableBody>
                            {RISK_TIER_NAMES_WITHOUT_HIGHEST.map((name, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div className={styles.ratingLabelContainer}>
                                            <CircleIndicator variant={getRiskVariantColor(index)} />
                                            <label htmlFor={name}>
                                                <Text noStyles>{name}</Text>
                                            </label>
                                        </div>
                                    </TableCell>
                                    <TableCellDefaultText>
                                        <Text variant="Text3" noStyles>
                                            {tableMinimums[index]}
                                        </Text>
                                    </TableCellDefaultText>
                                    <TableCell>
                                        <FormFieldText formFieldId={name} value={tableState.values[index]} handleChange={(event: React.FormEvent<HTMLInputElement>) => handleTableInputChanged(event, index)} />
                                    </TableCell>
                                    <TableCell>{tableState.errors && tableState.errors[index] && <GenericTooltip text={tableState.errors[index]!} title={`${name} error`} fontAwesomeIcon={faX} />}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    <div className={styles.ratingLabelContainer}>
                                        <CircleIndicator variant={getRiskVariantColor(RISK_TIER_NAMES_WITHOUT_HIGHEST.length + 1)} />
                                        <Text noStyles>{RISK_TIER_NAME_HIGHEST}</Text>
                                    </div>
                                </TableCell>
                                <TableCellDefaultText>
                                    <Text variant="Text3" noStyles>
                                        {tableMinimums[4]}
                                    </Text>
                                </TableCellDefaultText>
                                <TableCellDefaultText>
                                    <div className={styles.maximumValue}>
                                        <Text variant="Text3" noStyles>
                                            {props.maximum}
                                        </Text>
                                    </div>
                                </TableCellDefaultText>
                                {/* An empty cell is used to align with the other rows, which have an extra cell for their error indicators. An error cannot occur in this row. */}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            {sliderIsStale && (
                <div className={styles.sliderErrorContainer}>
                    <GenericTooltip text="The sliders are not showing the latest table input because the table input is invalid." fontAwesomeIcon={faX} />
                </div>
            )}
        </div>
    );
};
