/**
 * "Third-Party Monitoring" is the name of the Metric that this component renders.
 * Multiple External Integrations may have a "Third-Party Monitoring" Metric, so this component is External Integration-agnostic.
 * "Third-Party Monitoring Metric values", "Third-Party Risk Scores", and "Third-Party Risk Ratings" are used interchangeably to refer to the same thing: multiple categories and corresponding risk ratings/risk scores for a specific Third Party.
 */

import { Fragment } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Carousel } from 'Components/Carousel/Carousel';
import { CarouselItem } from 'Components/Carousel/CarouselItem/CarouselItem';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { IntegrationName, TprmThirdPartyRiskScores, TprmThirdPartyRiskScoresCategory } from 'Models/ExternalIntegrations';

import styles from './ThirdPartyMonitoring.module.css';

export interface ThirdPartyMonitoringProps {
    thirdPartyRiskScores: TprmThirdPartyRiskScores[];
}

export const ThirdPartyMonitoring = (props: ThirdPartyMonitoringProps): JSX.Element => {
    const thirdPartyRiskScores = props.thirdPartyRiskScores;

    if (thirdPartyRiskScores.length === 1) {
        return (
            <Fragment>
                <ThirdPartyRiskScoringContent thirdPartyRiskScores={thirdPartyRiskScores[0]} />
            </Fragment>
        );
    } else {
        return (
            <Carousel>
                {thirdPartyRiskScores.map((thirdPartyRiskScoreItem: TprmThirdPartyRiskScores) => (
                    <CarouselItem key={thirdPartyRiskScoreItem.risk_scoring_service}>
                        <div className={styles.carouselButtonPadding}>
                            <ThirdPartyRiskScoringContent thirdPartyRiskScores={thirdPartyRiskScoreItem} />
                        </div>
                    </CarouselItem>
                ))}
            </Carousel>
        );
    }
};

/**
 * Format the component header depending on which Third-Party risk scoring service is being used. In particular, a service-specific hyperlink is created to allow users to easily access more details on the Third-Party scores.
 */
const ThirdPartyRiskScoringHeader = (thirdPartyRiskScores: TprmThirdPartyRiskScores): JSX.Element => {
    const analysis_timestamp = thirdPartyRiskScores.analysis_timestamp ? ' as of ' + iso8601ToUsDateLong(thirdPartyRiskScores.analysis_timestamp) : '';

    // Display this if we cannot link to the Third-Party risk scoring service for any reason.
    const headerWithoutHyperlink = (
        <Text color="white" noStyles={true} variant="Header3">
            {thirdPartyRiskScores.risk_scoring_service} overview of {thirdPartyRiskScores.vendor_name}
            {analysis_timestamp}
        </Text>
    );

    if (thirdPartyRiskScores.error_message || !thirdPartyRiskScores.hyperlink_path) {
        return headerWithoutHyperlink;
    }

    switch (thirdPartyRiskScores.risk_scoring_service) {
        case IntegrationName.BITSIGHT:
            return (
                <Link openInNewTab size="lg" to={`https://service.bitsighttech.com/app/spm/company/${thirdPartyRiskScores.hyperlink_path}/overview/`}>
                    {`${thirdPartyRiskScores.risk_scoring_service} overview of ${thirdPartyRiskScores.vendor_name}${analysis_timestamp}`}
                </Link>
            );
        case IntegrationName.RISKRECON:
            return (
                <Link openInNewTab size="lg" to={`https://portal.riskrecon.com/portal/third_party/${thirdPartyRiskScores.hyperlink_path}/overview`}>
                    {`${thirdPartyRiskScores.risk_scoring_service} overview of ${thirdPartyRiskScores.vendor_name}${analysis_timestamp}`}
                </Link>
            );
        case IntegrationName.SECURITYSCORECARD:
            return (
                <Link openInNewTab size="lg" to={`https://platform.securityscorecard.io/#/scorecard/${thirdPartyRiskScores.hyperlink_path}/factors`}>
                    {`${thirdPartyRiskScores.risk_scoring_service} overview of ${thirdPartyRiskScores.vendor_name}${analysis_timestamp}`}
                </Link>
            );
        default:
            return headerWithoutHyperlink;
    }
};

interface ThirdPartyRiskScoringContentProps {
    thirdPartyRiskScores: TprmThirdPartyRiskScores;
}
const ThirdPartyRiskScoringContent = (props: ThirdPartyRiskScoringContentProps): JSX.Element => {
    return (
        <Fragment>
            {ThirdPartyRiskScoringHeader(props.thirdPartyRiskScores)}
            <hr />
            {props.thirdPartyRiskScores.error_message && (
                <div className={styles.errorMessage}>
                    <Text color="white">{props.thirdPartyRiskScores.error_message}</Text>
                </div>
            )}
            {props.thirdPartyRiskScores.categories.length > 0 && (
                <div className={styles.riskScores}>
                    <div className={styles.overallScoreOutside}>
                        <div className={styles.overallScoreInside}>
                            <Text color="white" variant="Header3">
                                Overall Rating
                            </Text>
                            <div className={styles.ratingCenter}>
                                <div className={styles.ratingIndicator}>
                                    <CircleIndicator variant={getIndicatorVariant(props.thirdPartyRiskScores.overall_score, props.thirdPartyRiskScores.risk_scoring_service)} />
                                </div>
                                <Text color="white" noStyles={true} variant="Text1">
                                    {/* Show one decimal place for RiskRecon. Otherwise show no decimal places (integer). */}
                                    {props.thirdPartyRiskScores.risk_scoring_service === IntegrationName.RISKRECON ? props.thirdPartyRiskScores.overall_score.toFixed(1) : props.thirdPartyRiskScores.overall_score}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.categories}>
                        {props.thirdPartyRiskScores.categories.map((categoryItem: TprmThirdPartyRiskScoresCategory) => (
                            <div className={styles.category} key={categoryItem.label}>
                                <div className={styles.categoryName}>
                                    <Text color="white" noStyles={true} variant="Header4">
                                        {categoryItem.label}
                                    </Text>
                                </div>
                                <div className={styles.ratingIndicator}>
                                    <CircleIndicator variant={getIndicatorVariant(categoryItem.rating, props.thirdPartyRiskScores.risk_scoring_service)} />
                                </div>
                                <div className={styles.rating}>
                                    <Text color="white" noStyles={true} variant="Text1">
                                        {/* Show one decimal place for RiskRecon. Otherwise show no decimal places (integer). */}
                                        {props.thirdPartyRiskScores.risk_scoring_service === IntegrationName.RISKRECON ? categoryItem.rating.toFixed(1) : categoryItem.rating}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

/**
 * Determines the color that the icon should be, based on the rating and the rating service, since the various rating services have different scoring methods.
 */
const getIndicatorVariant = (rating: number, riskScoringService: IntegrationName): IndicatorVariant => {
    if (riskScoringService === IntegrationName.RISKRECON) {
        if (rating > 8 && rating <= 10) {
            return IndicatorVariant.DARK_GREEN;
        } else if (rating > 6 && rating <= 8) {
            return IndicatorVariant.LIGHT_GREEN;
        } else if (rating > 4 && rating <= 6) {
            return IndicatorVariant.YELLOW;
        } else if (rating > 2 && rating <= 4) {
            return IndicatorVariant.ORANGE;
        } else if (rating >= 0 && rating <= 2) {
            return IndicatorVariant.RED;
        } else {
            // Safeguard. This should never be reached.
            return IndicatorVariant.GRAY;
        }
    } else if (riskScoringService === IntegrationName.BITSIGHT) {
        if (rating > 770 && rating <= 900) {
            return IndicatorVariant.DARK_GREEN;
        } else if (rating > 640 && rating <= 770) {
            return IndicatorVariant.LIGHT_GREEN;
        } else if (rating > 510 && rating <= 640) {
            return IndicatorVariant.YELLOW;
        } else if (rating > 380 && rating <= 510) {
            return IndicatorVariant.ORANGE;
        } else if (rating >= 250 && rating <= 380) {
            return IndicatorVariant.RED;
        } else {
            // Safeguard. This should never be reached.
            return IndicatorVariant.GRAY;
        }
    } else if (riskScoringService === IntegrationName.SECURITYSCORECARD) {
        if (rating > 89 && rating <= 100) {
            return IndicatorVariant.DARK_GREEN;
        } else if (rating > 79 && rating <= 89) {
            return IndicatorVariant.LIGHT_GREEN;
        } else if (rating > 69 && rating <= 79) {
            return IndicatorVariant.YELLOW;
        } else if (rating > 59 && rating <= 69) {
            return IndicatorVariant.ORANGE;
        } else if (rating >= 0 && rating <= 59) {
            return IndicatorVariant.RED;
        } else {
            // Safeguard. This should never be reached.
            return IndicatorVariant.GRAY;
        }
    } else {
        // Safeguard. This should never be reached.
        return IndicatorVariant.GRAY;
    }
};
