export enum IndicatorVariant {
    RED = 'red',
    ORANGE = 'orange',
    YELLOW = 'yellow',
    LIGHT_GREEN = 'lightgreen',
    DARK_GREEN = 'darkgreen',
    BLUE = 'blue',
    GRAY = 'gray',
    LIGHTBLUE = 'lightblue',
}
