import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { Colors } from 'Components/Colors';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { AwsConfigIntegration, AwsConfigIntegrationSetupProgress, Metric, SetupProgressState } from 'Models/ExternalIntegrations';

import { AlertState } from './AwsConfigSetupWizard';
import { AwsConfigSetupWizardButtonRow } from './AwsConfigSetupWizardButtonRow';
import styles from '../ConfigureIntegration.module.css';

enum RequestState {
    NONE,
    SUBMITTING,
    SUBMITTED,
}

export interface AwsConfigSetupStepInitiateSetupProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    integration: AwsConfigIntegration;
    metrics: Metric[];
    progress: AwsConfigIntegrationSetupProgress;
    hideModal: () => void;
    refreshProgress: () => Promise<boolean>;
    setAlertState: (alertState: AlertState) => void;
    hasPreviousStep: boolean;
    hasNextStep: boolean;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
}

export const AwsConfigSetupStepInitiateSetup = (props: AwsConfigSetupStepInitiateSetupProps): JSX.Element => {
    const { refreshProgress, progress } = props;
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NONE);

    const setupInProgress = progress.role_template_state === SetupProgressState.IN_PROGRESS || progress.regional_rules_template_state === SetupProgressState.IN_PROGRESS || progress.global_rules_template_state === SetupProgressState.IN_PROGRESS;

    const performAutomatedSetup = async (): Promise<void> => {
        setRequestState(RequestState.SUBMITTING);
        props.setAlertState(undefined);

        try {
            await props.externalIntegrationsApi.performAwsConfigAutomatedSetup();

            setRequestState(RequestState.SUBMITTED);
            props.setAlertState({ variant: 'success', message: 'Setup started. Progress will be updated in real time below.' });

            // The API should return in-progress information at this point.
            // This kick-starts the process of periodic refreshes, which occur on a timer when setup is in progress.
            refreshProgress();
        } catch (err) {
            props.setAlertState({ variant: 'danger', message: `Setup could not be started. ${err.message}` });
        }
    };

    const progressInformationText = ((): JSX.Element | undefined => {
        if (setupInProgress) {
            return <Text>Setup is in progress.</Text>;
        }

        const roleFinished = progress.role_template_state === SetupProgressState.COMPLETED || progress.role_template_state === SetupProgressState.FAILED;
        const regionalFinished = progress.regional_rules_template_state === SetupProgressState.COMPLETED || progress.regional_rules_template_state === SetupProgressState.FAILED;
        const globalFinished = progress.global_rules_template_state === SetupProgressState.COMPLETED || progress.global_rules_template_state === SetupProgressState.FAILED;

        if (roleFinished && regionalFinished && globalFinished && progress.last_update_time) {
            const formattedTime = iso8601ToUsTimestampLong(progress.last_update_time);
            const failed = progress.role_template_state === SetupProgressState.FAILED || progress.regional_rules_template_state === SetupProgressState.FAILED || progress.global_rules_template_state === SetupProgressState.FAILED;

            return (
                <Text>
                    Setup {failed ? 'failed' : 'succeeded'} at {formattedTime}.
                </Text>
            );
        }

        return <Text>Setup has not been started.</Text>;
    })();

    const getProgressIndicator = (state: SetupProgressState): JSX.Element => {
        let color: Colors;
        let text: string;

        switch (state) {
            case SetupProgressState.NOT_STARTED:
                color = 'darkGray';
                text = 'NOT STARTED';
                break;
            case SetupProgressState.IN_PROGRESS:
                color = 'darkGray';
                text = 'IN PROGRESS';
                break;
            case SetupProgressState.FAILED:
                color = 'red';
                text = 'FAILED';
                break;
            case SetupProgressState.COMPLETED:
                color = 'darkGreen';
                text = 'SUCCEEDED';
                break;
        }

        return (
            <Text noStyles color={color}>
                {text}
            </Text>
        );
    };

    const ou_ids_count = props.integration.ou_ids.length;
    const ou_ids_noun = ou_ids_count === 1 ? 'organizational unit' : 'organizational units';

    const regions_count = props.integration.regions.length;
    const regions_noun = regions_count === 1 ? 'region' : 'regions';

    const metrics_count = props.metrics.filter((e) => e.enabled).length;
    const metrics_noun = metrics_count === 1 ? 'metric' : 'metrics';

    return (
        <>
            <Text>{`AWS resources will be deployed so that metrics can be gathered according to your configuration, which currently includes ${ou_ids_count} ${ou_ids_noun}, ${regions_count} ${regions_noun}, and ${metrics_count} enabled ${metrics_noun}. Ensure that this configuration is correct before beginning the automated setup. Note that metrics will not be gathered from your management account, even if it is part of the specified Organizational Unit(s).`}</Text>
            <div className={styles.progressInformation}>
                {progressInformationText}
                <div className={styles.progressRow}>
                    <div className={styles.automatedSetupStep}>
                        <Text noStyles>Set up read-only AWS Config access</Text>
                    </div>
                    {getProgressIndicator(progress.role_template_state)}
                </div>
                <div className={styles.progressRow}>
                    <div className={styles.automatedSetupStep}>
                        <Text noStyles>Set up regional AWS Config rules</Text>
                    </div>
                    {getProgressIndicator(progress.regional_rules_template_state)}
                </div>
                <div className={styles.progressRow}>
                    <div className={styles.automatedSetupStep}>
                        <Text noStyles>Set up global AWS Config rules</Text>
                    </div>
                    {getProgressIndicator(progress.global_rules_template_state)}
                </div>
            </div>
            <AwsConfigSetupWizardButtonRow {...props}>
                <div className={styles.buttonPadding}>
                    <Button variant="primary" onClick={performAutomatedSetup} fontAwesomeImage={faCogs} isLoading={requestState === RequestState.SUBMITTING || setupInProgress} loadingText="Setting Up">
                        SET UP
                    </Button>
                </div>
            </AwsConfigSetupWizardButtonRow>
        </>
    );
};
