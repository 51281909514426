import { faCalendar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableCellLink, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { RiskRating, Service, ServiceListingSortFilterOptions } from 'Models/TPRM';

import styles from './ServiceListingTableView.module.css';
import { TableRiskStatusBar } from './TableRiskStatusBar/TableRiskStatusBar';

export interface ServiceListingTableViewProps {
    thirdPartyServices: Service[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    selectedDeleteService: (service: Service) => void;
    selectedModifyService: (service: Service) => void;
    selectedServiceAssessmentDueDateService: (service: Service) => void;
    selectedSetInherentRiskQuestionnaireTargetCompletionDate: (service: Service) => void;
    currentSort: string;
    currentSortDirection: SortDirection;
}

export const ServiceListingTableView = (props: ServiceListingTableViewProps) => {
    const cachedData = useCachedData();

    const headerValues: HeaderData[] = [
        { dataKey: ServiceListingSortFilterOptions.VENDOR_NAME, label: 'THIRD PARTY' },
        { dataKey: ServiceListingSortFilterOptions.NAME, label: 'SERVICE' },
        { dataKey: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE, label: 'INHERENT RISK' },
        { dataKey: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE, label: 'RESIDUAL RISK' },
        { dataKey: ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID, label: 'THIRD-PARTY SERVICE MANAGER' },
        { dataKey: ServiceListingSortFilterOptions.DUE_DATE, label: 'ASSESSMENT DUE DATE' },
        { dataKey: ServiceListingSortFilterOptions.CREATED_TIME, label: 'DATE CREATED' },
    ];

    const tableRow = (thirdPartyService: Service, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Update Service',
                    onClickAction: () => props.selectedModifyService(props.thirdPartyServices[index]),
                    icon: faEdit,
                },
                {
                    text: 'Set Assessment Due Date',
                    onClickAction: () => props.selectedServiceAssessmentDueDateService(props.thirdPartyServices[index]),
                    icon: faCalendar,
                },
                {
                    text: 'Set Inherent Risk Questionnaire Target Completion Date',
                    onClickAction: () => props.selectedSetInherentRiskQuestionnaireTargetCompletionDate(props.thirdPartyServices[index]),
                    icon: faCalendar,
                },
                {
                    text: 'Delete Service',
                    onClickAction: () => props.selectedDeleteService(props.thirdPartyServices[index]),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${thirdPartyService.name} Menu`,
        };

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{thirdPartyService.vendor_name}</Text>
                </TableCellDefaultText>
                <TableCellLink link={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyService.vendor_id}/${SERVICES}/${thirdPartyService.id}/${DASHBOARDS}`}>{thirdPartyService.name}</TableCellLink>
                <TableCell>
                    <TableRiskStatusBar risk={thirdPartyService.inherent_risk_score ? thirdPartyService.inherent_risk_score : RiskRating.INACTIVE} />
                </TableCell>
                <TableCell>
                    <TableRiskStatusBar risk={thirdPartyService.residual_risk_score ? thirdPartyService.residual_risk_score : RiskRating.INACTIVE} />
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(thirdPartyService.vendor_service_manager_user_id, cachedData.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{thirdPartyService.assessment_workflow_setup.due_date ? iso8601ToUsDateShort(thirdPartyService.assessment_workflow_setup.due_date) : undefined}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateShort(thirdPartyService.created_time)}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: props.applySorting,
        currentSort: props.currentSort,
        currentSortDirection: props.currentSortDirection,
        tableIncludesOverflowMenu: true,
    };

    return (
        <div className={styles.riskTable}>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{props.thirdPartyServices.map(tableRow)}</TableBody>
            </Table>
        </div>
    );
};
