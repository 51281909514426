import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';

import { ThirdPartyExceptionResponse } from './Exceptions';
import { FileToBeUploaded, FileUpdates, UploadedFile } from './Files';
import { ThirdPartyIssueResponse } from './Issues';
import { ControlText, Effectiveness } from './OperationalControls';
import { ScheduleFrequency, ScheduleFrequencyKeys } from './ScheduleFrequency';
import { GroupOptionType, OptionType } from './Types/GlobalType';

// TODO TPRM: Investigate whether it still makes sense to have an INACTIVE rating. Absent inherent risk and residual risk are now `undefined`, rather than `0`.
export enum RiskRating {
    INACTIVE = 0,
    LOW,
    LOW_MODERATE,
    MODERATE,
    MODERATE_HIGH,
    HIGH,
}

/**
 * Allows the user to set the IRQ target completion date when starting OR restarting the assessment workflow.
 */
export interface StartAssessmentWorkflowRequest {
    irq_target_completion_date?: string;
}

/**
 Each field represents the maximum value for the range corresponding to an inherent risk score.
The end ranges ("low" and "high") can be known only within the context of the points assigned to answers: the lowest possible score for an IRQ is "low"'s minimum value; the highest possible score for an IRQ is "high"'s maximum value.

For example, if the values on this object are...
    low: 10
    low_moderate: 20
    moderate: 30
    moderate_high: 40

...and the lowest possible IRQ score is -10, and the highest possible IRQ score is 50...

...then the ranges for inherent risk scores are as follows:
    low: -10 to 10
    low_moderate: 11 to 20
    moderate: 21 to 30
    moderate_high: 31 to 40
    high: 41 to 50
 */
export interface RiskRatingPointThresholds {
    low: number;
    low_moderate: number;
    moderate: number;
    moderate_high: number;
}

export interface InherentRiskQuestionnaireSection {
    is_applicable: boolean;
    name: string;
    questions: InherentRiskQuestionnaireQuestion[];
    files: UploadedFile[];
    text?: string;
}

export interface InherentRiskQuestionnaireQuestion {
    text: string;
    answers: InherentRiskQuestionnaireAnswer[];
    selected_answer_index?: number;
}

export interface InherentRiskQuestionnaireAnswer {
    text: string;
    points: number;
}

export interface InherentRiskQuestionnaireSectionConfiguration {
    name: string;
    questions: InherentRiskQuestionnaireQuestionConfiguration[];
}

export interface InherentRiskQuestionnaireQuestionConfiguration {
    text: string;
    answers: InherentRiskQuestionnaireAnswerConfiguration[];
}

export interface InherentRiskQuestionnaireAnswerConfiguration {
    text: string;
    points: number;
}

export interface InherentRiskQuestionnaireResponse {
    third_party_id: string;
    service_id: string;
    has_been_submitted: boolean;
    thresholds: RiskRatingPointThresholds;
    sections: InherentRiskQuestionnaireSection[];
    inherent_risk_score?: 1 | 2 | 3 | 4 | 5;
}

export interface InherentRiskQuestionnaireSectionSelections {
    is_applicable: boolean;
    file_updates: FileUpdates;
    answers?: (number | null)[];
    text?: string;
}

export interface SaveOrSubmitInherentRiskQuestionnaireRequest {
    submit: boolean;
    inherent_risk_score?: number;
    section_selections: InherentRiskQuestionnaireSectionSelections[];
    irq_target_completion_date?: string;
    third_party_contacts: ThirdPartyContact[];
}

export interface InherentRiskQuestionnaireConfiguration {
    thresholds: RiskRatingPointThresholds;
    sections: InherentRiskQuestionnaireSectionConfiguration[];
}

export interface InherentRiskQuestionnaireProgress {
    numberOfQuestions: number;
    numberOfQuestionsAnswered: number;
}

/**
 * Contact information for Third-Party personnel. Used for both Third Party and Third-Party Service contact information.
 */
export interface ThirdPartyContact {
    name?: string;
    email_address?: string;
    phone_number?: string;
    additional_information?: string;
}

export interface SaveThirdPartyRequest {
    name?: string;
    ein?: string;
    country?: string;
    vendor_manager_user_id?: string; // TODO: Due to the ThirdPartyResponse building off ThirdParty, the attributes need to line up until the migration
    vendor_contacts?: ThirdPartyContact[]; // TODO: See above
    state?: string;
    city?: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
    [key: string]: any;
}

export interface ThirdParty {
    id: string;
    name: string;
    country: string;
    vendor_manager_user_id: string;
    created_time: string;
    vendor_contacts: ThirdPartyContact[];
    state?: string;
    city?: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
    ein?: string;
    website?: string;
}

export interface ThirdPartyResponse extends ThirdParty {
    issues: ThirdPartyIssueResponse[];
    exceptions: ThirdPartyExceptionResponse[];
    [key: string]: any;
}

// TODO: Can we consolidate this with ThirdPartyResponse?
export interface ThirdPartyResponseWithServices extends ThirdPartyResponse {
    services: Service[];
    [key: string]: any;
}

export type ThirdPartyIdToNameMap = Map<string, string>;

/**
 * Used to create or update a Third-Party Service.
 * IMPORTANT: If a new property is added to this interface, be sure to include it EVERYWHERE the request is built. Inadvertently omitting a property will lead to a bug where saving the service will clear the value of the omitted property.
 * TODO: Use a pattern that will avoid the landmine described above.
 */
export interface SaveThirdPartyServiceRequest {
    name: string;
    description: string;
    vendor_service_manager_user_id: string; // TODO: Due to the ThirdPartyResponse building off ThirdParty, the attributes need to line up until the migration
    delegates?: string[];
    vendor_contacts?: ThirdPartyContact[]; // TODO: See above
    assessment_due_date?: string | null; // Sending `null` removes the due date.
    irq_target_completion_date?: string; // Sending `undefined` removes the target date.
    responsible_organization?: string;
}

export interface UpdateServiceAssessmentRequest {
    is_submit: boolean;
    file_updates: FileUpdates;
    new_assessment_documents?: FileToBeUploaded[];
    assessment_control_effectiveness?: Effectiveness;
    assessment_residual_risk_score?: number;
    assessment_additional_information?: string;
    new_assessment_due_date?: string;
}

export interface UpdateCommonAssessmentRequest {
    parent_identifier: ServiceIdentifier;
    child_identifier: ServiceIdentifier;
    linked: boolean;
}

/**
 * AWAITING_ASSESSMENT -> EVALUATING_INHERENT_RISK when the user clicks the button in the UI to start a new assessment workflow. At this time, a new IRQ is generated (answers are preserved where possible).
 * EVALUATING_INHERENT_RISK -> PERFORMING_DUE_DILIGENCE when the user submits the IRQ. At this time, a new DDQ is generated (answers are preserved where possible).
 * PERFORMING_DUE_DILIGENCE -> AWAITING_ASSESSMENT when the user submits the Final Review. At this time, the service / its workflow are copied into an "archive" object to be shown in assessment reports.
 */
export enum ServiceAssessmentState {
    AWAITING_ASSESSMENT = 'Awaiting Assessment',
    EVALUATING_INHERENT_RISK = 'Evaluating Inherent Risk',
    PERFORMING_DUE_DILIGENCE = 'Performing Due Diligence',
}

export interface AssessmentWorkflowData {
    state: ServiceAssessmentState;

    irq_completed_time?: string;
    irq_inherent_risk_score?: number;
    irq_started_time?: string;

    final_review_additional_information?: string;
    final_review_control_effectiveness?: number;
    final_review_documents: UploadedFile[];
    final_review_residual_risk_score?: number;
}

export interface AssessmentWorkflowSetup {
    common_assessment_children: ServiceSummary[];
    common_assessment_parent?: ServiceSummary;
    irq_target_completion_date?: string;
    due_date?: string;
    schedule?: Schedule;
}

export interface ServiceSummary {
    id: string;
    vendor_id: string;
    name: string;
}

// DANGER: This interface is used incorrectly by `DueDiligenceQuestionnaire`. If you change _anything_ on this interface, you _might_ break the DDQ. (Specifically, the third party's usage of the DDQ. The client's usage of the Control Assessment will probably be okay). If you're unlucky enough to need to change something here, compare this with `PublicService` and look at how `DueDiligenceQuestionnaire` might be affected.
export interface Service {
    id: string;
    vendor_id: string;
    name: string;
    vendor_name: string;
    description: string;
    vendor_service_manager_user_id: string;
    created_time: string;
    delegates: string[]; // List of optional user IDs to receive Notifications in addition to the Vendor Service Manager.
    vendor_contacts: ThirdPartyContact[]; // This property is called "vendor_contacts" because of the `ThirdPartyContact` interface, but note that these are the contacts for the _service_. This is not the same data as `ThirdParty.vendor_contacts`.
    responsible_organization?: string;

    assessment_workflow_data: AssessmentWorkflowData;
    assessment_workflow_setup: AssessmentWorkflowSetup;

    last_assessed?: string;
    inherent_risk_score?: number;
    control_effectiveness?: number;
    residual_risk_score?: number;
}

export interface ServiceIdentifier {
    vendor_id: string;
    service_id: string;
}

export interface SetInherentRiskEvidenceRequest {
    inherent_risk_score: number;
    third_party_contacts: ThirdPartyContact[]; // A list of Third-Party Service contacts that will be notified about the Due Diligence Questionnaire (DDQ).
    file_updates: FileUpdates;
    text?: string;
    data_classification?: DataClassification;
    customer_details_annual_volume?: CustomerDetailsAnnualVolume;
    data_storage_location?: DataStorageLocation;
}

export interface InherentRiskEvidenceResponse {
    third_party_id: string;
    service_id: string;
    files: UploadedFile[];
    text?: string;
    data_classification?: DataClassification;
    customer_details_annual_volume?: CustomerDetailsAnnualVolume;
    data_storage_location?: DataStorageLocation;
}

export enum DataClassification {
    RESTRICTED = 'RESTRICTED',
    CONFIDENTIAL = 'CONFIDENTIAL',
    INTERNAL = 'INTERNAL',
    PUBLIC = 'PUBLIC',
}

export enum CustomerDetailsAnnualVolume {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export enum DataStorageLocation {
    ON_PREMISES = 'ON_PREMISES',
    ON_PREMISES_REMOTE_ACCESS = 'ON_PREMISES_REMOTE_ACCESS',
    ONSHORE = 'ONSHORE',
    ONSHORE_CLOUD_HOSTED = 'ONSHORE_CLOUD_HOSTED',
    OFFSHORE = 'OFFSHORE',
}

export const numberAsRiskScore = (riskScore: number): RiskRating => {
    if (riskScore === 0) {
        return RiskRating.INACTIVE;
    } else if (riskScore >= 1 && riskScore < 2) {
        return RiskRating.LOW;
    } else if (riskScore >= 2 && riskScore < 3) {
        return RiskRating.LOW_MODERATE;
    } else if (riskScore >= 3 && riskScore < 4) {
        return RiskRating.MODERATE;
    } else if (riskScore >= 4 && riskScore < 5) {
        return RiskRating.MODERATE_HIGH;
    } else if (riskScore === 5) {
        return RiskRating.HIGH;
    }

    return RiskRating.INACTIVE;
};

/**
 * This calculates what the risk score High Peaks recommends based on the answers tp the questions provided. The calculation comes from a rolling total. There are 5 possible risk values. That is divided by the 3 questions (hence every calculation begins with 5/3). Each question can has multiple answers. The lowest answers does not add a risk value. All other answers increment the riskiness of the selected answer. (ex. If there are 3 possible answers then the increment values are 0, .83333 and 1.6666 )
 * Choosing Public data for DataClassification will always return a low risk score.
 * @param dataClassification - value of data classification question
 * @param customerDetailsAnnualVolume  - value of customer details annual volume question
 * @param dataStorageLocation - value of data storage question
 */

export const calculateRiskScore = (dataClassification?: DataClassification, customerDetailsAnnualVolume?: CustomerDetailsAnnualVolume, dataStorageLocation?: DataStorageLocation): number => {
    let dataClassificationValue = 0;
    switch (dataClassification) {
        case DataClassification.PUBLIC:
            return RiskRating.LOW;
        case DataClassification.INTERNAL:
            dataClassificationValue = 5 / 3 / 3;
            break;
        case DataClassification.CONFIDENTIAL:
            dataClassificationValue = (5 / 3 / 3) * 2;
            break;
        case DataClassification.RESTRICTED:
            dataClassificationValue = 5 / 3;
            break;
    }
    let customerDetailsAnnualVolumeValue = 0;
    switch (customerDetailsAnnualVolume) {
        case CustomerDetailsAnnualVolume.LOW:
            customerDetailsAnnualVolumeValue = 0;
            break;
        case CustomerDetailsAnnualVolume.MEDIUM:
            customerDetailsAnnualVolumeValue = 5 / 3 / 2;
            break;
        case CustomerDetailsAnnualVolume.HIGH:
            customerDetailsAnnualVolumeValue = 5 / 3;
            break;
    }
    let dataStorageLocationValue = 0;
    switch (dataStorageLocation) {
        case DataStorageLocation.ON_PREMISES:
            dataStorageLocationValue = 0;
            break;
        case DataStorageLocation.ON_PREMISES_REMOTE_ACCESS:
            dataStorageLocationValue = 5 / 3 / 4;
            break;
        case DataStorageLocation.ONSHORE:
            dataStorageLocationValue = (5 / 3 / 4) * 2;
            break;
        case DataStorageLocation.ONSHORE_CLOUD_HOSTED:
            dataStorageLocationValue = (5 / 3 / 4) * 3;
            break;
        case DataStorageLocation.OFFSHORE:
            dataStorageLocationValue = 5 / 3;
            break;
    }

    const riskScore = dataClassificationValue + customerDetailsAnnualVolumeValue + dataStorageLocationValue;

    // This is different than the "NumberAsRiskScore" because 0-1 needs to equal low instead of inactive.
    if (riskScore >= 0 && riskScore < 1) {
        return RiskRating.LOW;
    } else if (riskScore >= 1 && riskScore < 2) {
        return RiskRating.LOW_MODERATE;
    } else if (riskScore >= 2 && riskScore < 3) {
        return RiskRating.MODERATE;
    } else if (riskScore >= 3 && riskScore < 4) {
        return RiskRating.MODERATE_HIGH;
    } else if (riskScore >= 4 && riskScore < 5) {
        return RiskRating.HIGH;
    } else {
        return riskScore;
    }
};

export const riskRatingAsString = (riskRating: RiskRating): string => {
    switch (riskRating) {
        case RiskRating.INACTIVE:
            return 'Inactive';
        case RiskRating.HIGH:
            return 'High';
        case RiskRating.MODERATE_HIGH:
            return 'Moderate/High';
        case RiskRating.MODERATE:
            return 'Moderate';
        case RiskRating.LOW_MODERATE:
            return 'Low/Moderate';
        case RiskRating.LOW:
            return 'Low';
    }
};

export const numberAsRiskRatingString = (riskRating: number): string => {
    return riskRatingAsString(numberAsRiskScore(riskRating));
};

export const riskRatingLabelAsNumber = (riskRatingLabel: string) => {
    switch (riskRatingLabel) {
        case 'Inactive':
            return RiskRating.INACTIVE;
        case 'High':
            return RiskRating.HIGH;
        case 'Moderate/High':
            return RiskRating.MODERATE_HIGH;
        case 'Moderate':
            return RiskRating.MODERATE;
        case 'Low/Moderate':
            return RiskRating.LOW_MODERATE;
        case 'Low':
            return RiskRating.LOW;
    }
};

export enum ServiceListingSortFilterOptions {
    VENDOR_NAME = 'vendor_name',
    VENDOR_ID = 'vendor_id',
    INHERENT_RISK_SCORE = 'inherent_risk_score',
    RESIDUAL_RISK_SCORE = 'residual_risk_score',
    NAME = 'name',
    VENDOR_SERVICE_MANAGER_USER_ID = 'vendor_service_manager_user_id',
    CREATED_TIME = 'created_time',
    DUE_DATE = 'due_date',
}

export enum ThirdPartyListingSortProperty {
    NAME = 'name',
    CREATED_TIME = 'created_time',
    THIRD_PARTY_MANAGER_USER_ID = 'third_party_manager_user_id',
    WEBSITE = 'website',
}

export const InherentRiskFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: Inactive',
        value: RiskRating.INACTIVE,
    },
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: High',
        value: RiskRating.HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: Moderate/High',
        value: RiskRating.MODERATE_HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: Moderate',
        value: RiskRating.MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: Low/Moderate',
        value: RiskRating.LOW_MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
        label: 'Inherent Risk: Low',
        value: RiskRating.LOW,
    },
];

export const ResidualRiskFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: Inactive',
        value: RiskRating.INACTIVE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: High',
        value: RiskRating.HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: Moderate/High',
        value: RiskRating.MODERATE_HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: Moderate',
        value: RiskRating.MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: Low/Moderate',
        value: RiskRating.LOW_MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
        label: 'Residual Risk: Low',
        value: RiskRating.LOW,
    },
];

export const RiskRatingSelectOptions: OptionType[] = [
    {
        label: 'Low',
        value: RiskRating.LOW,
    },
    {
        label: 'Low/Moderate',
        value: RiskRating.LOW_MODERATE,
    },
    {
        label: 'Moderate',
        value: RiskRating.MODERATE,
    },
    {
        label: 'Moderate/High',
        value: RiskRating.MODERATE_HIGH,
    },
    {
        label: 'High',
        value: RiskRating.HIGH,
    },
];

export const getRiskRatingVariantColor = (riskRating: RiskRating): IndicatorVariant => {
    switch (riskRating) {
        case RiskRating.INACTIVE:
            return IndicatorVariant.GRAY;
        case RiskRating.LOW:
            return IndicatorVariant.DARK_GREEN;
        case RiskRating.LOW_MODERATE:
            return IndicatorVariant.LIGHT_GREEN;
        case RiskRating.MODERATE:
            return IndicatorVariant.YELLOW;
        case RiskRating.MODERATE_HIGH:
            return IndicatorVariant.ORANGE;
        case RiskRating.HIGH:
            return IndicatorVariant.RED;
    }
};

// Questionnaire Configuration
export interface QuestionnaireConfigurationResponse {
    control_frameworks: ControlFrameworkConfiguration[];
}
export interface ControlFrameworkConfiguration {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ControlGroupConfiguration[];
    is_custom: boolean;
}

export interface ControlGroupConfiguration {
    control_framework: string;
    control_group_id: string;
    controls: ControlConfiguration[];
    control_group_name: string;
    control_group_description?: string;
    is_custom: boolean;
}

export interface ControlConfiguration {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    control_text: ControlText[];
    questions: QuestionConfiguration[];
    control_name?: string;
    is_custom: boolean;
}

export interface QuestionConfiguration {
    _type: QuestionType;
    text: string;
    mapped_risk_ratings: RiskRating[];
    options?: string[];
}

export enum QuestionType {
    FREEFORM = 'FREEFORM',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
}

export interface CreateControlConfigurationRequest {
    control_text: ControlText[];
    control_name: string;
}

export interface UpdateControlConfigurationRequest {
    control_text?: ControlText[];
    control_name?: string;
    questions?: QuestionConfiguration[];
}

export interface CreateControlGroupConfigurationRequest {
    control_group_name: string;
    control_group_description: string;
}

export interface UpdateControlGroupConfigurationRequest {
    control_group_name?: string;
    control_group_description?: string;
}

export interface CreateControlFrameworkConfigurationRequest {
    control_framework_name: string;
}

export interface UpdateControlFrameworkConfigurationRequest {
    control_framework_name: string;
}

export type UpdateConfigurationRequest = UpdateControlFrameworkConfigurationRequest | UpdateControlGroupConfigurationRequest | UpdateControlConfigurationRequest;

export const identifierMappedToRequest = (request: UpdateConfigurationRequest, framework: string, groupId?: string, controlId?: string): [string, UpdateConfigurationRequest] => {
    return [getOperationalControlIdentifierString(framework, groupId, controlId), request];
};

// Questionnaire Instance
export interface QuestionnaireGroupControlsResponse {
    controls: ControlInstanceResponse[];
}

export interface ControlFrameworkInstanceResponse {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ControlGroupInstanceResponse[];
    control_assessments_completed: number;
    questions_completed: number;
    is_custom: boolean;
}

export interface ControlGroupInstanceResponse {
    control_framework: string;
    control_group_id: string;
    control_group_name: string;
    number_of_controls: number;
    control_assessments_completed: number;
    number_of_questions: number;
    questions_completed: number;
    control_group_description?: string;
    is_custom: boolean;
}

export interface ControlInstanceResponse {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    control_group_name: string;
    control_text: ControlText[];
    questions: QuestionTypes[];
    is_custom: boolean;
    control_name?: string;
    control_assessment_effectiveness?: Effectiveness;
    control_assessment_comment?: string;
    control_assessment_documents: UploadedFile[];
}

export interface QuestionInstance {
    id: string;
    control_identifier: string;
    text: string;
    answer_text?: string;
    answer_documents: UploadedFile[];
}

export interface SingleSelectQuestionInstance extends QuestionInstance {
    _type: QuestionType.SINGLE_SELECT;
    options?: string[];
    answer_index?: number;
}

export interface MultipleSelectQuestionInstance extends QuestionInstance {
    _type: QuestionType.MULTIPLE_SELECT;
    options?: string[];
    answer_indexes?: number[];
}

export interface UpdateQuestionRequest {
    _type: QuestionType;
    id: string;
    control_identifier: string;
    answer_text?: string;
    answer_documents: UploadedFile[];
    answerIndex?: number | number[];
}

export interface FreeformQuestionInstance extends QuestionInstance {
    _type: QuestionType.FREEFORM;
}

export interface DocumentUploadQuestionInstance extends QuestionInstance {
    _type: QuestionType.DOCUMENT_UPLOAD;
}

export type QuestionTypes = FreeformQuestionInstance | SingleSelectQuestionInstance | MultipleSelectQuestionInstance | DocumentUploadQuestionInstance;

export type Answer = string | number | number[] | undefined;

export interface UpdateControlInstanceRequest {
    updates?: Partial<ControlInstanceResponse>;
    new_documentation?: FileToBeUploaded[];
}

export interface QuestionUpdates {
    updates?: Partial<QuestionTypes>;
    new_documentation?: FileToBeUploaded[];
}

export interface UpdateControlInstanceQuestionsRequest {
    questions_updates?: { [key: string]: QuestionUpdates };
}

export interface QuestionnaireAggregationResponse {
    number_of_controls: number;
    number_of_questions: number;
    number_of_control_assessments_completed: number;
    number_of_questions_completed: number;
    control_effectiveness_summary: {
        '0': number;
        '1': number;
        '2': number;
        '3': number;
        '4': number;
        '5': number;
    };
    due_diligence_started_time?: string;
    control_assessment_started_time?: string;
}

// Assessment report
export interface QuestionnaireReport {
    average_control_effectiveness: number;
    control_frameworks: ReportControlFrameworkResponse[];
}

export interface ReportControlFrameworkResponse {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ReportControlGroupResponse[];
    control_assessments_completed: number;
    questions_completed: number;
    control_framework_effectiveness: number;
    is_custom: boolean;
}
export interface ReportControlGroupResponse {
    control_framework: string;
    control_group_id: string;
    control_group_name: string;
    number_of_controls: number;
    control_assessments_completed: number;
    number_of_questions: number;
    questions_completed: number;
    control_group_description?: string;
    controls: ControlInstanceResponse[];
    control_group_effectiveness: number;
    is_custom: boolean;
}

export const thirdPartyNameComparator = (thirdPartyIdA: string, thirdPartyIdB: string, thirdPartyIdToNameMap: Map<string, string>): number => {
    const thirdPartyNameA = thirdPartyIdToNameMap.get(thirdPartyIdA)!;
    const thirdPartyNameB = thirdPartyIdToNameMap.get(thirdPartyIdB)!;
    return thirdPartyNameA.localeCompare(thirdPartyNameB);
};

export interface PublicDDQRegistrationRequest {
    email_address: string;
    password: string;
    password_confirm: string;
}

/**
 * DEPRECATED. Don't use this anymore. Mixing and matching letter case is confusing. Use ScheduleV2 instead.
 */
export type Schedule = {
    schedule_number: number;
    schedule_frequency: ScheduleFrequencyKeys;
};

// Rename this (remove the V2) once Schedule is eliminated.
export type ScheduleV2 = {
    schedule_number: number;
    schedule_frequency: ScheduleFrequency;
};

export type ServiceAssessmentSchedule = {
    low?: Schedule;
    low_moderate?: Schedule;
    moderate?: Schedule;
    moderate_high?: Schedule;
    high?: Schedule;
};

export interface RiskWorkflowArchiveMetadata {
    timestamp: string;
    assessment_submitter_id: string;
}

export interface RiskWorkflowArchive {
    control_instances: AssessmentControlInstances[];
    inherent_risk_evidence: InherentRiskEvidenceResponse;
    questionnaire_aggregation: RiskWorkflowArchiveQuestionnaireAggregation;
    service: Service;
    metadata: RiskWorkflowArchiveMetadata;
    vendor: ThirdPartyResponse;
}

export interface AssessmentControlInstances {
    control_identifier: string;
    control_assessment_documents: UploadedFile[];
    control_text: ControlText[];
    is_custom: boolean;
    questions: QuestionInstance[];
    control_assessment_effectiveness?: number;
}

export interface RiskWorkflowArchiveQuestionnaireAggregation extends QuestionnaireAggregationResponse {
    control_frameworks: { [key: string]: RiskWorkflowArchiveFramework };
    control_groups: { [key: string]: RiskWorkflowArchiveControlGroup };
}

export interface RiskWorkflowArchiveFramework {
    control_identifier: string;
    is_custom: boolean;
    control_framework_name: string;
    control_framework_effectiveness: number;
}

export interface RiskWorkflowArchiveControlGroup {
    control_identifier: string;
    control_group_name: string;
    control_group_effectiveness: number;
    is_custom: boolean;
}

export enum ReportListingTableFilterOptions {
    DATE_CREATED = 'created_time',
    INHERENT_RISK_SCORE = 'inherent_risk_score',
    RESIDUAL_RISK_SCORE = 'residual_risk_score',
    CONTROL_EFFECTIVENESS = 'control_effectiveness',
    REPORT_TITLE = 'reportTitle',
}

export const ControlEffectivenessFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Fail',
        value: Effectiveness.FAIL,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Weak',
        value: Effectiveness.WEAK,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Moderate',
        value: Effectiveness.MODERATE,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Strong',
        value: Effectiveness.STRONG,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Robust',
        value: Effectiveness.ROBUST,
    },
];

export interface FolderTypes {
    type_set: string[];
}
export interface CreateNewFolderRequest {
    name: string;
    type: string;
    third_party_manager_notifications_enabled: boolean;
    effective_date?: string;
    expiration_date?: string;
    comments?: string;
    files?: FileToBeUploaded[];
}

export interface FolderVersion {
    id: string;
    name: string;
    type: string;
    vendor_manager_notifications_enabled: boolean;
    created_time: string;
    last_updated: string;
    last_updated_by: string;
    effective_date?: string;
    expiration_date?: string;
    comments?: string;
    files?: UploadedFile[];
    [key: string]: any;
}

export interface FolderAllVersions {
    current_version: FolderVersion;
    past_versions: FolderVersion[];
}

/**
 * Used to (re-)send the DDQ email to the Third-Party Service contact(s).
 * Results in existing Third-Party Service contact credentials being deleted, forcing them to re-register if they already have.
 * Can optionally clear all existing progress on the DDQ and Control Assessment.
 */
export interface ResetAndNotifyThirdPartyRequest {
    reset_ddq: boolean;
    third_party_contacts: ThirdPartyContact[]; // A list of Third-Party Service contacts that will be notified about the Due Diligence Questionnaire (DDQ).
}
