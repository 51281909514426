import moment from 'moment';

/**
 * Convert a JavaScript Date object to an ISO 8601 string (YYYY-MM-DDTHH:MM:SS+##:##).
 * This is used to pass timestamps to the back-end API.
 * The time zone offset of the result is the user's (browser/OS/environment) local time zone, which may be different from the time zone of the object provided as input.
 * The back-end API converts local time to UTC before writing to the database, so responses from the back-end API will be in UTC.
 */
export const jsDateToIso8601 = (timestamp: Date): string => {
    const isoTimestamp = moment(timestamp).format();

    return isoTimestamp;
};

/**
 * Convert an ISO 8601 string (YYYY-MM-DDTHH:MM:SS+##:##) to a JavaScript Date object.
 * The time zone of the resulting Date object will be the user's (browser/OS/environment) local time zone, which may be different from the time zone offset of the timestamp provided as input.
 */
export const iso8601ToJsDate = (iso8601: string): Date => {
    return moment(iso8601).toDate();
};

/**
 * Convert an ISO 8601 timestamp (expected to be YYYY-MM-DDTHH:MM:SS+##:##, but could probably be any moment.js-parseable string) to US-format shorthand datestamp (MM/DD/YY).
 */
export const iso8601ToUsDateShort = (iso8601: string): string => {
    const momentObject = moment(iso8601);
    const usDatestamp = momentObject.format('MM/DD/YY');

    return usDatestamp;
};

/**
 * Convert an ISO 8601 timestamp (expected to be YYYY-MM-DDTHH:MM:SS+##:##, but could probably be any moment.js-parseable string) to US-format longhand datestamp (MM/DD/YYY).
 */
export const iso8601ToUsDateLong = (iso8601: string): string => {
    const momentObject = moment(iso8601);
    const usDatestamp = momentObject.format('MM/DD/YYYY');

    return usDatestamp;
};

/**
 * Convert an ISO 8601 timestamp (expected to be YYYY-MM-DDTHH:MM:SS+##:##, but could probably be any moment.js-parseable string) to US-format longhand timestamp (MM/DD/YYYY H:MM AM|PM).
 */
export const iso8601ToUsTimestampLong = (iso8601: string): string => {
    const momentObject = moment(iso8601);
    const usDatestamp = momentObject.format('MM/DD/YYYY h:mm A');

    return usDatestamp;
};
