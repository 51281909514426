import { useState } from 'react';

import { useCachedData } from 'Components/Context/CachedDataContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody } from 'Components/Table/Table/Table';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { Action, ActionStatus } from 'Models/Actions';
import { DisplayableTag } from 'Models/Tags';

import { ActionsTableRow, ActionsTableRowProps } from './ActionsTableRow/ActionsTableRow';

export interface ActionsTableProps {
    actions: Action[];
    showModal: (action: Action) => void;
    getDisplayableTags: (tagIds: string[]) => DisplayableTag[];
}

interface ActionsTableState {
    currentSort: string;
    currentSortDirection: SortDirection;
}

enum ActionsSortFilterOptions {
    TITLE = 'title',
    STATUS = 'status',
    OWNER = 'owner',
    MAPPED_RISK = 'mapped_risk',
    DUE_DATE = 'due_date',
    TAGS = 'tags',
}

export const ActionsTable = (props: ActionsTableProps) => {
    const cachedData = useCachedData();
    const [actionsTableState, setActionsTableState] = useState<ActionsTableState>({ currentSort: ActionsSortFilterOptions.TITLE, currentSortDirection: SortDirection.ASC });
    const headerValues: HeaderData[] = [
        { dataKey: ActionsSortFilterOptions.TITLE, label: 'TITLE' },
        { dataKey: ActionsSortFilterOptions.STATUS, label: 'STATUS' },
        { dataKey: ActionsSortFilterOptions.OWNER, label: 'OWNER' },
        { dataKey: ActionsSortFilterOptions.MAPPED_RISK, label: 'RISKS' },
        { dataKey: ActionsSortFilterOptions.DUE_DATE, label: 'DUE DATE' },
        { dataKey: ActionsSortFilterOptions.TAGS, label: 'TAGS' },
    ];

    const sortActions = (): Action[] => {
        let sortResult = 0;
        return props.actions.sort((actionA, actionB) => {
            switch (actionsTableState.currentSort) {
                case ActionsSortFilterOptions.TITLE:
                    sortResult = (actionA[actionsTableState.currentSort] as string).localeCompare(actionB[actionsTableState.currentSort] as string);
                    break;
                case ActionsSortFilterOptions.STATUS:
                    if (actionA.status === actionB.status) {
                        sortResult = 0;
                    } else {
                        sortResult = actionA.status === ActionStatus.CLOSED ? 1 : -1;
                    }
                    break;
                case ActionsSortFilterOptions.DUE_DATE:
                    sortResult = undefinedComparator(actionA.due_date, actionB.due_date, (dueDateA, dueDateB) => (dueDateA > dueDateB ? 1 : -1));
                    break;
                case ActionsSortFilterOptions.OWNER:
                    sortResult = compareUsersBySubjectForSorting(actionA.owner, actionB.owner, cachedData.users);
                    break;
                case ActionsSortFilterOptions.MAPPED_RISK:
                    sortResult = undefinedComparator(actionA.associated_risks, actionB.associated_risks, (associatedRisksA, associatedRisksB) => {
                        if (associatedRisksA.length > associatedRisksB.length) {
                            return 1;
                        }
                        return -1;
                    });
                    break;
                default:
                    sortResult = 0;
                    break;
            }

            return actionsTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setActionsTableState({ currentSort: newSort as ActionsSortFilterOptions, currentSortDirection: newSortDirection });
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: setCurrentSort,
        currentSort: actionsTableState.currentSort,
        currentSortDirection: actionsTableState.currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    const tableRow = (action: Action): JSX.Element => {
        const actionsTableRowProps: ActionsTableRowProps = {
            action: action,
            showModal: () => props.showModal(action),
            getDisplayableTags: props.getDisplayableTags,
        };
        return <ActionsTableRow key={action.id} {...actionsTableRowProps} />;
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortActions().map(tableRow)}</TableBody>
        </Table>
    );
};
