import { Fragment, ReactNode } from 'react';

import { RBACContext, Role, userHasAuthorizedRole } from './RBACContext';

export interface RBACComponentProps {
    roles: Role[];
    children: ReactNode;
}

/**
 * Use this as a wrapper around components that should be conditionally rendered based on the user's role.
 * For more complex logic or if this functionality is needed in code instead of JSX, use userHasAuthorizedRole().
 * @param props props.roles contains a list of roles that are authorized to access the child components. In other words, the child components will render if the user has at least one of these roles.
 * @returns
 */
export const RBACComponent = (props: RBACComponentProps): JSX.Element => {
    return (
        <RBACContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('RBACComponent must be used within a CachedDataProvider.');
                }
                if (userHasAuthorizedRole(context.roles, props.roles)) {
                    return <Fragment>{props.children}</Fragment>;
                }
                return null;
            }}
        </RBACContext.Consumer>
    );
};
