import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { FrameworkTargetEffectivenessTabProps, TargetEffectivenessTab, TargetEffectivenessTabType } from 'Components/OperationalControls/TargetEffectivenessTab/TargetEffectivenessTab';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { FRAMEWORKS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { LimitResponse } from 'Models/Limits';
import { DetailedControlFrameworkResponse } from 'Models/OperationalControls';

import { ControlFrameworkAssessmentTab, ControlFrameworkAssessmentTabProps } from './ControlFrameworkAssessmentTab/ControlFrameworkAssessmentTab';
import { FrameworkLimits, FrameworkLimitsProps } from './FrameworkLimit/FrameworkLimits';

export interface PathParams {
    controlFramework: string;
}

export interface FrameworkSettingsProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export const FrameworkSettings = (props: FrameworkSettingsProps) => {
    const [detailedControlFrameworkResponse, setDetailedControlFrameworkResponse] = useState<DetailedControlFrameworkResponse>();
    const [limitResponseList, setLimitResponseList] = useState<LimitResponse[]>();
    const cachedData = useCachedData();
    const { controlFramework } = useParams<keyof PathParams>() as PathParams;

    const getFrameworkConfiguration = useCallback(async (): Promise<void> => {
        try {
            const frameworkConfigurationResponse = await props.controlsApi.getControlFrameworkConfiguration(controlFramework);
            if (frameworkConfigurationResponse) {
                const frameworkConfiguration = frameworkConfigurationResponse.data;
                setDetailedControlFrameworkResponse(frameworkConfiguration);
            }
        } catch (err) {
            handleRequestError(err);
        }
    }, [controlFramework, props.controlsApi]);

    const getFrameworkLimits = useCallback(async (): Promise<void> => {
        try {
            const entity_id = controlFramework;
            const controlLimitsResponse = await props.alertsLimitsApi.getControlLimits(entity_id);
            const controlLimits = controlLimitsResponse.data;
            setLimitResponseList(controlLimits);
        } catch (error) {
            handleRequestError(error);
        }
    }, [controlFramework, props.alertsLimitsApi]);

    useEffect(() => {
        getFrameworkConfiguration();
        getFrameworkLimits();
    }, [getFrameworkConfiguration, getFrameworkLimits]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    if (cachedData.users && detailedControlFrameworkResponse && limitResponseList) {
        const editFrameworkProps: ControlFrameworkAssessmentTabProps = {
            controlsApi: props.controlsApi,
            detailedControlFrameworkResponse: detailedControlFrameworkResponse,
        };

        const frameworkLimitsProps: FrameworkLimitsProps = {
            alertsLimitsApi: props.alertsLimitsApi,
            detailedControlFrameworkResponse: detailedControlFrameworkResponse,
            getFrameworkLimits: getFrameworkLimits,
            limitResponseList: limitResponseList,
        };

        const frameworkEffectivenessTargetTabProps: FrameworkTargetEffectivenessTabProps = {
            _type: TargetEffectivenessTabType.FRAMEWORK,
            controlsApi: props.controlsApi,
            detailedFrameworkResponse: detailedControlFrameworkResponse,
            targetEffectivenessUpdated: getFrameworkConfiguration,
            linkOnSuccess: getFrameworkGroupControlURL(detailedControlFrameworkResponse.control_framework),
        };

        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}#${detailedControlFrameworkResponse.control_framework}`}>{detailedControlFrameworkResponse.control_framework}</BreadcrumbLink>
                        <BreadcrumbText>Settings</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={`${detailedControlFrameworkResponse.control_framework} Settings`}
                body={[
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab="frameworkConfiguration" removePadding>
                                <Tab eventKey="frameworkConfiguration" title="Assessment">
                                    <ControlFrameworkAssessmentTab {...editFrameworkProps} />
                                </Tab>
                                <Tab eventKey="frameworkLimits" title="Limits">
                                    <FrameworkLimits {...frameworkLimitsProps} />
                                </Tab>
                                <Tab eventKey="target" title="Target">
                                    <TargetEffectivenessTab {...frameworkEffectivenessTargetTabProps} />
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        );
    }

    return <Placeholder />;
};
