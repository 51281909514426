import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ComplianceRequirementForControlResponse, complianceRequirementStatusOptions } from 'Models/ComplianceRequirements';
import { AssessmentRole, AssessmentState, Effectiveness, EffectivenessSelectOptions, UpdateAssessmentRequest } from 'Models/OperationalControls';

import { CAState, CAStateAction, CAStateActionType } from '../ConductAssessment';
import styles from '../ConductAssessment.module.css';

export interface CurrentAssessmentProps {
    controlsApi: ControlsApi;
    dispatchCAStateChange: (action: CAStateAction) => void;
    handleRequestError: (error: Error) => void;
    loadCurrentAssessment: () => void;
    loadComplianceRequirements: () => void;
    caState: CAState;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
    hasReviewer: boolean;
}

enum ProcessingAction {
    APPROVE,
    SAVE,
    SUBMIT,
}

export const CurrentAssessment = (props: CurrentAssessmentProps): JSX.Element => {
    const formFieldChanged = (formFieldId: string, value: string | number): void => {
        const action: CAStateAction = {
            type: CAStateActionType.UpdateFormField,
            payload: {
                formFieldId: formFieldId,
                value: value,
            },
        };
        props.dispatchCAStateChange(action);
    };

    const formFieldChangedRegReq = (value: ChangeEventType, formFieldId: string): void => {
        const updatedComplianceRequirements = props.caState.currentAssessment.complianceRequirements ? [...props.caState.currentAssessment.complianceRequirements] : [];
        const tempComplianceRequirement = { ...updatedComplianceRequirements[parseInt(formFieldId)] };
        tempComplianceRequirement.status = value !== undefined ? String(value) : null;
        updatedComplianceRequirements[parseInt(formFieldId)] = tempComplianceRequirement;

        const action: CAStateAction = {
            type: CAStateActionType.UpdateFormField,
            payload: {
                formFieldId: 'complianceRequirements',
                value: updatedComplianceRequirements,
            },
        };
        props.dispatchCAStateChange(action);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        let action: CAStateAction = {
            type: CAStateActionType.UpdateUserInterfaceDisabled,
            payload: {
                formDisabled: true,
            },
        };
        props.dispatchCAStateChange(action);

        action = {
            type: CAStateActionType.UpdateUserInterfaceProcessing,
            payload: {
                isLoading: false,
                isSaving: false,
                isSubmitting: true,
            },
        };
        props.dispatchCAStateChange(action);

        if (props.caState.currentAssessment.startedInProgress && props.hasReviewer) {
            handleSubmitForReview();
        } else {
            handleApprove();
        }
    };

    const handleSubmitForReview = (): void => {
        updateAssessment(ProcessingAction.SUBMIT, AssessmentState.UNDER_REVIEW, props.caState.currentAssessment.ownerComment, undefined, props.caState.currentAssessment.effectiveness, props.caState.currentAssessment.complianceRequirements);
    };

    const handleApprove = (): void => {
        updateAssessment(ProcessingAction.APPROVE, AssessmentState.APPROVED, props.caState.currentAssessment.ownerComment, props.caState.currentAssessment.reviewerComment, props.caState.currentAssessment.effectiveness, props.caState.currentAssessment.complianceRequirements);
    };

    const handleSave = (): void => {
        const action: CAStateAction = {
            type: CAStateActionType.UpdateUserInterfaceProcessing,
            payload: {
                isLoading: false,
                isSaving: true,
                isSubmitting: false,
            },
        };
        props.dispatchCAStateChange(action);

        if (props.caState.currentAssessment.startedInProgress) {
            handleInProgressSave();
        } else {
            handleUnderReviewSave();
        }
    };

    const handleInProgressSave = (): void => {
        updateAssessment(ProcessingAction.SAVE, AssessmentState.IN_PROGRESS, props.caState.currentAssessment.ownerComment, undefined, props.caState.currentAssessment.effectiveness, props.caState.currentAssessment.complianceRequirements);
    };

    const handleUnderReviewSave = (): void => {
        updateAssessment(ProcessingAction.SAVE, AssessmentState.UNDER_REVIEW, props.caState.currentAssessment.ownerComment, props.caState.currentAssessment.reviewerComment, props.caState.currentAssessment.effectiveness, props.caState.currentAssessment.complianceRequirements);
    };

    const updateAssessment = async (processingAction: ProcessingAction, nextWorkflowState: AssessmentState, ownerComment?: string, reviewerComment?: string, effectiveness?: Effectiveness, complianceRequirements?: ComplianceRequirementForControlResponse[]): Promise<void> => {
        const action: CAStateAction = {
            type: CAStateActionType.UpdateUserInterfaceMessages,
            payload: {
                failureMessage: undefined,
                successMessage: undefined,
            },
        };
        props.dispatchCAStateChange(action);

        try {
            const updateAssessmentRequest: UpdateAssessmentRequest = {
                state: nextWorkflowState,
                owner_comment: ownerComment !== props.caState.currentAssessment.ownerCommentReference ? ownerComment : undefined,
                regulatory_requirements: JSON.stringify(complianceRequirements) !== JSON.stringify(props.caState.currentAssessment.complianceRequirementsReference) ? complianceRequirements : undefined,
                assessment_effectiveness: effectiveness !== props.caState.currentAssessment.effectivenessReference ? effectiveness : undefined,
                reviewer_comment: reviewerComment !== props.caState.currentAssessment.reviewerCommentReference ? reviewerComment : undefined,
            };
            await props.controlsApi.updateAssessment(props.controlFramework, props.controlGroupId, props.controlId, updateAssessmentRequest);

            /**
             * If the workflow has been approved, do not refresh the page and do not re-enable the form.
             * If the workflow has been saved or submitted, refresh the page and re-enable the form.
             * This allows a user who is both the Control Owner and Control Reviewer to continue the assessment, while disabling the form for a user who is only the Control Owner.
             */
            if (processingAction === ProcessingAction.APPROVE) {
                const action: CAStateAction = {
                    type: CAStateActionType.UpdateUserInterfaceMessages,
                    payload: {
                        failureMessage: undefined,
                        successMessage: 'Assessment approved.',
                    },
                };
                props.dispatchCAStateChange(action);
            } else {
                props.loadCurrentAssessment();
                props.loadComplianceRequirements();

                let successMessage;
                if (processingAction === ProcessingAction.SAVE) {
                    successMessage = 'Assessment updated.';
                } else {
                    successMessage = 'Assessment submitted for review.';
                }

                let action: CAStateAction = {
                    type: CAStateActionType.UpdateUserInterfaceMessages,
                    payload: {
                        failureMessage: undefined,
                        successMessage: successMessage,
                    },
                };
                props.dispatchCAStateChange(action);

                action = {
                    type: CAStateActionType.UpdateUserInterfaceDisabled,
                    payload: {
                        formDisabled: false,
                    },
                };
                props.dispatchCAStateChange(action);
            }
        } catch (error) {
            props.handleRequestError(error);
            // Re-enable the form if there was an error so that the user doesn't have to refresh the page to correct it.
            const action: CAStateAction = {
                type: CAStateActionType.UpdateUserInterfaceDisabled,
                payload: {
                    formDisabled: false,
                },
            };
            props.dispatchCAStateChange(action);
        } finally {
            const action: CAStateAction = {
                type: CAStateActionType.UpdateUserInterfaceProcessing,
                payload: {
                    isLoading: false,
                    isSaving: false,
                    isSubmitting: false,
                },
            };
            props.dispatchCAStateChange(action);
        }
    };

    return (
        <div className={styles.fullWidth}>
            <Form noValidate onSubmit={handleSubmit}>
                <Text variant="Header2">Current Control Assessment</Text>
                {props.caState.currentAssessment.dueDate && <DueDate timestamp={props.caState.currentAssessment.dueDate} />}
                <hr />
                <OwnerComment comment={props.caState.currentAssessment.ownerComment} disabled={props.caState.userInterface.formDisabled} handleChange={formFieldChanged} assessmentRoles={props.caState.controlDetails.assessmentRoles} />
                <ComplianceRequirements disabled={props.caState.userInterface.formDisabled} complianceRequirements={props.caState.currentAssessment.complianceRequirements} handleChange={formFieldChangedRegReq} assessmentRoles={props.caState.controlDetails.assessmentRoles} assessmentState={props.caState.currentAssessment.workflowState} />
                <EffectivenessDropdown workflowState={props.caState.currentAssessment.workflowState} disabled={props.caState.userInterface.formDisabled} effectiveness={props.caState.currentAssessment.effectiveness} handleChange={formFieldChanged} assessmentRoles={props.caState.controlDetails.assessmentRoles} />
                <ReviewerComment comment={props.caState.currentAssessment.reviewerComment} disabled={props.caState.userInterface.formDisabled} handleChange={formFieldChanged} startedInReview={props.caState.currentAssessment.startedInReview} assessmentRoles={props.caState.controlDetails.assessmentRoles} />
                <div className={styles.buttonContainer}>
                    <SaveButton disabled={props.caState.userInterface.formDisabled || props.caState.userInterface.isSaving || props.caState.userInterface.isSubmitting} workflowState={props.caState.currentAssessment.workflowState} handleSave={handleSave} isSavingAssessment={props.caState.userInterface.isSaving} assessmentRoles={props.caState.controlDetails.assessmentRoles} />
                    <SubmitForReviewButton disabled={props.caState.userInterface.formDisabled || props.caState.userInterface.isSaving || props.caState.userInterface.isSubmitting} isInProgress={props.caState.currentAssessment.startedInProgress} assessmentRoles={props.caState.controlDetails.assessmentRoles} isSubmittingAssessment={props.caState.userInterface.isSubmitting} hasReviewer={props.hasReviewer} />
                    <ApproveButton disabled={props.caState.userInterface.formDisabled || props.caState.userInterface.isSaving || props.caState.userInterface.isSubmitting} isInProgress={props.caState.currentAssessment.startedInProgress} isUnderReview={props.caState.currentAssessment.startedInReview} assessmentRoles={props.caState.controlDetails.assessmentRoles} isSubmittingAssessment={props.caState.userInterface.isSubmitting} hasReviewer={props.hasReviewer} />
                </div>
            </Form>
        </div>
    );
};

interface DueDateProps {
    timestamp: string;
}
const DueDate = (props: DueDateProps): JSX.Element => {
    return <Text variant="Text3">Due Date: {iso8601ToUsDateLong(props.timestamp)}</Text>;
};

interface OwnerCommentProps {
    comment?: string;
    disabled: boolean;
    handleChange: (formFieldId: string, value: string) => void;
    assessmentRoles: Set<AssessmentRole>;
}
const OwnerComment = (props: OwnerCommentProps): JSX.Element => {
    let disabled = false;
    if (props.disabled === true) {
        disabled = true;
    } else {
        disabled = !props.assessmentRoles.has(AssessmentRole.ADMIN) && !props.assessmentRoles.has(AssessmentRole.OWNER);
    }

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();

        props.handleChange(event.currentTarget.id, event.currentTarget.value);
    };

    return (
        <div className={styles.fieldContainer}>
            <FormFieldTextArea disabled={disabled} value={props.comment} handleChange={handleChange} formFieldId="ownerComment" formFieldLabel="Owner Comment" />
        </div>
    );
};

interface ComplianceRequirementsProps {
    disabled: boolean;
    complianceRequirements?: ComplianceRequirementForControlResponse[];
    handleChange: (value: ChangeEventType, formFieldId: string) => void;
    assessmentRoles: Set<AssessmentRole>;
    assessmentState: AssessmentState;
}
const ComplianceRequirements = (props: ComplianceRequirementsProps): JSX.Element | null => {
    if (!props.complianceRequirements || props.complianceRequirements.length === 0) {
        return null;
    }
    const isInProgressAndOwnerOrAdmin = props.assessmentState === AssessmentState.IN_PROGRESS && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.OWNER));

    let disabled = false;
    if (props.disabled === true) {
        disabled = true;
    } else {
        disabled = props.assessmentState !== AssessmentState.UNDER_REVIEW && !isInProgressAndOwnerOrAdmin;
    }

    return (
        <Fragment>
            <Text>Select whether the following requirements are being met by this control.</Text>
            <div className={styles.alignVertical}>
                {props.complianceRequirements?.map((item: ComplianceRequirementForControlResponse, index) => (
                    <div className={styles.complianceRequirementsContainer} key={item.identifier}>
                        <div className={styles.regReqIdPadding}>
                            <Text variant="Text4">{`${item.regulation} ${item.identifier}`}</Text>
                        </div>
                        <div className={styles.regReqTextPadding}>
                            <Text variant="Text4">{item.text}</Text>
                            <div className={styles.fieldMaxWidthRequirement}>
                                <FormFieldSelect disabled={disabled} options={complianceRequirementStatusOptions} handleChange={props.handleChange} formFieldId={String(index)} formFieldLabel="Requirement Status" selectedOption={item.status} isClearable />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

interface EffectivenessDropdownProps {
    effectiveness?: Effectiveness;
    disabled: boolean;
    handleChange: (formFieldId: string, value: number) => void;
    workflowState: keyof typeof AssessmentState;
    assessmentRoles: Set<AssessmentRole>;
}
const EffectivenessDropdown = (props: EffectivenessDropdownProps): JSX.Element => {
    const isInProgressAndOwnerOrAdmin = props.workflowState === AssessmentState.IN_PROGRESS && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.OWNER));

    let disabled = false;
    if (props.disabled === true) {
        disabled = true;
    } else {
        disabled = props.workflowState !== AssessmentState.UNDER_REVIEW && !isInProgressAndOwnerOrAdmin;
    }

    const handleChange = (value: ChangeEventType, formFieldId: string): void => {
        props.handleChange(formFieldId, Number(value));
    };

    return (
        <div className={`${styles.fieldContainer} ${styles.fieldMaxWidthEffectiveness}`}>
            <FormFieldSelect disabled={disabled} options={EffectivenessSelectOptions} handleChange={handleChange} formFieldId="effectiveness" selectedOption={Number(props.effectiveness)} formFieldLabel="Control Effectiveness" tooltip={'Sets the effectiveness of the control. The effectiveness should take evidence into consideration.'} />
        </div>
    );
};

interface ReviewerCommentProps {
    comment?: string;
    disabled: boolean;
    handleChange: (formFieldId: string, value: string) => void;
    startedInReview: boolean;
    assessmentRoles: Set<AssessmentRole>;
}
const ReviewerComment = (props: ReviewerCommentProps): JSX.Element | null => {
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();

        props.handleChange(event.currentTarget.id, event.currentTarget.value);
    };

    const shouldShow = props.startedInReview && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.REVIEWER));
    if (shouldShow) {
        return (
            <div className={styles.fieldContainer}>
                <FormFieldTextArea disabled={props.disabled} value={props.comment} handleChange={handleChange} formFieldId="reviewerComment" formFieldLabel="Reviewer Comment" />
            </div>
        );
    }

    return null;
};

interface SaveButtonProps {
    assessmentRoles: Set<AssessmentRole>;
    disabled: boolean;
    handleSave: () => void;
    isSavingAssessment: boolean;
    workflowState: keyof typeof AssessmentState;
}
const SaveButton = (props: SaveButtonProps): JSX.Element | null => {
    const isInProgressAndOwnerOrAdmin = props.workflowState === AssessmentState.IN_PROGRESS && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.OWNER));
    const displayed = props.workflowState === AssessmentState.UNDER_REVIEW || isInProgressAndOwnerOrAdmin;

    if (displayed) {
        return (
            <Button variant="secondary" disabled={props.disabled} onClick={props.handleSave} isLoading={props.isSavingAssessment} loadingText="Saving..." fontAwesomeImage={faSave}>
                SAVE
            </Button>
        );
    }

    return null;
};

interface SubmitForReviewButtonProps {
    assessmentRoles: Set<AssessmentRole>;
    disabled: boolean;
    isInProgress: boolean;
    isSubmittingAssessment: boolean;
    hasReviewer: boolean;
}
const SubmitForReviewButton = (props: SubmitForReviewButtonProps): JSX.Element | null => {
    const shouldShow = props.isInProgress && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.OWNER)) && props.hasReviewer;
    if (shouldShow) {
        return (
            <Button variant="submit" disabled={props.disabled} isLoading={props.isSubmittingAssessment} loadingText="Submitting...">
                SUBMIT
            </Button>
        );
    } else {
        return null;
    }
};

interface ApproveButtonProps {
    assessmentRoles: Set<AssessmentRole>;
    disabled: boolean;
    isInProgress: boolean;
    isUnderReview: boolean;
    isSubmittingAssessment: boolean;
    hasReviewer: boolean;
}
const ApproveButton = (props: ApproveButtonProps): JSX.Element | null => {
    const shouldShow = (props.isUnderReview || (props.isInProgress && !props.hasReviewer)) && (props.assessmentRoles.has(AssessmentRole.ADMIN) || props.assessmentRoles.has(AssessmentRole.REVIEWER));
    if (shouldShow) {
        return (
            <Button variant="submit" disabled={props.disabled} isLoading={props.isSubmittingAssessment} loadingText="Approving...">
                APPROVE
            </Button>
        );
    } else {
        return null;
    }
};
