import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';

import styles from './DeleteLimitModal.module.css';

export interface DeleteLimitModalProps {
    hideModal: () => void;
    deleteLimitTitle: string;
    deleteLimitId: string;
    alertsLimitsApi: AlertsLimitsApi;
    updateLimitListing: () => void;
}

export const DeleteLimitModal = (props: DeleteLimitModalProps) => {
    const [isDeletingLimit, setIsDeletingLimit] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const deleteLimit = async (limitId: string): Promise<void> => {
        setIsDeletingLimit(true);
        setFailureMessage(undefined);
        setSuccessMessage(undefined);
        try {
            await props.alertsLimitsApi.deleteLimit(limitId);
            setSuccessMessage('Limit deleted.');
            props.updateLimitListing();
        } catch (error) {
            handleRequestError(error);
        } finally {
            setIsDeletingLimit(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setSuccessMessage(undefined);
        setFailureMessage(error.message);
    };

    const hideModal = (): void => {
        if (!isDeletingLimit) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Delete" />
                <div className={styles.deleteText}>
                    <Text>Are you sure you want to delete: {props.deleteLimitTitle}?</Text>
                </div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={hideModal} fontAwesomeImage={faTimes} disabled={isDeletingLimit}>
                        CLOSE
                    </Button>
                    <Button variant="danger" onClick={() => deleteLimit(props.deleteLimitId!)} fontAwesomeImage={faTrash} loadingText={'Deleting...'} isLoading={isDeletingLimit} disabled={successMessage ? true : false}>
                        DELETE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
