import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, isEqual } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { Text } from 'Components/Text/Text';
import { countryOptions, getStateFieldLabel, getStateFieldOptions } from 'Config/CountryStateList';
import { TPRM_THIRD_PARTY_ADDRESS, TPRM_THIRD_PARTY_CITY, TPRM_THIRD_PARTY_CONTACT_EMAIL, TPRM_THIRD_PARTY_CONTACT_NAME, TPRM_THIRD_PARTY_CONTACT_PHONE_NUMBER, TPRM_THIRD_PARTY_EIN, TPRM_THIRD_PARTY_MANAGER, TPRM_THIRD_PARTY_NAME, TPRM_THIRD_PARTY_WEBSITE, TPRM_THIRD_PARTY_ZIP_CODE } from 'Config/Tooltips';
import { validateEin, validateEmail } from 'Helpers/InputValidation';
import { ValidationError } from 'Models/ErrorTypes';
import { SaveThirdPartyRequest, ThirdPartyContact, ThirdPartyResponse } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './SaveTPRMThirdPartyModal.module.css';

export interface SaveTPRMThirdPartyModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
    thirdParty?: ThirdPartyResponse; // If this is present, then we are modifying an existing Third Party. Else, we are creating a new one.
    thirdPartySaved: () => void;
}

interface FormFieldsState {
    name?: string;
    ein?: string;
    country?: string;
    thirdPartyManagerUserId?: string;
    thirdPartyContacts: ThirdPartyContact[];
    state?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    website?: string;
}

export const SaveTPRMThirdPartyModal = (props: SaveTPRMThirdPartyModalProps): JSX.Element => {
    const cachedData = useCachedData();

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [isSavingThirdParty, setIsSavingThirdParty] = useState<boolean>(false);
    const [thirdPartyManager, setThirdPartyManager] = useState<UserResponse>(); // Keeps track of the currently selected User in the Third-Party Manager drop-down menu.
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({
        name: props.thirdParty?.name,
        ein: props.thirdParty?.ein,
        country: props.thirdParty?.country,
        thirdPartyManagerUserId: props.thirdParty?.vendor_manager_user_id,
        thirdPartyContacts: props.thirdParty?.vendor_contacts || [],
        state: props.thirdParty?.state,
        city: props.thirdParty?.city,
        addressLine1: props.thirdParty?.address_line_1,
        addressLine2: props.thirdParty?.address_line_2,
        zipCode: props.thirdParty?.zip_code,
        website: props.thirdParty?.website,
    });

    useEffect(() => {
        const getSelectedUser = async (): Promise<void> => {
            const thirdPartyManager = cachedData.users.find((user) => user.cognito_subject === props.thirdParty?.vendor_manager_user_id);
            setThirdPartyManager(thirdPartyManager);
        };
        getSelectedUser();
    }, [props.thirdParty?.vendor_manager_user_id, cachedData.users]);

    const saveThirdParty = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setIsSavingThirdParty(true);
        try {
            validateForm();
            await props.tprmApi.saveThirdParty(getMinimalRequest(), props.thirdParty?.id);
            if (props.thirdParty !== undefined) {
                setSuccessMessage('Third Party updated.');
            } else {
                setSuccessMessage('Third Party created.');
            }
            props.thirdPartySaved();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSavingThirdParty(false);
        }
    };

    /**
     * This ensures only necessary fields are included in the request to the API.
     */
    const getMinimalRequest = (): SaveThirdPartyRequest => {
        const request: SaveThirdPartyRequest = {
            name: formFieldsState.name,
            ein: formFieldsState.ein,
            country: formFieldsState.country,
            vendor_manager_user_id: formFieldsState.thirdPartyManagerUserId,
            vendor_contacts: cloneDeep(formFieldsState.thirdPartyContacts), // cloneDeep() is used so that we can set empty strings to undefined in the next step without modifying the original state, which would throw a React error about switching from controlled to uncontrolled components.
            state: formFieldsState.state,
            city: formFieldsState.city,
            address_line_1: formFieldsState.addressLine1,
            address_line_2: formFieldsState.addressLine2,
            zip_code: formFieldsState.zipCode,
            website: formFieldsState.website,
        };

        const minimalRequest: SaveThirdPartyRequest = {};

        // Compare the form fields to the existing Third Party and only include fields that have changed.
        Object.keys(request).forEach((key) => {
            // We are updating an existing Third Party, only include fields that have changed.
            if (props.thirdParty !== undefined) {
                // Need special handling for vendor_contacts since JavaScript can't easily check arrays and objects for equality.
                if (key === 'vendor_contacts') {
                    if (!isEqual(props.thirdParty[key].sort(), request[key]?.sort())) {
                        minimalRequest[key] = request[key];
                    }
                }
                // Everything else can be directly checked for equality.
                else if (props.thirdParty[key] !== request[key]) {
                    minimalRequest[key] = request[key];
                }
            }
            // We are creating a new Third-Party Service, include everything.
            else {
                minimalRequest[key] = request[key];
            }
        });

        // Discard any Third-Party contacts where all attributes are "empty."
        // This is necessary because the API will save empty Third-Party contacts to the database (as {}) and then empty Third-Party contact "rows" will persist in the UI.
        minimalRequest.vendor_contacts = minimalRequest.vendor_contacts?.filter((thirdPartyContact) => {
            return Object.values(thirdPartyContact).some((value) => {
                if (typeof value === 'string' && value.trim().length === 0) {
                    return false;
                }
                return true;
            });
        });

        // Set any attributes of Third-Party contacts that are "empty" strings to undefined before submitting the request.
        // This is necessary because the API will return an error if email_address is an empty string. The other attributes are included simply for completeness.
        minimalRequest.vendor_contacts?.forEach((thirdPartyContact) => {
            if ((thirdPartyContact.email_address?.trim().length ?? 0) === 0) {
                thirdPartyContact.email_address = undefined;
            }
            if ((thirdPartyContact.name?.trim().length ?? 0) === 0) {
                thirdPartyContact.name = undefined;
            }
            if ((thirdPartyContact.phone_number?.trim().length ?? 0) === 0) {
                thirdPartyContact.phone_number = undefined;
            }
            if ((thirdPartyContact.additional_information?.trim().length ?? 0) === 0) {
                thirdPartyContact.additional_information = undefined;
            }
        });
        return minimalRequest;
    };

    const validateForm = (): void => {
        if (!formFieldsState.name) {
            throw new ValidationError('Third-party name is required.');
        }
        if (!formFieldsState.country) {
            throw new ValidationError('Third-party country/region is required.');
        }
        if (formFieldsState.ein && !validateEin(formFieldsState.ein)) {
            throw new ValidationError('Employer ID Number must only contain numbers and dashes.');
        }
        formFieldsState.thirdPartyContacts.forEach((thirdPartyContact) => {
            if (thirdPartyContact.email_address && !validateEmail(thirdPartyContact.email_address)) {
                throw new ValidationError('Invalid email address.');
            }
        });
        if (!formFieldsState.thirdPartyManagerUserId) {
            throw new ValidationError('Third-Party Manager is required.');
        }
    };

    /** Handles error responses from the API. */
    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    /** Handles changes made to general text fields. */
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    /** Handles changes made to general drop-down (select) fields. */
    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        if (formFieldId === 'country') {
            setFormFieldsState({ ...formFieldsState, [formFieldId]: value as string, state: '' });
        } else {
            setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
        }
    };

    /** Handles changes made to the Third-Party Manager drop-down (select) field. */
    const handleSelectUserChange = (user: UserResponse, formFieldId: string): void => {
        if (user !== thirdPartyManager) {
            setThirdPartyManager(user);
            setFormFieldsState({ ...formFieldsState, [formFieldId]: user.cognito_subject });
        }
    };

    /** Adds the fields (empty) for a new Third-Party contact. */
    const handleAddThirdPartyContact = (): void => {
        const thirdPartyContacts = [...formFieldsState.thirdPartyContacts];
        thirdPartyContacts.push({ name: '', email_address: '', phone_number: '', additional_information: '' });
        setFormFieldsState({ ...formFieldsState, thirdPartyContacts: thirdPartyContacts });
    };

    /** Deletes an existing Third-Party contact. */
    const handleDeleteThirdPartyContact = (index: number): void => {
        const thirdPartyContacts = [...formFieldsState.thirdPartyContacts];
        thirdPartyContacts.splice(index, 1);
        setFormFieldsState({ ...formFieldsState, thirdPartyContacts: thirdPartyContacts });
    };

    /** Handles changes made to an existing Third-Party contact. */
    const handleThirdPartyContactChange = (index: number, attribute: 'name' | 'email_address' | 'phone_number' | 'additional_information', value: string): void => {
        const thirdPartyContacts = [...formFieldsState.thirdPartyContacts];
        thirdPartyContacts[index] = {
            ...thirdPartyContacts[index],
            [attribute]: value,
        };
        setFormFieldsState({ ...formFieldsState, thirdPartyContacts: thirdPartyContacts });
    };

    const stateField = (): JSX.Element => {
        const formFieldLabel = getStateFieldLabel(formFieldsState.country);
        const tooltip = `The ${formFieldLabel} in which this third party resides.`;

        if (!formFieldsState.country) {
            // If a country has not yet been selected, return a disabled text field.
            return <FormFieldText value="Select a Country first" disabled formFieldType="text" formFieldId="state" formFieldLabel={formFieldLabel} required={false} tooltip={tooltip} />;
        }

        const stateFieldOptions = getStateFieldOptions(formFieldsState.country);

        if (stateFieldOptions === undefined) {
            // If the selected country does not have corresponding states/etc. from which to select, return a free-form text field.
            // The value defaults to the empty string to avoid transitioning the component from uncontrolled to controlled.
            return <FormFieldText value={formFieldsState.state || ''} formFieldType="text" handleChange={handleChange} formFieldId="state" formFieldLabel={formFieldLabel} required={false} tooltip={tooltip} />;
        }

        // If the selected country has corresponding states/etc. from which to select, return a select component with those values.
        // The value defaults to null to avoid transitioning the component from uncontrolled to controlled, and to clear out the selection when a new country is selected.
        return <FormFieldSelect selectedOption={formFieldsState.state || null} options={stateFieldOptions} handleChange={handleSelectChange} formFieldId="state" formFieldLabel={formFieldLabel} tooltip={tooltip} />;
    };

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={saveThirdParty}>
                    {props.thirdParty ? <Text variant="Header2">Update Third Party</Text> : <Text variant="Header2">Create Third Party</Text>}
                    <div className={styles.formFieldContainer}>
                        <FormFieldText value={formFieldsState.name || ''} formFieldType="text" handleChange={handleChange} formFieldId="name" formFieldLabel="Legal Name" required={true} tooltip={TPRM_THIRD_PARTY_NAME} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText value={formFieldsState.website || ''} formFieldType="text" handleChange={handleChange} formFieldId="website" formFieldLabel="Website URL" required={false} tooltip={TPRM_THIRD_PARTY_WEBSITE} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserSelect selectedUser={thirdPartyManager} users={cachedData.users} onUserSelected={handleSelectUserChange} formFieldId="thirdPartyManagerUserId" formFieldLabel="Third-Party Manager" tooltip={TPRM_THIRD_PARTY_MANAGER} isRequiredField />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldSelect selectedOption={formFieldsState.country} options={countryOptions} handleChange={handleSelectChange} formFieldId="country" formFieldLabel="Country/Region" isRequiredField />
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.addressLine1 || ''} formFieldType="text" handleChange={handleChange} formFieldId="addressLine1" formFieldLabel="Address Line 1" required={false} tooltip={TPRM_THIRD_PARTY_ADDRESS} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.addressLine2 || ''} formFieldType="text" handleChange={handleChange} formFieldId="addressLine2" formFieldLabel="Address Line 2" required={false} tooltip={TPRM_THIRD_PARTY_ADDRESS} />
                        </div>
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.city || ''} formFieldType="text" handleChange={handleChange} formFieldId="city" formFieldLabel="City" required={false} tooltip={TPRM_THIRD_PARTY_CITY} />
                        </div>
                        <div className={styles.formFieldContainer}>{stateField()}</div>
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.zipCode || ''} formFieldType="text" handleChange={handleChange} formFieldId="zipCode" formFieldLabel="Zip Code" required={false} tooltip={TPRM_THIRD_PARTY_ZIP_CODE} />
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldText value={formFieldsState.ein || ''} formFieldType="text" handleChange={handleChange} formFieldId="ein" formFieldLabel="Employer ID Number" tooltip={TPRM_THIRD_PARTY_EIN} />
                        </div>
                    </div>
                    <Text variant="Header3" color="darkGray">
                        Third-Party Contact Information
                    </Text>
                    {formFieldsState.thirdPartyContacts.map((thirdPartyContact, index) => (
                        <Fragment key={index}>
                            <div className={styles.formFieldGroup}>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'name', event.currentTarget.value)} formFieldId={`thirdPartyContactName${index}`} formFieldLabel={`Name`} tooltip={TPRM_THIRD_PARTY_CONTACT_NAME} value={thirdPartyContact.name || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="email" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'email_address', event.currentTarget.value)} formFieldId={`thirdPartyContactEmailAddress${index}`} formFieldLabel={`Email Address`} tooltip={TPRM_THIRD_PARTY_CONTACT_EMAIL} value={thirdPartyContact.email_address || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'phone_number', event.currentTarget.value)} formFieldId={`thirdPartyContactPhoneNumber${index}`} formFieldLabel={`Phone Number`} tooltip={TPRM_THIRD_PARTY_CONTACT_PHONE_NUMBER} value={thirdPartyContact.phone_number || ''} />
                                </div>
                                <div className={styles.trashIconContainer}>
                                    <IconButton aria-label={`delete third-party contact ${index}`} onClick={() => handleDeleteThirdPartyContact(index)} fontAwesomeImage={faTrash} />
                                </div>
                            </div>
                            <div className={styles.formFieldContainer}>
                                <FormFieldTextArea handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'additional_information', event.currentTarget.value)} formFieldId={`thirdPartyContactAdditionalInformation${index}`} formFieldLabel={`Additional Information`} tooltip="Any additional information for the contact at this third party." value={thirdPartyContact.additional_information || ''} />
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.formFieldContainer}>
                        <Button variant="linkText" size="lg" onClick={handleAddThirdPartyContact}>
                            {'+ Add Contact'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isSavingThirdParty}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={isSavingThirdParty} isLoading={isSavingThirdParty} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
