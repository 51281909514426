import { Skeleton } from '@mui/material';
import { clone, round } from 'lodash-es';
import moment from 'moment';
import { useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { CSSColors } from 'Components/Colors';
import { ChangeEventType, FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { Text } from 'Components/Text/Text';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ControlStrengthResponse, ControlsSnapshot, DateOptionSelectOptions, isValidDateOption } from 'Models/Dashboards';
import { CompletedAssessment, Effectiveness, effectivenessAsString } from 'Models/OperationalControls';
import { CustomDateRange, CustomDateRangeModal, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';
import { getDatesFromPreset } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.hooks';

import styles from './ControlsOverTime.module.css';
import { buildControlOverTimeChartByMonth, stringToEffectiveness } from '../OperationalControlsDashboard.helpers';
import { useControlsHistoryQuery } from '../OperationalControlsDashboard.hooks';
import { ControlsOverTimeDisplayModal } from './ControlsOverTimeDisplayModal/ControlsOverTimeDisplayModal';

enum Modals {
    NONE,
    DISPLAY_CONTROLS,
    CUSTOM_DATE,
}

export interface ControlsOverTimeProps {
    controlStrength?: ControlStrengthResponse;
    controlStrengthError?: Error;
    dashboardApi: DashboardApi;
}

export const ControlsOverTime = (props: ControlsOverTimeProps): JSX.Element => {
    const { controlsHistory, updateDateFilters, controlHistoryError } = useControlsHistoryQuery(props.dashboardApi);
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [effectivenessClicked, setEffectivenessClicked] = useState<Effectiveness>();
    const [controlsClicked, setControlsClicked] = useState<CompletedAssessment[]>();
    const [dateClicked, setDateClicked] = useState<string>();
    const [dateOption, setDateOption] = useState<DateOptionSelectOptions>(DateOptionSelectOptions.PREVIOUS_QUARTER);
    const [selectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const lineColor = CSSColors.LIGHT_BLUE;

    const chartData = () => {
        return buildControlOverTimeChartByMonth(controlsHistory!, [Effectiveness.FAIL, Effectiveness.WEAK, Effectiveness.MODERATE, Effectiveness.STRONG, Effectiveness.ROBUST]);
    };

    const controlMaximum = () => {
        const assessments = controlsHistory!.map((controls) => controls.assessments);
        const largestAmountOfControls = Math.max(...assessments.map((am) => am.length));
        return Math.ceil(largestAmountOfControls / 5) * 5;
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        updateDateFilters.setControlStrengthStartDate(jsDateToIso8601(customDateRange.startDate));
        updateDateFilters.setControlStrengthEndDate(jsDateToIso8601(customDateRange.endDate));
        setDateOption(DateOptionSelectOptions.CUSTOM);
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                const presetValue = getDatesFromPreset(value);
                setDateOption(value);
                updateDateFilters.setControlStrengthStartDate(jsDateToIso8601(presetValue.start));
                updateDateFilters.setControlStrengthEndDate(jsDateToIso8601(presetValue.end));
            }
        }
    };

    const handleChartClick = (item: string) => {
        const newItem = item.split(' ');
        if (stringToEffectiveness(newItem[3])) {
            const clickedEffectiveness = stringToEffectiveness(newItem[3]);
            const formattedDateClicked = `${newItem[0]} ${newItem[1]}`;
            const actualMonth = moment(formattedDateClicked, 'MMM YY');
            const selectedControls = controlsHistory!
                .filter((controls) => {
                    return controls.month.month === actualMonth.month() + 1 && controls.month.year === actualMonth.year();
                })
                .flatMap((selected) => {
                    return selected.assessments.filter((assessment) => {
                        return assessment.effectiveness === clickedEffectiveness;
                    });
                });
            setControlsClicked(selectedControls);
            setEffectivenessClicked(clickedEffectiveness!);
            setModalState(Modals.DISPLAY_CONTROLS);
            setDateClicked(formattedDateClicked);
        }
    };

    const averageControlMap = (snapshot: ControlsSnapshot[]) => {
        const today = moment();
        const snapShotCopy = clone(snapshot);
        if (snapShotCopy[snapShotCopy.length - 1].month.month === today.month() + 1 && snapshot[snapShotCopy.length - 1]?.month.year === today.year()) {
            const currentSnapshot = snapShotCopy.pop();
            snapShotCopy.push({
                assessments: currentSnapshot!.assessments,
                average_effectiveness: props.controlStrength!.controls_effectiveness_average,
                month: currentSnapshot!.month,
            });
            return snapShotCopy.map((control) => round(control.average_effectiveness, 1));
        }
        return snapshot.map((control) => round(control.average_effectiveness, 1));
    };

    if (props.controlStrengthError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Control Effectiveness Over Time
                    </Text>
                </div>
                <Text color="white">{props.controlStrengthError.message}</Text>
            </>
        );
    }

    if (controlHistoryError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Control Effectiveness Over Time
                    </Text>
                </div>
                <Text color="white" variant="Header4">
                    {controlHistoryError}
                </Text>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="pastDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_CONTROLS && controlsClicked && dateClicked && effectivenessClicked && <ControlsOverTimeDisplayModal hideModal={() => setModalState(Modals.NONE)} selectedControls={controlsClicked} effectiveness={effectivenessClicked} date={dateClicked} />}
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Control Effectiveness Over Time
                </Text>
                <FormFieldDateOptionSelect formFieldId="controlsOverTime" selectedOption={dateOption} handleChange={handleDateFilterChange} disabled={controlsHistory === undefined || props.controlStrength === undefined} />
            </div>
            {controlsHistory && props.controlStrength ? (
                <StackedBarAndLineChart
                    onChartClick={handleChartClick}
                    xAxisLabels={controlsHistory.map(
                        (control) =>
                            `${moment()
                                .month(control.month.month - 1)
                                .format('MMM')
                                .toUpperCase()} ${moment().year(control.month.year).format('YY')}`
                    )}
                    bars={chartData()}
                    yAxisInfo={[
                        { name: 'NUMBER OF CONTROLS', position: 'left', max: controlMaximum(), min: 0, interval: controlMaximum() / 5 },
                        {
                            name: 'AVERAGE CONTROL EFFECTIVENESS',
                            position: 'right',
                            max: 5,
                            min: 0,
                            interval: 1,
                            labelFormatter: (item) => {
                                return effectivenessAsString(item);
                            },
                        },
                    ]}
                    line={{ name: 'Average Control Effectiveness', color: lineColor, data: averageControlMap(controlsHistory) }}
                />
            ) : (
                <Skeleton variant="rounded" width={'100%'} height={300} />
            )}
        </>
    );
};
