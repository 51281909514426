import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { AssessmentDetailsResponse, ControlEffectivenessProgressBarVariantAndPercentage, OperationalControl, ProgressBarVariantPercent, assessmentStateAsString, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { ScheduleFrequency } from 'Models/ScheduleFrequency';

import styles from './ControlAssessmentDashlet.module.css';

export interface ControlAssessmentDashletProps {
    detailedControlResponse: OperationalControl;
    assessmentDetailsResponse?: AssessmentDetailsResponse;
}

export const ControlAssessmentDashlet = (props: ControlAssessmentDashletProps) => {
    const controlEffectivenessProgressBar: ProgressBarVariantPercent = (() => {
        return ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.detailedControlResponse.effectiveness.control_effectiveness));
    })();

    const getScheduleFrequencyText = (scheduleFrequency: 'DAYS' | 'MONTHS' | 'YEARS', reviewNumber: number) => {
        let frequencyText: string;

        switch (scheduleFrequency) {
            case 'DAYS':
                frequencyText = `${reviewNumber} ${ScheduleFrequency.DAYS}`;
                break;
            case 'MONTHS':
                frequencyText = `${reviewNumber} ${ScheduleFrequency.MONTHS}`;
                break;
            case 'YEARS':
                frequencyText = `${reviewNumber} ${ScheduleFrequency.YEARS}`;
                break;
        }

        if (Number(reviewNumber) === 1) {
            return frequencyText.slice(0, -1);
        } else {
            return frequencyText;
        }
    };

    return (
        <div className={styles.sectionContainer}>
            <div className={styles.effectivenessContainer}>
                <Text variant="Text4" color="darkGray" toUppercase noStyles>
                    Effectiveness
                </Text>
                <ProgressBarIndicator variant={controlEffectivenessProgressBar.variant} size="large" percent={controlEffectivenessProgressBar.percent} />
                <div className={styles.effectivenessLayout}>
                    <Text color="white" variant="Text1">
                        {numberAsEffectivenessString(props.detailedControlResponse.effectiveness.control_effectiveness)}
                    </Text>
                </div>
                <div className={styles.detailRowMultipleColumns}>
                    <div className={styles.detailRow}>
                        <Text variant="Text4" color="darkGray" toUppercase noStyles>
                            Current State:
                        </Text>
                        <Text color="white" variant="Text2">
                            {props.assessmentDetailsResponse ? assessmentStateAsString(props.assessmentDetailsResponse.assessment_state) : '-'}
                        </Text>
                    </div>
                    {props.detailedControlResponse.target_effectiveness && (
                        <div className={styles.detailRow}>
                            <Text variant="Text4" color="darkGray" toUppercase noStyles>
                                Target Effectiveness:
                            </Text>
                            <Text color="white" variant="Text2">
                                {`${numberAsEffectivenessString(props.detailedControlResponse.target_effectiveness)}`}
                            </Text>
                        </div>
                    )}
                </div>
                <div className={styles.detailRowMultipleColumns}>
                    <div className={styles.detailRow}>
                        <Text variant="Text4" color="darkGray" toUppercase noStyles>
                            Due Date:
                        </Text>
                        <Text color="white" variant="Text2">
                            {props.assessmentDetailsResponse ? iso8601ToUsDateShort(props.assessmentDetailsResponse.assessment_due_date) : '-'}
                        </Text>
                    </div>
                    <div className={styles.detailRow}>
                        <Text variant="Text4" color="darkGray" toUppercase noStyles>
                            Review Frequency:
                        </Text>
                        <Text color="white" variant="Text2">
                            {props.detailedControlResponse.configuration.review_configuration !== undefined ? `${getScheduleFrequencyText(props.detailedControlResponse.configuration.review_configuration.review_schedule_frequency, props.detailedControlResponse.configuration.review_configuration.review_schedule_number)}` : '-'}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
