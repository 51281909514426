import { Navigator } from './Navigator';

export class WindowNavigator implements Navigator {
    navigateTo = (path: string, replace?: boolean): void => {
        if (replace) {
            window.location.replace(path);
        } else {
            window.location.href = path;
        }
    };

    currentLocation = (): Location => {
        return window.location;
    };

    getSearchParameter = (key: string): string | null => {
        return new URLSearchParams(window.location.search).get(key);
    };

    openExternalUrl = (url: string, inNewTab: boolean): void => {
        if (inNewTab) {
            window.open(url);
        } else {
            this.navigateTo(url);
        }
    };
}
