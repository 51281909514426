import { useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { DropdownItemClickType, PrimaryAndSecondaryDropdownButtonProps, PrimaryDropdownButton } from 'Components/Buttons/DropdownButton';
import { DeleteLimitModal, DeleteLimitModalProps } from 'Components/Limits/DeleteLimitModal/DeleteLimitModal';
import { CreateEffectivenessLimitModal, CreateEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/CreateEffectivenessLimitModal/CreateEffectivenessLimitModal';
import { ModifyEffectivenessLimitModal, ModifyEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/ModifyEffectivenessLimitModal/ModifyEffectivenessLimitModal';
import { LimitsTables, LimitsTablesProps } from 'Components/Limits/LimitsTables/LimitsTables';
import { Text } from 'Components/Text/Text';
import { EffectivenessLimit, Limit, LimitResponse } from 'Models/Limits';
import { DetailedControlGroupResponse } from 'Models/OperationalControls';

import { ControlGroupDeleted } from '../ControlGroupSettings';

export interface ControlGroupLimitsTabProps {
    limitResponseList: LimitResponse[];
    alertsLimitsApi: AlertsLimitsApi;
    getControlGroupLimits: () => void;
    detailedControlGroupResponse: DetailedControlGroupResponse;
}
export enum Modals {
    CreateEffectivenessLimitModal,
    DeleteLimitModal,
    ModifyLimitModal,
    None,
}

export const ControlGroupLimitsTab = ({ controlGroupIsDeleted = false, ...props }: ControlGroupLimitsTabProps & ControlGroupDeleted) => {
    const [displayedModal, setDisplayedModal] = useState(Modals.None);
    const [deleteLimitId, setDeleteLimitId] = useState<string>();
    const [deleteLimitTitle, setDeleteLimitTitle] = useState<string>();
    const [modifyLimit, setModifyLimit] = useState<Limit>();

    const getLimitsDropdownButton = () => {
        const dropdownButtonProps: PrimaryAndSecondaryDropdownButtonProps = {
            dropdownItems: [],
        };

        if (props.limitResponseList.length === 0) {
            dropdownButtonProps.dropdownItems.push({
                _type: DropdownItemClickType.ONCLICK,
                text: 'Create Effectiveness Limit',
                onClick: () => displayModal(Modals.CreateEffectivenessLimitModal),
            });
        }

        if (dropdownButtonProps.dropdownItems.length > 0) {
            return <PrimaryDropdownButton {...dropdownButtonProps}>ADD LIMITS</PrimaryDropdownButton>;
        }
    };

    const displayDeleteLimitModal = (deleteLimitId: string, deleteLimitTitle: string): void => {
        setDeleteLimitId(deleteLimitId);
        setDeleteLimitTitle(deleteLimitTitle);
        setDisplayedModal(Modals.DeleteLimitModal);
    };

    const displayModifyLimitModal = (modifyLimit: Limit): void => {
        setModifyLimit(modifyLimit);
        setDisplayedModal(Modals.ModifyLimitModal);
    };

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const createEffectivenessLimitModalProps: CreateEffectivenessLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        controlIdentifier: `${props.detailedControlGroupResponse.control_framework}#${props.detailedControlGroupResponse.control_group_id}`,
        hideModal: () => displayModal(Modals.None),
        onLimitCreated: props.getControlGroupLimits,
    };
    const limitsTableProps: LimitsTablesProps = {
        limitResponseList: props.limitResponseList,
        displayDeleteLimitModal: displayDeleteLimitModal,
        displayModifyLimitModal: displayModifyLimitModal,
        alertsLimitsApi: props.alertsLimitsApi,
        addLimitsButton: getLimitsDropdownButton(),
    };
    const deleteLimitModalProps: DeleteLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        deleteLimitId: deleteLimitId!,
        deleteLimitTitle: deleteLimitTitle!,
        hideModal: () => displayModal(Modals.None),
        updateLimitListing: props.getControlGroupLimits,
    };
    const modifyLimitModalProps: ModifyEffectivenessLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        hideModal: () => displayModal(Modals.None),
        limit: modifyLimit! as EffectivenessLimit,
        onLimitModified: props.getControlGroupLimits,
    };

    if (controlGroupIsDeleted) {
        return <Text>This control group and all associated limits have been deleted.</Text>;
    }

    return (
        <>
            {displayedModal === Modals.CreateEffectivenessLimitModal && <CreateEffectivenessLimitModal {...createEffectivenessLimitModalProps} />}
            {displayedModal === Modals.ModifyLimitModal && <ModifyEffectivenessLimitModal {...modifyLimitModalProps} />}
            {displayedModal === Modals.DeleteLimitModal && <DeleteLimitModal {...deleteLimitModalProps} />}
            <LimitsTables {...limitsTableProps} />
        </>
    );
};
