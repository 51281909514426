/**
 * This contains models for Client (customer) objects.
 */

/*
 * The different add-on modules of SummIT Security that a Client can be licensed for.
 * Ensure changes to this enum are reflected in the corresponding enum in the back-end.
 */
export enum License {
    ARTIFICIAL_INTELLIGENCE = 'Artificial Intelligence',
}

/**
 * On the back-end this model is simply called "Client" and while I normally prefer model names to match when they are defined in multiple places,
 * I'm leaving it as-is in the front-end code since "Client" is such a generic word.
 */
export interface ClientDetailsModel {
    client_id: string;
    client_name: string;
    licenses: License[]; // This will be an empty array for Clients that only have the base package of SummIT Security features. It is only populated for add-on modules
}
