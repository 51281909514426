import { ModalHeader } from 'Components/Modal/ModalHeader';
import { RiskRatingHistoryAccordion } from 'Components/RiskRegister/RiskRatingHistoryAccordion/RiskRatingHistoryAccordion';
import { RiskRatingHistoryResponse } from 'Models/RiskRegister';

import styles from './RiskRatingHistoryTab.module.css';

export interface RiskRatingHistoryTabProps {
    histories: RiskRatingHistoryResponse[];
    timestampOfHistoryToAutoExpand?: string;
}

export const RiskRatingHistoryTab = (props: RiskRatingHistoryTabProps): JSX.Element => {
    return (
        <>
            <div className={styles.riskHistoryTab}>
                <ModalHeader text="Risk Rating History" />
                <RiskRatingHistoryAccordion histories={props.histories} timestampOfHistoryToAutoExpand={props.timestampOfHistoryToAutoExpand} />
            </div>
        </>
    );
};
