import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'Components/Buttons/Buttons';

import styles from '../ConfigureIntegration.module.css';

export interface AwsConfigSetupWizardButtonRowProps {
    hasPreviousStep: boolean;
    hasNextStep: boolean;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
    hideModal: () => void;
    children?: React.ReactNode;
}

/**
 * A generic row of buttons that is always present in the AWS Config setup wizard.
 * Close, Back, and Next buttons are handled uniformly for all wizard steps.
 * Additionally, each step can provide an actionable button that will be placed between the Back and Next buttons.
 */
export const AwsConfigSetupWizardButtonRow = (props: AwsConfigSetupWizardButtonRowProps): JSX.Element => {
    return (
        <div className={styles.buttonRowContainer}>
            <div className={styles.buttonPadding}>
                <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                    CLOSE
                </Button>
            </div>

            <div className={styles.buttonsRightContainer}>
                {props.hasPreviousStep && (
                    <div className={styles.buttonPadding}>
                        <Button variant="primary" onClick={props.goToPreviousStep} fontAwesomeImage={faArrowLeft}>
                            BACK
                        </Button>
                    </div>
                )}

                {props.children}

                {props.hasNextStep && (
                    <div className={styles.buttonPadding}>
                        <Button variant="primary" onClick={props.goToNextStep} fontAwesomeImage={faArrowRight}>
                            NEXT
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
