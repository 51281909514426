/**
 * React context for data that is used repeatedly throughout the application. Namely, Client details and a list of the Client's Users.
 * This prevents needing to make repeated queries to get those details in multiple components, and prevents needing to prop drill throughout the component tree.
 * In this context (pun unintended), Client refers to a SummIT Security customer.
 */

import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { UsersApi } from 'Api/Users/UsersApi';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { ClientDetailsModel, License } from 'Models/ClientDetailsModel';
import { UserResponse } from 'Models/User';

interface CachedDataContextValue {
    clientDetails: ClientDetailsModel;
    refreshUsers: () => void;
    users: UserResponse[];
}
export const CachedDataContext = createContext<CachedDataContextValue | undefined>(undefined);

interface CachedDataProviderProps {
    children: React.ReactNode;
    clientDetailsApi: ClientDetailsApi;
    usersApi: UsersApi;
}
export const CachedDataProvider = (props: CachedDataProviderProps): JSX.Element => {
    const [clientDetails, setClientDetails] = useState<ClientDetailsModel>();
    const [error, setError] = useState<Error>();
    const [users, setUsers] = useState<UserResponse[]>();

    const getUsers = useCallback(() => {
        const getUsers = async (): Promise<void> => {
            try {
                const usersResponse = await props.usersApi.getUsers();
                const users = usersResponse.data;
                setUsers(users);
            } catch (error) {
                setError(error);
            }
        };
        getUsers();
    }, [props.usersApi]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        const getClientDetails = async (): Promise<void> => {
            try {
                const clientDetailsResponse = await props.clientDetailsApi.getClientDetails();
                setClientDetails(clientDetailsResponse.data);
            } catch (error) {
                setError(error);
            }
        };
        getClientDetails();
    }, [props.clientDetailsApi]);

    if (error) {
        return <Text>{error.message}</Text>;
    } else if (clientDetails === undefined || users === undefined) {
        return <Placeholder />;
    } else {
        return <CachedDataContext.Provider value={{ clientDetails, refreshUsers: getUsers, users }}>{props.children}</CachedDataContext.Provider>;
    }
};

export const useCachedData = (): CachedDataContextValue => {
    const context = useContext(CachedDataContext);
    if (context === undefined) {
        throw new Error('useCachedData must be used within a CachedDataProvider.');
    }
    return context;
};

/**
 * Compare a list of licenses that a Client has with another list of authorized licenses. Used to determine if a Client should have access to some SummIT Security feature.
 * If simply wrapping a component for conditional rendering, use <ClientLicenseComponent>.
 * @param authorizedLicenses list of licenses to compare the Client's licenses against.
 * @param clientLicenses list of licenses that the Client has.
 * @returns true if the Client has at least one license in authorizedLicenses, else false.
 */
export const clientHasAuthorizedLicense = (authorizedLicenses: License[], clientLicenses: License[]): boolean => {
    return clientLicenses.some((license) => authorizedLicenses.includes(license));
};
