import { useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { getFrameworkGroupControlParts } from 'Helpers/ControlFormatter/ControlFormatter';
import { DetailedControlFrameworkResponse, DetailedControlGroupResponse, Effectiveness, EffectivenessSelectOptions, OperationalControl } from 'Models/OperationalControls';

import styles from './TargetEffectivenessTab.module.css';

export enum TargetEffectivenessTabType {
    CONTROL = 'control',
    CONTROL_GROUP = 'control group',
    FRAMEWORK = 'framework',
}

interface BaseTargetEffectivenessTabProps {
    controlsApi: ControlsApi;
    targetEffectivenessUpdated: () => void;
    linkOnSuccess: string;
}

export interface ControlTargetEffectivenessTabProps extends BaseTargetEffectivenessTabProps {
    _type: TargetEffectivenessTabType.CONTROL;
    detailedControlResponse: OperationalControl;
    isDeleted?: boolean;
}

export interface ControlGroupTargetEffectivenessTabProps extends BaseTargetEffectivenessTabProps {
    _type: TargetEffectivenessTabType.CONTROL_GROUP;
    detailedControlGroupResponse: DetailedControlGroupResponse;
    isDeleted?: boolean;
}

export interface FrameworkTargetEffectivenessTabProps extends BaseTargetEffectivenessTabProps {
    _type: TargetEffectivenessTabType.FRAMEWORK;
    detailedFrameworkResponse: DetailedControlFrameworkResponse;
}

export type TargetEffectivenessTabProps = ControlTargetEffectivenessTabProps | ControlGroupTargetEffectivenessTabProps | FrameworkTargetEffectivenessTabProps;

export const TargetEffectivenessTab = (props: TargetEffectivenessTabProps): JSX.Element => {
    const defaultEffectiveness = ((): Effectiveness | null => {
        if (props._type === TargetEffectivenessTabType.CONTROL && props.detailedControlResponse.target_effectiveness !== undefined) {
            return props.detailedControlResponse.target_effectiveness;
        } else if (props._type === TargetEffectivenessTabType.CONTROL_GROUP && props.detailedControlGroupResponse.target_effectiveness !== undefined) {
            return props.detailedControlGroupResponse.target_effectiveness;
        } else if (props._type === TargetEffectivenessTabType.FRAMEWORK && props.detailedFrameworkResponse.target_effectiveness !== undefined) {
            return props.detailedFrameworkResponse.target_effectiveness;
        } else return null;
    })();

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [isUpdatingEffectiveness, setIsUpdatingEffectiveness] = useState(false);
    const [selectedTargetEffectiveness, setSelectedTargetEffectiveness] = useState<Effectiveness | null>(defaultEffectiveness);

    const OCObjectText = ((): string => {
        if (props._type === TargetEffectivenessTabType.CONTROL) {
            return 'Control';
        } else if (props._type === TargetEffectivenessTabType.CONTROL_GROUP) {
            return 'Control group';
        } else {
            return 'Framework';
        }
    })();

    const isSaveButtonDisabled = (props._type === TargetEffectivenessTabType.CONTROL || props._type === TargetEffectivenessTabType.CONTROL_GROUP) && props.isDeleted;

    const handleSelectTargetChange = (value: ChangeEventType): void => {
        if (value !== undefined) {
            setSelectedTargetEffectiveness(Number(value));
        } else {
            setSelectedTargetEffectiveness(null);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsUpdatingEffectiveness(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            if (props._type === TargetEffectivenessTabType.CONTROL) {
                const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(props.detailedControlResponse);
                await props.controlsApi.updateControlTargetEffectiveness(controlFramework, controlGroupId, controlId, { target_effectiveness: selectedTargetEffectiveness });
            } else if (props._type === TargetEffectivenessTabType.CONTROL_GROUP) {
                await props.controlsApi.updateControlGroupTargetEffectiveness(props.detailedControlGroupResponse.control_framework, props.detailedControlGroupResponse.control_group_id, { target_effectiveness: selectedTargetEffectiveness });
            } else if (props._type === TargetEffectivenessTabType.FRAMEWORK) {
                await props.controlsApi.updateFrameworkTargetEffectiveness(props.detailedFrameworkResponse.control_framework, { target_effectiveness: selectedTargetEffectiveness });
            }

            props.targetEffectivenessUpdated();

            setSuccessMessage(`${OCObjectText} target effectiveness updated.`);
        } catch (e) {
            handleRequestError(e);
        } finally {
            setIsUpdatingEffectiveness(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    return (
        <Form noValidate onSubmit={handleSubmit}>
            {successMessage && <LinkButtonToast variant="success" clearToast={() => setSuccessMessage(undefined)} linkButtonText={`Return to ${OCObjectText.toLowerCase()}`} linkButtonTo={props.linkOnSuccess} text={successMessage} />}
            {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
            <div className={styles.formFieldTarget}>
                <FormFieldSelect options={EffectivenessSelectOptions} handleChange={handleSelectTargetChange} formFieldId="targetEffectiveness" selectedOption={Number(selectedTargetEffectiveness)} formFieldLabel="Target Effectiveness" isClearable tooltip={`Select an effectiveness value that represents the organization's goal to be met or exceeded for this ${OCObjectText}.`} />
            </div>
            <div className={styles.buttonContainer}>
                <Button variant="submit" isLoading={isUpdatingEffectiveness} loadingText="Saving..." disabled={isSaveButtonDisabled}>
                    SAVE
                </Button>
            </div>
        </Form>
    );
};
