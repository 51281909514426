import { CONTROLS, DDQ, DOCUMENTS, FRAMEWORKS, GROUPS, PUBLIC, QUESTIONS, SERVICES, TPRM } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { ControlFrameworkInstanceResponse, QuestionnaireGroupControlsResponse, Service, UpdateControlInstanceQuestionsRequest, UpdateControlInstanceRequest } from 'Models/TPRM';
import { publicRestApiDelete, publicRestApiGet, publicRestApiPost } from 'PublicRestApiConnector';

import { DDQApi } from './DDQApi';

export class AWSPublicDDQApi implements DDQApi {
    getServiceDetails = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<Service>> => {
        return publicRestApiGet(`/${PUBLIC}/${TPRM}/${SERVICES}/`, {});
    };

    getQuestionnaireFrameworks = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<ControlFrameworkInstanceResponse[]>> => {
        return publicRestApiGet(`/${PUBLIC}/${TPRM}/${DDQ}/${FRAMEWORKS}/`);
    };

    getQuestionnaireGroupControls = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string): Promise<ResponseModel<QuestionnaireGroupControlsResponse>> => {
        return publicRestApiGet(`/${PUBLIC}/${TPRM}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}`);
    };

    thirdPartySaveControl = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, request: UpdateControlInstanceQuestionsRequest): Promise<ResponseModel<void>> => {
        return publicRestApiPost(`/${PUBLIC}/${TPRM}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}`, request);
    };
    clientSaveControl = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, request: UpdateControlInstanceRequest): Promise<ResponseModel<void>> => {
        return Promise.reject(new Error('This should not be called by public DDQs'));
    };
    deleteAnswerDocument = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, questionId: string, documentId: string): Promise<ResponseModel<void>> => {
        return publicRestApiDelete(`/${PUBLIC}/${TPRM}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}/${QUESTIONS}/${questionId}/${DOCUMENTS}/${documentId}`);
    };
    deleteControlAssessmentDocument = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, documentId: string): Promise<ResponseModel<void>> => {
        return Promise.reject(new Error('This should not be called by public DDQs'));
    };
}
