import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { QuestionConfiguration, UpdateControlConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';

import { QuestionnaireConfigurationModal, SelectedQuestionWrapper } from '../DueDiligenceQuestionnaireConfiguration';

export interface DeleteQuestionModalProps extends QuestionnaireConfigurationModal {
    selectedQuestionWrapper: SelectedQuestionWrapper;
}

export const DeleteQuestionModal = (props: DeleteQuestionModalProps): JSX.Element => {
    const deleteQuestion = async (): Promise<string> => {
        const request: UpdateControlConfigurationRequest = {
            questions: Array.from(props.selectedQuestionWrapper.controlQuestions.values())
                .map((question): QuestionConfiguration => {
                    return {
                        _type: question._type,
                        text: question.text,
                        mapped_risk_ratings: question.riskRatings,
                        options: question.options,
                    };
                })
                .filter((questionConfiguration, index) => {
                    return index !== props.selectedQuestionWrapper.selectedQuestionIndex;
                }),
        };

        await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, props.selectedQuestionWrapper.framework, props.selectedQuestionWrapper.groupId, props.selectedQuestionWrapper.controlId)]));
        props.onModalActionComplete();
        return 'Question deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Question Configuration" areYouSureText="Are you sure you want to delete the following question?" performOperation={deleteQuestion} hideModal={props.hideModal}>
            <Text>{props.selectedQuestionWrapper.controlQuestions.get(props.selectedQuestionWrapper.selectedQuestionIndex)?.text}</Text>
        </ConfirmationModal>
    );
};
