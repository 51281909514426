import { ACTIVE, CONTROLS, DEACTIVATE, GOVERNANCE, INACTIVE, VERSIONS } from 'Config/Paths';
import { CreateGovernanceVersionRequest, GovernanceVersion } from 'Models/Governance';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { GovernanceApi } from './GovernanceApi';

export class AWSGovernanceApi implements GovernanceApi {
    getGovernanceItemsForControl = (controlId: string): Promise<ResponseModel<GovernanceVersion[]>> => {
        const encoded = encodeURIComponent(controlId);
        return restApiGet(`/${GOVERNANCE}/${CONTROLS}/${encoded}`, {});
    };

    getAllActiveVersions = (): Promise<ResponseModel<GovernanceVersion[]>> => {
        return restApiGet(`/${GOVERNANCE}/${ACTIVE}`, {});
    };

    getAllRetiredVersions = (): Promise<ResponseModel<GovernanceVersion[]>> => {
        return restApiGet(`/${GOVERNANCE}/${INACTIVE}`, {});
    };

    getAllVersionsForGovernance = (governanceId: string): Promise<ResponseModel<GovernanceVersion[]>> => {
        return restApiGet(`/${GOVERNANCE}/${governanceId}/${VERSIONS}`, {});
    };

    retireGovernance = (governanceId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`${GOVERNANCE}/${governanceId}/${DEACTIVATE}`);
    };

    getEffectiveVersionForGovernance = (governanceId: string): Promise<ResponseModel<GovernanceVersion | void>> => {
        return restApiGet(`/${GOVERNANCE}/${governanceId}/${VERSIONS}/${ACTIVE}`, {});
    };

    createInitialGovernanceVersion = (request: CreateGovernanceVersionRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${GOVERNANCE}/${VERSIONS}`, request);
    };

    addGovernanceVersion = (governanceId: string, request: CreateGovernanceVersionRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${GOVERNANCE}/${governanceId}/${VERSIONS}`, request);
    };
}
