/*
	AWSComplianceRequirementsApi.ts -- AWS-specific interface used to CRUD Compliance Requirements.
*/

import { ASSESSMENTS, COMPLIANCE_REQUIREMENTS, CONTROLS, FRAMEWORKS, GROUPS, MAPPING, OPERATIONAL_CONTROLS, REQUIREMENTS } from 'Config/Paths';
import { ComplianceRequirementForControlResponse, ComplianceRequirementWithAllControlStatusesResponse, ComplianceRequirementsForRegulationResponse, Regulation, UpdateAssignedControlRequest } from 'Models/ComplianceRequirements';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiGet, restApiPost } from 'RestApiConnector';

import { ComplianceRequirementsApi } from './ComplianceRequirementsApi';

export class AWSComplianceRequirementsApi implements ComplianceRequirementsApi {
    getAvailableRegulations = (): Promise<ResponseModel<Regulation[]>> => {
        return restApiGet(`/${COMPLIANCE_REQUIREMENTS}`, {});
    };
    getComplianceRequirementsForRegulation = (regulationName: string): Promise<ResponseModel<ComplianceRequirementsForRegulationResponse>> => {
        return restApiGet(`/${COMPLIANCE_REQUIREMENTS}/${regulationName}`, {});
    };
    getComplianceRequirementsForControlDetails = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<ComplianceRequirementForControlResponse[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${COMPLIANCE_REQUIREMENTS}`, {});
    };
    getComplianceRequirementsForControlAssessment = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<ComplianceRequirementForControlResponse[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${COMPLIANCE_REQUIREMENTS}/${ASSESSMENTS}`, {});
    };
    updateComplianceRequirementControls = (regulationName: string, complianceRequirementId: string, body: UpdateAssignedControlRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${COMPLIANCE_REQUIREMENTS}/${regulationName}/${REQUIREMENTS}/${complianceRequirementId}/${MAPPING}`, body);
    };
    getRequirementDetails = (regulationName: string, complianceRequirementId: string): Promise<ResponseModel<ComplianceRequirementWithAllControlStatusesResponse>> => {
        return restApiGet(`/${COMPLIANCE_REQUIREMENTS}/${regulationName}/${REQUIREMENTS}/${complianceRequirementId}/${MAPPING}`);
    };
}
