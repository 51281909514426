/*
    FormFieldTextArea.tsx - Component for text fields within an HTML form.
*/
import { FormControl, FormGroup } from 'react-bootstrap';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

interface FormFieldTextAreaProps {
    formFieldId: string;
    formFieldLabel: string;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: string;
    placeholder?: string;
    handleChange?: any;
    invalidMessage?: string;
    disabled?: boolean;
    value?: string;
    rows?: number;
}

export function FormFieldTextArea(props: FormFieldTextAreaProps): JSX.Element {
    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <FormLabel required={props.required} hideRequiredSymbol={props.hideRequiredSymbol}>
                {props.formFieldLabel}
            </FormLabel>
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <FormControl as="textarea" rows={props.rows ? props.rows : 5} name={props.formFieldId} className={styles.customFormControl} required={props.required} placeholder={props.placeholder} onChange={props.handleChange} disabled={props.disabled} value={props.value} />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
}
