/*
	HeaderNotifications.tsx -- A component for the header bar to create a notification dropdown.
*/

import { faBell } from '@fortawesome/free-solid-svg-icons';

import { DropdownItem, DropdownItemClickType, IconDropdownButton } from 'Components/Buttons/DropdownButton';
import { sortNotifications } from 'Helpers/Compare';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getNotificationDisplayName } from 'Helpers/NotificationFormatter/NotificationFormatter';
import { getNotificationUrl } from 'Helpers/URLBuilder/URLBuilder';
import { UserNotification } from 'Models/Notification';

export interface HeaderNotificationProps {
    notifications: UserNotification[];
}

export const HeaderNotifications = (props: HeaderNotificationProps) => {
    /**
     * Given a list of Notifications, sort/format them into items appropriate for a drop-down menu.
     */
    const convertNotificationsToDropdownItems = (notifications: UserNotification[]): DropdownItem[] => {
        const dropdownItems: DropdownItem[] = sortNotifications(notifications).map((notification: UserNotification) => {
            return {
                _type: DropdownItemClickType.HYPERLINK,
                text: `${getNotificationDisplayName(notification)} due ${iso8601ToUsDateShort(notification.due_date)}`,
                href: getNotificationUrl(notification),
            };
        });

        return dropdownItems;
    };

    return <IconDropdownButton badgeColor="blue" badgeVariant="standard" dropdownItems={convertNotificationsToDropdownItems(props.notifications)} emptyText="No Notifications" icon={faBell} />;
};
