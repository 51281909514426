import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useRef } from 'react';

import { ChartAxis, ChartSeries, axisColor, chartColor, chartFont } from './charts.common';

export interface StackedAreaChartProps {
    onChartClick: (item: string) => void;
    xAxisLabels: string[];
    yAxisInfo: ChartAxis;
    lines: ChartSeries[];
}

export const StackedAreaChart = (props: StackedAreaChartProps): JSX.Element => {
    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);
    const labelColor = getComputedStyle(document.documentElement).getPropertyValue(chartColor);

    const chartRef = useRef() as React.MutableRefObject<ReactECharts>;

    // This ensures that the chart does not re-render when a new onclick is created in the prop
    useEffect(() => {
        const instance = chartRef.current.getEchartsInstance();
        instance.on('click', (params) => {
            props.onChartClick(`${params.name} | ${params.seriesName}`);
        });
    }, [props]);

    const getOption = (info: Omit<StackedAreaChartProps, 'onChartClick'>, primaryFont: string, labelColor: string): EChartsOption => {
        // Add a non functional axis to complete the grid
        const yAxes: ChartAxis[] = [info.yAxisInfo, { name: '', position: 'right' }];

        return {
            aria: {
                show: true,
            },
            tooltip: {
                trigger: 'axis',
            },
            legend: { show: false },
            textStyle: {
                fontFamily: primaryFont,
            },
            grid: {
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: info.xAxisLabels,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: axisColor,
                        },
                    },
                    boundaryGap: false,
                    axisLabel: {
                        color: labelColor,
                    },
                },
            ],

            yAxis: yAxes.map((yAxis) => ({
                type: 'value',
                name: yAxis.name,
                position: yAxis.position,
                min: yAxis.min,
                max: yAxis.max,
                minInterval: yAxis.minInterval,
                maxInterval: yAxis.maxInterval,
                interval: yAxis.interval,
                alignTicks: true,
                show: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: axisColor,
                    },
                },
                axisLabel: {
                    formatter: yAxis.labelFormatter,
                    color: labelColor,
                },
                nameLocation: 'middle',
                nameGap: 50,
                nameRotate: yAxis.position === 'left' ? 90 : 270,
                splitLine: {
                    lineStyle: {
                        color: axisColor,
                    },
                },
                nameTextStyle: {
                    color: labelColor,
                },
            })),
            series: info.lines.map((lineInfo) => ({
                name: lineInfo.name,
                type: 'line',
                stack: 'Total',
                areaStyle: {},
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: lineInfo.color,
                },
                symbol: 'circle',
                symbolSize: 10,
                data: lineInfo.data,
            })),
        };
    };

    return (
        <ReactECharts
            option={getOption(props, primaryFont, labelColor)}
            style={{
                height: '100%',
                width: '100%',
                minHeight: '400px',
                minWidth: '400px',
            }}
            ref={chartRef}
        />
    );
};
