import { useState } from 'react';

import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { RiskAssessment, RiskCategoryResponse } from 'Models/RiskRegister';
import { OptionType } from 'Models/Types/GlobalType';

import { AssessmentHistoryTable } from './AssessmentHistoryTable/AssessmentHistoryTable';
import styles from './AssessmentListing.module.css';
import { ALL_RISKS } from '../RiskAssessmentListing';

export interface AssessmentListingProps {
    categories: RiskCategoryResponse[];
    handleRiskCategoryChange: (value: ChangeEventType) => void;
    selectedRiskCategory: string;
    riskAssessments: RiskAssessment[];
}

export enum AverageOrTotal {
    AVERAGE,
    TOTAL,
}

const averageOrTotalOptions: OptionType[] = [
    { label: 'Average', value: AverageOrTotal.AVERAGE },
    { label: 'Total', value: AverageOrTotal.TOTAL },
];

export const AssessmentListing = (props: AssessmentListingProps): JSX.Element => {
    const [averageOrTotal, setAverageOrTotal] = useState<AverageOrTotal>(AverageOrTotal.AVERAGE);

    return (
        <>
            <div className={styles.filterContainer}>
                <div className={styles.selectContainer}>
                    <FormFieldSelect options={averageOrTotalOptions} formFieldId="averageOrTotal" formFieldLabel="Display Average/Total Risk" handleChange={(value) => setAverageOrTotal(value as AverageOrTotal)} selectedOption={averageOrTotal} />
                </div>
                <div className={styles.selectContainer}>
                    <FormFieldSelect options={props.categories.map((category) => ({ label: category.title, value: category.id }))} formFieldId="selectedRiskCategory" formFieldLabel="Filter By Risk Category" handleChange={props.handleRiskCategoryChange} selectedOption={props.selectedRiskCategory} />
                </div>
            </div>
            <AssessmentHistoryTable
                averageOrTotal={averageOrTotal}
                riskAssessments={props.riskAssessments.filter((riskAssessment) => {
                    if (riskAssessment.category.id === props.selectedRiskCategory) {
                        return true;
                    } else if (props.selectedRiskCategory === ALL_RISKS) {
                        return true;
                    } else return false;
                })}
            />
        </>
    );
};
