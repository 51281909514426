import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

import styles from './Dashlet.module.css';

/**
 * @param title             - The title of the dashlet. All dashlets should have a title and this is the default method for displaying the title, but it is optional because some dashlets have customized headers, such as if the title is a hyperlink, or if there are drop-down filters or buttons aligned with the title.
 * @param titleTooltip      - A tooltip that can be included with the dashlet title.
 * @param content           - The content of the dashlet.
 * @param dashlets          - Nested dashlets that are displayed within this dashlet. This is used to create a grid of dashlets.
 * @param verticalAlignment - If true, the dashlets will be displayed in a column. If false, the dashlets will be displayed in a row. NOTE: Do not manually provide this prop, it is automatically provided by the parent component (which can be another Dashlet when dashlets are nested).
 */
export interface DashletProps {
    title?: string;
    titleTooltip?: string;
    content?: React.ReactNode;
    dashlets?: DashletProps[];
    verticalAlignment?: boolean;
}
/**
 * A dashlet is a grouping of content within a dashboard. It is the familiar dark blue box with rounded corners. Multiple dashlets on a page are automatically aligned in an alternating row/column layout based on nesting.
 */
export const Dashlet = (props: DashletProps): JSX.Element => {
    if (props.dashlets) {
        return (
            <div className={props.verticalAlignment ? styles.dashletAlignmentColumn : styles.dashletAlignmentRow}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} verticalAlignment={!dashlet.verticalAlignment} {...dashlet} />
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.dashlet}>
                <div className={styles.title}>
                    <Text variant="Header2" color="white">
                        {props.title}
                    </Text>
                    {props.titleTooltip && <FormFieldTooltip text={props.titleTooltip} />}
                </div>
                {props.content}
            </div>
        );
    }
};
