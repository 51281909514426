import axios, { AxiosInstance } from 'axios';

import { AWSConfig } from 'Config/AWSConfig';
import { CLIENTS, LOGIN, PUBLIC, REGISTER, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { PublicDDQRegistrationRequest } from 'Models/TPRM';

import { PublicDDQAuthApi } from './PublicDDQAuthApi';

export class AWSPublicDDQAuthApi implements PublicDDQAuthApi {
    axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create();
        this.axiosInstance.defaults.baseURL = AWSConfig.apiGateway.URL;

        this.axiosInstance.interceptors.response.use(
            (response) => {
                return response.data;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    registerForDDQ = async (request: PublicDDQRegistrationRequest, clientId: string, thirdPartyId: string, serviceId: string): Promise<ResponseModel<void>> => {
        try {
            return await this.axiosInstance.post(`${PUBLIC}/${TPRM}/${REGISTER}/${CLIENTS}/${clientId}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/`, request);
        } catch (error) {
            console.error(error.response);
            throw new Error(error.response.data.message);
        }
    };

    isAuthorized = async (credentials: string): Promise<ResponseModel<void>> => {
        try {
            const headers = { Authorization: `Basic ${credentials}` };
            return await this.axiosInstance.get(`${PUBLIC}/${TPRM}/${LOGIN}`, { headers: headers });
        } catch (error) {
            console.error(error.response);
            throw new Error(error.response.data.message);
        }
    };
}
