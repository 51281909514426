import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, Props } from 'react-select';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { ColorTheme, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

export interface FormFieldMultiOptionSelectProps {
    accessibilityLabel: string;
    formFieldId?: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string | JSX.Element;
    invalidMessage?: string;
    handleChange?: (value: GroupOptionType[], formFieldId?: string) => void;
    disabled?: boolean;
    options?: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

export const FormFieldMultiOptionSelect = (props: FormFieldMultiOptionSelectProps) => {
    const colorTheme = props.colorTheme ?? 'light';

    const handleChange = (value: OnChangeValue<GroupOptionType, true>): void => {
        if (props.handleChange) {
            if (Array.isArray(value)) {
                props.handleChange(value, props.formFieldId);
            } else {
                props.handleChange([], props.formFieldId);
            }
        }
    };

    const selectProps: Props<GroupOptionType, true> = {
        options: props.options,
        onChange: handleChange,
        isMulti: true,
        isDisabled: props.disabled,
        inputId: props.formFieldId,
        defaultValue: props.defaultSelectedOptions,
    };
    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            {props.formFieldLabel && (
                <FormLabel color={colorTheme === 'light' ? undefined : 'white'} required={props.isRequiredField}>
                    {props.formFieldLabel}
                </FormLabel>
            )}
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <Select
                {...selectProps}
                aria-label={props.accessibilityLabel}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: colorTheme === 'light' ? 'var(--hps-blue)' : 'transparent',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                        backgroundColor: colorTheme === 'light' ? base.backgroundColor : 'var(--hps-blue)',
                    }),
                    multiValue: (styles) => ({
                        ...styles,
                        backgroundColor: colorTheme === 'light' ? styles.backgroundColor : 'var(--hps-white)',
                    }),
                    multiValueRemove: (styles, _) => ({
                        ...styles,
                        color: colorTheme === 'light' ? 'var(--hps-gray)' : 'var(--hps-blue)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
