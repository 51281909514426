import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';

import styles from './DeleteTPRMThirdPartyModal.module.css';

export interface DeleteTPRMThirdPartyModalProps {
    hideModal: () => void;
    thirdParty: ThirdPartyResponseWithServices;
    tprmApi: TPRMApi;
    thirdPartyDeleted: () => void;
}

export const DeleteTPRMThirdPartyModal = (props: DeleteTPRMThirdPartyModalProps) => {
    const deleteThirdParty = async (): Promise<string> => {
        await props.tprmApi.deleteThirdParty(props.thirdParty.id);
        props.thirdPartyDeleted();
        return 'Third party deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Third Party" areYouSureText={`Are you sure you want to delete "${props.thirdParty.name}"?`} hideModal={props.hideModal} performOperation={deleteThirdParty}>
            <ul className={styles.deleteConfirmationList}>
                <li>
                    <Text>All of the third party's services will be deleted.</Text>
                </li>
                <li>
                    <Text>All issues mapped to the third party will be deleted.</Text>
                </li>
                <li>
                    <Text>All exceptions mapped to the third party will be deleted.</Text>
                </li>
            </ul>
        </ConfirmationModal>
    );
};
