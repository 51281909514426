import { forwardRef } from 'react';
import { Carousel as BSCarousel, CarouselItemProps as BSCarouselItemProps } from 'react-bootstrap';

export interface CarouselItemProps {
    children: JSX.Element;
}

export const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps & BSCarouselItemProps>(({ children, ...props }, ref) => {
    return (
        <BSCarousel.Item {...props} ref={ref}>
            {children}
        </BSCarousel.Item>
    );
});
