import { ServiceMultiOptionFilter } from 'Components/Filters/ServiceMultiOptionFilter/ServiceMultiOptionFilter';
import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions, OptionType } from 'Models/Types/GlobalType';

import styles from './ServiceListingBodyToolbar.module.css';

export interface ServiceListingBodyToolbarProps {
    filterOptions: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];
    thirdPartyFilter: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentSort: string;
}

const ServiceListingSortOptions: OptionType[] = [
    {
        label: 'Assessment Due Date',
        value: ServiceListingSortFilterOptions.DUE_DATE,
    },
    {
        label: 'Date Created',
        value: ServiceListingSortFilterOptions.CREATED_TIME,
    },
    {
        label: 'Residual Risk',
        value: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
    },
    {
        label: 'Risk Rating',
        value: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
    },
    {
        label: 'Service',
        value: ServiceListingSortFilterOptions.NAME,
    },
    {
        label: 'Service Manager',
        value: ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID,
    },
    {
        label: 'Third Party',
        value: ServiceListingSortFilterOptions.VENDOR_NAME,
    },
];

export const ServiceListingBodyToolbar = (props: ServiceListingBodyToolbarProps) => {
    const handleSelectChange = (value: ChangeEventType): void => {
        const sortOption = value as ServiceListingSortFilterOptions;
        if (sortOption) {
            props.sortCardsBy(sortOption, SortDirection.ASC);
        }
    };

    return (
        <div className={styles.filter}>
            <div className={styles.sortSelect}>
                <FormFieldSelect options={ServiceListingSortOptions} handleChange={handleSelectChange} formFieldId="isSortingCards" formFieldLabel="Sort" selectedOption={props.currentSort} />
            </div>
            <div className={styles.filterSelectServiceManager}>
                <UserFilter filterLabel="Filter by Service Manager" onUsersSelected={props.thirdPartyFilter} userFilterId="vendor_service_manager_user_id" formFieldId="serviceManagerFilter" />
            </div>
            <div className={styles.filterSelectServiceAttributes}>
                <ServiceMultiOptionFilter filterOptions={props.filterOptions} defaultSelectedOptions={props.defaultSelectedOptions} thirdPartyFilter={props.thirdPartyFilter} />
            </div>
        </div>
    );
};
