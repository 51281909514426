import { Link } from 'Components/Buttons/Buttons';
import { MappableItem, MultipleMappingBase, MultipleMappingBaseProps } from 'Components/MultipleMapping/MultipleMappingBase';
import { Text } from 'Components/Text/Text';
import { CONFIGURATION } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { controlTextToString, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { OperationalControl } from 'Models/OperationalControls';

import { ControlMappingListItem } from './ControlMappingListItem/ControlMappingListItem';

export interface ControlMappingItem extends MappableItem {
    control: OperationalControl;
}

export interface MultipleControlMappingProps {
    controls: OperationalControl[];
    handleControlChange: (controls: string[]) => void;
    currentMappedControlIdentifiers?: string[];
}

export const MultipleControlMapping = (props: MultipleControlMappingProps): JSX.Element => {
    const renderMappingItems = (items: ControlMappingItem[], handleSelected: (value: ControlMappingItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, selected: ControlMappingItem[]) => {
        return items.map((item, index) => <ControlMappingListItem key={index} controlMappingItem={item} handleSelected={handleSelected} selected={selected.includes(item)} />);
    };

    const multipleMappingBaseProps: MultipleMappingBaseProps<ControlMappingItem> = {
        mappingItems: props.controls.map((control) => ({ id: control.identifier, title: getHumanReadableControlIdFromControl(control), description: controlTextToString(control.metadata.control_text), control })),
        handleChange: props.handleControlChange,
        sortMappingItems: (items: ControlMappingItem[]) => items.sort((a, b) => controlComparator(a.control, b.control)),
        currentMappedIdentifiers: props.currentMappedControlIdentifiers,
        renderMappingItems: renderMappingItems,
    };

    if (multipleMappingBaseProps.mappingItems) {
        return <MultipleMappingBase {...multipleMappingBaseProps} />;
    } else {
        return (
            <>
                <Text variant="Text3" noStyles>
                    There are no enabled controls.
                </Text>
                <Link size={'sm'} to={`/${CONFIGURATION}#companySettings`}>
                    Enable Controls
                </Link>
            </>
        );
    }
};
