export type Colors = 'blue' | 'lightBlue' | 'darkBlue' | 'darkGray' | 'lightGray' | 'white' | 'red' | 'yellow' | 'darkGreen';

// These hex values must match index.css
// These colors should only be used when css styling is not an option. For example, when a third-party library prevents us from applying styles.

export enum CSSColors {
    BLUE = '#053c59',
    LIGHT_BLUE = '#05a4ee',
    DARK_BLUE = '#052637',
    DARK_GRAY = '#8fa4af',
    LIGHT_GRAY = '#F2F2F2',
    WHITE = '#FFFFFF',
    RED = '#DC3545',
    YELLOW = '#D0DA41',
    DARK_GREEN = '#31673D',
    ORANGE = '#ffc107',
    LIGHT_GREEN = '#8DDA41',
    GRAY = '#6A899A',
}
