import { uniqBy } from 'lodash-es';

import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import { ComplianceRequirementStatus } from 'Components/ComplianceRequirements/ComplianceRequirementStatus';
import { Text } from 'Components/Text/Text';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';
import { COMPLIANCE_REQUIREMENTS, DETAILS, REQUIREMENTS } from 'Config/Paths';
import { sortComplianceRequirementForControl } from 'Helpers/Compare';
import { makeComplianceRequirementHumanReadable } from 'Helpers/ComplianceRequirementFormatter/ComplianceRequirementFormatter';
import { ComplianceRequirementForControlResponse, ComplianceRequirementStatusValues, titleCaseRequirementStatus } from 'Models/ComplianceRequirements';

import styles from './ControlRequirementListing.module.css';

export interface ControlRequirementListingProps {
    complianceRequirements: ComplianceRequirementForControlResponse[];
    displayMappedControlsModal: (complianceRequirement: ComplianceRequirementForControlResponse) => void;
}

export const ControlRequirementListing = (props: ControlRequirementListingProps): JSX.Element => {
    const calculateAmountControlsMapped = (complianceRequirement: ComplianceRequirementForControlResponse): string => {
        const filteredControls = uniqBy(complianceRequirement.assigned_control_sets.flat(), (control) => {
            return control.identifier;
        });
        return `${filteredControls.length} ${filteredControls.length === 1 ? 'Control' : 'Controls'}`;
    };
    return (
        <>
            <div className={styles.headerContainer}>
                <Text variant="Header2" noStyles>
                    Compliance Requirements
                </Text>
            </div>
            {props.complianceRequirements.length === 0 && <Text>There are no compliance requirements mapped to this control.</Text>}
            {props.complianceRequirements.length > 0 &&
                sortComplianceRequirementForControl(props.complianceRequirements).map((requirement, index) => {
                    return (
                        <div key={index}>
                            <Accordion>
                                <AccordionItem eventKey={requirement.identifier}>
                                    <div className={styles.requirementRow}>
                                        <AccordionToggle eventKey={requirement.identifier} ariaLabelSuffix="Compliance Requirement" />
                                        <div className={styles.requirementStatusContainer}>
                                            <ComplianceRequirementStatus status={requirement.status} />
                                        </div>
                                        <div className={styles.requirementTextContainer}>
                                            <Link variant="link" size="lg" to={`/${COMPLIANCE_REQUIREMENTS}/${requirement.regulation}/${REQUIREMENTS}/${requirement.identifier}/${DETAILS}`}>
                                                {makeComplianceRequirementHumanReadable(requirement.regulation, requirement.identifier)}
                                            </Link>
                                            <Text variant="Text3" noStyles color="darkGray">
                                                {requirement.text}
                                            </Text>
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionCollapse eventKey={requirement.identifier}>
                                    <div className={styles.description}>
                                        <div className={styles.overallStatusAndControlsMappedContainer}>
                                            {/** Overall Requirement Status when it is NOT undefined. */}
                                            {requirement.overall_status && (
                                                <div className={styles.overallStatusContainer}>
                                                    <Text variant="Text4" noStyles toUppercase>
                                                        Overall Requirement Status:&nbsp;
                                                        <GenericTooltip text={'The overall requirement status is determined by all controls the requirement is mapped to.'}></GenericTooltip>
                                                    </Text>

                                                    <div className={styles.overallStatus}>
                                                        <ComplianceRequirementStatus status={requirement.overall_status} />
                                                        <Text variant="Text3" color="darkGray">
                                                            {titleCaseRequirementStatus(requirement.overall_status as ComplianceRequirementStatusValues)}
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}

                                            {/** Overall Requirement Status when it is undefined. */}
                                            {!requirement.overall_status && (
                                                <div className={styles.overallStatusContainer}>
                                                    <Text variant="Text4" noStyles toUppercase>
                                                        Overall Requirement Status:&nbsp;
                                                        <GenericTooltip text={'The overall requirement status is determined by all controls the requirement is mapped to.'}></GenericTooltip>
                                                    </Text>

                                                    <div className={styles.overallStatus}>
                                                        <Text variant="Text3" color="darkGray">
                                                            -
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}

                                            {/** Controls Mapped */}
                                            <div className={styles.controlsMappedContainer}>
                                                <Text variant="Text4" noStyles toUppercase>
                                                    Controls Mapped:
                                                </Text>
                                                <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal(requirement)}>
                                                    {calculateAmountControlsMapped(requirement)}
                                                </Button>
                                            </div>
                                        </div>

                                        {/** Parent Requirement */}
                                        {requirement.parent_context && (
                                            <div>
                                                <Text variant="Text4" noStyles toUppercase>
                                                    Parent Requirement:
                                                </Text>
                                                <Text variant="Text3" color="darkGray">
                                                    {requirement.parent_context}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                </AccordionCollapse>
                                <hr className={styles.hrModifier} />
                            </Accordion>
                        </div>
                    );
                })}
        </>
    );
};
