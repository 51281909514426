import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, Props } from 'react-select';

import { DateOptionSelectOptions, DefaultDateRangeSelectOptions } from 'Models/Dashboards';
import { OptionType } from 'Models/Types/GlobalType';

import styles from '../FormField.module.css';

export type ChangeEventType = string | number | Array<string | number> | undefined;

export interface FormFieldDateOptionSelectProps {
    formFieldId: string;
    invalidMessage?: string;
    handleChange?: (value: ChangeEventType, formFieldId: string) => void;
    selectedOption?: DateOptionSelectOptions;
    disabled?: boolean;
    options?: OptionType[];
}

interface GroupedOption {
    readonly label: string;
    readonly options: readonly OptionType[];
}

export const FormFieldDateOptionSelect = (props: FormFieldDateOptionSelectProps): JSX.Element => {
    const convertSelectedOption = (selectedOption?: DateOptionSelectOptions): OptionType | OptionType[] | undefined | null => {
        if (selectedOption === undefined || selectedOption === null) {
            return selectedOption;
        } else {
            return groupedOptions[0].options?.find((option) => option.value === selectedOption);
        }
    };

    const dateRangeSelectOptions = props.options ?? DefaultDateRangeSelectOptions;

    const handleChange = (value: OnChangeValue<OptionType, true | false>): void => {
        if (props.handleChange) {
            if (Array.isArray(value)) {
                props.handleChange(
                    value.map((option) => option.value),
                    props.formFieldId
                );
            } else if (value && 'value' in value) {
                props.handleChange(value['value'], props.formFieldId);
            } else {
                props.handleChange(undefined, props.formFieldId);
            }
        }
    };

    const groupedOptions: readonly GroupedOption[] = [
        {
            label: 'Choose Date Range',
            options: dateRangeSelectOptions,
        },
        {
            label: 'Enter a Custom Date',
            options: [
                {
                    value: DateOptionSelectOptions.CUSTOM,
                    label: 'Custom Date',
                },
            ],
        },
    ];

    const selectProps: Props<OptionType, true | false> = {
        options: groupedOptions,
        onChange: handleChange,
        value: convertSelectedOption(props.selectedOption),
        isDisabled: props.disabled,
        inputId: props.formFieldId,
        placeholder: 'Choose a date...',
    };

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <Select
                {...selectProps}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: 'transparent',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: 'var(--hps-white)',
                        backgroundColor: 'var(--hps-blue)',
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: 'var(--hps-white)',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: 'var(--hps-white)',
                    }),
                    placeholder: (styles) => ({
                        ...styles,
                        color: 'var(--hps-white)',
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
