import { Colors } from 'Components/Colors';
import { Text } from 'Components/Text/Text';

import styles from './VisualLabel.module.css';

export interface VisualLabelProps {
    children: string;
    color?: Colors;
    required?: boolean;
    tooltip?: JSX.Element;
}

/**
 * A **non-semantic** label, with an optional tooltip and an optional required symbol.
 * This component should be used when a purely visual label should be present on the screen.
 *
 * For a component that provides both visual and semantic labeling within a Bootstrap form, use `FormLabel`.
 * If this component needs to provide semantic labeling, wrap it in a `<label>` that has the `htmlFor` prop.
 */
export const VisualLabel = (props: VisualLabelProps): JSX.Element => {
    return (
        <div className={styles.visualLabel}>
            <Text color={props.color} noStyles={true} variant="Text4" toUppercase>
                {props.children}
            </Text>
            {props.tooltip}
            {props.required && (
                <Text color={props.color} noStyles={true} variant="Text3">
                    &nbsp;*
                </Text>
            )}
        </div>
    );
};
