import { LOGIN, LOGOUT } from 'Config/Paths';
import { DESTINATION } from 'Helpers/Auth/AuthHandler';
import { AuthNavigator, OidcLoginSuccessResult } from 'Helpers/AuthNavigator';
import { Navigator } from 'Helpers/Navigator';
import { WindowNavigator } from 'Helpers/WindowNavigator';
import { AuthState } from 'Models/Auth';

export class WindowAuthNavigator implements AuthNavigator {
    navigator: WindowNavigator;

    constructor(navigator: Navigator = new WindowNavigator()) {
        this.navigator = navigator;
    }

    navigateToLogout = (authState: AuthState): void => {
        if (this.getIsLogout()) {
            return;
        }

        let destinationQuery = '';

        switch (authState) {
            case AuthState.UNAUTHENTICATED:
            case AuthState.TIMEOUT:
                const currentLocation = this.navigator.currentLocation();
                const destination = currentLocation.pathname + currentLocation.search + currentLocation.hash;
                destinationQuery = `?${DESTINATION}=${encodeURIComponent(destination)}`;
                break;
        }

        this.navigator.navigateTo(`/${LOGOUT}/${authState}${destinationQuery}`);
    };

    /**
     * Called when the user has been logged out of multiple tabs, then logs in via another tab, then clicks the "Log In" button in this tab.
     *
     * Navigates the user to the SummIT Security page they were initially trying to access.
     */
    navigateToLoginDestination = (): void => {
        this.navigator.navigateTo(this.getLoginDestination());
    };

    /**
     * Called when the user is unathenticated and needs to log in at Okta.
     *
     * Navigates the user to the login page served by Okta.
     */
    initiateOidcLogin = (url: string): void => {
        this.navigator.openExternalUrl(url, false);
    };

    /**
     * Called after the user has logged in at Okta.
     *
     * Navigates the user to the SummIT Security page they were initially trying to access.
     * Removes the URL used for handling OIDC login from browser history. (Otherwise, the user will encounter an error if they hit the browser's back button immediately after logging in.)
     */
    completeOidcLogin = (destinationPath: string): void => {
        this.navigator.navigateTo(destinationPath, true);
    };

    getLoginDestination = (): string => {
        if (this.getIsLogin()) {
            return '/';
        } else if (this.getIsLogout()) {
            // Note: `getSearchParameter` will automatially decode URI-encoded strings. `decodeURIComponent` is not used here because we need to preserve a layer of encoding for any characters that were "double-encoded" in `navigateToLogout`. For example, `navigateToLogout` will encode "?controlId=CIS%20CSC%20(v7.1)%231%231.1" as "%3FcontrolId%3DCIS%2520CSC%2520(v7.1)%25231%2523".
            const destination = this.navigator.getSearchParameter(DESTINATION);
            return destination === null ? '/' : destination;
        } else {
            const currentLocation = this.navigator.currentLocation();
            return currentLocation.pathname + currentLocation.search + currentLocation.hash;
        }
    };

    getLoginError = (): string | null => {
        return this.navigator.getSearchParameter('error');
    };

    getLoginSuccessResult = (): OidcLoginSuccessResult | undefined => {
        const code = this.navigator.getSearchParameter('code');
        const stateGuid = this.navigator.getSearchParameter('state');

        if (code && stateGuid) {
            return {
                code: code,
                stateGuid: stateGuid,
            };
        } else {
            return undefined;
        }
    };

    getIsLogin = (): boolean => {
        return this.navigator.currentLocation().pathname.startsWith(`/${LOGIN}`);
    };

    getIsLogout = (): boolean => {
        return this.navigator.currentLocation().pathname.startsWith(`/${LOGOUT}`);
    };
}
