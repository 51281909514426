import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'Components/Buttons/Buttons';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { Text } from 'Components/Text/Text';
import { CREATE, RISK_REGISTER } from 'Config/Paths';
import { RiskResponse } from 'Models/RiskRegister';
import { DisplayableTag } from 'Models/Tags';
import { UserResponse } from 'Models/User';

import styles from './Risks.module.css';

export interface RisksProps {
    users: UserResponse[];
    risks: RiskResponse[];
    controlIdentifier: string;
    getDisplayableTags: (tagIds: string[]) => DisplayableTag[];
}

export const Risks = (props: RisksProps): JSX.Element => {
    return (
        <>
            <div className={styles.header}>
                <Text color="blue" variant="Header2">
                    Risks Mitigated by Control
                </Text>
                <RBACComponent roles={[Role.ADMIN, Role.RISK_MANAGER, Role.RISK_USER]}>
                    <Link variant="primaryButton" to={`/${RISK_REGISTER}/${CREATE}?controlId=${encodeURIComponent(props.controlIdentifier)}`} fontAwesomeImage={faPlus}>
                        Add New
                    </Link>
                </RBACComponent>
            </div>
            <div className={styles.pageSection}>
                <RiskTable type="current risks" risks={props.risks} getDisplayableTags={props.getDisplayableTags} controlContext={props.controlIdentifier} />
            </div>
        </>
    );
};
