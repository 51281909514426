import { useEffect, useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { IssuesListing, IssuesListingProps } from 'Components/Issues/IssuesListing/IssuesListing';
import { AssociatedControlsModal } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ControlIssueResponse, IssueResponse, IssuesExceptionsModule, ThirdPartyIssueResponse } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

export interface IssuesTabOperationalControlsProps {
    issuesType: IssuesExceptionsModule.CONTROLS;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    users: UserResponse[];
}

export interface IssuesTabTprmProps {
    issuesType: IssuesExceptionsModule.TPRM;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    users: UserResponse[];
    thirdParties: ThirdPartyResponseWithServices[];
    thirdPartyIdQueryParam?: string;
}

export type IssuesTabProps = IssuesTabOperationalControlsProps | IssuesTabTprmProps;

/**
 * Renders a table of issues after fetching them via the API.
 */
export const IssuesTab = (props: IssuesTabProps) => {
    const [issues, setIssues] = useState<IssueResponse[]>();
    const [mappedControlsToDisplay, setMappedControlsToDisplay] = useState<OperationalControl[]>();
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);

    useEffect(() => {
        const getIssues = async (): Promise<void> => {
            try {
                const response = props.issuesType === IssuesExceptionsModule.CONTROLS ? await props.issuesApi.getAllControlIssues() : await props.issuesApi.getAllThirdPartyIssues();
                setIssues(response.data);
            } catch (error) {
                setLoadingErrorOccurred(true);
            }
        };

        getIssues();
    }, [props.issuesApi, props.issuesType]);

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    } else if (!issues) {
        return <Placeholder />;
    }

    const issuesListingProps: IssuesListingProps = {
        ...(props.issuesType === IssuesExceptionsModule.CONTROLS
            ? {
                  type: IssuesExceptionsModule.CONTROLS,
                  issues: issues as ControlIssueResponse[],
                  displayMappedControlsModal: setMappedControlsToDisplay,
              }
            : {
                  type: IssuesExceptionsModule.TPRM,
                  issues: issues as ThirdPartyIssueResponse[],
                  thirdParties: props.thirdParties,
                  preselectedThirdPartyIdForFilter: props.thirdPartyIdQueryParam,
              }),
        hideTitle: true,
    };

    return (
        <>
            {mappedControlsToDisplay && <AssociatedControlsModal associatedControls={mappedControlsToDisplay} hideModal={() => setMappedControlsToDisplay(undefined)} />}
            <IssuesListing {...issuesListingProps} />
        </>
    );
};
