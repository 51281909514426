/**
 * PageLayoutDefault: The default layout that most pages use, with a gray header and one or more white body sections.
 *  - Examples: Actions Listing, Action Details, Governance Listing, Governance Details.
 *
 * PageLayoutDefaultGridAndTable: A layout that uses the standard gray header, but with a grid and table layout instead of a series of body sections.
 *  - Examples: TPRM Third Party Listing, TPRM Third Party Service Listing.
 *
 * PageLayoutDashboard: A layout for full-page dashboards WITHOUT tabs.
 *  - Examples: TPRM Third Party Service Dashboard.
 *
 * PageLayoutDashboardTabs: A layout for full-page dashboards WITH tabs.
 *  - Examples: The overall "Dashboard" page.
 *
 * PageLayoutHybridDashboard: This is essentially a layout hybrid of PageLayoutDefault and PageLayoutDashboard. The header is a dashboard, while the body is the standard series of body sections.
 *  - Examples: Control Details, Risk Details.
 *
 * PageLayoutHybridDashboardGridAndTable: This is similar to PageLayoutHybridDashboard, but the content is a grid of cards and a table instead of a series of body sections.
 *  - Examples: Control Group Details. The Framework Details page manually implements this (dashboard header with grid and table body), since it has tabs which make it a unique layout.
 */

import { Dashlet, DashletProps } from 'Components/PageLayout/Dashlet/Dashlet';
import dashletStyles from 'Components/PageLayout/Dashlet/Dashlet.module.css';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { DashboardType } from 'Models/Dashboards';

import styles from './PageLayoutDashboard.module.css';

/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param dashlets          - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface PageLayoutDashboardFullProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    dashlets: DashletProps[];
}
/**
 * A layout for full-page dashboards WITHOUT tabs.
 * Note that the <Nav> and application <Header> components are static siblings of this and other PageLayout components and are neither implemented nor documented in this module.
 */
export const PageLayoutDashboard = (props: PageLayoutDashboardFullProps): JSX.Element => {
    return (
        <div className={styles.background}>
            <Header breadcrumb={props.headerBreadcrumb} title={props.headerTitle} />
            <Body dashlets={props.dashlets} includePadding={true} />
        </div>
    );
};

/**
 * @param title         - The title of the tab.
 * @param dashboardType - Used to determine which dashboard to display when the tab is selected.
 * @param dashlets      - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface PageLayoutDashboardFullTabProps {
    title: string;
    dashboardType: DashboardType;
    dashlets: DashletProps[];
}
/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param defaultTab        - The tab that is selected by default.
 * @param onTabChange       - Function that is called when the tab is changed.
 * @param tabs              - List of tabs that comprise the dashboard. Each tab has a title and a list of dashlets.
 */
interface PageLayoutDashboardFullTabsProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    defaultTab: string;
    onTabChange?: (eventKey?: string) => void;
    tabs: PageLayoutDashboardFullTabProps[];
}
/**
 * A layout for full-page dashboards WITH tabs.
 * Note that the <Nav> and application <Header> components are static siblings of this and other PageLayout components and are neither implemented nor documented in this module.
 */
export const PageLayoutDashboardTabs = (props: PageLayoutDashboardFullTabsProps): JSX.Element => {
    return (
        <div className={styles.background}>
            <Header breadcrumb={props.headerBreadcrumb} title={props.headerTitle} />
            <div className={styles.pageDashboardTabs}>
                <div>
                    <PrimaryTabs defaultActiveTab={props.defaultTab} onSelect={props.onTabChange} removePadding transparent tabButtonTextColor={'white'}>
                        {props.tabs.map((tab, index) => (
                            <Tab key={index} eventKey={tab.dashboardType} title={tab.title} unmountOnExit={true}>
                                <Body dashlets={tab.dashlets} includePadding={false} />
                            </Tab>
                        ))}
                    </PrimaryTabs>
                </div>
            </div>
        </div>
    );
};

/**
 * @param breadcrumb    - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param title         - The title of the page.
 */
interface HeaderProps {
    breadcrumb: React.ReactNode;
    title: string;
}
/**
 * The page header is the top section of the page, which contains some information that is common across all pages, such as the breadcrumb and title of the page.
 * WARNING: Do not confuse this with the overall application <Header> component, which is rendered above the "page."
 */
const Header = (props: HeaderProps): JSX.Element => {
    return (
        <div>
            <div className={styles.pageHeaderContent}>
                {props.breadcrumb}
                <Text color="white" variant="Header1">
                    {props.title}
                </Text>
            </div>
        </div>
    );
};

/**
 * @param dashlets - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface BodyProps {
    dashlets: DashletProps[];
    includePadding: boolean;
}
/**
 * The wrapper around the top level of dashlets.
 */
const Body = (props: BodyProps): JSX.Element => {
    return (
        <div className={props.includePadding ? styles.pageBodyWithPadding : styles.pageBodyNoPadding}>
            <div className={dashletStyles.dashletAlignmentColumn}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} {...dashlet} />
                ))}
            </div>
        </div>
    );
};
