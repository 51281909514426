import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ControlTable } from 'Components/ControlTable/ControlTable';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './AssociatedControlsModal.module.css';

const DEFAULT_NUMBER_OF_CONTROLS_SHOWN = 5;

export interface AssociatedControlsModalProps {
    hideModal: () => void;
    associatedControls: OperationalControl[];

    /**
     * If undefined, "Controls Mapped" will be the header text, and no secondary text will be used.
     */
    headerText?: { text: string; secondaryText?: string };
}

/**
 * This is a generic modal used to show the user the controls that are associated with an arbitrary entity. It is intended to be used in "listing" components, where one of the columns shows an "X Controls" button that opens this modal.
 * Renders a `ControlTable` populated with controls, if a non-zero number of controls are provided; otherwise, renders text informing the user that no controls are mapped to the entity.
 *
 * IMPORTANT: This follows the same patterns as `CollapsibleModal`. If a change is made here, consider whether the same change needs to be made there, and vice-versa.
 */
export const AssociatedControlsModal = (props: AssociatedControlsModalProps): JSX.Element => {
    const [showAll, setShowAll] = useState<boolean>(false);

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.headerText?.text ?? 'Controls Mapped'} secondaryText={props.headerText?.secondaryText} />
                {props.associatedControls.length > 0 ? (
                    <div className={styles.controlTable}>
                        <ControlTable controls={props.associatedControls} limit={showAll ? undefined : DEFAULT_NUMBER_OF_CONTROLS_SHOWN} />
                    </div>
                ) : (
                    <div>
                        <Text>No controls mapped.</Text>
                    </div>
                )}
                <div className={styles.showMoreButton}>
                    {props.associatedControls.length > DEFAULT_NUMBER_OF_CONTROLS_SHOWN && (
                        <Button variant="linkText" size="lg" onClick={() => setShowAll(!showAll)}>
                            {showAll ? (
                                <>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                    {` Show Less`}
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                    {` Show ${props.associatedControls.length - DEFAULT_NUMBER_OF_CONTROLS_SHOWN} More`}
                                </>
                            )}
                        </Button>
                    )}
                </div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
