import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, OptionProps, Props, components } from 'react-select';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { getUserNameFromUser, sortFormattedUsers } from 'Helpers/UserUtils';
import { Status, UserResponse } from 'Models/User';

import { UserOption, UserOptionProps } from './UserOption/UserOption';

/**
 * @param users This is not eligible for the cachedData.users context because one of its parents (ManageRisk) filters the users list before passing it as a prop.
 */
interface FormFieldNonClearableUserSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUserSelected: (value: UserResponse, formFieldId: string) => void;
    selectedUser?: UserResponse;
    disabled?: boolean;
    users: UserResponse[];
    includeInactive?: boolean;
    isClearable?: false;
}

/**
 * @param users This is not eligible for the cachedData.users context because one of its parents (ManageRisk) filters the users list before passing it as a prop.
 */
interface FormFieldClearableUserSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUserSelected: (value: UserResponse | undefined, formFieldId: string) => void;
    selectedUser?: UserResponse;
    disabled?: boolean;
    users: UserResponse[];
    includeInactive?: boolean;
    isClearable: true;
}

export type FormFieldUserSelectProps = FormFieldNonClearableUserSelectProps | FormFieldClearableUserSelectProps;

class UserResponseOption {
    value: string;
    label: string;
    user: UserResponse;

    constructor(user: UserResponse) {
        this.value = user.cognito_subject;
        this.label = getUserNameFromUser(user);
        this.user = user;
    }
}

export const FormFieldUserSelect = (props: FormFieldUserSelectProps) => {
    const mapUserToUserOption = (user: UserResponse): UserResponseOption => new UserResponseOption(user);

    const filterAndFormatUsers = (): UserResponseOption[] => {
        let users = [...props.users];

        if (!props.includeInactive) {
            users = users.filter((user) => user.status === Status.ACTIVE);
        }

        return sortFormattedUsers(users).map(mapUserToUserOption);
    };

    const handleChange = (value: OnChangeValue<UserResponseOption, false>): void => {
        if (value && value instanceof UserResponseOption) {
            props.onUserSelected(value.user, props.formFieldId);
        } else if (props.isClearable) {
            props.onUserSelected(undefined, props.formFieldId);
        }
    };

    const userOption = (props: OptionProps<UserResponseOption, false>): JSX.Element => {
        const userOptionProps: UserOptionProps = {
            user: props.data.user,
        };
        return (
            <components.Option {...props}>
                <UserOption {...userOptionProps} />
            </components.Option>
        );
    };

    const selectProps: Props<UserResponseOption, false> = {
        options: filterAndFormatUsers(),
        onChange: handleChange,
        value: props.selectedUser ? mapUserToUserOption(props.selectedUser) : undefined,
        isDisabled: props.disabled,
        inputId: props.formFieldId,
        isClearable: props.isClearable,
    };

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <FormLabel required={props.isRequiredField}>{props.formFieldLabel}</FormLabel>
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <Select
                {...selectProps}
                components={{ Option: userOption }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: 'var(--hps-blue)',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: 'var(--hps-blue)',
                    }),
                    multiValueRemove: (styles, _) => ({
                        ...styles,
                        ':hover': {
                            color: 'var(--hps-blue)',
                        },
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: 'var(--hps-blue)',
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
