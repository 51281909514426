import { ARTIFICIAL_INTELLIGENCE, ASSESSMENTS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiPost } from 'RestApiConnector';

import { ArtificialIntelligenceApi } from './ArtificialIntelligenceApi';

export class AWSArtificialIntelligenceApi implements ArtificialIntelligenceApi {
    aiTprmServiceAssessment = (thirdPartyId: string, thirdPartyServiceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${ARTIFICIAL_INTELLIGENCE}/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${thirdPartyServiceId}/${ASSESSMENTS}`);
    };
}
