import { useState } from 'react';

import { Button } from 'Components/Buttons/Buttons';
import { DisplayableTag } from 'Models/Tags';

import styles from './TagListing.module.css';
import { Tag } from '../Tag';

export interface TagListingProps {
    tags: DisplayableTag[];
    showAll?: boolean;
}

export const TagListing = (props: TagListingProps): JSX.Element => {
    const [isShowingMoreTags, setIsShowingMoreTags] = useState<boolean>(false);
    const maxTagDefaultAmount = props.showAll === true ? props.tags.length : 5;

    const filteredTags = isShowingMoreTags ? props.tags : props.tags.slice(0, maxTagDefaultAmount);

    return (
        <>
            <div className={props.tags.length > 0 ? styles.tags : ''}>
                {filteredTags.map((tag) => (
                    <Tag key={tag.id} tag={tag} />
                ))}
            </div>
            {props.tags.length > maxTagDefaultAmount && !isShowingMoreTags && (
                <Button variant="linkText" size="sm" onClick={() => setIsShowingMoreTags(true)}>
                    Show all tags
                </Button>
            )}
        </>
    );
};
