import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { Switch } from 'Components/Buttons/Switch';
import { Role } from 'Components/Context/RBACContext';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { validateEmail } from 'Helpers/InputValidation';
import { UserNameFormat, getUserNameFromUser } from 'Helpers/UserUtils';
import { ValidationError } from 'Models/ErrorTypes';
import { OptionType } from 'Models/Types/GlobalType';
import { CreateUserRequest, ModifyUserRequest, UserResponse } from 'Models/User';

import styles from './ManageUserModal.module.css';

export interface ManageUserModalProps {
    allExistingUsers?: UserResponse[];
    hideModal: () => void;
    selectedUserForModification?: UserResponse;
    usersApi: UsersApi;
    userCreated: () => void;
    userModified: () => void;
}

export const ManageUserModal = (props: ManageUserModalProps) => {
    const [department, setDepartment] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [failureMessage, setFailureMessage] = useState<string>();
    const [firstName, setFirstName] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>('');
    const [mfaRequired, setMfaRequired] = useState<boolean>(true);
    const [roles, setRoles] = useState<Role[]>([]);
    const [sendRegistrationEmail, setSendRegistrationEmail] = useState<boolean>(true);
    const [successMessage, setSuccessMessage] = useState<string>();

    useEffect(() => {
        setDepartment(props.selectedUserForModification ? props.selectedUserForModification.department : '');
        setEmail(props.selectedUserForModification ? props.selectedUserForModification.email_address : '');
        setFirstName(props.selectedUserForModification ? props.selectedUserForModification.first_name : '');
        setLastName(props.selectedUserForModification ? props.selectedUserForModification.last_name : '');
        setMfaRequired(props.selectedUserForModification ? props.selectedUserForModification.mfa_required : true);
        setRoles(props.selectedUserForModification ? props.selectedUserForModification.roles : []);
    }, [props.selectedUserForModification]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setIsSaving(true);

        try {
            validateForm();

            if (props.selectedUserForModification) {
                const request: ModifyUserRequest = {
                    department: department,
                    email_address: email,
                    first_name: firstName,
                    last_name: lastName,
                    mfa_required: mfaRequired,
                    roles: roles,
                };
                await props.usersApi.modifyUser(props.selectedUserForModification.cognito_subject!, request);
                setSuccessMessage('User updated.');
                props.userModified();
            } else {
                const request: CreateUserRequest = {
                    department: department,
                    email_address: email,
                    first_name: firstName,
                    last_name: lastName,
                    mfa_required: mfaRequired,
                    roles: roles,
                    send_registration_email: sendRegistrationEmail,
                };
                await props.usersApi.createUser(request);
                setSuccessMessage('User created.');
                props.userCreated();
            }
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSaving(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const validateForm = (): void => {
        if (!firstName) {
            throw new ValidationError("Please provide the user's first name.");
        }
        if (!lastName) {
            throw new ValidationError("Please provide the user's last name.");
        }
        if (!email) {
            throw new ValidationError("Please provide the user's email.");
        }
        if (!validateEmail(email)) {
            throw new ValidationError('Please provide a valid email address.');
        }
        if (!department) {
            throw new ValidationError("Please provide the user's department.");
        }
        if (!props.selectedUserForModification && props.allExistingUsers) {
            const emailArray = props.allExistingUsers.map(({ email_address }) => email_address);
            if (emailArray.includes(email)) {
                throw new ValidationError(`${email} already exists.`);
            }
        }
    };

    const getRoleOptions = (): OptionType[] => {
        const roleOptions: OptionType[] = [];
        for (const [key, value] of Object.entries(Role)) {
            roleOptions.push({ label: key, value: value });
        }

        return roleOptions;
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFormBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={props.selectedUserForModification ? `Modify ${getUserNameFromUser(props.selectedUserForModification, UserNameFormat.FIRST_SPACE_LAST)}` : 'Create User'} />
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="firstName" value={firstName} formFieldLabel="First Name" required={true} invalidMessage="Please enter the user's first name." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setFirstName(event.currentTarget.value)} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="lastName" value={lastName} formFieldLabel="Last Name" required={true} invalidMessage="Please enter the user's last name." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setLastName(event.currentTarget.value)} />
                        </div>
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="email" value={email} formFieldLabel="Email Address" required={true} invalidMessage="Please enter the user's email address." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setEmail(event.currentTarget.value)} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="department" value={department} formFieldLabel="Department" required={true} invalidMessage="Please enter the user's Department." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setDepartment(event.currentTarget.value)} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect options={getRoleOptions()} selectMultiple selectedOption={roles} handleChange={(value: ChangeEventType, formFieldId: string): void => setRoles(value as Role[])} formFieldId="roles" formFieldLabel="Roles" />
                        </div>
                    </div>
                    <div>
                        <Switch checked={mfaRequired} name="MFA Required" onChange={(event: React.FormEvent<HTMLInputElement>): void => setMfaRequired(!mfaRequired)} />
                        <Text noStyles={true}>Require Multi-Factor Authentication (MFA).</Text>
                        <FormFieldTooltip text="High Peaks Solutions strongly recommends against disabling this unless MFA is enforced by an external identity provider." />
                    </div>
                    {!props.selectedUserForModification && (
                        <div>
                            <Switch checked={sendRegistrationEmail} name="Send Registration Email" onChange={(event: React.FormEvent<HTMLInputElement>): void => setSendRegistrationEmail(!sendRegistrationEmail)} />
                            <Text noStyles={true}>Send registration email to the new user.</Text>
                            <FormFieldTooltip text="This should be disabled if authentication is performed by an external identity provider." />
                        </div>
                    )}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} disabled={isSaving} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={successMessage ? true : false} isLoading={isSaving} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
