import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { IssueHistoryResponse } from 'Models/Issues';

import { IssueDetailsSnapshot } from '../IssueDetailsSnapshot/IssueDetailsSnapshot';

export interface IssueHistoryModalProps {
    history: IssueHistoryResponse;
    documentApi: DocumentApi;
    hideModal: () => void;
}

/**
 * Renders a modal that displays a detailed historical snapshot of an issue. Matches the "main" section (containing current information) of the Issue Details page. Does not include information about impacted controls or third parties.
 */
export const IssueHistoryModal = (props: IssueHistoryModalProps) => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.history.issue.title} secondaryText={iso8601ToUsDateShort(props.history.timestamp)} />
                <IssueDetailsSnapshot issue={props.history.issue} documentApi={props.documentApi} />
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
