import { Badge as MuiBadge } from '@mui/material';
import { ReactNode } from 'react';

export interface BadgeProps {
    amount: number;
    color: 'blue' | 'red';
    variant?: 'dot' | 'standard';
    children: ReactNode;
}

export const Badge = (props: BadgeProps): JSX.Element => {
    const color = props.color === 'blue' ? 'var(--hps-light-blue)' : 'var(--hps-red)';
    return (
        <MuiBadge badgeContent={props.amount} sx={{ '& .MuiBadge-badge': { backgroundColor: color } }} variant={props.variant}>
            {props.children}
        </MuiBadge>
    );
};
