import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { ControlSelector, ControlSelectorProps } from 'Components/ControlSelector/ControlSelector';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Text } from 'Components/Text/Text';
import { CONFIGURATION } from 'Config/Paths';
import { getFrameworkGroupControlParts, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { ControlHierarchyControlGroup, ControlHierarchyFramework, sortControlHierarchy } from 'Models/ControlHierarchy';
import { OperationalControl } from 'Models/OperationalControls';
import { ResponseModel } from 'Models/ResponseModel';
import { ToggleControlsRequest } from 'Models/ToggleControls';

export enum Modals {
    ConfirmationModal,
    None,
}

export interface ControlTogglerProps {
    controlsApi: ControlsApi;
}

export const ControlToggler = (props: ControlTogglerProps): JSX.Element => {
    const [controlHierarchyFrameworks, setControlHierarchyFrameworks] = useState<ControlHierarchyFramework[]>([]);
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [enabledControls, setEnabledControls] = useState<string[]>([]);
    const [enabledFrameworks, setEnabledFrameworks] = useState<string[]>([]);
    const [enabledGroups, setEnabledGroups] = useState<string[]>([]);
    const [failureMessage, setFailureMessage] = useState<string>();

    /**
     * Get the Control hierarchy from the back-end. Set state variables that contain the Control hierarchy and lists of enabled Frameworks, Groups, and Controls.
     */
    const getControlHierarchy = useCallback(async (): Promise<void> => {
        try {
            const response: ResponseModel<ControlHierarchyFramework[]> = await props.controlsApi.getControlsHierarchy();

            const controlHierarchy = response.data;

            sortControlHierarchy(controlHierarchy);

            const enabledControls = [];
            const enabledGroups = [];
            const enabledFrameworks = [];
            let frameworkName;
            let groupId;
            let controlId;
            for (let i = 0; i < controlHierarchy.length; i++) {
                if (controlHierarchy[i].enabled === true) {
                    frameworkName = getOperationalControlIdentifierString(controlHierarchy[i].control_framework);
                    enabledFrameworks.push(frameworkName);

                    for (let j = 0; j < controlHierarchy[i].control_groups.length; j++) {
                        if (controlHierarchy[i].control_groups[j].enabled === true) {
                            groupId = controlHierarchy[i].control_groups[j].control_group_id;
                            enabledGroups.push(getOperationalControlIdentifierString(frameworkName, groupId));

                            for (let k = 0; k < controlHierarchy[i].control_groups[j].controls.length; k++) {
                                if (controlHierarchy[i].control_groups[j].controls[k].configuration.enabled === true) {
                                    controlId = getFrameworkGroupControlParts(controlHierarchy[i].control_groups[j].controls[k]).controlId;
                                    enabledControls.push(getOperationalControlIdentifierString(frameworkName, groupId, controlId));
                                }
                            }
                        }
                    }
                }
            }
            setControlHierarchyFrameworks(controlHierarchy);
            setEnabledFrameworks(enabledFrameworks);
            setEnabledGroups(enabledGroups);
            setEnabledControls(enabledControls);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.controlsApi]);

    useEffect(() => {
        getControlHierarchy();
    }, [getControlHierarchy]);

    const handleRequestError = (error: Error): void => setFailureMessage(error.message);

    const isFrameworkChecked = (name: string, controlGroups: ControlHierarchyControlGroup[]): boolean => {
        const isInSelectedControls = enabledFrameworks.includes(name) && controlGroups.every((controlGroup) => controlGroup.controls.every((control) => enabledControls.includes(name + '#' + controlGroup.control_group_id + '#' + getFrameworkGroupControlParts(control).controlId)));
        return isInSelectedControls;
    };

    /**
     * The indeterminate property of the material-ui Checkbox has precedence over the checked property.
     */
    const isFrameworkIndeterminate = (frameworkName: string, controlGroups: ControlHierarchyControlGroup[]): boolean => {
        // If every Control within the Framework is enabled, the Framework is not indeterminate (i.e. it is checked).
        if (controlGroups.every((controlGroup) => controlGroup.controls.every((control) => enabledControls.includes(getOperationalControlIdentifierString(frameworkName, controlGroup.control_group_id, getFrameworkGroupControlParts(control).controlId))))) {
            return false;
        }

        // If the Framework is enabled (and not every Control within the Framework is enabled), the Framework is indeterminate.
        if (enabledFrameworks.indexOf(getOperationalControlIdentifierString(frameworkName)) !== -1) {
            return true;
        }

        // The Framework is disabled, so it is not indeterminate (i.e. it is unchecked).
        return false;
    };

    const isGroupChecked = (name: string, controls: OperationalControl[]): boolean => {
        const isInSelectedControls = enabledGroups.includes(name) && controls.every((control) => enabledControls.includes(name + '#' + getFrameworkGroupControlParts(control).controlId));
        return isInSelectedControls;
    };

    /**
     * The indeterminate property of the material-ui Checkbox has precedence over the checked property.
     */
    const isGroupIndeterminate = (frameworkName: string, controlGroup: ControlHierarchyControlGroup): boolean => {
        // If every Control within the Group is enabled, the Group is not indeterminate (i.e. it is checked).
        if (controlGroup.controls.every((control) => enabledControls.includes(getOperationalControlIdentifierString(frameworkName, controlGroup.control_group_id, getFrameworkGroupControlParts(control).controlId)))) {
            return false;
        }

        // If the Group is enabled (and not every Control within the Group is enabled), the Group is indeterminate.
        if (enabledGroups.indexOf(getOperationalControlIdentifierString(frameworkName, controlGroup.control_group_id)) !== -1) {
            return true;
        }

        // The Group is disabled, so it is not indeterminate (i.e. it is unchecked).
        return false;
    };

    const handleFrameworkChange = (event: React.FormEvent<HTMLInputElement>, controlGroups: ControlHierarchyControlGroup[]): void => {
        const frameworkList = [...enabledFrameworks];
        const groupList = [...enabledGroups];
        const controlList = [...enabledControls];

        const framework = event.currentTarget.name;

        if (frameworkList.indexOf(framework) === -1) {
            frameworkList.push(framework);

            controlGroups.forEach((controlGroup: ControlHierarchyControlGroup) => {
                if (!groupList.includes(framework + '#' + controlGroup.control_group_id)) {
                    groupList.push(framework + '#' + controlGroup.control_group_id);
                }

                controlGroup.controls.forEach((control: OperationalControl) => {
                    if (!controlList.includes(control.identifier)) {
                        controlList.push(control.identifier);
                    }
                });
            });
        } else {
            let index = frameworkList.indexOf(framework);
            if (index !== -1) {
                frameworkList.splice(index, 1);
            }

            controlGroups.forEach((controlGroup: ControlHierarchyControlGroup) => {
                index = groupList.indexOf(framework + '#' + controlGroup.control_group_id);
                if (index !== -1) {
                    groupList.splice(index, 1);
                }

                controlGroup.controls.forEach((control: OperationalControl) => {
                    index = controlList.indexOf(control.identifier);
                    if (index !== -1) {
                        controlList.splice(index, 1);
                    }
                });
            });
        }

        setEnabledFrameworks(frameworkList);
        setEnabledGroups(groupList);
        setEnabledControls(controlList);
    };

    const handleGroupChange = (event: React.FormEvent<HTMLInputElement>, controls: OperationalControl[]): void => {
        const frameworkList = [...enabledFrameworks];
        const groupList = [...enabledGroups];
        const controlList = [...enabledControls];

        const group = event.currentTarget.name;
        const groupParts = group.split('#');
        const framework = groupParts[0];

        if (groupList.indexOf(group) === -1) {
            if (frameworkList.indexOf(framework) === -1) {
                frameworkList.push(framework);
            }

            groupList.push(group);

            controls.forEach((control: OperationalControl) => {
                if (!controlList.includes(group + '#' + getFrameworkGroupControlParts(control).controlId)) {
                    controlList.push(group + '#' + getFrameworkGroupControlParts(control).controlId);
                }
            });
        } else {
            let index = groupList.indexOf(group);
            if (index !== -1) {
                groupList.splice(index, 1);
            }

            controls.forEach((control: OperationalControl) => {
                index = controlList.indexOf(group + '#' + getFrameworkGroupControlParts(control).controlId);
                if (index !== -1) {
                    controlList.splice(index, 1);
                }
            });
        }

        setEnabledFrameworks(frameworkList);
        setEnabledGroups(groupList);
        setEnabledControls(controlList);
    };

    const handleControlChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const frameworkList = [...enabledFrameworks];
        const groupList = [...enabledGroups];
        const controlList = [...enabledControls];

        const control = event.currentTarget.name;
        const controlParts = control.split('#');
        const framework = controlParts[0];
        const group = `${controlParts[0]}#${controlParts[1]}`;

        if (controlList.indexOf(control) === -1) {
            if (frameworkList.indexOf(framework) === -1) {
                frameworkList.push(framework);
            }

            if (groupList.indexOf(group) === -1) {
                groupList.push(group);
            }

            controlList.push(control);
        } else {
            const index = controlList.indexOf(control);
            if (index !== -1) {
                controlList.splice(index, 1);
            }
        }

        setEnabledFrameworks(frameworkList);
        setEnabledGroups(groupList);
        setEnabledControls(controlList);
    };

    const submitHandler = async (): Promise<string> => {
        const request: ToggleControlsRequest = {
            enabled_frameworks: enabledFrameworks,
            enabled_groups: enabledGroups,
            enabled_controls: enabledControls,
        };

        await props.controlsApi.toggleControls(request);
        await getControlHierarchy();
        return 'Controls toggled.';
    };

    const controlSelectorProps: ControlSelectorProps = {
        controlsApi: props.controlsApi,
        submitHandler: () => setDisplayedModal(Modals.ConfirmationModal),
        isFrameworkChecked: isFrameworkChecked,
        isFrameworkIndeterminate: isFrameworkIndeterminate,
        isGroupChecked: isGroupChecked,
        isGroupIndeterminate: isGroupIndeterminate,
        handleFrameworkChange: handleFrameworkChange,
        handleGroupChange: handleGroupChange,
        handleControlChange: handleControlChange,
        controlHierarchyFrameworks: controlHierarchyFrameworks,
        selectedControls: enabledControls,
        failureMessage: failureMessage,
        legendItems: [
            {
                icon: '/checkbox_unchecked.PNG',
                text: 'Disabled. All of the child elements are disabled.',
            },
            {
                icon: '/checkbox_indeterminant.PNG',
                text: 'Enabled. Some of the child elements are disabled.',
            },
            {
                icon: '/checkbox_checked.PNG',
                text: 'Enabled. All of the child elements are enabled.',
            },
        ],
    };

    return (
        <RBACComponent roles={[Role.ADMIN]}>
            {displayedModal === Modals.ConfirmationModal && (
                <ConfirmationModal operationType="nonDestructive" headerText="Toggle Controls" areYouSureText="Are you sure you want to toggle the selected controls?" buttonText="TOGGLE" buttonLoadingText="Toggling..." buttonIcon={faCheck} performOperation={submitHandler} hideModal={() => setDisplayedModal(Modals.None)}>
                    <Text>Disabling controls will remove related configurations, such as limits, notifications, and recurring assessments, and will unmap things like metrics, governance documents, compliance requirements, and open issues/exceptions.</Text>
                </ConfirmationModal>
            )}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${CONFIGURATION}`}>Settings</BreadcrumbLink>
                        <BreadcrumbText>Control Selector</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Control Selector"
                headerDescription="Enable and disable controls, groups of controls, or entire control frameworks."
                body={[
                    {
                        content: (
                            <>
                                <Text color="red">WARNING: Disabling controls will remove related configurations, such as limits, notifications, and recurring assessments, and will unmap things like metrics, governance documents, compliance requirements, and open issues/exceptions.</Text>
                                <ControlSelector {...controlSelectorProps} />;
                            </>
                        ),
                    },
                ]}
            />
        </RBACComponent>
    );
};
