import { DOWNLOAD, FILE_PROCESSING, UPLOAD } from 'Config/Paths';
import { SignedDownloadRequest, SignedDownloadResponse, SignedUploadRequest, SignedUploadResponse } from 'Models/Files';
import { ResponseModel } from 'Models/ResponseModel';
import { documentGet, documentUpload, restApiGet } from 'RestApiConnector';

import { DocumentApi } from './DocumentApi';

export class AWSDocumentApi implements DocumentApi {
    uploadDocument(url: string, fields: { string: string }, file: File): Promise<any> {
        return documentUpload(url, fields, file);
    }

    downloadText(filePath: string): Promise<Blob> {
        return documentGet(filePath);
    }

    // Get an S3 presigned POST (pointed at a CloudFront Signed URL) in order to create or update a file in an S3 bucket behind CloudFront.
    getSignedUpload(file_name: string, file_id?: string): Promise<ResponseModel<SignedUploadResponse>> {
        const request: SignedUploadRequest = {
            file_name: file_name,
        };
        if (file_id) {
            request['file_id'] = file_id;
        }
        return restApiGet(`/${FILE_PROCESSING}/${UPLOAD}`, request);
    }

    getSignedDownload(file_id: string): Promise<ResponseModel<SignedDownloadResponse>> {
        const request: SignedDownloadRequest = {
            file_id: file_id,
        };
        return restApiGet(`/${FILE_PROCESSING}/${DOWNLOAD}`, request);
    }
}
