/*
	TableRow Presentation for a control rendered as a "list."
*/

import { Link } from 'Components/Buttons/Buttons';
import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { controlTextToString, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { ControlEffectivenessProgressBarVariantAndPercentage, OperationalControl, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import styles from './ControlTableRow.module.css';

interface ControlTableRowProps {
    control: OperationalControl;
}

export const ControlTableRow = (props: ControlTableRowProps) => {
    const progressBarValues = ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.control.effectiveness.control_effectiveness));

    return (
        <TableRow>
            <TableCellDefaultText>
                <Link size="lg" to={getFrameworkGroupControlURL(props.control.identifier)}>
                    {getHumanReadableControlIdFromControl(props.control)}
                </Link>
                <Text color="darkGray" variant="Text3" noStyles>
                    {controlTextToString(props.control.metadata.control_text)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color="darkGray" variant="Text3" noStyles>
                    {props.control.metadata.control_group_name}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <div className={styles.progressBar}>
                    <ProgressBarIndicator variant={progressBarValues.variant} percent={progressBarValues.percent} size="small" />
                </div>
                <Text color="darkGray" variant="Text3" noStyles>
                    {numberAsEffectivenessString(props.control.effectiveness.control_effectiveness)}
                </Text>
            </TableCellDefaultText>
        </TableRow>
    );
};
