import { Fragment, FunctionComponent } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { Text } from 'Components/Text/Text';
import { getEffectivenessVariantColor, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { QuestionType } from 'Models/TPRM';

import { Control, Framework, Group, Question } from '../AssessmentAndReportTab';
import styles from '../AssessmentAndReportTab.module.css';
import { ControlAssessmentAccordionRow } from './ControlAssessmentAccordionRow/ControlAssessmentAccordionRow';

export interface ControlAssessmentAccordionFrameworkProps {
    framework: Framework;
    children?: React.ReactNode;
}

export const ControlAssessmentAccordionFramework: FunctionComponent<ControlAssessmentAccordionFrameworkProps> = (props) => {
    return (
        <Accordion defaultActiveKey={props.framework.frameworkName}>
            <ControlAssessmentAccordionRow eventKey={props.framework.frameworkName} level={1} accessibilityTitle={props.framework.frameworkName}>
                <div className={styles.accordionRow}>
                    <div data-testid="rowcontent">
                        <Text variant="Text3" noStyles>
                            {props.framework.frameworkName}
                        </Text>
                    </div>
                    <div data-testid={`effectivenessFor ${props.framework.frameworkName}`} className={styles.accordionRightContent}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(props.framework.effectiveness))} />
                        <div className={styles.accordionEffectivenessText}>
                            <Text variant="Text3" noStyles>
                                {numberAsEffectivenessString(props.framework.effectiveness)}
                            </Text>
                        </div>
                    </div>
                </div>
            </ControlAssessmentAccordionRow>
            <AccordionCollapse eventKey={props.framework.frameworkName} accordionFor={props.framework.frameworkName}>
                <Fragment>{props.children}</Fragment>
            </AccordionCollapse>
        </Accordion>
    );
};

export interface ControlAssessmentAccordionGroupProps {
    group: Group;
    children?: React.ReactNode;
}

export const ControlAssessmentAccordionGroup: FunctionComponent<ControlAssessmentAccordionGroupProps> = (props) => {
    return (
        <Accordion defaultActiveKey={props.group.displayText}>
            <ControlAssessmentAccordionRow eventKey={props.group.displayText} level={2} accessibilityTitle={props.group.displayText}>
                <div className={styles.accordionRow}>
                    <div data-testid="rowcontent">
                        <Text variant="Text3" noStyles>
                            {props.group.displayText}
                        </Text>
                    </div>
                    <div data-testid={`effectivenessFor ${props.group.displayText}`} className={styles.accordionRightContent}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(props.group.effectiveness))} />
                        <div className={styles.accordionEffectivenessText}>
                            <Text variant="Text3" noStyles>
                                {numberAsEffectivenessString(props.group.effectiveness)}
                            </Text>
                        </div>
                    </div>
                </div>
            </ControlAssessmentAccordionRow>
            <AccordionCollapse eventKey={props.group.displayText} accordionFor={props.group.displayText}>
                <Fragment>{props.children}</Fragment>
            </AccordionCollapse>
        </Accordion>
    );
};

export interface ControlAssessmentAccordionControlProps {
    control: Control;
    children?: React.ReactNode;
}

export const ControlAssessmentAccordionControl: FunctionComponent<ControlAssessmentAccordionControlProps> = (props) => {
    return (
        <Accordion>
            <ControlAssessmentAccordionRow eventKey={props.control.displayText} level={3} accessibilityTitle={props.control.displayText}>
                <div className={styles.accordionRow}>
                    <div data-testid="rowcontent">
                        <Text variant="Text3" noStyles>
                            {props.control.displayText}
                        </Text>
                    </div>
                    <div data-testid={`effectivenessFor ${props.control.displayText}`} className={styles.accordionRightContent}>
                        <CircleIndicator variant={getEffectivenessVariantColor(props.control.effectiveness)} />
                        <div className={styles.accordionEffectivenessText}>
                            <Text variant="Text3" noStyles>
                                {numberAsEffectivenessString(props.control.effectiveness)}
                            </Text>
                        </div>
                    </div>
                </div>
            </ControlAssessmentAccordionRow>
            <AccordionCollapse eventKey={props.control.displayText} accordionFor={props.control.displayText}>
                <Fragment>{props.children}</Fragment>
            </AccordionCollapse>
        </Accordion>
    );
};

export interface ControlAssessmentAccordionQuestionProps {
    documentApi: DocumentApi;
    questionNumber: number;
    question: Question;
}

export const ControlAssessmentAccordionQuestion: FunctionComponent<ControlAssessmentAccordionQuestionProps> = (props) => {
    const renderAnswers = () => {
        if (props.question.answerIndexes) {
            const answers: JSX.Element[] = [];
            props.question.answerIndexes.forEach((answerIndex, index) => {
                answers.push(
                    <Text variant="Text3" color="darkGray" key={index}>
                        <li>{props.question.options![answerIndex]}</li>
                    </Text>
                );
            });
            return answers;
        } else return <></>;
    };

    const renderDocuments = () => {
        return props.question.answerDocuments.map((document, index) => <UploadedFileAndState key={index} documentApi={props.documentApi} file={document} onDownloadError={console.log} />);
    };

    return (
        <ControlAssessmentAccordionRow level={4}>
            <div className={styles.accordionRow}>
                <div className={styles.accordionRowQuestion}>
                    <div data-testid="questionPrefix" className={styles.accordionQuestionPrefix}>
                        <Text variant="Text3">{`Question ${props.questionNumber + 1}:`}</Text>
                    </div>
                    <div>
                        <div data-testid="rowcontent">
                            <Text variant="Text3">{props.question.text}</Text>
                        </div>
                        <div data-testid="rowcontent">
                            {props.question._type === QuestionType.FREEFORM && props.question.answerText && (
                                <Text variant="Text3" color="darkGray">
                                    {props.question.answerText}
                                </Text>
                            )}
                            {props.question._type === QuestionType.SINGLE_SELECT && props.question.answerIndex !== undefined && props.question.options && (
                                <Text variant="Text3" color="darkGray">
                                    {props.question.options[props.question.answerIndex]}
                                </Text>
                            )}
                            {props.question._type === QuestionType.MULTIPLE_SELECT && props.question.options && <ul className={styles.answerList}>{renderAnswers()}</ul>}
                            {props.question._type === QuestionType.DOCUMENT_UPLOAD && <div className={styles.answerDocuments}>{renderDocuments()}</div>}
                        </div>
                    </div>
                </div>
                <data className={styles.accordionRightContent}></data>
            </div>
        </ControlAssessmentAccordionRow>
    );
};
