/**
 * Helper functions shared by multiple "Limits" components.
 */

import { ValidationError } from 'Models/ErrorTypes';
import { MetricName } from 'Models/ExternalIntegrations';

export const validateLimitValue = (metricName: MetricName, limitValue: number): void => {
    // RiskRecon.
    if (metricName === MetricName.AVERAGE_THIRD_PARTY_SCORE || metricName === MetricName.MY_OVERALL_SCORE) {
        if (limitValue < 0.01 || limitValue > 10) {
            throw new ValidationError('Invalid Limit Value. Valid range for Limit Value is 0.01 - 10');
        }
        // Proofpoint Targeted Attack Protection
    } else if (metricName === MetricName.PERCENT_THREATS_BLOCKED) {
        if (limitValue < 0.01 || limitValue > 100) {
            throw new ValidationError('Invalid Limit Value. Valid range for Limit Value is 0.01 - 100');
        }
        // Proofpoint Security Awareness Training.
    } else if (metricName === MetricName.SIMULATED_PHISHING_CAMPAIGN_RESULTS) {
        if (limitValue < 0 || limitValue > 100) {
            throw new ValidationError('Invalid Limit Value. Valid range for Limit Value is 0 - 100');
        }
        // BitSight.
    } else if (metricName === MetricName.CRITICAL_THIRD_PARTY_SECURITY_RATINGS || metricName === MetricName.MY_SECURITY_RATING || metricName === MetricName.PEER_SECURITY_RATINGS) {
        if (limitValue < 250 || limitValue > 900) {
            throw new ValidationError('Invalid Limit Value. Valid range for Limit Value is 250 - 900');
        }
        // Default.
    } else {
        if (limitValue < 0) {
            throw new ValidationError('Invalid Limit Value. Valid range for Limit Value is >= 0');
        }
    }
};
