import { ReactElement } from 'react';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';

export interface AccordionCollapseProps {
    eventKey: string;
    children: ReactElement;
    accordionFor?: string;
}

export const AccordionCollapse = (props: AccordionCollapseProps) => {
    return (
        <BootstrapAccordion.Collapse eventKey={props.eventKey} data-testid={props.accordionFor ? `accordionFor-${props.accordionFor}` : undefined}>
            {props.children}
        </BootstrapAccordion.Collapse>
    );
};
