import { FormLabel as BootstrapFormLabel } from 'react-bootstrap';

import { Colors } from 'Components/Colors';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';

interface FormLabelProps {
    color?: Colors;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: JSX.Element;
    children: string;
}

export const FormLabel = (props: FormLabelProps): JSX.Element => {
    return (
        <BootstrapFormLabel>
            <VisualLabel color={props.color} required={props.required && !props.hideRequiredSymbol} tooltip={props.tooltip}>
                {props.children}
            </VisualLabel>
        </BootstrapFormLabel>
    );
};
