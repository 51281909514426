import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { RiskAssessmentExportRow } from 'Models/Exports';
import { formatTotalImpactOrProbability } from 'Models/RiskRegister';

export interface RisksAssessmentsExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}
export const RiskAssessmentsExport = (props: RisksAssessmentsExportProps): JSX.Element => {
    const [riskAssessments, setRiskAssessments] = useState<RiskAssessmentExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getRiskAssessments = async (): Promise<void> => {
            try {
                const response = await props.exportsApi.getRiskAssessmentsExport();

                // Apply rounding to decimals.
                const responseDataRounded = response.data.map((riskAssessmentExportRow: RiskAssessmentExportRow) => {
                    riskAssessmentExportRow.average_inherent_risk = Number(formatTotalImpactOrProbability(riskAssessmentExportRow.average_inherent_risk));
                    riskAssessmentExportRow.average_current_risk = Number(formatTotalImpactOrProbability(riskAssessmentExportRow.average_current_risk));
                    riskAssessmentExportRow.average_target_risk = Number(formatTotalImpactOrProbability(riskAssessmentExportRow.average_target_risk));
                    riskAssessmentExportRow.average_control_environment_effectiveness = Number(formatTotalImpactOrProbability(riskAssessmentExportRow.average_control_environment_effectiveness));
                    return riskAssessmentExportRow;
                });

                setRiskAssessments(responseDataRounded);
            } catch (error) {
                setError(error.message);
            }
        };

        getRiskAssessments();
    }, [props.exportsApi]);

    const columns: GridColumn<RiskAssessmentExportRow>[] = [
        { field: 'category', headerName: 'Category', width: 300 },
        dateColumn('timestamp', 'Timestamp'),
        { field: 'submitted_by', headerName: 'Submitted By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'total_inherent_risk', headerName: 'Total Inherent Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'total_current_risk', headerName: 'Total Current Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'total_target_risk', headerName: 'Total Target Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'average_inherent_risk', headerName: 'Average Inherent Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'average_current_risk', headerName: 'Average Current Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'average_target_risk', headerName: 'Average Target Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'average_control_environment_effectiveness', headerName: 'Average Control Environment Effectiveness', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'comments', headerName: 'Comments', width: 300 },
        { field: 'documents', headerName: 'Documents', width: 300 },
        { field: 'link', headerName: 'Link', width: 300 },
    ];

    if (riskAssessments) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Risk Reviews</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Risk Reviews Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(risk_assessment: RiskAssessmentExportRow) => `${risk_assessment.category}#${risk_assessment.timestamp}`} rows={riskAssessments} title="Risk Reviews Export Preview" fileName="Risk Reviews Export" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
