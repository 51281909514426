import { faTimes, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { Service, UpdateCommonAssessmentRequest } from 'Models/TPRM';

export type ModalSuccessMessage = string;

export interface ConfirmUnlinkServicesModalProps {
    tprmApi: TPRMApi;
    service: Service;
    hideModal: () => void;
    onComplete: () => void;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    complete: boolean;
}

export const ConfirmUnlinkServicesModal = (props: ConfirmUnlinkServicesModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });

    const onClickDelete = async () => {
        try {
            setFormState({ isLoading: true, complete: false });

            const request: UpdateCommonAssessmentRequest = {
                parent_identifier: { vendor_id: props.service.assessment_workflow_setup.common_assessment_parent!.vendor_id, service_id: props.service.assessment_workflow_setup.common_assessment_parent!.id },
                child_identifier: { vendor_id: props.service.vendor_id, service_id: props.service.id },
                linked: false,
            };
            await props.tprmApi.updateCommonAssessment(request);

            setFormState({ isLoading: false, complete: true, successMessage: 'Common assessment updated.' });
            props.onComplete();
        } catch (error) {
            setFormState({ isLoading: false, failureMessage: error.message, complete: false });
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate>
                    <ModalHeader text="Common Assessment" />
                    <Text>{`Are you sure you want ${props.service.name} to stop using the common assessment provided by ${props.service.assessment_workflow_setup.common_assessment_parent!.name}?`}</Text>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="danger" onClick={onClickDelete} fontAwesomeImage={faUnlink} disabled={formState.isLoading || formState.complete} isLoading={formState.isLoading} loadingText="Deleting...">
                            STOP USING COMMON ASSESSMENT
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
