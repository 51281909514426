/*
	ControlSelector.tsx -- Presentation component for selecting one or more Frameworks, Groups, and Controls.
*/

import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { Fragment, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { Button, Link } from 'Components/Buttons/Buttons';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { controlTextToString, getFrameworkGroupControlParts, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { ellipsize } from 'Helpers/StringUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { ControlHierarchyControlGroup, ControlHierarchyFramework } from 'Models/ControlHierarchy';
import { OperationalControl } from 'Models/OperationalControls';
import { ControlText } from 'Pages/ControlDetails/ControlText/ControlText';

import styles from './ControlSelector.module.css';
import { ControlSelectorRow } from './ControlSelectorRow/ControlSelectorRow';
import { Legend, LegendItem } from '../Legend/Legend';

export interface ControlSelectorProps {
    controlsApi: ControlsApi;
    submitHandler: () => void;
    isFrameworkChecked: (name: string, controlGroups: ControlHierarchyControlGroup[]) => boolean;
    isFrameworkIndeterminate: (frameworkName: string, controlGroups: ControlHierarchyControlGroup[]) => boolean;
    isGroupChecked: (name: string, controls: OperationalControl[]) => boolean;
    isGroupIndeterminate: (frameworkName: string, controlGroup: ControlHierarchyControlGroup) => boolean;
    handleFrameworkChange: (event: React.FormEvent<HTMLInputElement>, controlGroups: ControlHierarchyControlGroup[]) => void;
    handleGroupChange: (event: React.FormEvent<HTMLInputElement>, controls: OperationalControl[]) => void;
    handleControlChange: (event: React.FormEvent<HTMLInputElement>) => void;
    controlHierarchyFrameworks: ControlHierarchyFramework[];
    selectedControls: string[];
    failureMessage?: string;
    legendItems: LegendItem[];
}

export const ControlSelector = (props: ControlSelectorProps) => {
    const [showFullControlText, setShowFullControlText] = useState<boolean>(false);

    const renderFramework = (framework: ControlHierarchyFramework): JSX.Element => {
        if (framework.enabled) {
            return (
                <Link openInNewTab aria-label={`Navigate to ${framework.control_framework}`} size="sm" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}#${framework.control_framework}`}>
                    {framework.control_framework}
                </Link>
            );
        } else {
            return <Text noStyles={true}>{framework.control_framework}</Text>;
        }
    };

    const renderGroup = (framework: ControlHierarchyFramework, group: ControlHierarchyControlGroup): JSX.Element => {
        if (group.enabled) {
            if (group.is_custom) {
                return (
                    <Link openInNewTab aria-label={`Navigate to ${group.control_group_name}`} size="sm" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${framework.control_framework}/${GROUPS}/${group.control_group_id}`}>
                        {group.control_group_name}
                    </Link>
                );
            } else {
                return (
                    <Fragment>
                        <Link openInNewTab aria-label={`Navigate to ${group.control_group_id} - ${group.control_group_name}`} size="sm" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${framework.control_framework}/${GROUPS}/${group.control_group_id}`}>
                            {group.control_group_id}
                        </Link>
                        <div className={styles.groupNameOrControlText}>
                            <Text noStyles={true}>{group.control_group_name}</Text>
                        </div>
                    </Fragment>
                );
            }
        } else {
            return <Text noStyles={true}>{group.is_custom ? group.control_group_name : `${group.control_group_id} - ${group.control_group_name}`}</Text>;
        }
    };

    const renderControl = (control: OperationalControl): JSX.Element => {
        const { controlId } = getFrameworkGroupControlParts(control);
        const controlTextString = ellipsize(controlTextToString(control.metadata.control_text), 300);
        if (control.configuration.enabled) {
            return (
                <Fragment>
                    <div className={styles.controlIdOrNameHyperlink}>
                        <Link openInNewTab aria-label={`Navigate to ${control.metadata.is_custom ? controlTextString : `${controlId} - ${controlTextString}`}`} size="sm" to={getFrameworkGroupControlURL(control.identifier)}>
                            {control.metadata.is_custom ? control.metadata.control_name : controlId}
                        </Link>
                    </div>
                    {showFullControlText && (
                        <div className={styles.groupNameOrControlText}>
                            <ControlText alwaysShowFullControlText={true} color="blue" controlText={control.metadata.control_text} textVariant="Text3" />
                        </div>
                    )}
                    {!showFullControlText && (
                        <div className={styles.groupNameOrControlText}>
                            <Text noStyles={true}>{controlTextString}</Text>
                        </div>
                    )}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className={styles.controlIdOrNameHyperlink}>
                        <Text noStyles={true}>{control.metadata.is_custom ? control.metadata.control_name : controlId}</Text>
                    </div>
                    {showFullControlText && (
                        <div className={styles.groupNameOrControlText}>
                            <ControlText alwaysShowFullControlText={true} color="blue" controlText={control.metadata.control_text} textVariant="Text3" />
                        </div>
                    )}
                    {!showFullControlText && (
                        <div className={styles.groupNameOrControlText}>
                            <Text noStyles={true}>{control.metadata.is_custom ? ` - ${controlTextString}` : controlTextString}</Text>
                        </div>
                    )}
                </Fragment>
            );
        }
    };

    if (props.controlHierarchyFrameworks.length > 0) {
        return (
            <Fragment>
                {props.failureMessage && <Alert variant="danger">{props.failureMessage}</Alert>}
                <Legend legendItems={props.legendItems} />
                <div className={styles.headerContainer}>
                    <div className={styles.headerTextWithShowHideButton}>
                        <Text noStyles={true} variant="Header2">
                            Controls
                        </Text>
                        <div className={styles.showHideButtonContainer}>
                            <Button onClick={() => setShowFullControlText(!showFullControlText)} size="sm" variant="linkText">
                                {showFullControlText ? 'SHOW ABBREVIATED CONTROL TEXT' : 'SHOW FULL CONTROL TEXT'}
                            </Button>
                        </div>
                    </div>
                    <Button variant="primary" onClick={() => props.submitHandler()} fontAwesomeImage={faChevronCircleRight}>
                        SUBMIT
                    </Button>
                </div>
                <div className={styles.pageSection}>
                    {props.controlHierarchyFrameworks.map((controlHierarchyFramework: ControlHierarchyFramework, index: number) => (
                        <div key={index}>
                            <Accordion key={index}>
                                <ControlSelectorRow eventKey={controlHierarchyFramework.control_framework} level={1} aria-label={controlHierarchyFramework.control_framework}>
                                    <div className={styles.row}>
                                        <div className={styles.controlInformation}>{renderFramework(controlHierarchyFramework)}</div>
                                        <Checkbox indeterminate={props.isFrameworkIndeterminate(controlHierarchyFramework.control_framework, controlHierarchyFramework.control_groups)} color="default" onChange={(event) => props.handleFrameworkChange(event, controlHierarchyFramework.control_groups)} name={controlHierarchyFramework.control_framework} checked={props.isFrameworkChecked(controlHierarchyFramework.control_framework, controlHierarchyFramework.control_groups)} />
                                    </div>
                                </ControlSelectorRow>
                                <AccordionCollapse eventKey={controlHierarchyFramework.control_framework} accordionFor={controlHierarchyFramework.control_framework}>
                                    <Fragment>
                                        {controlHierarchyFramework.control_groups.map((controlGroupHierarchy: ControlHierarchyControlGroup, index) => {
                                            const controlGroupId = getOperationalControlIdentifierString(controlHierarchyFramework.control_framework, controlGroupHierarchy.control_group_id);
                                            return (
                                                <Accordion key={index}>
                                                    <ControlSelectorRow aria-label={controlGroupHierarchy.is_custom ? controlGroupHierarchy.control_group_name : `${controlGroupHierarchy.control_group_id}. ${controlGroupHierarchy.control_group_name}`} eventKey={controlGroupHierarchy.controls[0] ? controlGroupHierarchy.control_group_name : ''} key={index} level={2}>
                                                        <div className={styles.row}>
                                                            <div className={styles.controlInformation}>{renderGroup(controlHierarchyFramework, controlGroupHierarchy)}</div>
                                                            <Checkbox indeterminate={props.isGroupIndeterminate(controlHierarchyFramework.control_framework, controlGroupHierarchy)} color="default" onChange={(event) => props.handleGroupChange(event, controlGroupHierarchy.controls)} name={controlGroupId} checked={props.isGroupChecked(controlGroupId, controlGroupHierarchy.controls)} />
                                                        </div>
                                                    </ControlSelectorRow>
                                                    <AccordionCollapse eventKey={controlGroupHierarchy.control_group_name} accordionFor={controlGroupHierarchy.control_group_name}>
                                                        <Fragment>
                                                            {controlGroupHierarchy.controls.map((control: OperationalControl, index) => {
                                                                const { controlId } = getFrameworkGroupControlParts(control);
                                                                const controlTextString = ellipsize(controlTextToString(control.metadata.control_text), 300);
                                                                return (
                                                                    <ControlSelectorRow key={index} level={3} aria-label={control.metadata.is_custom ? controlTextString : `${controlId}. ${controlTextString}`}>
                                                                        <div className={styles.row}>
                                                                            <div className={styles.controlInformation}>{renderControl(control)}</div>
                                                                            <Checkbox color="default" onChange={props.handleControlChange} name={control.identifier} checked={props.selectedControls.includes(control.identifier)} />
                                                                        </div>
                                                                    </ControlSelectorRow>
                                                                );
                                                            })}
                                                        </Fragment>
                                                    </AccordionCollapse>
                                                </Accordion>
                                            );
                                        })}
                                    </Fragment>
                                </AccordionCollapse>
                            </Accordion>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    } else {
        return <Placeholder />;
    }
};
