import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';

import styles from './ControlAssessmentAccordionRow.module.css';

export interface ControlAssessmentAccordionRowProps {
    eventKey?: string;
    level: number;
    accessibilityTitle?: string;
    children?: React.ReactNode;
}

export const ControlAssessmentAccordionRow = (props: ControlAssessmentAccordionRowProps): JSX.Element => {
    const getStyleClassArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.inlineStylesLevel0;
            case 2:
                return styles.inlineStylesLevel1;
            case 3:
                return styles.inlineStylesLevel2;
            case 4:
                return styles.inlineStylesLevel3;
            default:
                return styles.inlineStylesLevel4;
        }
    };

    const getStyleClassNoArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.inlineStylesLevel1;
            case 2:
                return styles.inlineStylesLevel2;
            case 3:
                return styles.inlineStylesLevel3;
            case 4:
                return styles.inlineStylesLevel4;
            default:
                return styles.inlineStylesLevel5;
        }
    };

    if (props.eventKey && props.accessibilityTitle) {
        return (
            <AccordionItem eventKey={props.eventKey}>
                <div className={`${styles.row} ${getStyleClassArrow()}`}>
                    <AccordionToggle eventKey={props.eventKey} ariaLabelSuffix={props.accessibilityTitle} />
                    {props.children}
                </div>
                <hr className={styles.hrModifier} />
            </AccordionItem>
        );
    }

    return (
        <>
            <div className={`${styles.row} ${styles.leafRow} ${getStyleClassNoArrow()}`}>{props.children}</div>
            <hr className={styles.hrModifier} />
        </>
    );
};
