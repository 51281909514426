import { faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { Text } from 'Components/Text/Text';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './ModifyRequirementAssociatedControlsModal.module.css';

export interface ModifyRequirementAssociatedControlsModalProps {
    saveControlSet: (controls: string[]) => Promise<void>;
    currentMappedControlIdentifiers: string[];
    hideModal: () => void;
    controlMappingItems: OperationalControl[];
    controlMappingItemsError?: Error;
    updateRequirementDetails: () => Promise<void>;
}

interface FormState {
    successMessage?: string;
    failureMessage?: string;
}

export const ModifyRequirementAssociatedControlsModal = (props: ModifyRequirementAssociatedControlsModalProps): JSX.Element => {
    const [controlsToAssociate, setControlsToAssociate] = useState<string[]>(props.currentMappedControlIdentifiers);
    const [isModifyingControls, setIsModifyingControls] = useState(false);
    const [formState, setFormState] = useState<FormState>({});
    const modifyControls = async (): Promise<void> => {
        if (controlsToAssociate.length === 0) {
            setFormState({ failureMessage: 'Please select controls.' });
            return;
        }
        try {
            setIsModifyingControls(true);
            await props.saveControlSet(controlsToAssociate);
            await props.updateRequirementDetails();
            setFormState({
                successMessage: 'Controls mapped.',
            });
        } catch (error) {
            setFormState({ failureMessage: error.message });
        } finally {
            setIsModifyingControls(false);
        }
    };

    if (props.controlMappingItemsError !== undefined) {
        return <Text>{props.controlMappingItemsError.message}</Text>;
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {formState.successMessage && <Alert variant="success">{formState?.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState?.failureMessage}</Alert>}
                <ModalHeader text="Map Requirement to Controls" />
                <MultipleControlMapping
                    controls={props.controlMappingItems}
                    handleControlChange={(selectedControls) => {
                        setControlsToAssociate(selectedControls);
                    }}
                    currentMappedControlIdentifiers={props.currentMappedControlIdentifiers}
                />
                <div className={styles.modalFormButtonContainer}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isModifyingControls}>
                        CLOSE
                    </Button>
                    <Button variant="primary" onClick={modifyControls} fontAwesomeImage={faChevronCircleRight} loadingText={'Updating...'} isLoading={isModifyingControls} disabled={formState.successMessage ? true : false || props.controlMappingItems!.length === 0}>
                        SAVE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
