import { ALERTS, EXTERNAL_INTEGRATIONS, LIMITS, MAPPING } from 'Config/Paths';
import { AlertRequest, AlertResponse } from 'Models/Alerts';
import { IntegrationResponse } from 'Models/ExternalIntegrations';
import { CreateEffectivenessLimitRequest, CreateMetricLimitRequest, LimitResponse, ModifyLimitRequest } from 'Models/Limits';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { AlertsLimitsApi } from './AlertsLimitsApi';

export class AWSAlertsLimitsApi implements AlertsLimitsApi {
    createLimit = (createLimitRequest: CreateEffectivenessLimitRequest | CreateMetricLimitRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${LIMITS}`, createLimitRequest);
    };
    getControlLimits = (entity_id: string): Promise<ResponseModel<LimitResponse[]>> => {
        const encoded = encodeURIComponent(entity_id);
        return restApiGet(`/${LIMITS}?entity_id=${encoded}`, {});
    };

    getAllLimits = (): Promise<ResponseModel<LimitResponse[]>> => {
        return restApiGet(`/${LIMITS}`, {});
    };

    deleteLimit = (limitId: string): Promise<ResponseModel<void>> => {
        const encodedLimitId = encodeURIComponent(limitId);
        return restApiDelete(`/${LIMITS}/${encodedLimitId}`);
    };

    getAllAlerts = (alertRequest?: AlertRequest): Promise<ResponseModel<AlertResponse[]>> => {
        return restApiGet(`/${ALERTS}`, alertRequest);
    };

    modifyLimit = (limitId: string, modifyLimitRequest: ModifyLimitRequest): Promise<ResponseModel<void>> => {
        const encodedLimitId = encodeURIComponent(limitId);
        return restApiPost(`/${LIMITS}/${encodedLimitId}`, modifyLimitRequest);
    };

    getControlMappingMetric = (controlId: string): Promise<ResponseModel<IntegrationResponse[]>> => {
        const encoded = encodeURIComponent(controlId);
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${MAPPING}?control=${encoded}`, {});
    };
}
