import { useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { controlComparator, undefinedComparator } from 'Helpers/Compare';
import { controlTextToString, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { effectivenessAsString, getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskControlMapping, WARNING_MESSAGE_INCOMPLETE_CONTROL_DISTRIBUTION, formatAverageWeightedEffectiveness } from 'Models/RiskRegister';

import styles from './MitigatingControlsListing.module.css';

export interface MitigatingControlsListingProps {
    averageEffectiveness: number;
    riskControlMapping: RiskControlMapping[];
}

export enum MitigatingControlsSortFilterOptions {
    CONTROL = 'control',
    DISTRIBUTION = 'distribution',
    EFFECTIVENESS = 'effectiveness',
    LAST_UPDATED_DATE = 'last_updated_date',
}

export const MitigatingControlsListing = (props: MitigatingControlsListingProps): JSX.Element => {
    const [sortBy, setSortBy] = useState<MitigatingControlsSortFilterOptions>(MitigatingControlsSortFilterOptions.CONTROL);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const headerValues: HeaderData[] = [
        { dataKey: MitigatingControlsSortFilterOptions.CONTROL, label: 'CONTROL' },
        { dataKey: MitigatingControlsSortFilterOptions.DISTRIBUTION, label: 'DISTRIBUTION' },
        { dataKey: MitigatingControlsSortFilterOptions.EFFECTIVENESS, label: 'EFFECTIVENESS' },
        { dataKey: MitigatingControlsSortFilterOptions.LAST_UPDATED_DATE, label: 'LAST REVIEW DATE' },
    ];

    const tableHeaderProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: (sortProperty: string, sortDirection: SortDirection) => {
            setSortBy(sortProperty as MitigatingControlsSortFilterOptions);
            setSortDirection(sortDirection);
        },
        currentSort: sortBy,
        currentSortDirection: sortDirection,
        tableIncludesOverflowMenu: false,
    };

    const controlEnvironmentTotalPercentage = props.riskControlMapping.map((mapping) => mapping.weight).reduce((acc, val) => (acc += val), 0);
    const controlsDistributedIncompletely = props.riskControlMapping.length > 0 && controlEnvironmentTotalPercentage !== 100;

    const sortedControlMappings = [...props.riskControlMapping].sort((mappingA, mappingB) => {
        let sortResult: number;

        switch (sortBy) {
            case MitigatingControlsSortFilterOptions.CONTROL:
                sortResult = controlComparator(mappingA.control, mappingB.control);
                break;
            case MitigatingControlsSortFilterOptions.DISTRIBUTION:
                sortResult = mappingA.weight > mappingB.weight ? 1 : -1;
                break;
            case MitigatingControlsSortFilterOptions.EFFECTIVENESS:
                sortResult = mappingA.control.effectiveness.control_effectiveness > mappingB.control.effectiveness.control_effectiveness ? 1 : -1;
                break;
            case MitigatingControlsSortFilterOptions.LAST_UPDATED_DATE:
                sortResult = undefinedComparator(mappingA.control.effectiveness.last_updated, mappingB.control.effectiveness.last_updated, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                break;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
    });

    return (
        <>
            <div className={styles.controlMitigationTab}>
                <div className={styles.header}>
                    <Text variant="Header2" noStyles>
                        Controls Mitigating Risk
                    </Text>
                </div>
                <hr />
                <div className={styles.averageWeightedEffectiveness}>
                    <Text noStyles color="darkGray" variant="Header4">
                        Average Weighted Effectiveness
                    </Text>
                    <div className={styles.average}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(props.averageEffectiveness))} />
                        <Text noStyles variant="Text1" color="blue">
                            {formatAverageWeightedEffectiveness(props.averageEffectiveness)}
                        </Text>
                    </div>
                    {controlsDistributedIncompletely && <Text color="red">{WARNING_MESSAGE_INCOMPLETE_CONTROL_DISTRIBUTION}</Text>}
                </div>
                <Table>
                    <SortableTableHeader {...tableHeaderProps} />
                    <TableBody>
                        {sortedControlMappings.map((controlMapping) => {
                            const controlIdentifier = controlMapping.control.identifier;

                            return (
                                <TableRow key={controlIdentifier}>
                                    <TableCellDefaultText>
                                        <Link size="sm" to={getFrameworkGroupControlURL(controlIdentifier)}>
                                            {getHumanReadableControlIdFromControl(controlMapping.control)}
                                        </Link>
                                        <Text noStyles variant="Text4">
                                            {controlTextToString(controlMapping.control.metadata.control_text)}
                                        </Text>
                                    </TableCellDefaultText>
                                    <TableCellDefaultText>
                                        <Text noStyles>{controlMapping.weight}%</Text>
                                    </TableCellDefaultText>
                                    <TableCell>
                                        <div className={styles.controlEnvironmentEffectivenessContainer}>
                                            <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(controlMapping.control.effectiveness.control_effectiveness))} />
                                            <Text noStyles>{effectivenessAsString(controlMapping.control.effectiveness.control_effectiveness)}</Text>
                                        </div>
                                    </TableCell>
                                    <TableCellDefaultText>{controlMapping.control.effectiveness.last_updated !== undefined && <Text noStyles>{iso8601ToUsDateShort(controlMapping.control.effectiveness.last_updated)}</Text>}</TableCellDefaultText>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};
