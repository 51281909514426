import { createContext, useContext, useState } from 'react';

export interface NavContextValue {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const NavContext = createContext<NavContextValue | undefined>(undefined);

export interface NavProviderProps {
    children: React.ReactNode;
}

export const NavProvider = ({ children }: NavProviderProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);

    const value = { isOpen, setIsOpen };
    return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNav = (): NavContextValue => {
    const context = useContext(NavContext);

    if (context === undefined) {
        throw new Error('useNav must be used within a NavProvider.');
    }
    return context;
};
