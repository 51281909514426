import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ContentTriggerTooltipProps {
    tooltipText: React.ReactNode;
    testid?: string;
    children?: React.ReactNode;
}

export const ContentTriggerTooltip = (props: ContentTriggerTooltipProps): JSX.Element => {
    return (
        <OverlayTrigger placement="auto" delay={{ show: 100, hide: 200 }} overlay={<Tooltip id="information">{props.tooltipText}</Tooltip>}>
            <div data-testid={props.testid}>{props.children}</div>
        </OverlayTrigger>
    );
};
