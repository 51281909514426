/*
	HeaderAlerts.tsx -- A component for the header bar to create an Alert dropdown.
*/

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { DropdownItem, DropdownItemClickType, IconDropdownButton } from 'Components/Buttons/DropdownButton';
import { NumberOfAlertsToDisplay } from 'Config/Parameters';
import { ALERTS } from 'Config/Paths';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { AlertResponse } from 'Models/Alerts';

export interface HeaderAlertsProps {
    alerts: AlertResponse[];
}

export const HeaderAlerts = (props: HeaderAlertsProps) => {
    /**
     * Given a list of Alerts, sort/format them into items appropriate for a drop-down menu.
     */
    const convertAlertsToDropdownItems = (alerts: AlertResponse[]): DropdownItem[] => {
        const dropdownItems: DropdownItem[] = alerts
            .sort((a, b) => {
                if (a.created_time > b.created_time) {
                    return -1;
                } else if (a.created_time < b.created_time) {
                    return 1;
                } else {
                    // If the dates are the same, sort by title (Control ID).
                    if (a.title > b.title) {
                        return 1;
                    } else if (a.title < b.title) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            })
            .slice(0, NumberOfAlertsToDisplay) // Limit the number of alerts shown to prevent the list from growing infinitely. Note that the call to the API also includes this in a query parameter.
            .map((alert: AlertResponse) => {
                return {
                    _type: DropdownItemClickType.HYPERLINK,
                    text: `${alert.title} - ${iso8601ToUsTimestampLong(alert.created_time)}`,
                    href: getFrameworkGroupControlURL(alert.entity_id),
                };
            });

        if (alerts.length > NumberOfAlertsToDisplay) {
            dropdownItems.push({
                _type: DropdownItemClickType.HYPERLINK,
                text: 'View All Alerts',
                href: `/${ALERTS}`,
            });
        }

        return dropdownItems;
    };

    return <IconDropdownButton badgeColor="red" badgeVariant="dot" dropdownItems={convertAlertsToDropdownItems(props.alerts)} emptyText="No Alerts" icon={faExclamationTriangle} />;
};
