import { OperationalControl } from './OperationalControls';
import { OptionType } from './Types/GlobalType';

// Must exactly match the enum of the same name on the API side.
export enum IntegrationName {
    AWS_CONFIG = 'AWS Config',
    BITSIGHT = 'BitSight',
    MICROSOFT = 'Microsoft', // Microsoft has multiple APIs. These APIs use the same credentials for authentication (OAuth via an application [or "client"] registered in the Azure AD tenant of the SummIT Security customer), so we combine them into a single "Microsoft" Integration, rather than forcing customers to configure and manage multiple Microsoft Integrations within SummIT Security.
    PROOFPOINT_TARGETED_ATTACK_PROTECTION = 'Proofpoint Targeted Attack Protection',
    PROOFPOINT_SECURITY_AWARENESS_TRAINING = 'Proofpoint Security Awareness Training',
    QUALYS = 'Qualys',
    RAPID7_INSIGHT_PLATFORM = 'Rapid7 Insight Platform',
    RISKRECON = 'RiskRecon',
    SECURITYSCORECARD = 'SecurityScorecard',
    SOPHOS = 'Sophos',
    TENABLE = 'Tenable',
}

// Must exactly match the enum of the same name on the API side.
// Must document Metric Limit descriptions in src\Models\Limits.ts.
export enum MetricName {
    ACTIVE_VULNERABILITIES = 'Active Vulnerabilities', // Rapid7 Insight Platform
    AGGREGATED_RISK_SCORE = 'Aggregated Risk Score', // Rapid7 Insight Platform
    AVERAGE_THIRD_PARTY_SCORE = 'Average Vendor Score', // RiskRecon
    CLOUD_TRAIL_ENABLED = 'CloudTrail: Accounts Without CloudTrail Enabled', // AWS Config
    CRITICAL_THIRD_PARTY_SECURITY_RATINGS = 'Critical Third-Party Security Ratings', // BitSight
    DATA_LOSS_EVENTS = 'Data Loss Events', // RiskRecon
    DEVICE_COUNT = 'Device Count', // Microsoft
    DYNAMODB_PITR_ENABLED = 'DynamoDB: Tables Without PITR Enabled', // AWS Config
    ENDPOINT_ENCRYPTION = 'Endpoint Encryption', // Sophos
    ENDPOINT_HEALTH = 'Endpoint Health', // Sophos
    EXPOSURE_SCORE = 'Exposure Score', // Microsoft
    LAMBDA_FUNCTION_PUBLIC_ACCESS_PROHIBITED = 'Lambda: Publicly Accessible Functions', // AWS Config
    MALICIOUS_EMAILS = 'Malicious Emails', // Proofpoint Targeted Attack Protection
    MALWARE_FAMILIES = 'Malware Families', // Proofpoint Targeted Attack Protection
    MY_OVERALL_SCORE = 'My Overall Score', // RiskRecon
    MY_SECURITY_RATING = 'My Security Rating', // BitSight. This is analogous to RiskRecon's "My Overall Score", but this needs to be a separate Metric because the possible values are different, so the validation for Limits is different.
    NONCOMPLIANT_DEVICES = 'Noncompliant Devices', // Microsoft
    OUTDATED_SCANS = 'Outdated Scans', // Rapid7 Insight Platform
    OVERDUE_ACCESS_REVIEWS = 'Overdue Access Reviews', // Microsoft
    OVERDUE_SECURITY_TRAINING = 'Overdue Security Training', // Microsoft
    OVERDUE_VULNERABILITIES = 'Overdue Vulnerabilities', // Microsoft, Qualys, Tenable
    PEER_SECURITY_RATINGS = 'Peer Security Ratings', // BitSight
    PERCENT_THREATS_BLOCKED = 'Percent of Threats Blocked', // Proofpoint Targeted Attack Protection
    PHISHING_CLICKS = 'Phishing Clicks', // Proofpoint Targeted Attack Protection
    RISKY_DEVICES = 'Risky Devices', // Microsoft
    RISKY_PORTS = 'Risky Ports', // RiskRecon
    RISKY_SIGN_IN_EVENTS = 'Risky Sign-In Events', // Microsoft
    S3_ACCOUNT_LEVEL_PUBLIC_ACCESS_BLOCKS_PERIODIC = 'S3: Accounts Without Public Access Blocks', // AWS Config
    S3_BUCKET_SERVER_SIDE_ENCRYPTION_ENABLED = 'S3: Buckets Without Server-Side Encryption', // AWS Config
    SECURE_SCORE = 'Secure Score', // Microsoft
    SIMULATED_PHISHING_CAMPAIGN_RESULTS = 'Simulated Phishing Campaign Results', // Proofpoint Security Awareness Training
    SIMULATED_PHISHING_RESULTS = 'Simulated Phishing Results', // Proofpoint Security Awareness Training
    UNRESOLVED_ALERTS = 'Unresolved Alerts', // Microsoft
    THIRD_PARTY_MONITORING = 'Vendor Monitoring', // BitSight, RiskRecon, SecurityScorecard
    VERY_ATTACKED_PEOPLE_SUMMARY = 'Very Attacked People Summary', // Proofpoint Targeted Attack Protection
    WEB_ENCRYPTION_FINDINGS = 'Web Encryption Findings', // RiskRecon
}

type MetricMetadataInterface = {
    [key in IntegrationName]: {
        [key in MetricName]?: {
            description: string; // Description of the Metric.
            recommended_control_mapping: string; // High Peaks Solutions' recommendation for which Controls the Metric should be mapped to.
        };
    };
};

// There must be a definition for every IntegrationName and MetricName.
export const MetricMetadata: MetricMetadataInterface = {
    [IntegrationName.AWS_CONFIG]: {
        [MetricName.CLOUD_TRAIL_ENABLED]: {
            description: 'The number of AWS accounts with CloudTrail disabled.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.AE-1, DE.AE-3, DE.AE-4, DE.CM-1, DE.CM-3, DE.CM-6, DE.CM-7, ID.AM-3, PR.DS-5, PR.MA-2, PR.PT-1; NIST CSF (v2.0): DE.AE-03, DE.CM-01, DE.CM-03, DE.CM-06, DE.CM-09',
        },
        [MetricName.DYNAMODB_PITR_ENABLED]: {
            description: 'The number DynamoDB tables with "point-in-time-recovery" (PITR) disabled.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.IP-4; NIST CSF (v2.0): PR.DS-11',
        },
        [MetricName.LAMBDA_FUNCTION_PUBLIC_ACCESS_PROHIBITED]: {
            description: 'The number of Lambda functions that are publicly accessible.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AC-3, PR.AC-4, PR.AC-5, PR.DS-5, PR.PT-3, PR.PT-4; NIST CSF (v2.0): PR.IR-01, PR.AA-05, PR.DS-01, PR.PS-01',
        },
        [MetricName.S3_ACCOUNT_LEVEL_PUBLIC_ACCESS_BLOCKS_PERIODIC]: {
            description: 'The number of S3 buckets that are publicly accessible.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AC-5, PR.DS-5, PR.PT-3; NIST CSF (v2.0): PR.IR-01, PR.DS-01, PR.PS-01',
        },
        [MetricName.S3_BUCKET_SERVER_SIDE_ENCRYPTION_ENABLED]: {
            description: 'The number of S3 buckets that do not have server-side encryption enabled.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.DS-1; NIST CSF (v2.0): PR.DS-01',
        },
    },
    [IntegrationName.BITSIGHT]: {
        [MetricName.CRITICAL_THIRD_PARTY_SECURITY_RATINGS]: {
            description: 'Daily security ratings for your critical third-parties.',
            recommended_control_mapping: 'CIS CSC (v7.1): None; NIST CSF (v1.1): ID.SC-2; NIST CSF (v2.0): GV.SC-07, ID.RA-10; NIST SP 800-53 (Rev. 5): SA-9; NIST SP 800-171 (Rev. 2): 11.1; NIST Privacy Framework (v1.0): ID.DE-P2',
        },
        [MetricName.MY_SECURITY_RATING]: {
            description: 'The security rating that BitSight assigns to your organization. Obtained daily.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6, 3.7; NIST CSF (v1.1): ID.RA-1, ID.RA-5; NIST CSF (v2.0): ID.RA-01, ID.RA-05; NIST SP 800-53 (Rev. 5): RA-3, RA-5, RA-7; NIST SP 800-171 (Rev. 2): 11.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
        [MetricName.PEER_SECURITY_RATINGS]: {
            description: 'Daily security ratings for a group of peer organizations.',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.BE-2; NIST CSF (v2.0): GV.OC-01',
        },
        [MetricName.THIRD_PARTY_MONITORING]: {
            description: 'A variety of risk scores for individual third parties. This metric cannot be mapped to internal controls. It is automatically applied to third parties in the Third-Party Risk Management module.',
            recommended_control_mapping: 'N/A',
        },
    },
    [IntegrationName.MICROSOFT]: {
        [MetricName.DEVICE_COUNT]: {
            description: 'A daily count of devices from both Defender and Intune.',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.AM-1; NIST CSF (v2.0): ID.AM-01',
        },
        [MetricName.EXPOSURE_SCORE]: {
            description: 'A daily overall exposure score for the organization.',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.RA-5; NIST CSF (v2.0): ID.RA-05',
        },
        [MetricName.NONCOMPLIANT_DEVICES]: {
            description: 'A daily count of devices with a "noncompliant" status, according to Microsoft Intune.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.IP-1; NIST CSF (v2.0): PR.PS-01',
        },
        [MetricName.OVERDUE_ACCESS_REVIEWS]: {
            description: 'A daily count of overdue access reviews.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AC-1; NIST CSF (v2.0): PR.AA-05',
        },
        [MetricName.OVERDUE_SECURITY_TRAINING]: {
            description: 'A daily count of overdue security trainings.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AT-1; NIST CSF (v2.0): PR.AT-01',
        },
        [MetricName.OVERDUE_VULNERABILITIES]: {
            description: 'A daily count of vulnerabilities classified by Microsoft as having a CVSSv3 rating of "Critical" that were first detected over 1 month ago.',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.RA-1; NIST CSF (v2.0): ID.RA-01',
        },
        [MetricName.RISKY_DEVICES]: {
            description: 'A daily count of devices deemed to have a "High" risk score by Microsoft Defender.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.CM-7; NIST CSF (v2.0): DE.CM-09',
        },
        [MetricName.RISKY_SIGN_IN_EVENTS]: {
            description: 'A daily count of user sign-in events deemed to have increased risk.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.CM-7; NIST CSF (v2.0): DE.CM-03, DE.CM-06',
        },
        [MetricName.SECURE_SCORE]: {
            description: 'A daily comparison of current secure score versus max secure score.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.IP-1; NIST CSF (v2.0): PR.PS-01',
        },
        [MetricName.UNRESOLVED_ALERTS]: {
            description: 'A daily count of unresolved alerts organized by severity.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.CM-1; NIST CSF (v2.0): DE.CM-01',
        },
    },
    [IntegrationName.PROOFPOINT_TARGETED_ATTACK_PROTECTION]: {
        [MetricName.MALICIOUS_EMAILS]: {
            description: 'An hourly comparison of malicious emails blocked and delivered.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.CM-4; NIST CSF (v2.0): DE.CM-01, DE.CM-09',
        },
        [MetricName.MALWARE_FAMILIES]: {
            description: 'A daily count of malware families observed across email campaigns.',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.RA-2; NIST CSF (v2.0): ID.RA-02',
        },
        [MetricName.PERCENT_THREATS_BLOCKED]: {
            description: 'An hourly percentage of malicious emails quarantined and malicious links blocked compared with the total malicious emails received and malicious links clicked by users.',
            recommended_control_mapping: 'CIS CSC (v7.1): 7.10; NIST CSF (v1.1): DE.CM-4; NIST CSF (v2.0): DE.CM-01, DE.CM-09; NIST SP 800-53 (Rev. 5): SC-35, SI-3; NIST SP 800-171 (Rev. 2): 14.2, 14.5, 14.6; NIST Privacy Framework (v1.0): None',
        },
        [MetricName.PHISHING_CLICKS]: {
            description: 'An hourly comparison of phishing link clicks that were blocked and allowed.',
            recommended_control_mapping: 'NIST CSF (v1.1): DE.CM-4; NIST CSF (v2.0): DE.CM-01, DE.CM-09',
        },
        [MetricName.VERY_ATTACKED_PEOPLE_SUMMARY]: {
            description: 'Bi-weekly statistics of "very attacked people."',
            recommended_control_mapping: 'NIST CSF (v1.1): ID.RA-2; NIST CSF (v2.0): ID.RA-02',
        },
    },
    [IntegrationName.PROOFPOINT_SECURITY_AWARENESS_TRAINING]: {
        [MetricName.SIMULATED_PHISHING_CAMPAIGN_RESULTS]: {
            description: 'A weekly percentage of users who clicked and reported simulated phishing emails, deduplicated and organized by campaign.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AT-1; NIST CSF (v2.0): PR.AT-01',
        },
        [MetricName.SIMULATED_PHISHING_RESULTS]: {
            description: 'A daily count of users who have clicked and reported simulated phishing emails.',
            recommended_control_mapping: 'NIST CSF (v1.1): PR.AT-1; NIST CSF (v2.0): PR.AT-01',
        },
    },
    [IntegrationName.QUALYS]: {
        [MetricName.OVERDUE_VULNERABILITIES]: {
            description: 'A daily count of vulnerabilities classified by Qualys as having a severity of 3, 4, or 5 that were first detected over 30 days ago and last detected within the past 7 days.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6; NIST CSF (v1.1): DE.CM-8, ID.RA-1, PR.IP-12, RS.MI-3; NIST CSF (v2.0): ID.RA-01, PR.PS-02; NIST SP 800-53 (Rev. 5): RA-5; NIST SP 800-171 (Rev. 2): 11.2, 11.3, 12.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
    },
    [IntegrationName.RAPID7_INSIGHT_PLATFORM]: {
        [MetricName.ACTIVE_VULNERABILITIES]: {
            description: 'A daily count of vulnerabilities classified by Rapid7 as having a critical severity.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6; NIST CSF (v1.1): DE.CM-8, ID.RA-1, PR.IP-12, RS.MI-3; NIST CSF (v2.0): ID.RA-01, PR.PS-02; NIST SP 800-53 (Rev. 5): RA-5; NIST SP 800-171 (Rev. 2): 11.2, 11.3, 12.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
        [MetricName.AGGREGATED_RISK_SCORE]: {
            description: "A daily sum of the risk scores of all your organization's assets.",
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6; NIST CSF (v1.1): DE.CM-8, ID.RA-1, PR.IP-12, RS.MI-3; NIST CSF (v2.0): ID.RA-01, PR.PS-02; NIST SP 800-53 (Rev. 5): RA-5; NIST SP 800-171 (Rev. 2): 11.2, 11.3, 12.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
        [MetricName.OUTDATED_SCANS]: {
            description: 'A daily count of assets that were last scanned for vulnerabilities over 30 days ago.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6; NIST CSF (v1.1): DE.CM-8, ID.RA-1, PR.IP-12, RS.MI-3; NIST CSF (v2.0): ID.RA-01, PR.PS-02; NIST SP 800-53 (Rev. 5): RA-5; NIST SP 800-171 (Rev. 2): 11.2, 11.3, 12.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
    },
    [IntegrationName.RISKRECON]: {
        [MetricName.AVERAGE_THIRD_PARTY_SCORE]: {
            description: 'The average overall score for all of your third-party suppliers. Obtained daily.',
            recommended_control_mapping: 'CIS CSC (v7.1): None; NIST CSF (v1.1): ID.SC-2; NIST CSF (v2.0): GV.SC-07, ID.RA-10; NIST SP 800-53 (Rev. 5): SA-9; NIST SP 800-171 (Rev. 2): 11.1; NIST Privacy Framework (v1.0): ID.DE-P2',
        },
        [MetricName.DATA_LOSS_EVENTS]: {
            description: 'The count of data loss events at your organization, according to RiskRecon. Obtained daily.',
            recommended_control_mapping: 'CIS CSC (v7.1): 14.7; NIST CSF (v1.1): PR.DS-5; NIST CSF (v2.0): PR.DS-01, PR.DS-02; NIST SP 800-53 (Rev. 5): SC-8, SC-28; NIST SP 800-171 (Rev. 2): 13.1, 13.4; NIST Privacy Framework (v1.0): PR.DS-P5',
        },
        [MetricName.MY_OVERALL_SCORE]: {
            description: 'The score that RiskRecon assigns to your organization. Obtained daily.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6, 3.7; NIST CSF (v1.1): ID.RA-1, ID.RA-5; NIST CSF (v2.0): ID.RA-01, ID.RA-05; NIST SP 800-53 (Rev. 5): RA-3, RA-5, RA-7; NIST SP 800-171 (Rev. 2): 11.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
        [MetricName.RISKY_PORTS]: {
            description: 'A daily count of "Unsafe Network Services" in the RiskRecon "Network Filtering" domain.',
            recommended_control_mapping: 'CIS CSC (v7.1): 9.2, 9.3, 9.4, 12.4; NIST CSF (v1.1): PR.AC-5, PR.PT-4; NIST CSF (v2.0): PR.AA-06, PR.IR-01; NIST SP 800-53 (Rev. 5): CA-3, CA-9; NIST SP 800-171 (Rev. 2): 4.2, 4.6, 4.7; NIST Privacy Framework (v1.0): PR.AC-P5, PR.PT-P3',
        },
        [MetricName.THIRD_PARTY_MONITORING]: {
            description: 'A variety of risk scores for individual third parties. This metric cannot be mapped to internal controls. It is automatically applied to third parties in the Third-Party Risk Management module.',
            recommended_control_mapping: 'N/A',
        },
        [MetricName.WEB_ENCRYPTION_FINDINGS]: {
            description: 'A daily count of issues in the RiskRecon "Web Encryption" domain.',
            recommended_control_mapping: 'CIS CSC (v7.1): 14.4, 18.5; NIST CSF (v1.1): PR.DS-2; NIST CSF (v2.0): PR.DS-02; NIST SP 800-53 (Rev. 5): SC-8; NIST SP 800-171 (Rev. 2): 1.13, 13.1, 13.8; NIST Privacy Framework (v1.0): CT.DP-P1',
        },
    },
    [IntegrationName.SECURITYSCORECARD]: {
        [MetricName.THIRD_PARTY_MONITORING]: {
            description: 'A variety of risk scores for individual third parties. This metric cannot be mapped to internal controls. It is automatically applied to third parties in the Third-Party Risk Management module.',
            recommended_control_mapping: 'N/A',
        },
    },
    [IntegrationName.SOPHOS]: {
        [MetricName.ENDPOINT_ENCRYPTION]: {
            description: 'A daily count of endpoints with volume encryption enabled vs. disabled.',
            recommended_control_mapping: 'CIS CSC (v7.1): 14.8; NIST CSF (v1.1): PR.DS-1; NIST CSF (v2.0): PR.DS-01; NIST SP 800-53 (Rev. 5): SC-28; NIST SP 800-171 (Rev. 2): 13.16; NIST Privacy Framework (v1.0): CT.DP-P1',
        },
        [MetricName.ENDPOINT_HEALTH]: {
            description: 'A daily count of healthy and unhealthy endpoints. An endpoint is unhealthy if any of the following are true: 1) An assigned Sophos product is not installed. 2) The Sophos endpoint status is not "good." 3) Any Sophos service status is not "good." 4) The Sophos threat status is not "good." Note that this metric does not take product version information into account.',
            recommended_control_mapping: 'CIS CSC (v7.1): 8.1; NIST CSF (v1.1): DE.CM-4; NIST CSF (v2.0): DE.CM-01, DE.CM-09; NIST SP 800-53 (Rev. 5): SC-35, SI-3; NIST SP 800-171 (Rev. 2): 14.2, 14.4; NIST Privacy Framework (v1.0): None',
        },
    },
    [IntegrationName.TENABLE]: {
        [MetricName.OVERDUE_VULNERABILITIES]: {
            description: 'A daily count of vulnerabilities classified by Tenable as "critical" that were first detected over 30 days ago and last detected within the past 7 days.',
            recommended_control_mapping: 'CIS CSC (v7.1): 3.6; NIST CSF (v1.1): DE.CM-8, ID.RA-1, PR.IP-12, RS.MI-3; NIST CSF (v2.0): ID.RA-01, PR.PS-02; NIST SP 800-53 (Rev. 5): RA-5; NIST SP 800-171 (Rev. 2): 11.2, 11.3, 12.2; NIST Privacy Framework (v1.0): PR.PO-P10',
        },
    },
};

export interface AwsRegion {
    code: string;
    name: string;
}

export const awsRegions: AwsRegion[] = [
    { code: 'us-east-1', name: 'N. Virginia' },
    { code: 'us-east-2', name: 'Ohio' },
    { code: 'us-west-1', name: 'N. California' },
    { code: 'us-west-2', name: 'Oregon' },

    { code: 'eu-central-1', name: 'Frankfurt' },
    { code: 'eu-west-1', name: 'Ireland' },
    { code: 'eu-west-2', name: 'London' },
    { code: 'eu-west-3', name: 'Paris' },
    { code: 'eu-south-1', name: 'Milan' },
    { code: 'eu-north-1', name: 'Stockholm' },

    { code: 'ap-east-1', name: 'Hong Kong' },
    { code: 'ap-south-1', name: 'Mumbai' },
    { code: 'ap-northeast-1', name: 'Tokyo' },
    { code: 'ap-northeast-2', name: 'Seoul' },
    { code: 'ap-northeast-3', name: 'Osaka' },
    { code: 'ap-southeast-1', name: 'Singapore' },
    { code: 'ap-southeast-2', name: 'Sydney' },

    { code: 'ca-central-1', name: 'Central' },

    { code: 'af-south-1', name: 'Cape Town' },

    { code: 'me-south-1', name: 'Bahrain' },

    { code: 'sa-east-1', name: 'Sao Paulo' },
];

export const awsRegionsSelectOptions: OptionType[] = awsRegions.map((region) => {
    return { value: region.code, label: `${region.code} (${region.name})` };
});

export interface AwsConfigIntegrationRequest {
    management_account_id: string;
    ou_ids: string[];
    home_region: string;
    regions: string[];
}

export interface BitSightIntegrationRequest {
    api_token: string;
    critical_third_party_folder?: string;
    peer_folder?: string;
}

export interface MicrosoftIntegrationRequest {
    azure_client_id: string;
    client_secret: string;
    tenant_id: string;
}

export interface ProofpointTargetedAttackProtectionIntegrationRequest {
    principal: string;
    secret: string;
}

export interface ProofpointSecurityAwarenessTrainingIntegrationRequest {
    api_token_key: string;
    region: string;
}

export interface QualysIntegrationRequest {
    username: string;
    password: string;
    platform: string;
}

export interface Rapid7InsightPlatformIntegrationRequest {
    api_key: string;
    region: string;
}

export interface RiskReconIntegrationRequest {
    api_key: string;
    client_name: string;
}

export interface SecurityScorecardIntegrationRequest {
    api_key: string;
}

export interface SophosIntegrationRequest {
    sophos_client_id: string;
    client_secret: string;
}

export interface TenableIntegrationRequest {
    access_key: string;
    secret_key: string;
}

export interface SetMetricControlMappingRequest {
    control_mapping: string[];
}

export interface ToggleMetricRequest {
    enabled: boolean;
}

export enum SetupProgressState {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
}

export enum TemplateOperationFailureReason {
    NONE = 'NONE',
    TIMEOUT = 'TIMEOUT',
    FAILURE = 'FAILURE',
    OTHER = 'OTHER',
}

export interface AwsConfigMisconfigurationDetailResponse {
    account_id?: string;
    region?: string;
    description: string;
}

export interface AwsConfigIntegrationSetupProgress {
    role_template_state: SetupProgressState;
    regional_rules_template_state: SetupProgressState;
    global_rules_template_state: SetupProgressState;

    role_template_failure_reason: TemplateOperationFailureReason;
    regional_rules_template_failure_reason: TemplateOperationFailureReason;
    global_rules_template_failure_reason: TemplateOperationFailureReason;

    last_update_time?: string;
}

// A metric, as defined by the External Integrations Manage service.
export interface Metric {
    integration_name: IntegrationName;
    metric_name: MetricName;
    enabled?: boolean;
    control_mapping?: OperationalControl[];
    last_poll_success?: boolean;
    last_poll_timestamp?: string;
}

// A metric, as defined by the External Integrations Run service.
export interface RunMetricResponse {
    client_id: string;
    metric_name: MetricName;
    enabled?: boolean;
    control_mapping?: string[];
}

export interface IntegrationResponse {
    integration_name: IntegrationName;
    metrics: Metric[];
}

export interface AwsConfigIntegration {
    management_account_id: string;
    ou_ids: string[];
    home_region: string;
    regions: string[];
    client_id: string;
    integration_name: IntegrationName;
}

/**
 * See hps_model/external_integrations_run/domain.py for corresponding object on the API side.
 *
 * risk_scoring_service: This will either be BitSight, RiskRecon, or SecurityScorecard.
 * hyperlink_path: This is a string that is part of the URL path for the Third Party in the applicable risk scoring service's portal. It is used by the front-end to create a clickable hyperlink. The format/value depends on the Integration:
 *  - For BitSight, this is currently unused. We need to figure out the URL for Third Parties in the BitSight portal.
 *  - For RiskRecon, this is the analysis_id. I'm not certain what that represents; I think it can change over time for Third Parties. My best guess is that it's a unique ID assigned to a third party each time they are scanned/analyzed, so this value would identify the most recent analysis data for the Third Party.
 *  - For SecurityScorecard, this is the Third Party's domain.
 */
export interface TprmThirdPartyRiskScores {
    risk_scoring_service: IntegrationName;
    vendor_name: string;
    hyperlink_path?: string;
    overall_score: number;
    categories: TprmThirdPartyRiskScoresCategory[];
    error_message?: string;
    analysis_timestamp?: string;
}

export interface TprmThirdPartyRiskScoresCategory {
    label: string | TprmRiskScoresLabelBitSight | TprmRiskScoresLabelRiskRecon; // The type for SecurityScorecard's labels (they call them "factors") is "string" because the API documentation does not provide information about them, so we will accept them as-is rather than mapping them to more user-friendly values, like we do with BitSight and RiskRecon labels.
    rating: number;
}

export enum TprmRiskScoresLabelBitSight {
    BOTNET_INFECTIONS = 'Botnet Infections',
    DESKTOP_SOFTWARE = 'Desktop Software',
    DKIM = 'DKIM',
    DNSSEC = 'DNSSEC',
    FILE_SHARING = 'File Sharing',
    INSECURE_SYSTEMS = 'Insecure Systems',
    MALWARE_SERVERS = 'Malware Servers',
    MOBILE_APPLICATION_SECURITY = 'Mobile Application Security',
    MOBILE_SOFTWARE = 'Mobile Software',
    OPEN_PORTS = 'Open Ports',
    PATCHING_CADENCE = 'Patching Cadence',
    POTENTIALLY_EXPLOITED = 'Potentially Exploited',
    SECURITY_INCIDENTS = 'Security Incidents',
    SERVER_SOFTWARE = 'Server Software',
    SSL_CERTIFICATES = 'SSL Certificates',
    SSL_CONFIGURATIONS = 'SSL Configurations',
    SPAM_PROPAGATION = 'Spam Propagation',
    SPF = 'SPF',
    UNSOLICITED_COMMUNICATIONS = 'Unsolicited Communications',
    WEB_APPLICATION_HEADERS = 'Web Application Headers',
}

export enum TprmRiskScoresLabelRiskRecon {
    APPLICATION_SECURITY = 'Application Security',
    BREACH_EVENTS = 'Breach Events',
    DNS_SECURITY = 'DNS Security',
    EMAIL_SECURITY = 'Email Security',
    NETWORK_FILTERING = 'Network Filtering',
    SOFTWARE_PATCHING = 'Software Patching',
    SYSTEM_HOSTING = 'System Hosting',
    SYSTEM_REPUTATION = 'System Reputation',
    WEB_ENCRYPTION = 'Web Encryption',
}
