import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { AccordionCollapse, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ACTIONS, RISKS, RISK_REGISTER } from 'Config/Paths';
import { ACTIONS_LINK, ACTIONS_OWNER } from 'Config/Tooltips';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument, submitRequestWithFiles } from 'Helpers/FileUtils';
import { validateUrl } from 'Helpers/InputValidation';
import { isUserWithRiskRole } from 'Helpers/UserUtils';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { Action, ActionStatus, ActionStatusOptions, CreateActionRequest, UpdateActionRequest } from 'Models/Actions';
import { ValidationError } from 'Models/ErrorTypes';
import { FileToBeUploaded, FileUpdates, UploadedFile } from 'Models/Files';
import { RiskResponse } from 'Models/RiskRegister';
import { GroupOptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';
import { RiskTab } from 'Pages/RiskRegister/RiskDetails/RiskDetails';
import { RiskMapping } from 'Pages/RiskRegister/RiskMapping/RiskMapping';

import styles from './ManageActions.module.css';

interface UrlParams {
    action_id?: string;
}

export enum ActionsPageType {
    CREATE_NEW,
    MANAGE,
}

enum Modal {
    Delete,
    None,
}

enum SubmitState {
    None,
    Saving,
    Saved,
    Deleted,
}

export interface ManageActionsProps {
    documentApi: DocumentApi;
    actionsApi: ActionsApi;
    pageType: ActionsPageType;
    tagsApi: TagsApi;
    riskRegisterApi: RiskRegisterApi;
}
interface FormFieldState {
    title?: string;
    description?: string;
    owner?: UserResponse;
    tags?: string[];
    documents?: FileToBeUploaded[];
    dueDate?: Date;
    comments?: string;
    link?: string;
    documentUpdates?: FileUpdates[];
    status?: ActionStatus;
}

export const ManageActions = (props: ManageActionsProps) => {
    const { action_id } = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const riskIdQueryParam = query.get('riskId');

    const [documents, onAddDocuments, onRemoveDocument] = useFileDragAndDrop();
    const cachedData = useCachedData();
    const [displayedModal, setDisplayedModal] = useState(Modal.None);
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState<string>();
    const [formFieldsState, setFormFieldsState] = useState<FormFieldState>({});
    const tagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [toastErrorMessage, setToastErrorMessage] = useState<string>();
    const [toastSuccessMessage, setToastSuccessMessage] = useState<string>();
    const [initialVersion, setInitialVersion] = useState<Action>();
    const [submitState, setSubmitState] = useState(SubmitState.None);
    const [existingFiles, setExistingFiles] = useState<UploadedFile[]>([]);
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>([]);
    const [actionId, setActionId] = useState<string | undefined>(props.pageType === ActionsPageType.MANAGE ? action_id : undefined);
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [mappedRiskIds, setMappedRiskIds] = useState<string[] | undefined>(() => {
        if (props.pageType === ActionsPageType.MANAGE) {
            return undefined;
        } else {
            if (riskIdQueryParam) {
                return [riskIdQueryParam];
            } else {
                return [];
            }
        }
    });

    useEffect(() => {
        if (cachedData.users === undefined) {
            return;
        }

        if (props.pageType === ActionsPageType.CREATE_NEW) {
            return;
        }

        const getInitialVersion = async (action_id: string): Promise<void> => {
            try {
                const response = await props.actionsApi.getActionDetails(action_id);
                const currentVersion = response.data;
                setInitialVersion(currentVersion);
                setExistingFiles(currentVersion.documents ?? []);
                setMappedRiskIds(currentVersion.associated_risks.map((risk) => risk.id));
                if (currentVersion) {
                    setFormFieldsState({
                        title: currentVersion.title,
                        description: currentVersion.description,
                        owner: cachedData.users.find((user) => user.cognito_subject === currentVersion.owner),
                        tags: currentVersion.tags,
                        documents: currentVersion.documents,
                        dueDate: currentVersion.due_date ? iso8601ToJsDate(currentVersion.due_date) : undefined,
                        comments: currentVersion.comments,
                        link: currentVersion.link ? currentVersion.link : '',
                        status: currentVersion.status,
                    });
                }
            } catch (error) {
                setPageLoadErrorMessage(error.message);
            }
        };
        if (props.pageType === ActionsPageType.MANAGE && action_id) {
            getInitialVersion(action_id);
        }
    }, [action_id, props.actionsApi, props.pageType, cachedData.users]);

    useEffect(() => {
        const getRiskMappingItems = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getAllRisks();
                setRisks(response.data);
            } catch (error) {
                setPageLoadErrorMessage(error.message);
            }
        };
        getRiskMappingItems();
    }, [props.riskRegisterApi]);

    const preselectedRisk = useMemo(() => {
        if (riskIdQueryParam) {
            if (risks) {
                return risks.find((risk) => risk.id === riskIdQueryParam);
            } else {
                return undefined;
            }
        } else {
            return null;
        }
    }, [riskIdQueryParam, risks]);

    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Files',
        onAddFiles: onAddDocuments,
        onRemoveFile: onRemoveDocument,
        files: documents,
    };
    const validateFormInput = () => {
        if (formFieldsState.title === undefined || formFieldsState.title.length === 0) {
            throw new ValidationError('Title is required.');
        }

        if (formFieldsState.description === undefined || formFieldsState.description.length === 0) {
            throw new ValidationError('Description is required.');
        }

        if (formFieldsState.owner === undefined) {
            throw new ValidationError('Owner is required.');
        }

        if (formFieldsState.link && formFieldsState.link.length > 0) {
            const validationResult = validateUrl(formFieldsState.link);
            if (validationResult['valid'] !== true) {
                throw new Error(validationResult['message']);
            }
        }
    };

    const saveAction = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setToastErrorMessage(undefined);
        setToastSuccessMessage(undefined);
        setSubmitState(SubmitState.Saving);

        try {
            validateFormInput();
            await submitRequestWithFiles(
                props.documentApi,
                documents.map((document) => ({ file: document })),
                async (newDocumentation) => {
                    if (action_id && props.pageType === ActionsPageType.MANAGE) {
                        const updateActionRequest: UpdateActionRequest = {
                            title: formFieldsState.title!,
                            description: formFieldsState.description!,
                            status: formFieldsState.status!,
                            owner: formFieldsState.owner!.cognito_subject,
                            tags: formFieldsState.tags ? formFieldsState.tags : [],
                            due_date: formFieldsState.dueDate ? jsDateToIso8601(formFieldsState.dueDate) : undefined,
                            comments: formFieldsState.comments,
                            link: formFieldsState.link !== '' ? formFieldsState.link : undefined,
                            document_updates: {
                                existing_files_to_delete: existingFilesToDelete,
                                new_files: newDocumentation,
                            },
                            associated_risks: mappedRiskIds && mappedRiskIds.length > 0 ? mappedRiskIds : [],
                        };
                        await props.actionsApi.updateAction(action_id, updateActionRequest);
                    } else {
                        const createActionRequest: CreateActionRequest = {
                            title: formFieldsState.title!,
                            description: formFieldsState.description!,
                            owner: formFieldsState.owner!.cognito_subject,
                            tags: formFieldsState.tags ? formFieldsState.tags : [],
                            due_date: formFieldsState.dueDate ? jsDateToIso8601(formFieldsState.dueDate) : undefined,
                            comments: formFieldsState.comments,
                            link: formFieldsState.link !== '' ? formFieldsState.link : undefined,
                            documents: newDocumentation,
                            associated_risks: mappedRiskIds && mappedRiskIds.length > 0 ? mappedRiskIds : [],
                        };
                        const createActionResponse = await props.actionsApi.createAction(createActionRequest);
                        setActionId(createActionResponse.data);
                    }
                }
            );
            setSubmitState(SubmitState.Saved);

            let successMessage = 'Action saved.';

            if (mappedRiskIds && mappedRiskIds.length > 0) {
                if (mappedRiskIds.length > 1) {
                    successMessage += ' It may take a few seconds for the action to show up in the risk treatment plans.';
                } else {
                    successMessage += ' It may take a few seconds for the action to show up in the risk treatment plan.';
                }
            }
            setToastSuccessMessage(successMessage);
        } catch (error) {
            setToastErrorMessage(error.message);
            setSubmitState(SubmitState.None);
        }
    };

    const deleteFile = (file: UploadedFile) => {
        const existingFilesCopy: UploadedFile[] = [...existingFiles];
        existingFilesCopy.splice(existingFiles.indexOf(file), 1);
        setExistingFiles(existingFilesCopy);
        setExistingFilesToDelete([...existingFilesToDelete, file.file_id]);
    };

    const deleteAction = async (): Promise<string> => {
        await props.actionsApi.deleteAction(actionId!);
        setSubmitState(SubmitState.Deleted);
        return 'Action deleted.';
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleChangeDueDate = (date: Date | undefined): void => {
        setFormFieldsState({ ...formFieldsState, dueDate: date });
    };

    const handleStatusSelectChange = (value: ChangeEventType): void => {
        setFormFieldsState({ ...formFieldsState, status: value as ActionStatus });
    };

    const handleSelectOwnerChange = (user: UserResponse): void => {
        setFormFieldsState({ ...formFieldsState, owner: user });
    };

    if (pageLoadErrorMessage) {
        return <Text>{pageLoadErrorMessage}</Text>;
    } else if (tagOptionsState.type === 'failure') {
        return <Text>{tagOptionsState.message}</Text>;
    } else if (cachedData.users && risks && mappedRiskIds && tagOptionsState.type === 'success' && preselectedRisk !== undefined) {
        const tagOptions = tagOptionsState.data;

        const buildBreadcrumb = (): JSX.Element => {
            if (action_id && initialVersion && props.pageType === ActionsPageType.MANAGE) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${ACTIONS}`}>Action Planning</BreadcrumbLink>
                        {submitState === SubmitState.Deleted ? <BreadcrumbText>{initialVersion.title}</BreadcrumbText> : <BreadcrumbLink link={`/${ACTIONS}/${action_id}`}>{initialVersion.title}</BreadcrumbLink>}
                        <BreadcrumbText>Manage Action</BreadcrumbText>
                    </Breadcrumb>
                );
            } else if (preselectedRisk) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}`}>Risk Listing</BreadcrumbLink>
                        <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}/${preselectedRisk.id}#${RiskTab.TREATMENT_PLAN}`}>{preselectedRisk.title}</BreadcrumbLink>
                        <BreadcrumbText>Create New Action</BreadcrumbText>
                    </Breadcrumb>
                );
            } else {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${ACTIONS}`}>Action Planning</BreadcrumbLink>
                        <BreadcrumbText>Create New Action</BreadcrumbText>
                    </Breadcrumb>
                );
            }
        };

        const getHeaderTitle = (): string => {
            if (action_id && initialVersion && props.pageType === ActionsPageType.MANAGE) {
                return 'Manage Action';
            } else {
                return 'Create New Action';
            }
        };

        return (
            <>
                {displayedModal === Modal.Delete && initialVersion && (
                    <ConfirmationModal operationType="delete" headerText="Delete Action" areYouSureText={`Are you sure you want to delete "${initialVersion.title}"?`} performOperation={deleteAction} hideModal={() => setDisplayedModal(Modal.None)}>
                        <Text>The action will be removed from all risk treatment plans that include it.</Text>
                    </ConfirmationModal>
                )}
                {toastSuccessMessage && <LinkButtonToast variant="success" clearToast={() => setToastSuccessMessage(undefined)} text={toastSuccessMessage} linkButtonText={preselectedRisk ? 'View Treatment Plan' : 'View Action'} linkButtonTo={preselectedRisk ? `/${RISK_REGISTER}/${RISKS}/${preselectedRisk.id}#${RiskTab.TREATMENT_PLAN}` : `/${ACTIONS}/${actionId}`} />}
                {toastErrorMessage && <TextToast variant="failure" clearToast={() => setToastErrorMessage(undefined)} text={toastErrorMessage} />}
                <PageLayoutDefault
                    headerBreadcrumb={buildBreadcrumb()}
                    headerTitle={getHeaderTitle()}
                    body={[
                        {
                            content: (
                                <Form onSubmit={saveAction} noValidate>
                                    <fieldset disabled={submitState === SubmitState.Saved || submitState === SubmitState.Deleted}>
                                        <div className={styles.formFieldsContainer}>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldText formFieldLabel="Title" formFieldId="title" handleChange={handleChange} value={formFieldsState.title ?? ''} required />
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldTextArea formFieldLabel="Description" formFieldId="description" handleChange={handleChange} value={formFieldsState.description ?? ''} required />
                                            </div>
                                            <div className={styles.fieldGroupContainer}>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldUserSelect users={cachedData.users.filter(isUserWithRiskRole)} onUserSelected={handleSelectOwnerChange} formFieldId="owner" selectedUser={formFieldsState.owner} formFieldLabel="Owner" isRequiredField tooltip={ACTIONS_OWNER} />
                                                </div>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldMultiOptionSelect
                                                        defaultSelectedOptions={tagOptions
                                                            .map((group) => group.options)
                                                            .flat()
                                                            .filter((option) => (formFieldsState.tags ?? []).includes(option.value as string))}
                                                        formFieldLabel="Tags"
                                                        formFieldId="tags"
                                                        handleChange={(value: GroupOptionType[]) => setFormFieldsState({ ...formFieldsState, tags: value.map((tag) => tag.value as string) })}
                                                        options={tagOptions}
                                                        accessibilityLabel="tag selection"
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.fieldGroupContainer}>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldDatePicker formFieldLabel="Due Date" formFieldId="dueDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} selected={formFieldsState.dueDate} handleChange={handleChangeDueDate} />
                                                </div>
                                                {props.pageType === ActionsPageType.MANAGE && (
                                                    <div className={styles.fieldContainer}>
                                                        <FormFieldSelect isRequiredField formFieldLabel="Status" formFieldId="status" handleChange={handleStatusSelectChange} options={ActionStatusOptions} selectedOption={formFieldsState.status} />
                                                    </div>
                                                )}
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldText formFieldLabel="Link" formFieldId="link" handleChange={handleChange} value={formFieldsState.link ?? ''} tooltip={ACTIONS_LINK} />
                                                </div>
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldTextArea formFieldId="comments" formFieldLabel="Comments" handleChange={handleChange} value={formFieldsState.comments ?? ''} />
                                            </div>
                                            <div className={styles.dragAndDrop}>
                                                <FileDragAndDrop {...fileDragAndDropProps} />
                                                {initialVersion?.documents && initialVersion.documents.length > 0 && (
                                                    <>
                                                        <VisualLabel>Existing Files</VisualLabel>
                                                        <Table>
                                                            <TableBody>
                                                                {[...existingFiles]
                                                                    .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                                                    .map((file) => (
                                                                        <TableRow key={file.file_id}>
                                                                            <TableCell>
                                                                                <Text noStyles>{file.filename}</Text>
                                                                            </TableCell>
                                                                            <TableOverflowCell>
                                                                                <div className={styles.overflowContainer}>
                                                                                    <OverflowMenu
                                                                                        overflowItems={[
                                                                                            {
                                                                                                text: 'Download file',
                                                                                                onClickAction: () => downloadDocument(props.documentApi, file),
                                                                                                icon: faDownload,
                                                                                            },
                                                                                            {
                                                                                                text: 'Delete file',
                                                                                                onClickAction: () => deleteFile(file),
                                                                                                icon: faTrash,
                                                                                            },
                                                                                        ]}
                                                                                        accessibilityTitle={file.filename}
                                                                                    />
                                                                                </div>
                                                                            </TableOverflowCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.riskMapping}>
                                            <Accordion defaultActiveKey={riskIdQueryParam || mappedRiskIds.length > 0 ? 'riskTreatments' : undefined}>
                                                <div className={styles.accordionContainer}>
                                                    <AccordionToggle eventKey={'riskTreatments'} ariaLabelSuffix={'Map to Risk Treatment Plan'} />
                                                    <div className={styles.accordionHeaderContainer}>
                                                        <Text variant="Header2">Map to Risk Treatment Plan</Text>
                                                    </div>
                                                </div>
                                                <hr />
                                                <AccordionCollapse eventKey={'riskTreatments'}>
                                                    <div className={styles.accordionCollapse}>
                                                        <RiskMapping
                                                            riskMappingItems={risks}
                                                            handleRiskChange={(unmappedSelectedRisks) => {
                                                                setMappedRiskIds(unmappedSelectedRisks);
                                                            }}
                                                            currentMappedRiskIdentifiers={mappedRiskIds}
                                                        />
                                                    </div>
                                                </AccordionCollapse>
                                            </Accordion>
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            {props.pageType === ActionsPageType.MANAGE && (
                                                <Button variant="danger" disabled={submitState !== SubmitState.None} onClick={() => setDisplayedModal(Modal.Delete)} fontAwesomeImage={faTrash}>
                                                    Delete
                                                </Button>
                                            )}
                                            <div className={styles.submitButton}>
                                                <Button variant="submit" disabled={submitState !== SubmitState.None} isLoading={submitState === SubmitState.Saving} loadingText="Submitting...">
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </Form>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else {
        return <Placeholder />;
    }
};
