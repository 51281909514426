import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';

import { CSSColors } from 'Components/Colors';

import { LineAndScatterChartType } from './LineAndScatterChart';

export interface TrendLineChartProps {
    data: { value: number; xAxisLabel: string }[];
}

/**
 * Renders a simple line graph with no displayed axes. Intended to provide a rough visual summary of values changing over time.
 */
export const TrendLineChart = (props: TrendLineChartProps): JSX.Element => {
    const chartRef = useRef() as React.MutableRefObject<ReactECharts>;

    return (
        <ReactECharts
            option={{
                aria: {
                    show: true,
                },
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    top: 10,
                    bottom: 10,
                    left: 0,
                    width: '99%', // If set to 100%, hovering over the rightmost data point fails to show the dotted x-axis line.
                },
                xAxis: [
                    {
                        type: 'category',
                        data: props.data.map((element) => element.xAxisLabel),
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        boundaryGap: false,
                    },
                ],
                yAxis: [
                    {
                        show: false,
                    },
                ],
                series: [
                    {
                        type: LineAndScatterChartType.LINE,
                        itemStyle: {
                            color: CSSColors.LIGHT_BLUE,
                        },
                        lineStyle: {
                            width: 5,
                        },
                        data: props.data.map((element) => element.value),
                        symbol: 'none',
                        smooth: true,
                    },
                ],
            }}
            style={{
                height: '100%',
                width: '100%',
            }}
            ref={chartRef}
        />
    );
};
