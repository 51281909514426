import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

const INTEGRATIONNAME = IntegrationName.RAPID7_INSIGHT_PLATFORM;

export const Rapid7InsightPlatform = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    const [apiKey, setApiKey] = useState<string>();
    const [region, setRegion] = useState<string>();

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm(`Are you sure you want to delete ${INTEGRATIONNAME}? \r\n\r\n All limits created for this integration will also be deleted.`);

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(INTEGRATIONNAME);
            setSuccessMessage(`${INTEGRATIONNAME} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(apiKey, region);
            await props.externalIntegrationsApi.setExternalIntegration(INTEGRATIONNAME, {
                api_key: apiKey!,
                region: region!,
            });
            setSuccessMessage(`${INTEGRATIONNAME} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    const validateForm = (apiKey?: string, region?: string): void => {
        if (!apiKey || apiKey.length === 0) {
            throw new ValidationError('Invalid API key.');
        }
        // Case-insensitive validation because the SummIT Security API and will force the region to lower-case when constructing the URL.
        if (!region || (region.toLowerCase() !== 'ap' && region.toLowerCase() !== 'au' && region.toLowerCase() !== 'ca' && region.toLowerCase() !== 'eu' && region.toLowerCase() !== 'us')) {
            throw new ValidationError('Invalid region.');
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={`Configure ${INTEGRATIONNAME} Integration`} />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="apiKey" formFieldLabel="API Key" formFieldType="password" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setApiKey(event.currentTarget.value)} required={true} tooltip={`The API key provided by ${INTEGRATIONNAME}.`} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="region" formFieldLabel="Region" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setRegion(event.currentTarget.value)} required={true} tooltip={`The ${INTEGRATIONNAME} API region your organization uses. Valid values are: ap, au, ca, eu, and us.`} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting || isSubmitting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={faTrash}>
                            DELETE
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={faTimes}>
                                    CLOSE
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting || isSubmitting} isLoading={isSubmitting} loadingText="Saving...">
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
