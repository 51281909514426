/**
 * PageLayoutDefault: The default layout that most pages use, with a gray header and one or more white body sections.
 *  - Examples: Actions Listing, Action Details, Governance Listing, Governance Details.
 *
 * PageLayoutDefaultGridAndTable: A layout that uses the standard gray header, but with a grid and table layout instead of a series of body sections.
 *  - Examples: TPRM Third Party Listing, TPRM Third Party Service Listing.
 *
 * PageLayoutDashboard: A layout for full-page dashboards WITHOUT tabs.
 *  - Examples: TPRM Third Party Service Dashboard.
 *
 * PageLayoutDashboardTabs: A layout for full-page dashboards WITH tabs.
 *  - Examples: The overall "Dashboard" page.
 *
 * PageLayoutHybridDashboard: This is essentially a layout hybrid of PageLayoutDefault and PageLayoutDashboard. The header is a dashboard, while the body is the standard series of body sections.
 *  - Examples: Control Details, Risk Details.
 *
 * PageLayoutHybridDashboardGridAndTable: This is similar to PageLayoutHybridDashboard, but the content is a grid of cards and a table instead of a series of body sections.
 *  - Examples: Control Group Details. The Framework Details page manually implements this (dashboard header with grid and table body), since it has tabs which make it a unique layout.
 */

import { Dashlet, DashletProps } from 'Components/PageLayout/Dashlet/Dashlet';
import dashletStyles from 'Components/PageLayout/Dashlet/Dashlet.module.css';
import { GridAndTable } from 'Components/PageLayout/GridAndTable/GridAndTable';
import { Text } from 'Components/Text/Text';

import styles from './PageLayoutHybridDashboard.module.css';

/**
 * @param headerBreadcrumb      - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle           - The title of the page.
 * @param headerButtons         - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param headerDescription     - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 * @param headerFilters         - Drop-down menu filters placed in the header above the dashlets.
 * @param headerDashlets        - List of dashlets that comprise a dashboard within the header. Dashlets can be nested within one another to create a grid of dashlets.
 * @param bodyFilters           - Optional filters that are placed above the grid/table view in the body of the page.
 * @param bodyCards             - Content cards that form the grid in the body of the page. Uses can toggle between this and the table view.
 * @param bodyTable             - A table that represents the content of the page. Users can toggle between this and the grid view.
 * @param bodyNoContentMessage  - A message to display when there is no content to display (e.g., there are no cards and the table is empty). Only provide this when there is no content, since the conditional will select this over the cards/table if it has a value.
 */
interface PageLayoutDashboardHalfGridAndTableProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    headerButtons?: React.ReactNode;
    headerDescription?: React.ReactNode | string;
    headerFilters?: React.ReactNode;
    headerDashlets: DashletProps[];
    bodyFilters?: React.ReactNode;
    bodyCards: React.ReactNode[];
    bodyTable: React.ReactNode;
    bodyNoContentMessage?: string;
}
/**
 * This is similar to PageLayoutHybridDashboard, but the content is a grid of cards and a table instead of a series of body sections.
 * Note that the <Nav> and application <Header> components are static siblings of this and other PageLayout components and are neither implemented nor documented in this module.
 */
export const PageLayoutHybridDashboardGridAndTable = (props: PageLayoutDashboardHalfGridAndTableProps): JSX.Element => {
    return (
        <>
            <div className={styles.background}>
                <Header breadcrumb={props.headerBreadcrumb} title={props.headerTitle} buttons={props.headerButtons} description={props.headerDescription} filters={props.headerFilters} dashlets={props.headerDashlets} />
            </div>
            <GridAndTable filters={props.bodyFilters} cards={props.bodyCards} table={props.bodyTable} noContentMessage={props.bodyNoContentMessage} />
        </>
    );
};

/**
 * @param breadcrumb    - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param title         - The title of the page.
 * @param buttons       - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param description   - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 * @param filters       - Drop-down menu filters placed in the header above the dashlets.
 * @param dashlets      - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface HeaderProps {
    breadcrumb: React.ReactNode;
    title: string;
    buttons?: React.ReactNode;
    description?: React.ReactNode | string;
    filters?: React.ReactNode;
    dashlets: DashletProps[];
}
/**
 * The page header is the top section of the page, which contains some information that is common across all pages, such as the breadcrumb and title of the page.
 * WARNING: Do not confuse this with the overall application <Header> component, which is rendered above the "page."
 */
const Header = (props: HeaderProps): JSX.Element => {
    return (
        <div className={styles.pageHeaderContent}>
            {props.breadcrumb}
            <div className={styles.titleAndButtons}>
                <Text color="white" variant="Header1">
                    {props.title}
                </Text>
                <div className={styles.buttons}>{props.buttons}</div>
            </div>
            {typeof props.description === 'string' ? (
                <Text color="darkGray" variant="Header3">
                    {props.description}
                </Text>
            ) : (
                props.description
            )}
            <div className={styles.filters}>{props.filters}</div>
            <div className={dashletStyles.dashletAlignmentColumn}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} {...dashlet} />
                ))}
            </div>
        </div>
    );
};
