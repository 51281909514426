/**
 * A simple function that provides a consistent way to display Compliance Requirements.
 * TODO: If/when we reach a point where we have a single (or limited number of) Compliance Requirement object(s), update the parameters to be that/those object(s), rather than these two string attributes of it, which are more error prone.
 * @param regulation For example, "PCI DSS (v4.0)" or "23 NYCRR 500 (March 2017)"
 * @param identifier For example, "1" or "2.a"
 * @returns For example, "PCI DSS (v4.0): 1" or "23 NYCRR 500 (March 2017): 2.a"
 */
export const makeComplianceRequirementHumanReadable = (regulation: string, identifier: string): string => {
    return regulation + ': ' + identifier;
};
