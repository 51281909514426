import { DDQApi } from 'Api/TPRM/DDQApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { UploadedFile } from 'Models/Files';

import { DDQStateAction, DDQStateActionAnswerDocumentDeleted, DDQStateActionType } from '../DueDiligenceQuestionnaire';

export interface DeleteAnswerDocumentModalProps {
    ddqApi: DDQApi;
    thirdPartyId: string;
    serviceId: string;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
    questionId: string;
    answerDocument: UploadedFile;
    hideModal: () => void;
    dispatchDdqStateChange: (action: DDQStateAction) => void;
}

export const DeleteAnswerDocumentModal = (props: DeleteAnswerDocumentModalProps): JSX.Element => {
    const deleteDocument = async (): Promise<string> => {
        await props.ddqApi.deleteAnswerDocument(props.thirdPartyId, props.serviceId, props.controlFramework, props.controlGroupId, props.controlId, props.questionId, props.answerDocument.file_id);

        const action: DDQStateActionAnswerDocumentDeleted = {
            type: DDQStateActionType.AnswerDocumentDeleted,
            payload: {
                framework: props.controlFramework,
                groupId: props.controlGroupId,
                controlId: props.controlId,
                questionId: props.questionId,
                documentation: props.answerDocument,
            },
        };
        props.dispatchDdqStateChange(action);

        return 'Document deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Document" areYouSureText="Are you sure you want to delete the following document?" performOperation={deleteDocument} hideModal={props.hideModal}>
            <Text>{props.answerDocument.filename}</Text>
        </ConfirmationModal>
    );
};
