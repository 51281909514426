import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { IssuesSortFilterOptions } from 'Models/Issues';
import { ThirdPartyResponse } from 'Models/TPRM';
import { ColorTheme, Filter, OptionType } from 'Models/Types/GlobalType';

export interface ThirdPartyFilterProps {
    thirdParties: ThirdPartyResponse[];
    selectedThirdPartyIds: string[];
    selectedFilterOptions: (selectedFilterOptions: Filter | Filter[]) => void;
    disabled?: boolean;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

/**
 * Renders a drop-down that allows the user to filter by third party.
 */
export const ThirdPartyFilter = (props: ThirdPartyFilterProps) => {
    const handleThirdPartySelected = (thirdParties: ChangeEventType): void => {
        const filters: Filter = {
            key: IssuesSortFilterOptions.THIRD_PARTY,
            value: thirdParties ? (thirdParties as string[]) : [],
        };
        props.selectedFilterOptions(filters);
    };

    const formatThirdPartyOptions = (): OptionType[] => {
        const thirdPartyOptions: OptionType[] = props.thirdParties.map((thirdParty) => ({
            value: thirdParty.id,
            label: thirdParty.name,
        }));
        return thirdPartyOptions;
    };

    return <FormFieldSelect colorTheme={props.colorTheme} disabled={props.disabled} selectMultiple selectedOption={props.selectedThirdPartyIds} formFieldId="thirdPartiesFilter" formFieldLabel="Filter by Third Party" handleChange={handleThirdPartySelected} options={formatThirdPartyOptions()} />;
};
