/*
    NavHeader.js -- Component for the header at the top of the navigation menu.
*/
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Text } from 'Components/Text/Text';

import styles from './NavHeader.module.css';

export const NavHeader = (): JSX.Element => {
    const cachedData = useCachedData();

    return (
        <div className={styles.navHeader}>
            <Text color="white" variant="Text2">
                {cachedData.clientDetails.client_name}
            </Text>
        </div>
    );
};
