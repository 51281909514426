import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';

import { Control, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';

export interface DeleteControlModalProps extends QuestionnaireConfigurationModal {
    control: Control;
}

export const DeleteControlModal = (props: DeleteControlModalProps): JSX.Element => {
    const deleteControl = async (): Promise<string> => {
        await props.tprmApi.deleteControlConfiguration(props.control.framework, props.control.groupId, props.control.controlId);
        props.onModalActionComplete();
        return 'Control deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Control Configuration" areYouSureText="Are you sure you want to delete the following control?" performOperation={deleteControl} hideModal={props.hideModal}>
            <Text>{controlTextToString(props.control.controlText)}</Text>
        </ConfirmationModal>
    );
};
