import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import { useParams } from 'react-router';

import { Button } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';
import { AuthState } from 'Models/Auth';
import { INVALID_DDQ_ERROR } from 'Pages/TPRM/Public/VSCLogin/VSCLogin';

import styles from './LogOut.module.css';

interface LogOutProps {
    children: ReactNode;
    logoutButton?: ReactNode;
}

const LogOut = (props: LogOutProps) => {
    return (
        <div className={styles.logOutBackground}>
            <div className={styles.logOutContainer}>
                <div className={styles.logOutTitle}>
                    <Text variant="Header1" color="white">
                        {props.children}
                    </Text>
                </div>
                {props.logoutButton}
            </div>
        </div>
    );
};

export interface LogOutType {
    error?: string;
}
export interface ClientLogOutProps {
    authHandler: AuthHandler;
}

/**
 * Screen to be displayed when logging out a SummIT client. This occurs when the user explicitly logs out, or when the API returns a 401 response.
 *
 * It has a login button to enable the user to log back in.
 *
 * A sensible question is: If we want a seamless experience for the user, why show the Logout screen and require clicking the Login button at all?
 * We do this because Logouts should be explicit, for security reasons.
 * One scenario that leads to a 401 response is a second browser logging in as the same user, which de-auths the session in the original browser.
 * In this scenario, it is important that the user knows that a logout occurred.
 */
export const ClientLogOut = (props: ClientLogOutProps): JSX.Element => {
    const { error } = useParams<keyof LogOutType>() as LogOutType;

    let errorMessage: string;

    switch (error) {
        case AuthState.UNAUTHENTICATED:
            errorMessage = 'You must be authenticated to access SummIT Security.';
            break;
        case AuthState.LOGGED_OUT:
            errorMessage = 'Successfully logged out of SummIT Security.';
            break;
        case AuthState.TIMEOUT:
            errorMessage = 'Your SummIT Security session has timed out.';
            break;
        default:
            errorMessage = 'An unexpected error has occurred.';
            break;
    }

    return (
        <LogOut
            logoutButton={
                <Button variant="primary" onClick={props.authHandler.logIn} fontAwesomeImage={faArrowAltCircleRight}>
                    Log In
                </Button>
            }
        >
            {errorMessage}
        </LogOut>
    );
};

/**
 * Screen to be displayed when logging out a third party from a public endpoint.
 */
export const PublicLogOut = (): JSX.Element => {
    const { error } = useParams<keyof LogOutType>() as LogOutType;

    switch (error) {
        case AuthState.LOGGED_OUT:
            return <LogOut>Successfully logged out of SummIT Security.</LogOut>;
        case INVALID_DDQ_ERROR:
            return <LogOut>The requested third-party questionnaire was not found.</LogOut>;
        default:
            return <LogOut>You are not authorized for use of SummIT Security. Contact your SummIT Security third-party manager to be authorized.</LogOut>;
    }
};
