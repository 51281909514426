/*
    AssessmentDetails.tsx -- Displays the history of an assessment.
*/

import { faClipboard, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useMemo, useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Role } from 'Components/Context/RBACContext';
import { SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { AssessmentDetailsResponse, AssessmentHistoryResponse, AssessmentHistorySortFilterOptions, AssessmentRole, AssessmentState, OperationalControl, assessmentStateAsString, determineUserAssessmentRoles } from 'Models/OperationalControls';

import styles from './AssessmentDetails.module.css';

export interface AssessmentDetailsProps {
    detailedControlResponse: OperationalControl;
    assessmentDetailsResponse?: AssessmentDetailsResponse;
    assessmentDetailsError?: string;
    assessmentHistoryList?: AssessmentHistoryResponse[];
    assessmentLinkData: { to: string; state: Record<string, any> };
    authenticatedUserSubject?: string;
    authenticatedUserRoles: Role[];
}

export const AssessmentDetails = (props: AssessmentDetailsProps): JSX.Element => {
    const [currentSort, setCurrentSort] = useState<string>(AssessmentHistorySortFilterOptions.TIMESTAMP);
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.DESC);
    const userAssessmentRoles = useMemo(() => determineUserAssessmentRoles(props.authenticatedUserSubject, props.authenticatedUserRoles, props.detailedControlResponse.configuration.review_configuration?.owner_subject, props.detailedControlResponse.configuration.review_configuration?.reviewer_subject), [props.authenticatedUserSubject, props.authenticatedUserRoles, props.detailedControlResponse]);

    const assessmentHasStarted = (): boolean => {
        if (props.assessmentDetailsResponse?.assessment_state === AssessmentState.UNDER_REVIEW) {
            return true;
        }

        return props.assessmentDetailsResponse?.assessment_state === AssessmentState.IN_PROGRESS && ((props.assessmentDetailsResponse.owner_comments && props.assessmentDetailsResponse.owner_comments.length > 0) || props.assessmentDetailsResponse.assessment_effectiveness !== undefined);
    };

    const assessmentButton = (): JSX.Element | null => {
        const assessmentConfigured = props.assessmentDetailsResponse !== undefined;
        const userCanSubmitForReview = userAssessmentRoles.has(AssessmentRole.ADMIN) || userAssessmentRoles.has(AssessmentRole.OWNER);
        const userCanApprove = userAssessmentRoles.has(AssessmentRole.ADMIN) || userAssessmentRoles.has(AssessmentRole.REVIEWER);

        if (!assessmentConfigured) {
            return null;
        }

        let buttonText = 'ASSESSMENT';

        if (!assessmentHasStarted()) {
            if (userCanSubmitForReview) {
                buttonText = 'BEGIN ASSESSMENT';
            }
            return (
                <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    {buttonText}
                </Link>
            );
        }

        if (props.assessmentDetailsResponse?.assessment_state === AssessmentState.IN_PROGRESS) {
            return (
                <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    ASSESSMENT
                </Link>
            );
        }

        if (userCanApprove) {
            buttonText = 'REVIEW ASSESSMENT';
        }

        return (
            <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboardCheck}>
                {buttonText}
            </Link>
        );
    };

    const sortEvidence = (): AssessmentHistoryResponse[] => {
        if (!props.assessmentHistoryList) {
            return [];
        }
        const copiedList = [...props.assessmentHistoryList];

        copiedList.sort((assessmentHistoryItemA, assessmentHistoryItemB) => {
            let propertyA;
            let propertyB;

            if (currentSort === AssessmentHistorySortFilterOptions.OWNER_COMMENTS) {
                propertyA = getOwnerCommentString(assessmentHistoryItemA);
                propertyB = getOwnerCommentString(assessmentHistoryItemB);
            } else if (currentSort === AssessmentHistorySortFilterOptions.REVIEWER_COMMENT) {
                propertyA = assessmentHistoryItemA[currentSort]?.text;
                propertyB = assessmentHistoryItemB[currentSort]?.text;
            } else {
                propertyA = assessmentHistoryItemA[currentSort];
                propertyB = assessmentHistoryItemB[currentSort];
            }

            return undefinedComparator(propertyA, propertyB, (propertyA, propertyB) => {
                const sortCompareResult = (propertyA as string).localeCompare(propertyB as string);

                return currentSortDirection === SortDirection.ASC ? sortCompareResult : -sortCompareResult;
            });
        });

        return copiedList;
    };

    const tableRow = (assessmentHistory: AssessmentHistoryResponse): JSX.Element => {
        return (
            <TableRow key={assessmentHistory.timestamp}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateLong(assessmentHistory.timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{assessmentStateAsString(assessmentHistory.assessment_state)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getOwnerCommentString(assessmentHistory)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{assessmentHistory.reviewer_comment?.text !== undefined ? assessmentHistory.reviewer_comment.text : '-'}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    const getOwnerCommentString = (assessmentHistory: AssessmentHistoryResponse): string | undefined => {
        const comments = assessmentHistory.owner_comments;
        if (!comments || comments.length === 0) {
            return;
        }

        return comments[comments.length - 1].text;
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: [
            { dataKey: AssessmentHistorySortFilterOptions.TIMESTAMP, label: 'TIME' },
            { dataKey: AssessmentHistorySortFilterOptions.ASSESSMENT_STATE, label: 'STATE' },
            { dataKey: AssessmentHistorySortFilterOptions.OWNER_COMMENTS, label: 'OWNER COMMENT' },
            { dataKey: AssessmentHistorySortFilterOptions.REVIEWER_COMMENT, label: 'REVIEWER COMMENT' },
        ],
        applySorting: (newSort, newSortDirection) => {
            setCurrentSort(newSort as AssessmentHistorySortFilterOptions);
            setCurrentSortDirection(newSortDirection);
        },
        currentSort,
        currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    return (
        <Fragment>
            <div className={styles.formHeaderContainer}>
                <Text color="blue" variant="Header2">
                    Assessment History
                </Text>
                {assessmentButton()}
            </div>
            {props.assessmentDetailsError && <Text color="red">{props.assessmentDetailsError}</Text>}
            <div className={styles.tableContainer}>
                <Table>
                    <SortableTableHeader {...sortableTableProps} />
                    <TableBody>{sortEvidence().map(tableRow)}</TableBody>
                </Table>
            </div>
        </Fragment>
    );
};
