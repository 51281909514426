import moment from 'moment';

import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { Text } from 'Components/Text/Text';
import { getHumanReadableControlIdFromControlId } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';

import styles from './AssessmentControlDisplayModal.module.css';

export interface AssessmentForDisplay {
    identifier: string; // The canonical identifier for a Control. For example, "CIS CSC (v8.0)#1#1.1".
    name?: string; // The name of the Control. Present for custom Controls; absent for standard Controls.
    date: string;
}

export interface AssessmentControlDisplayModalProps {
    hideModal: () => void;
    selectedAssessments: AssessmentForDisplay[];
    modalTitle: string;
}

export const AssessmentControlDisplayModal = (props: AssessmentControlDisplayModalProps): JSX.Element => {
    const sortCompletedAssessments = (controlA: AssessmentForDisplay, controlB: AssessmentForDisplay): number => {
        if (controlA.date > controlB.date) {
            return 1;
        } else if (controlA.date < controlB.date) {
            return -1;
        } else {
            const controlADisplayName = getHumanReadableControlIdFromControlId(controlA.identifier, controlA.name);
            const controlBDisplayName = getHumanReadableControlIdFromControlId(controlB.identifier, controlB.name);
            return controlADisplayName.localeCompare(controlBDisplayName, undefined, { numeric: true, sensitivity: 'base' });
        }
    };

    const formatDate = (date: string) => {
        return moment(date).add(1, 'hour').format('MM/DD/YYYY');
    };

    const completedAssessmentListing = props.selectedAssessments.sort(sortCompletedAssessments).map((assessment) => (
        <div className={styles.detailsContainer} key={`${assessment.identifier} ${assessment.date}`}>
            <Link to={getFrameworkGroupControlURL(assessment.identifier)} size="sm" openInNewTab>
                {getHumanReadableControlIdFromControlId(assessment.identifier, assessment.name)}
            </Link>
            <div className={styles.detailsDateContainer}>
                <Text color="darkGray" noStyles={true}>
                    {formatDate(assessment.date)}
                </Text>
            </div>
        </div>
    ));

    return <CollapsibleModal displayItems={completedAssessmentListing} headerText={{ text: props.modalTitle }} hideModal={props.hideModal} />;
};
