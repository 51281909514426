import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { Text, TextProps } from 'Components/Text/Text';
import { FileStateToolTip } from 'Components/Tooltips/FileStateToolTip';
import { downloadDocument } from 'Helpers/FileUtils';
import { FileState, UploadedFile } from 'Models/Files';

import styles from './UploadedFileAndState.module.css';

export interface UploadedFileAndStateProps {
    documentApi: DocumentApi;
    file: UploadedFile;

    /**
     * If provided, `title` will be used as the text representing the file to the user. Otherwise, the file's name will be used.
     */
    title?: string;

    /**
     * If provided, `textVariant` will apply to the text representing the file to the user. Otherwise, the default variant will be used.
     */
    textVariant?: TextProps['variant'];

    /**
     * If `true`, the user will not be able to click on the file name/title to download the file.
     * Use this property only if you are providing a different way to download the file (e.g. via an overflow menu).
     */
    unclickable?: boolean;

    /**
     * This will be called if the user clicks on the file name/title and an error occurs during download.
     * Unless this component is `unclickable`, you _must_ define this prop.
     */
    onDownloadError?: (errorMessage: string) => void;
}

/**
 * Displays a file (via its name or a specific title--see `UploadedFileAndStateProps`) that has been uploaded by a user.
 * Displays a tooltip after the file name/title to indicate the state of the file. If the file has failed file processing, or is still being processed, the name/title will be greyed out and unclickable.
 * Unless this component is `unclickable`, it handles the download of the file.
 */
export const UploadedFileAndState = (props: UploadedFileAndStateProps) => {
    const fileCanBeDownloaded = props.file.file_state === FileState.PASSED;
    const usesLink = fileCanBeDownloaded && !props.unclickable;
    const underscoreClass = props.file.filename.includes('_') ? styles.underScore : undefined;

    const tryDownloadDocument = async () => {
        try {
            await downloadDocument(props.documentApi, props.file);
        } catch (error) {
            props.onDownloadError?.(`Something went wrong when trying to download "${props.file.filename}".`);
        }
    };

    return (
        <div className={`${styles.container} ${underscoreClass}`}>
            {usesLink && (
                <Button variant="linkText" size="sm" onClick={tryDownloadDocument}>
                    {props.file.filename}
                </Button>
            )}
            {!usesLink && (
                <Text variant={props.textVariant} color={fileCanBeDownloaded ? undefined : 'darkGray'} noStyles>
                    {props.title ?? props.file.filename}
                </Text>
            )}
            <span className={styles.tooltipContainer}>
                <FileStateToolTip fileState={props.file.file_state} />
            </span>
        </div>
    );
};
