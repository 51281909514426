export type TagCategory = Required<TagCategoryRequest>;

export interface TagRequest {
    title: string;
    id?: string;
}

export interface TagCategoryRequest {
    title: string;
    color: string;
    tags: TagRequest[];
    id?: string;
}

export interface UpdateTagCategoryRequest {
    category_set: TagCategoryRequest[];
}

export interface TagCategoryResponse {
    category_set: TagCategory[];
}

export interface DisplayableTag {
    id: string;
    title: string;
    category_color: string;
    category_title: string;
}

export const tagsComparator = (a: DisplayableTag, b: DisplayableTag) => {
    if (a.category_title < b.category_title) {
        return -1;
    } else if (a.category_title > b.category_title) {
        return 1;
    } else {
        if (a.title < b.title) {
            return -1;
        } else if (a.title > b.title) {
            return 1;
        } else {
            return 0;
        }
    }
};
