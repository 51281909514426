/*
	AWSSessionApi.ts -- AWS-specific interface to keep the session alive.
*/

import { KEEP_ALIVE } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiGet } from 'RestApiConnector';

import { SessionApi } from './SessionApi';

export class AWSSessionApi implements SessionApi {
    keepAlive = (): Promise<ResponseModel<void>> => {
        return restApiGet(`/${KEEP_ALIVE}`, {});
    };
}
