import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMServiceExportRow } from 'Models/Exports';
import { numberAsEffectivenessString } from 'Models/OperationalControls';
import { ThirdPartyContact, numberAsRiskRatingString } from 'Models/TPRM';

export interface ThirdPartyServicesProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ThirdPartyServicesExport = (props: ThirdPartyServicesProps): JSX.Element => {
    const [services, setServices] = useState<TPRMServiceExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getThirdPartyServices = async (): Promise<void> => {
            try {
                const thirdPartyServicesResponse = await props.exportsApi.getThirdPartyServicesExport();
                setServices(thirdPartyServicesResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getThirdPartyServices();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMServiceExportRow>[] = [
        { field: 'third_party_name', headerName: 'Third-Party Name', width: 300 },
        { field: 'name', headerName: 'Service Name', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'assessment_state', headerName: 'Assessment State', width: 300 },
        dateColumn('assessment_due_date', 'Assessment Due Date'),
        { field: 'inherent_risk_score', headerName: 'Inherent Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'assessment_control_effectiveness', headerName: 'Overall Control Effectiveness', width: 300, valueGetter: (value) => numberAsEffectivenessString(value) },
        { field: 'assessment_residual_risk_score', headerName: 'Residual Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'third_party_service_manager_user_id', headerName: 'Third-Party Service Manager', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'responsible_organization', headerName: 'Responsible Organization', width: 300 },
        {
            field: 'delegates',
            headerName: 'Delegates',
            width: 300,
            valueGetter: (value) =>
                value
                    .map((userId: string) => getUserNameFromSubject(userId, cachedData.users, UserNameFormat.FIRST_SPACE_LAST))
                    .sort()
                    .join(', '),
        },
        {
            field: 'third_party_contacts',
            headerName: 'Third-Party Service Contacts',
            width: 300,
            valueGetter: (value) =>
                value
                    // Only show the name for each contact. Omit their email address and phone number.
                    .filter((thirdPartyContact: ThirdPartyContact) => thirdPartyContact.name !== undefined)
                    .sort((thirdPartyContactA: ThirdPartyContact, thirdPartyContactB: ThirdPartyContact) => (thirdPartyContactA?.name || '').localeCompare(thirdPartyContactB?.name || ''))
                    .map((thirdPartyContact: ThirdPartyContact) => thirdPartyContact.name)
                    .join(', '),
        },
        dateColumn('created_time', 'Created Date'),
    ];

    if (services) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Third-Party Services</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Third-Party Services Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(thirdPartyService: TPRMServiceExportRow) => thirdPartyService.id} rows={services} title="Third-Party Services Export Preview" fileName="Third-Party Services" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
