import { useEffect, useRef } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { MultipleChoiceSelectionGroup } from 'Components/FormField/MultipleChoiceSelectionGroup/MultipleChoiceSelectionGroup';
import { RadioButtonGroup, RadioButtonOptionType } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { Text } from 'Components/Text/Text';
import { UploadedFile } from 'Models/Files';
import { Answer, QuestionType } from 'Models/TPRM';
import { DocumentListing, DocumentListingProps } from 'Pages/TPRM/Components/DocumentListing/DocumentListing';

import { DDQStateAction, DDQStateActionControlScrolledOver, DDQStateActionQuestionFileDeselected, DDQStateActionQuestionFilesSelected, DDQStateActionType, DDQStateControl, DDQStateQuestion, DeleteAnswerDocumentModalPayload, STICKY_HEADER_HEIGHT } from '../../DueDiligenceQuestionnaire';
import styles from '../../DueDiligenceQuestionnaire.module.css';

export interface DDQQuestionProps {
    control: DDQStateControl;
    question: DDQStateQuestion;
    questionNumber: number;
    documentApi: DocumentApi;
    isThirdParty: boolean;
    riskWorkflowIsNotInProgress: boolean;
    disabled: boolean;
    isStickyHeaderVisible: boolean;
    isScrolledOverQuestion: boolean;
    onAnswerChanged: (questionId: string, answer?: Answer, additionalInformation?: string) => void;
    dispatchDdqStateChange: (action: DDQStateAction) => void;
    onSelectDocumentToDelete: (payload: DeleteAnswerDocumentModalPayload) => void;
}
export const FreeformDDQQuestion = (props: DDQQuestionProps): JSX.Element => {
    const onAnswerChanged = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        props.onAnswerChanged(props.question.id, event.currentTarget.value);
    };
    return (
        <GenericDDQQuestion {...props} showAdditionalInformation={false}>
            <FormFieldTextArea disabled={props.disabled} formFieldId={props.question.id} formFieldLabel="Answer" value={props.question.answer.current as string} handleChange={onAnswerChanged} />
        </GenericDDQQuestion>
    );
};
export const SingleSelectDDQQuestion = (props: DDQQuestionProps): JSX.Element => {
    const onAnswerRadioChanged = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        const index = props.question.options?.indexOf(event.currentTarget.value);
        props.onAnswerChanged(props.question.id, index);
    };

    const createOptionType = (options?: string[]): RadioButtonOptionType[] => {
        const radioOptions: RadioButtonOptionType[] = [];
        if (options) {
            options.forEach((option) => {
                radioOptions.push({
                    label: option,
                    value: option,
                });
            });
        }

        return radioOptions;
    };

    const radioSelected = (index: number): string | undefined => {
        if (props.question.options) {
            return props.question.options[index];
        }
        return undefined;
    };

    return (
        <GenericDDQQuestion {...props} onAdditionalInformationChange={(value: string) => props.onAnswerChanged(props.question.id, props.question.answer.current, value)}>
            <>
                <RadioButtonGroup formFieldLabel="Answer" disabled={props.disabled} formFieldId="singleSelectQuestion" handleChange={onAnswerRadioChanged} options={createOptionType(props.question.options)} selectedOption={radioSelected(props.question.answer.current as number)} />
            </>
        </GenericDDQQuestion>
    );
};

export const MultiChoiceDDQQuestion = (props: DDQQuestionProps): JSX.Element => {
    const onMultipleChoiceAnswerChange = (answers: number[]): void => {
        props.onAnswerChanged(props.question.id, answers);
    };
    return (
        <GenericDDQQuestion {...props} onAdditionalInformationChange={(value: string) => props.onAnswerChanged(props.question.id, props.question.answer.current, value)}>
            <>
                <MultipleChoiceSelectionGroup formFieldLabel="Answer" disabled={props.disabled} formFieldId="multiAnswerSelection" handleChange={onMultipleChoiceAnswerChange} options={props.question.options!} selectedOptions={props.question.answer.current ? (props.question.answer.current as number[]) : []} />
            </>
        </GenericDDQQuestion>
    );
};

export const DocumentUploadDDQQuestion = (props: DDQQuestionProps): JSX.Element => {
    return <GenericDDQQuestion {...props} disabled={props.disabled} onAdditionalInformationChange={(value: string) => props.onAnswerChanged(props.question.id, props.question.answer.current, value)} />;
};

interface GenericDDQQuestionProps extends DDQQuestionProps {
    children?: JSX.Element;
    showAdditionalInformation?: boolean;
    isStickyHeaderVisible: boolean;
    onAdditionalInformationChange?: (value: string) => void;
}

const GenericDDQQuestion = ({ showAdditionalInformation = true, ...props }: GenericDDQQuestionProps): JSX.Element => {
    const questionContainerElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (questionContainerElement && questionContainerElement.current) {
            const questionContainerElementTop = questionContainerElement.current.offsetTop;
            const questionContainerElementBottom = questionContainerElement.current.offsetTop + questionContainerElement.current.offsetHeight;
            const stickyHeaderOffset = props.isStickyHeaderVisible ? STICKY_HEADER_HEIGHT : 0;

            const onScroll = (event: Event) => {
                const action: DDQStateActionControlScrolledOver = {
                    type: DDQStateActionType.ControlScrolledOver,
                    payload: {
                        framework: props.control.framework,
                        groupId: props.control.groupId,
                        controlId: props.control.id,
                        questionId: props.question.id,
                        questionNumber: props.questionNumber,
                    },
                };

                // The top of the viewport plus space for the sticky header.
                const visiblePageYOffset = window.pageYOffset + stickyHeaderOffset;

                // Whether or not the top of the viewport is within the question's area.
                const visiblePageYOffsetIsInsideQuestion = visiblePageYOffset > questionContainerElementTop && visiblePageYOffset < questionContainerElementBottom;

                // Prevent further dispatches when the question is already displayed within the sticky header.
                if (visiblePageYOffsetIsInsideQuestion && !props.isScrolledOverQuestion) {
                    props.dispatchDdqStateChange(action);
                }
            };

            window.addEventListener('scroll', onScroll);

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, [props]);
    const onSelectNewDocumentation = (questionId: string, files: File[]) => {
        const action: DDQStateActionQuestionFilesSelected = {
            type: DDQStateActionType.QuestionFilesSelected,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                questionId: questionId,
                files: files,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const onDeselectNewDocumentation = (questionId: string, file: File) => {
        const action: DDQStateActionQuestionFileDeselected = {
            type: DDQStateActionType.QuestionFileDeselected,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                questionId: questionId,
                file: file,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const onSelectExistingDocumentToDelete = (existingDocument: UploadedFile) => {
        props.onSelectDocumentToDelete({
            answerDocument: existingDocument,
            controlFramework: props.control.framework,
            controlGroupId: props.control.groupId,
            controlId: props.control.id,
            questionId: props.question.id,
        });
    };

    const documentationLabel = ((): string => {
        if (props.isThirdParty) {
            return props.question._type === QuestionType.DOCUMENT_UPLOAD ? 'DOCUMENT UPLOAD' : 'SUPPORTING DOCUMENTATION';
        } else {
            return props.question._type === QuestionType.DOCUMENT_UPLOAD ? 'DOCUMENTATION' : 'SUPPORTING DOCUMENTATION';
        }
    })();

    const documentListingProps: DocumentListingProps = {
        dragAndDropInputId: `${props.control.id}-question-${props.questionNumber + 1}-files`,
        dragAndDropLabelText: documentationLabel,
        documentApi: props.documentApi,
        existingDocuments: props.question.answerDocuments,
        newDocuments: props.question.newDocuments,
        actions: props.isThirdParty ? ['upload', 'delete'] : ['download'],
        disableFileUpload: props.disabled,
        hideRemoveNewDocumentButton: true,
        disableFileOverflow: props.isThirdParty && (props.riskWorkflowIsNotInProgress || props.disabled), // Client users should always be able to download. Third-Party users cannot upload when the DDQ is disabled or the risk workflow is not in progress.
        onSelectNewDocuments: (files) => onSelectNewDocumentation(props.question.id, files),
        onDeselectNewDocument: (file) => onDeselectNewDocumentation(props.question.id, file),
        onSelectExistingDocumentToDelete: onSelectExistingDocumentToDelete,
    };

    const onAdditionalInformationChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (props.onAdditionalInformationChange) props.onAdditionalInformationChange(event.currentTarget.value);
    };

    return (
        <div ref={questionContainerElement}>
            <Text variant="Header3">{`${props.questionNumber + 1}. ${props.question.text}`}</Text>
            <div className={styles.formFieldContainer}>
                {props.children}
                <div className={styles.documentListing}>
                    <DocumentListing {...documentListingProps} />
                    {!props.isThirdParty && props.question.answerDocuments.length < 1 && <Text color="darkGray">No documents have been uploaded.</Text>}
                </div>
            </div>
            <div className={styles.formFieldContainer}>{showAdditionalInformation && <FormFieldTextArea disabled={props.disabled} formFieldId={props.question.id} formFieldLabel="Additional Information" value={props.question.additionalInformation.current} handleChange={onAdditionalInformationChange} />}</div>
        </div>
    );
};
