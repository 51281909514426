import { DocumentApi } from 'Api/Document/DocumentApi';
import { FileDownloadResponse, FileToBeUploaded, SignedUploadResponse, UploadedFile } from 'Models/Files';

import { validateFileForUpload } from './InputValidation';

/**
 * Contains all information required to upload a document to S3.
 * If the intention is to create a new version of an existing document, then `file_id` _must_ be defined.
 */
export interface DocumentForUpload {
    file: File;
    file_id?: string;
}

interface PendingDocumentUpload {
    signedUpload: SignedUploadResponse;
    file: File;
}

/**
 * Downloads a file from S3, using a signed URL from the backend API.
 * This function _must_ be used within a try-catch (and it _must_ be `await`-ed), as errors may occur when communicating with S3 or our backend API.
 */
export const downloadDocument = async (documentApi: DocumentApi, file: UploadedFile): Promise<void> => {
    const response = await documentApi.getSignedDownload(file.file_id);
    const signedDownloadResponse = response.data;
    const link = document.createElement('a');
    link.href = signedDownloadResponse.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * Downloads a file directly from the backend to be saved in the user's downloads directory.
 * This function is only used in rare cases, when retrieving files generated by or directly served by the backend code. If you want to retrieve files from the backend file store, you probably want to use `downloadDocument` or `downloadText` instead.
 * @param encoding - Indicates whether the file content should be treated as binary or plaintext.
 * @param response - The raw content and name of the file.
 */
export const saveFileFromApi = (encoding: 'binary' | 'plaintext', response: FileDownloadResponse): void => {
    let content: string | Uint8Array;

    if (encoding === 'binary') {
        content = new Uint8Array(
            atob(response.file_content)
                .split('')
                .map((char) => char.charCodeAt(0))
        );
    } else {
        content = atob(response.file_content);
    }

    const blobUrl = window.URL.createObjectURL(new Blob([content]));

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = response.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * Standardizes the "S3 dance". This function _must_ be used within a try-catch (and it _must_ be `await`-ed), as errors may occur when communicating with S3 or our backend API.
 * This function is intended to be used when a form involves uploading a single document to S3.
 *
 * @param documentApi - The DocumentApi that will be used to obtain a presigned URL and upload the file.
 * @param file - The file selected for upload by the user.
 * @param submitRequest - A function that sends a request to our backend API. It will be executed after obtaining a presigned URL for the file. If it succeeds, the file will be uploaded immediately afterward.
 */
export const submitRequestWithFile = async <T>(documentApi: DocumentApi, file: DocumentForUpload, submitRequest: (newDocumentation: FileToBeUploaded) => Promise<T>): Promise<T> => {
    return await submitRequestWithFiles(documentApi, [file], async (newDocumentation) => {
        return await submitRequest(newDocumentation[0]);
    });
};

/**
 * Standardizes the "S3 dance". This function _must_ be used within a try-catch (and it _must_ be `await`-ed), as errors may occur when communicating with S3 or our backend API.
 * This function is intended to be used when a form involves uploading multiple documents to S3.
 *
 * @param documentApi - The DocumentApi that will be used to obtain a presigned URLs and upload the files.
 * @param files - The files selected for upload by the user.
 * @param submitRequest - A function that sends a request to our backend API. It will be executed after obtaining a presigned URL for each file. If it succeeds, each file will be uploaded immediately afterward.
 */
export const submitRequestWithFiles = async <T>(documentApi: DocumentApi, files: DocumentForUpload[], submitRequest: (newDocumentation: FileToBeUploaded[]) => Promise<T>): Promise<T> => {
    // Validate each file before uploading.
    for (const document of files) {
        validateFileForUpload(document.file);
    }

    const pendingDocumentUploads: PendingDocumentUpload[] = [];

    try {
        // For each document being uploaded, obtain a presigned URL.
        for (const document of files) {
            const signedUploadResponse = (await documentApi.getSignedUpload(document.file.name, document.file_id)).data;
            pendingDocumentUploads.push({ signedUpload: signedUploadResponse, file: document.file });
        }
    } catch (error) {
        console.log(error);
        throw new Error('Something went wrong when trying to upload files.');
    }

    // Construct a list of NewDocumentation from the names of the provided files and their corresponding IDs from the presigned URLs.
    const newDocumentation: FileToBeUploaded[] = pendingDocumentUploads.map((pendingUpload) => {
        return {
            filename: pendingUpload.file.name,
            file_id: pendingUpload.signedUpload.file_id,
        };
    });

    // Submit the request to our backend API.
    const submitResult = await submitRequest(newDocumentation);

    const failedFileNames: string[] = [];

    // Upload each document to S3. If any error occurs, remember it for below, but continue trying to upload other documents.
    for (const pendingUpload of pendingDocumentUploads) {
        try {
            await documentApi.uploadDocument(pendingUpload.signedUpload.url, pendingUpload.signedUpload.fields, pendingUpload.file);
        } catch (error) {
            failedFileNames.push(pendingUpload.file.name);
        }
    }

    if (failedFileNames.length > 0) {
        throw new Error(`Something went wrong when trying to upload: ${failedFileNames.join(', ')}.`);
    }

    return submitResult;
};
