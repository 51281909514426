import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { Text } from 'Components/Text/Text';
import { getHumanReadableControlIdFromControlId, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { CompletedAssessment, Effectiveness, effectivenessAsString } from 'Models/OperationalControls';

import styles from './ControlsOverTimeDisplayModal.module.css';

export interface ControlsOverTimeDisplayModalProps {
    hideModal: () => void;
    selectedControls: CompletedAssessment[];
    effectiveness: Effectiveness;
    date: string;
}

export const ControlsOverTimeDisplayModal = (props: ControlsOverTimeDisplayModalProps): JSX.Element => {
    const sortCompletedAssessments = (controlA: CompletedAssessment, controlB: CompletedAssessment): number => {
        let controlId;

        controlId = getOperationalControlIdentifierString(controlA.control_framework, controlA.control_group_id, controlA.control_id);
        const controlADisplayName = getHumanReadableControlIdFromControlId(controlId, controlA.control_name);

        controlId = getOperationalControlIdentifierString(controlB.control_framework, controlB.control_group_id, controlB.control_id);
        const controlBDisplayName = getHumanReadableControlIdFromControlId(controlId, controlB.control_name);

        return controlADisplayName.localeCompare(controlBDisplayName, undefined, { numeric: true, sensitivity: 'base' });
    };

    const controlOverTimeListing = props.selectedControls.sort(sortCompletedAssessments).map((control) => {
        const controlId = getOperationalControlIdentifierString(control.control_framework, control.control_group_id, control.control_id);
        return (
            <div className={styles.detailsContainer} key={control.timestamp}>
                <Link to={getFrameworkGroupControlURL(controlId)} size="sm" openInNewTab>
                    {getHumanReadableControlIdFromControlId(controlId, control.control_name)}
                </Link>
                <div className={styles.detailsDateContainer}>
                    <Text color="darkGray" noStyles={true}>
                        {iso8601ToUsDateShort(control.timestamp)}
                    </Text>
                </div>
            </div>
        );
    });

    return <CollapsibleModal displayItems={controlOverTimeListing} headerText={{ text: 'Control Assessments', secondaryText: `${effectivenessAsString(props.effectiveness)} - ${props.date}` }} hideModal={props.hideModal} />;
};
