import { Component, ReactNode } from 'react';
import { Location, useLocation } from 'react-router-dom';

import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';

import styles from './ErrorBoundary.module.css';

export interface ErrorBoundaryProps {
    children: ReactNode;
}

/**
 * Catches rendering errors originated by children and renders a "fallback" UI.
 * This component is intended to be used only at the topmost level of the app, as the UI (simply an error message) it displays is highly generic.
 *
 * IMPORTANT: Error boundaries do not catch errors for:
 * Event handlers
 * Asynchronous code
 * Errors generated by the error boundary itself
 */
export const ErrorBoundary = (props: ErrorBoundaryProps) => {
    const location = useLocation();
    return <ErrorBoundaryDisplay location={location}>{props.children}</ErrorBoundaryDisplay>;
};

interface ErrorBoundaryDisplayProps {
    location: Location;
    children: ReactNode;
}

interface ErrorBoundaryDisplayState {
    hasError: boolean;
}

/**
 * Renders an error message when an uncaught exception is thrown by a child component.
 * Error boundaries must be class components. Because this component needs access to the router location, it must be wrapped by `ErrorBoundary`.
 */
class ErrorBoundaryDisplay extends Component<ErrorBoundaryDisplayProps, ErrorBoundaryDisplayState> {
    constructor(props: ErrorBoundaryDisplayProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    /**
     * Prevents the "error" case of the `render` method below from continuing to render after the user has navigated away from the child page that originally threw the exception.
     */
    componentDidUpdate(prevProps: Readonly<ErrorBoundaryDisplayProps>) {
        if (this.state.hasError && prevProps.location !== this.props.location) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.errorMessage}>
                    <Text>{GENERIC_ERROR_MESSAGE}</Text>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}
