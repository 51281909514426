import { faChevronCircleRight, faDownload, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowItem, OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { RadioButtonGroup } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT, TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE } from 'Config/Tooltips';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { downloadDocument } from 'Helpers/FileUtils';
import { validateFileForUpload } from 'Helpers/InputValidation';
import { Navigator } from 'Helpers/Navigator';
import { addFiles, removeFiles } from 'Hooks/FileDragAndDrop';
import { SignedUploadResponse, UploadedFile } from 'Models/Files';
import { InherentRiskQuestionnaireQuestion, InherentRiskQuestionnaireResponse, InherentRiskQuestionnaireSection, RiskRating, RiskRatingPointThresholds, RiskRatingSelectOptions, SaveOrSubmitInherentRiskQuestionnaireRequest, Service, ServiceAssessmentState, ThirdPartyContact, riskRatingAsString } from 'Models/TPRM';

import { ConfirmSubmitIrqModal, ConfirmSubmitIrqModalProps } from './ConfirmSubmitIrqModal/ConfirmSubmitIrqModal';
import styles from './InherentRiskQuestionnaire.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export enum Modals {
    ClearIRQModal,
    ConfirmSubmitIrqModal,
    None,
}

export interface InherentRiskQuestionnaireProps {
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
    navigator: Navigator;
}

type ActiveInherentRisks = Exclude<RiskRating, RiskRating.INACTIVE>;
type DDQQuestionCount = Record<ActiveInherentRisks, number>;

// IMPORTANT: If you add a new field to the form, make sure you manually disable it when the form is supposed to be disabled. `fieldSet` can't be used to wrap the entire form: when the form is disabled / unable to be submitted, the user still needs to be able to click the overflow menus for files to be able to download them.
export const InherentRiskQuestionnaire = (props: InherentRiskQuestionnaireProps) => {
    const params = useParams<keyof UrlParams>() as UrlParams;

    const [displayedModal, setDisplayedModal] = useState<Modals>();
    const [toastMessage, setToastMessage] = useState<{ message: string; type: 'success' | 'failure' }>();

    const [requestState, setRequestState] = useState<'idle' | 'saving' | 'submitting'>('idle');

    const [selectedRiskRating, setSelectedRiskRating] = useState<RiskRating>();
    const [serviceResponse, setServiceResponse] = useState<Service>();

    const [thirdPartyServiceTitle, setThirdPartyServiceTitle] = useState<string>();

    const [questionnaire, setQuestionnaire] = useState<InherentRiskQuestionnaireResponse>();
    const [fileInfoBySection, setFileInfoBySection] = useState<{ newFiles: File[]; existingFilesToDelete: string[] }[]>();

    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();

    const [dueDiligenceQuestionCount, setDueDiligenceQuestionCount] = useState<DDQQuestionCount>();

    const formDisabled = requestState !== 'idle' || serviceResponse?.assessment_workflow_data.state !== ServiceAssessmentState.EVALUATING_INHERENT_RISK;

    const recommendedRiskRating = useMemo(() => {
        return questionnaire ? calculateRecommendedInherentRiskScore(questionnaire.sections, questionnaire.thresholds) : undefined;
    }, [questionnaire]);

    const getServiceAndQuestionnaire = useCallback(async (): Promise<void> => {
        try {
            const service = (await props.tprmApi.getServiceDetails(params.third_party_id, params.service_id)).data;
            setServiceResponse(service);
            setThirdPartyServiceTitle(`${service.vendor_name} - ${service.name}`);

            const questionnaire = (await props.tprmApi.getInherentRiskQuestionnaire(params.third_party_id, params.service_id)).data!;
            setQuestionnaire(questionnaire);
            setFileInfoBySection(questionnaire.sections.map(() => ({ newFiles: [], existingFilesToDelete: [] })));
            setSelectedRiskRating(questionnaire.inherent_risk_score);

            const dueDiligenceQuestionnaireConfiguration = (await props.tprmApi.getQuestionnaireConfiguration()).data;

            const questionCountMap = { [RiskRating.LOW]: 0, [RiskRating.LOW_MODERATE]: 0, [RiskRating.MODERATE]: 0, [RiskRating.MODERATE_HIGH]: 0, [RiskRating.HIGH]: 0 };

            const questionCountByRiskRating = dueDiligenceQuestionnaireConfiguration.control_frameworks
                .flatMap((framework) => framework.control_groups.flatMap((controlGroup) => controlGroup.controls.flatMap((control) => control.questions.flatMap((question) => question.mapped_risk_ratings))))
                .reduce((acc, curr) => {
                    if (curr !== RiskRating.INACTIVE) {
                        acc[curr]++;
                    }
                    return acc;
                }, questionCountMap);

            setDueDiligenceQuestionCount(questionCountByRiskRating);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                console.error('Error: ', error);
            }
        }
    }, [params.third_party_id, params.service_id, props.tprmApi]);

    useEffect(() => {
        getServiceAndQuestionnaire();
    }, [getServiceAndQuestionnaire]);

    if (tprmAccessDenied) {
        return (
            <PageBackground color="white">
                <PageContent>
                    <div className={styles.zeroStateContainer}>
                        <Text>{UNAUTHORIZED_MESSAGE}</Text>
                    </div>
                </PageContent>
            </PageBackground>
        );
    }

    if (!(questionnaire && fileInfoBySection && serviceResponse && thirdPartyServiceTitle && dueDiligenceQuestionCount)) {
        return <Placeholder />;
    }

    const handleSubmitClicked = () => {
        if (!selectedRiskRating) {
            setToastMessage({ type: 'failure', message: 'Select an inherent risk rating before submitting the inherent risk questionnaire.' });
            return;
        }

        const allQuestionsAnswered = questionnaire.sections.every((section) => {
            return !section.is_applicable || section.questions.every((question) => question.selected_answer_index !== undefined);
        });

        if (!allQuestionsAnswered) {
            setToastMessage({ type: 'failure', message: 'All questions must be answered before submitting the inherent risk questionnaire.' });
            return;
        }

        setDisplayedModal(Modals.ConfirmSubmitIrqModal);
    };

    /**
     * Set the inherent risk rating for the Vendor Service.
     * @param submit Indicates whether the IRQ should be saved as in-progress or submitted as complete.
     * @param vendorContacts A list of Vendor Service contacts to notify about the Due Diligence Questionnaire (DDQ).
     */
    const saveQuestionnaire = async (submit: boolean, vendorContacts: ThirdPartyContact[]): Promise<void> => {
        setRequestState(submit ? 'submitting' : 'saving');
        setToastMessage(undefined);

        try {
            // Validate each file before uploading.
            for (const file of fileInfoBySection.flatMap((section) => section.newFiles)) {
                validateFileForUpload(file);
            }

            const pendingDocumentUploadsBySection: {
                signedUpload: SignedUploadResponse;
                file: File;
            }[][] = [];

            try {
                for (const [index, fileInfo] of fileInfoBySection.entries()) {
                    if (!questionnaire.sections[index].is_applicable) {
                        pendingDocumentUploadsBySection.push([]);
                        continue;
                    }

                    const pendingDocumentUploads: {
                        signedUpload: SignedUploadResponse;
                        file: File;
                    }[] = [];

                    for (const file of fileInfo.newFiles) {
                        const signedUploadResponse = (await props.documentApi.getSignedUpload(file.name)).data;
                        pendingDocumentUploads.push({ signedUpload: signedUploadResponse, file: file });
                    }

                    pendingDocumentUploadsBySection.push(pendingDocumentUploads);
                }
            } catch (error) {
                throw new Error('Something went wrong when trying to upload files.');
            }

            // Submit the request to our backend API.
            const request: SaveOrSubmitInherentRiskQuestionnaireRequest = {
                submit: submit,
                inherent_risk_score: selectedRiskRating,
                section_selections: questionnaire.sections.map((section, sectionIndex) => {
                    // If a section does not apply, no responses are included, as the backend will ignore them (i.e., not persist them) even if included.
                    return {
                        is_applicable: section.is_applicable,
                        answers: section.is_applicable ? section.questions.map((question) => question.selected_answer_index ?? null) : undefined,
                        text: section.is_applicable ? section.text : undefined,
                        file_updates: section.is_applicable
                            ? {
                                  existing_files_to_delete: fileInfoBySection[sectionIndex].existingFilesToDelete,
                                  new_files: pendingDocumentUploadsBySection[sectionIndex].map((pendingUpload) => ({
                                      filename: pendingUpload.file.name,
                                      file_id: pendingUpload.signedUpload.file_id,
                                  })),
                              }
                            : {},
                    };
                }),
                third_party_contacts: vendorContacts,
            };

            await props.tprmApi.saveInherentRiskQuestionnaire(params.third_party_id, params.service_id, request);

            const failedFileNames: string[] = [];
            // Upload each document to S3. If any error occurs, remember it for below, but continue trying to upload other documents.
            for (const pendingUpload of pendingDocumentUploadsBySection.flat()) {
                try {
                    await props.documentApi.uploadDocument(pendingUpload.signedUpload.url, pendingUpload.signedUpload.fields, pendingUpload.file);
                } catch (error) {
                    failedFileNames.push(pendingUpload.file.name);
                }
            }

            if (failedFileNames.length > 0) {
                throw new Error(`Something went wrong when trying to upload: ${failedFileNames.join(', ')}.`);
            }

            await getServiceAndQuestionnaire();
            setToastMessage({ message: `Inherent risk questionnaire ${submit ? 'submitted' : 'saved'}.`, type: 'success' });

            // Reset "pending" files (files to upload and files to delete). Uploaded files (`existingFiles`) have already been updated with the data re-fetched by `getServiceDetails`.
            setFileInfoBySection(questionnaire.sections.map(() => ({ newFiles: [], existingFilesToDelete: [] })));
        } catch (error) {
            setToastMessage({ message: error.message, type: 'failure' });
        } finally {
            setRequestState('idle');
        }
    };

    const handleChangeSelectedRiskRating = (value: ChangeEventType): void => {
        setSelectedRiskRating(value as RiskRating);
    };

    const onConfirmBeginRiskWorkflow = (vendorContacts: ThirdPartyContact[]) => {
        setDisplayedModal(Modals.None);
        saveQuestionnaire(true, vendorContacts);
    };

    const confirmSubmitIrqModalProps: ConfirmSubmitIrqModalProps = {
        inherentRiskRating: selectedRiskRating!,
        hideModal: () => setDisplayedModal(Modals.None),
        service: serviceResponse,
        onConfirm: onConfirmBeginRiskWorkflow,
        hasValidDDQConfiguration: selectedRiskRating !== RiskRating.INACTIVE && dueDiligenceQuestionCount[selectedRiskRating!] > 0 ? true : false,
    };

    const alertForDisabledIrq = (() => {
        switch (serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT} />
                        </div>
                    </Alert>
                );
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE} />
                        </div>
                    </Alert>
                );
            default:
                return undefined;
        }
    })();

    return (
        <>
            {toastMessage && <TextToast variant={toastMessage.type} clearToast={() => setToastMessage(undefined)} text={toastMessage.message} />}
            {displayedModal === Modals.ConfirmSubmitIrqModal && <ConfirmSubmitIrqModal {...confirmSubmitIrqModalProps} />}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.vendor_id}/${SERVICES}/${serviceResponse.id}/${DASHBOARDS}`}>{thirdPartyServiceTitle}</BreadcrumbLink>
                        <BreadcrumbText>Inherent Risk Questionnaire</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Inherent Risk Questionnaire"
                headerDescription="The recommended inherent risk rating will be updated as questions are answered. The inherent risk rating drives prioritization of controls to reduce organizational risk. Questions are based on the inherent risk questionnaire configuration at the time when this third-party service's assessment workflow was started, and therefore may differ from the current inherent risk questionnaire configuration."
                body={[
                    {
                        content: (
                            <Form noValidate>
                                <div className={styles.sections}>
                                    {alertForDisabledIrq}
                                    {questionnaire.sections.map((section, sectionIndex) => {
                                        return (
                                            <SectionDisplay
                                                key={section.name}
                                                disabled={formDisabled}
                                                documentApi={props.documentApi}
                                                section={section}
                                                sectionIndex={sectionIndex}
                                                onUpdateSection={(updatedSection) => {
                                                    const newSections = [...questionnaire.sections];
                                                    newSections[sectionIndex] = updatedSection;
                                                    setQuestionnaire({ ...questionnaire, sections: newSections });
                                                }}
                                                newFiles={fileInfoBySection[sectionIndex].newFiles}
                                                onAddNewFiles={(files) => {
                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].newFiles = addFiles(newFileInfo[sectionIndex].newFiles, files);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                                onRemoveNewFile={(file) => {
                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].newFiles = removeFiles(newFileInfo[sectionIndex].newFiles, file);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                                deleteExistingFile={(file) => {
                                                    const newSections = [...questionnaire.sections];
                                                    newSections[sectionIndex] = { ...section, files: section.files.filter((f) => f.file_id !== file.file_id) };
                                                    setQuestionnaire({ ...questionnaire, sections: newSections });

                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].existingFilesToDelete.push(file.file_id);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                                <fieldset disabled={formDisabled}>
                                    <div className={styles.sectionHeader}>
                                        <div className={styles.sectionNameAndUserSelectContainer}>
                                            <Text variant="Header2" noStyles>
                                                Overall
                                            </Text>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className={styles.questions}>
                                        <FormFieldSelect formFieldId="selectedRiskRating" formFieldLabel="Inherent Risk Rating" options={RiskRatingSelectOptions} selectedOption={selectedRiskRating} handleChange={handleChangeSelectedRiskRating} isRequiredField badge={recommendedRiskRating ? `Recommendation: ${riskRatingAsString(recommendedRiskRating)}` : undefined} />
                                    </div>
                                    <div className={styles.buttonsContainer}>
                                        <Button fontAwesomeImage={faSave} variant="secondary" isLoading={requestState === 'saving'} loadingText="Saving..." onClick={() => saveQuestionnaire(false, [])}>
                                            SAVE
                                        </Button>
                                        <Button fontAwesomeImage={faChevronCircleRight} variant="primary" isLoading={requestState === 'submitting'} loadingText="Submitting..." onClick={handleSubmitClicked}>
                                            SUBMIT
                                        </Button>
                                    </div>
                                </fieldset>
                            </Form>
                        ),
                    },
                ]}
            />
        </>
    );
};

interface SectionDisplayProps {
    documentApi: DocumentApi;
    section: InherentRiskQuestionnaireSection;
    sectionIndex: number;
    disabled: boolean;
    onUpdateSection: (updatedSection: InherentRiskQuestionnaireSection) => void;
    newFiles: File[];
    deleteExistingFile: (file: UploadedFile) => void;
    onAddNewFiles: (files: File[]) => void;
    onRemoveNewFile: (file: File) => void;
}

const SectionDisplay = (props: SectionDisplayProps) => {
    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Files',
        onAddFiles: props.onAddNewFiles,
        onRemoveFile: props.onRemoveNewFile,
        files: props.newFiles,
        disabled: props.disabled,
    };

    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader}>
                <div className={styles.sectionNameAndCheckboxContainer}>
                    <Text variant="Header2" noStyles>
                        {props.section.name}
                    </Text>
                    <div className={styles.checkboxContainer}>
                        <Checkbox
                            disabled={props.disabled}
                            checked={props.section.is_applicable}
                            color="default"
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, is_applicable: event.currentTarget.checked });
                            }}
                        />
                        <Text noStyles>Relevant to this service</Text>
                        <FormFieldTooltip text="Indicates whether the questions in this section are applicable to the third-party service. If unchecked, responses in this section will not be required for this service." />
                    </div>
                </div>
                <hr />
            </div>
            {props.section.is_applicable && (
                <>
                    {props.section.questions.map((question, questionIndex) => {
                        const questionDisplayProps: QuestionDisplayProps = {
                            question,
                            section: props.section,
                            questionIndex,
                            onUpdateQuestion: (updatedQuestion) => {
                                props.onUpdateSection({ ...props.section, questions: props.section.questions.map((q, i) => (i === questionIndex ? updatedQuestion : q)) });
                            },
                            disabled: props.disabled,
                        };
                        return <QuestionDisplay key={question.text} {...questionDisplayProps} />;
                    })}
                    <div className={styles.textEvidenceContainer}>
                        <FormFieldTextArea
                            formFieldId={`${props.section.name} additional information`}
                            formFieldLabel="Additional Information"
                            handleChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, text: event.currentTarget.value });
                            }}
                            value={props.section.text}
                            disabled={props.disabled}
                        />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FileDragAndDrop {...fileDragAndDropProps} />
                        {props.section.files.length > 0 && (
                            <>
                                <VisualLabel>Existing Files</VisualLabel>
                                <Table>
                                    <TableBody>
                                        {[...props.section.files]
                                            .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                            .map((file) => {
                                                const overflowItems: OverflowItem[] = [
                                                    {
                                                        text: 'Download file',
                                                        onClickAction: () => downloadDocument(props.documentApi, file),
                                                        icon: faDownload,
                                                    },
                                                ];

                                                if (!props.disabled) {
                                                    overflowItems.push({
                                                        text: 'Delete file',
                                                        onClickAction: () => props.deleteExistingFile(file),
                                                        icon: faTrash,
                                                    });
                                                }

                                                return (
                                                    <TableRow key={file.file_id}>
                                                        <TableCell>
                                                            <Text noStyles>{file.filename}</Text>
                                                        </TableCell>
                                                        <TableOverflowCell>
                                                            <div className={styles.overflowContainer}>
                                                                <OverflowMenu overflowItems={overflowItems} accessibilityTitle={file.filename} />
                                                            </div>
                                                        </TableOverflowCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

interface QuestionDisplayProps {
    question: InherentRiskQuestionnaireQuestion;
    section: InherentRiskQuestionnaireSection;
    questionIndex: number;
    onUpdateQuestion: (updatedQuestion: InherentRiskQuestionnaireQuestion) => void;
    disabled: boolean;
}

const QuestionDisplay = (props: QuestionDisplayProps) => {
    return (
        <div className={styles.question}>
            <Text variant="Text2" noStyles>{`${props.questionIndex + 1}. ${props.question.text}`}</Text>
            <RadioButtonGroup
                formFieldId={`${props.section.name}${props.question.text}`}
                handleChange={(event) => props.onUpdateQuestion({ ...props.question, selected_answer_index: parseInt(event.target.value) })}
                options={props.question.answers.map((answer, index) => ({
                    label: answer.text,
                    value: index,
                }))}
                selectedOption={props.question.selected_answer_index}
                disabled={props.disabled}
            />
        </div>
    );
};

export const calculateRecommendedInherentRiskScore = (sections: InherentRiskQuestionnaireSection[], thresholds: RiskRatingPointThresholds): RiskRating | undefined => {
    let totalPoints = 0;
    for (const section of sections) {
        if (!section.is_applicable) {
            continue;
        }

        for (const question of section.questions) {
            if (question.selected_answer_index === undefined) {
                return undefined;
            }

            totalPoints += question.answers[question.selected_answer_index].points;
        }
    }

    if (totalPoints <= thresholds.low) {
        return RiskRating.LOW;
    } else if (totalPoints <= thresholds.low_moderate) {
        return RiskRating.LOW_MODERATE;
    } else if (totalPoints <= thresholds.moderate) {
        return RiskRating.MODERATE;
    } else if (totalPoints <= thresholds.moderate_high) {
        return RiskRating.MODERATE_HIGH;
    } else {
        return RiskRating.HIGH;
    }
};

// TODO TPRM TESTS
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faChevronCircleRight, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
// import '@testing-library/jest-dom';
// import { render, screen, waitFor } from '@testing-library/react';
// import { userEvent } from '@testing-library/user-event';
// import { mock, mockReset } from 'jest-mock-extended';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import selectEvent from 'react-select-event';
// import { beforeEach, describe, expect, it } from 'vitest';

// import { DocumentApi } from 'Api/Document/DocumentApi';
// import { TPRMApi } from 'Api/TPRM/TPRMApi';
// import { CachedDataProvider } from 'Components/Context/CachedDataContext';
// import { Role } from 'Components/Context/RBACContext';
// import { API_FORBIDDEN_RESPONSE_MESSAGE, UNAUTHORIZED_MESSAGE } from 'Config/Errors';
// import { DASHBOARDS, IRQ, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
// import { Navigator } from 'Helpers/Navigator';
// import { FileState } from 'Models/Files';
// import { CustomerDetailsAnnualVolume, DataClassification, DataStorageLocation, RiskRating, Service, ServiceAssessmentState, SetInherentRiskEvidenceRequest } from 'Models/TPRM';
// import { Status } from 'Models/User';
// import { getDefaultClientDetailsApiMock } from 'Tests/data/Clients';
// import { getTestDocumentUploadQuestionConfiguration, getTestFreeFormQuestionConfiguration, getTestMultipleSelectQuestionConfiguration, getTestNistFrameworkConfiguration, getTestNistIdAm1ControlConfiguration, getTestNistIdAm2ControlConfiguration, getTestNistIdAm3ControlConfiguration, getTestNistIdAmControlGroupConfiguration, getTestNistPrIp1ControlConfiguration, getTestNistPrIpControlGroupConfiguration, getTestSingleSelectQuestionConfiguration, getTestThirdPartyService1, getTestThirdPartyServiceWithParent } from 'Tests/data/TPRM';
// import { getDefaultMockUsersApi } from 'Tests/data/Users';
// import { removeExistingFile, selectNewFile, unselectNewFile } from 'Tests/utils/InputUtils';
// import { getByBrokenUpText } from 'Tests/utils/TextMatcherUtils';

// import { InherentRiskQuestionnaire, InherentRiskQuestionnaireProps } from './InherentRiskQuestionnaire';
// import { ManageTPRMServices, ManageTPRMServicesProps } from '../ManageTPRMServices/ManageTPRMServices';

// describe(
//     'InherentRiskQuestionnaire component',
//     (): void => {
//         library.add(faChevronCircleRight, faSyncAlt);

//         const clientDetailsApiMock = getDefaultClientDetailsApiMock();
//         const documentApiMock = mock<DocumentApi>();
//         const navigatorMock = mock<Navigator>();
//         const tprmApiMock = mock<TPRMApi>();
//         const usersApiMock = getDefaultMockUsersApi();

//         const inherentRiskQuestionnaireProps: InherentRiskQuestionnaireProps = {
//             tprmApi: tprmApiMock,
//             documentApi: documentApiMock,
//             navigator: navigatorMock,
//         };

//         const baseService: Service = getTestThirdPartyService1();
//         const baseServiceWithParent: Service = getTestThirdPartyServiceWithParent();

//         const testFreeFormQuestionConfiguration = getTestFreeFormQuestionConfiguration();
//         const testSingleSelectQuestionConfiguration = getTestSingleSelectQuestionConfiguration();
//         const testMultipleSelectQuestionConfiguration = getTestMultipleSelectQuestionConfiguration();
//         const testDocumentUploadQuestionConfiguration = getTestDocumentUploadQuestionConfiguration();

//         const testNistIdAm1ControlConfiguration = getTestNistIdAm1ControlConfiguration();
//         testNistIdAm1ControlConfiguration.questions = [testFreeFormQuestionConfiguration];

//         const testNistIdAm2ControlConfiguration = getTestNistIdAm2ControlConfiguration();
//         testNistIdAm2ControlConfiguration.questions = [testSingleSelectQuestionConfiguration];

//         const testNistIdAm3ControlConfiguration = getTestNistIdAm3ControlConfiguration();
//         testNistIdAm3ControlConfiguration.questions = [testMultipleSelectQuestionConfiguration];

//         const testNistPrIp1ControlConfiguration = getTestNistPrIp1ControlConfiguration();
//         testNistPrIp1ControlConfiguration.questions = [testDocumentUploadQuestionConfiguration];

//         const testNistIdAmControlGroupConfiguration = getTestNistIdAmControlGroupConfiguration();
//         testNistIdAmControlGroupConfiguration.controls = [testNistIdAm1ControlConfiguration, testNistIdAm2ControlConfiguration, testNistIdAm3ControlConfiguration];

//         const testNistPrIpControlGroupConfiguration = getTestNistPrIpControlGroupConfiguration();
//         testNistPrIpControlGroupConfiguration.controls = [testNistPrIp1ControlConfiguration];

//         const testNistFrameworkConfiguration = getTestNistFrameworkConfiguration();
//         testNistFrameworkConfiguration.control_groups = [testNistIdAmControlGroupConfiguration, testNistPrIpControlGroupConfiguration];

//         beforeEach(() => {
//             mockReset(documentApiMock);
//             mockReset(tprmApiMock);
//             mockReset(navigatorMock);

//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [];

//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: baseServiceWithParent,
//                 })
//             );
//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [],
//                     },
//                 })
//             );

//             tprmApiMock.getQuestionnaireConfiguration.mockResolvedValue({ data: { control_frameworks: [testNistFrameworkConfiguration] } });

//             documentApiMock.getSignedDownload.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         url: 'http://localhost',
//                     },
//                 })
//             );

//             documentApiMock.getSignedUpload.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         file_id: 'id',
//                         url: 'http://testurl',
//                         fields: {
//                             string: 'value',
//                         },
//                     },
//                 })
//             );
//         });

//         const url = `/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${IRQ}`;

//         it('shows an unauthorized error message if the API forbids access to TPRM', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockRejectedValueOnce(new Error(API_FORBIDDEN_RESPONSE_MESSAGE));

//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => screen.getByText(UNAUTHORIZED_MESSAGE));
//         });

//         it('should render the IRQ', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));
//             expect(tprmApiMock.getServiceDetails).toBeCalledWith(':third_party_id', ':service_id');
//             screen.getByText('This form is used to select an inherent risk rating for the service. The inherent risk rating drives prioritization of controls to reduce organizational risk.');

//             //Question 1
//             screen.getByText('1. What is the classification of the most sensitive data the third party will access for the service?');
//             screen.getByText('Restricted');
//             screen.getByText('Confidential');
//             screen.getByText('Internal');
//             screen.getByText('Public');

//             // Question 2
//             screen.getByText('2. What is the annual volume of the most sensitive data the third party will access or process for the service?');
//             screen.getByText('< 1,000 records');
//             screen.getByText('1,000 - 100,000 records');
//             screen.getByText('> 100,000 records');

//             //Question 3
//             screen.getByText('3. Where is the most sensitive data stored that the third party will access for the service?');
//             screen.getByText('On-Premises');
//             screen.getByText('On-Premises Remote Access');
//             screen.getByText('Onshore');
//             screen.getByText('Onshore Cloud-Hosted');
//             screen.getByText('Offshore');

//             // All other fields
//             screen.getByText('Additional Information');

//             screen.getByText('Files');

//             screen.getByText('Inherent Risk Rating');
//             screen.getByText('Select...');
//             screen.getByText('SAVE');
//         });

//         it('should call setIRQ', async (): Promise<void> => {
//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [RiskRating.HIGH];
//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         document_evidence_file_state: FileState.PROCESSING,
//                         document_evidence_filename: 'Test file',
//                         document_evidence_id: 'id',
//                         files: [],
//                     },
//                 })
//             );

//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             const radioButtons = screen.getAllByRole('radio');

//             await userEvent.click(radioButtons[0]); //Restricted
//             await userEvent.click(radioButtons[4]); //< 1,000 records
//             await userEvent.click(radioButtons[7]); //On-Premises

//             const inputEl = screen.getByLabelText('Files');
//             const file = new File(['(⌐□_□)'], 'chucknorris.png', {
//                 type: 'image/png',
//             });

//             await userEvent.upload(inputEl, file);

//             await waitFor(() => screen.getByText('chucknorris.png'));

//             await userEvent.type(screen.getByLabelText('Additional Information'), 'IRQ text');

//             const riskBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(riskBox);
//             await selectEvent.select(riskBox, 'High');

//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN RISK WORKFLOW' }));

//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN' }));

//             await waitFor(() => expect(navigatorMock.navigateTo).toHaveBeenCalledTimes(1));
//             expect(navigatorMock.navigateTo).toHaveBeenLastCalledWith(`/${TPRM}/${THIRD_PARTIES}/${baseServiceWithParent.vendor_id}/${SERVICES}/${baseServiceWithParent.id}/${DASHBOARDS}`);

//             const request: SetInherentRiskEvidenceRequest = {
//                 customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                 data_classification: DataClassification.PUBLIC,
//                 data_storage_location: DataStorageLocation.ON_PREMISES,
//                 inherent_risk_score: RiskRating.HIGH,
//                 file_updates: {
//                     existing_files_to_delete: [],
//                     new_files: [{ filename: 'chucknorris.png', file_id: 'id' }],
//                 },
//                 text: 'IRQ text',
//                 third_party_contacts: [{ name: baseService.vendor_contacts[0].name!, email_address: baseService.vendor_contacts[0].email_address!, phone_number: baseService.vendor_contacts[0].phone_number! }],
//             };
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', request);
//         });

//         it('should render text within a textarea', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.LOW,
//                     },
//                 })
//             );
//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         text: 'IRQ text',
//                         files: [],
//                     },
//                 })
//             );

//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             screen.getByLabelText('Additional Information');

//             await waitFor(() => screen.getByText('IRQ text'));

//             expect(screen.queryByRole('img', { name: 'File Processing' })).toBeNull();
//         });

//         it('should display a recommended risk score', async (): Promise<void> => {
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));
//             expect(screen.queryByText('Recommendation:')).toBeNull();

//             const radioButtons = screen.getAllByRole('radio');

//             await userEvent.click(radioButtons[0]); //Public
//             await userEvent.click(radioButtons[4]); //< 1,000 records
//             await userEvent.click(radioButtons[7]); //On-Premises

//             await waitFor(() => screen.getByText('Recommendation: Low'));
//         });

//         it('should call the getIRQ details and display the returned values', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toBeCalled();

//             const radioButtons = screen.getAllByRole('radio');

//             expect(radioButtons[6]).toBeChecked();
//             expect(radioButtons[3]).toBeChecked();
//             expect(radioButtons[9]).toBeChecked();

//             screen.getByText('Moderate');
//         });

//         it('should call modify IRQ with updated text', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.HIGH,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire
//                 .mockReturnValueOnce(
//                     Promise.resolve({
//                         data: {
//                             service_id: baseServiceWithParent.id,
//                             third_party_id: baseServiceWithParent.vendor_id,
//                             customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                             data_classification: DataClassification.RESTRICTED,
//                             data_storage_location: DataStorageLocation.ONSHORE,
//                             files: [],
//                         },
//                     })
//                 )
//                 .mockReturnValueOnce(
//                     Promise.resolve({
//                         data: {
//                             service_id: baseServiceWithParent.id,
//                             third_party_id: baseServiceWithParent.vendor_id,
//                             customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                             data_classification: DataClassification.RESTRICTED,
//                             data_storage_location: DataStorageLocation.ONSHORE,
//                             files: [],
//                         },
//                     })
//                 );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toBeCalled();

//             const radioButtons = screen.getAllByRole('radio');

//             await userEvent.click(radioButtons[0]); //Restricted
//             await userEvent.click(radioButtons[4]); //< 1,000 records
//             await userEvent.click(radioButtons[7]); //On-Premises

//             await userEvent.type(screen.getByLabelText('Additional Information'), 'IRQ text');
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));

//             await waitFor(() => screen.getByText('Inherent risk questionnaire updated.'));

//             const request: SetInherentRiskEvidenceRequest = {
//                 customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                 data_classification: DataClassification.PUBLIC,
//                 data_storage_location: DataStorageLocation.ON_PREMISES,
//                 inherent_risk_score: RiskRating.HIGH,
//                 text: 'IRQ text',
//                 file_updates: {
//                     existing_files_to_delete: [],
//                     new_files: [],
//                 },
//                 third_party_contacts: [], // The confirmation modal where Third-Party Service contacts are selected only appears if the IRQ rating changes.
//             };
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', request);
//         });

//         it('should download additional text', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toBeCalled();
//         });

//         it('should attempt to download a document', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toBeCalled();

//             await userEvent.click(screen.getByText('A document for download Menu'));
//             await userEvent.click(screen.getByText('Download file'));

//             expect(documentApiMock.getSignedDownload).toBeCalledWith('document-evidence-id');
//         });

//         it('should attempt to upload a document', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             // Page load.
//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [],
//                     },
//                 })
//             );

//             // After hitting the "SAVE" button.
//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [{ file_id: '1234', filename: 'chucknorris.png', file_state: FileState.PROCESSING }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();

//             const errorText = 'error';
//             const error = Error(errorText);
//             tprmApiMock.setIRQ.mockRejectedValueOnce(error);

//             const inputEl = screen.getByLabelText('Files');
//             const file = new File(['(⌐□_□)'], 'chucknorris.png', {
//                 type: 'image/png',
//             });

//             await userEvent.upload(inputEl, file);

//             screen.getByText('chucknorris.png');

//             // display error message
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             expect(screen.queryByText('Successfully updated')).toBeNull();
//             await waitFor(() => screen.getByText(errorText));

//             // display success message
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             await waitFor(() => screen.getByText('Inherent risk questionnaire updated.'));
//             expect(screen.queryByText(errorText)).toBeNull();
//             expect(tprmApiMock.setIRQ).toHaveBeenCalledTimes(2);
//             expect(documentApiMock.getSignedUpload).toBeCalledWith('chucknorris.png', undefined);
//             expect(documentApiMock.uploadDocument).toHaveBeenCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toHaveBeenCalledTimes(2);
//             screen.getAllByText('chucknorris.png');
//             screen.getByRole('img', { name: 'File Processing' });
//         });

//         it('allows the user to select/unselect new files and to remove existing files', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [
//                             { file_id: 'file ID 1', filename: 'file1.txt', file_state: FileState.PASSED },
//                             { file_id: 'file ID 2', filename: 'file2.txt', file_state: FileState.PASSED },
//                         ],
//                     },
//                 })
//             );

//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );
//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             await selectNewFile('file3.txt');

//             await selectNewFile('file4.txt');
//             await unselectNewFile('file4.txt');

//             await removeExistingFile('file1.txt Menu');

//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             await waitFor(() => screen.getByText('Inherent risk questionnaire updated.'));

//             const expectedRequest: SetInherentRiskEvidenceRequest = {
//                 inherent_risk_score: RiskRating.MODERATE,
//                 file_updates: {
//                     existing_files_to_delete: ['file ID 1'],
//                     new_files: [{ filename: 'file3.txt', file_id: 'id' }],
//                 },
//                 third_party_contacts: [],
//             };
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', expectedRequest);
//         });

//         it('should throw an error from update call', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         inherent_risk_score: RiskRating.MODERATE,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         files: [],
//                     },
//                 })
//             );

//             tprmApiMock.setIRQ.mockRejectedValue(Error('The error message.'));
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             expect(tprmApiMock.getServiceDetails).toBeCalled();
//             expect(tprmApiMock.getInherentRiskQuestionnaire).toBeCalled();

//             const radioButtons = screen.getAllByRole('radio');

//             await userEvent.click(radioButtons[0]);

//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));

//             expect(tprmApiMock.setIRQ).toBeCalled();

//             await waitFor(() => screen.getByText('The error message.'));
//             expect(screen.queryByText('Inherent Risk Questionnaire updated.')).toBeNull();
//         });

//         it('should create and display an error from the setIRQ', async (): Promise<void> => {
//             tprmApiMock.setIRQ.mockRejectedValue(Error('The error message.'));
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));

//             const radioButtons = screen.getAllByRole('radio');

//             await userEvent.click(radioButtons[0]); //Restricted
//             await userEvent.click(radioButtons[4]); //< 1,000 records
//             await userEvent.click(radioButtons[7]); //On-Premises

//             const riskBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(riskBox);
//             await selectEvent.select(riskBox, 'High');
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN RISK WORKFLOW' }));
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN' }));

//             await waitFor(() => screen.getByText('The error message.'));
//         });

//         it('should show the delete modal.', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         inherent_risk_score: RiskRating.HIGH,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));
//             await userEvent.click(screen.getByRole('button', { name: 'CLEAR QUESTIONNAIRE' }));

//             await waitFor(() => screen.getByText('Are you sure you want to clear the inherent risk questionnaire?'));

//             await userEvent.click(screen.getByText('CLOSE'));
//             await waitFor(() => expect(screen.queryByText('Are you sure you want to clear the inherent risk questionnaire?', { exact: true })).toBeNull());
//         });

//         it('should clear the irq selections after a successful clear call', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails
//                 .mockReturnValue(
//                     Promise.resolve({
//                         data: {
//                             ...baseServiceWithParent,
//                             assessment_workflow_data: {
//                                 state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                                 final_review_documents: [],
//                             },
//                             inherent_risk_score: RiskRating.INACTIVE,
//                         },
//                     })
//                 )
//                 .mockReturnValueOnce(
//                     Promise.resolve({
//                         data: {
//                             ...baseServiceWithParent,
//                             assessment_workflow_data: {
//                                 state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                                 final_review_documents: [],
//                             },
//                             inherent_risk_score: RiskRating.MODERATE,
//                         },
//                     })
//                 );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                         text: 'IRQ text',
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             //Ensure radio boxes are selected, text is in the additional comments box, and a file is ready to download after loading the page from a mocked existing IRQ
//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));
//             expect(tprmApiMock.getServiceDetails).toBeCalledTimes(1);
//             let radioButtons = screen.getAllByRole('radio');
//             expect(radioButtons[6]).toBeChecked();
//             expect(radioButtons[3]).toBeChecked();
//             expect(radioButtons[9]).toBeChecked();
//             await waitFor(() => screen.getByText('IRQ text'));
//             await waitFor(() => screen.getByText('A document for download'));
//             screen.getByText('Moderate');

//             // Open, clear and close the modal
//             await userEvent.click(screen.getByRole('button', { name: 'CLEAR QUESTIONNAIRE' }));
//             await waitFor(() => screen.getByText('Are you sure you want to clear the inherent risk questionnaire?'));
//             await userEvent.click(screen.getByRole('button', { name: 'CLEAR' }));
//             await waitFor(() => screen.getByText('Inherent risk questionnaire cleared.', { exact: true }));
//             await userEvent.click(screen.getByText('CLOSE'));
//             await waitFor(() => expect(screen.queryByText('Are you sure you want to clear the inherent risk questionnaire?', { exact: true })).toBeNull());
//             expect(tprmApiMock.deleteIRQ).toBeCalledWith(':third_party_id', ':service_id');
//             expect(tprmApiMock.getServiceDetails).toBeCalledWith(':third_party_id', ':service_id');
//             expect(tprmApiMock.getServiceDetails).toBeCalledTimes(2);

//             // Ensure everything on the page is cleared
//             radioButtons = screen.getAllByRole('radio');

//             expect(radioButtons[6]).not.toBeChecked();
//             expect(radioButtons[3]).not.toBeChecked();
//             expect(radioButtons[9]).not.toBeChecked();
//             await waitFor(() => expect(screen.queryByText('IRQ text', { exact: true })).toBeNull());
//             await waitFor(() => expect(screen.queryByText('A document for download', { exact: true })).toBeNull());
//             await waitFor(() => expect(screen.queryByText('Moderate', { exact: true })).toBeNull());
//         });

//         it('should navigate back to the TPRM page through the breadcrumb', async (): Promise<void> => {
//             const tprmApiMock = mock<TPRMApi>();

//             const manageTPRMServicesProps: ManageTPRMServicesProps = {
//                 tprmApi: tprmApiMock,
//             };

//             tprmApiMock.getThirdParties.mockReturnValue(
//                 Promise.resolve({
//                     data: [],
//                 })
//             );

//             usersApiMock.getUsers.mockReturnValue(
//                 Promise.resolve({
//                     data: [
//                         {
//                             client_id: '12345678-90ab-cdef-1234-567890abcdef',
//                             cognito_subject: 'subject',
//                             email_address: 'test@test.com',
//                             first_name: 'Ryan',
//                             last_name: 'Schalk',
//                             department: 'IT',
//                             roles: [Role.ADMIN],
//                             status: Status.ACTIVE,
//                             mfa_required: true,
//                         },
//                     ],
//                 })
//             );

//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={`/${TPRM}/${SERVICES}`} element={<ManageTPRMServices {...manageTPRMServicesProps} />} />
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => expect(screen.getAllByText('Inherent Risk Questionnaire')).toHaveLength(2));
//             await userEvent.click(screen.getByText('Third-Party Risk Management'));
//             await waitFor(() => expect(window.location.pathname).toBe(`/${TPRM}/${SERVICES}`));
//         });

//         it('should handle submitting the existing IRQ when the risk workflow is not started', async () => {
//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [RiskRating.LOW];

//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_state: ServiceAssessmentState.NOT_STARTED,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );
//             await waitFor(() => screen.getByText('The Risk Workflow process has not been started. To begin the process, complete the Inherent Risk Questionnaire.'));
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN RISK WORKFLOW' }));

//             await waitFor(() => screen.getByText('Begin Risk Workflow Process'));
//             screen.getByText(baseServiceWithParent.vendor_contacts[0].email_address!);
//             screen.getByText('Beginning the risk workflow process will make the third-party questionnaire, control assessment, and final report available for changes.');
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN' }));
//             await waitFor(() => expect(navigatorMock.navigateTo).toHaveBeenCalledTimes(1));
//             expect(navigatorMock.navigateTo).toHaveBeenLastCalledWith(`/${TPRM}/${THIRD_PARTIES}/${baseServiceWithParent.vendor_id}/${SERVICES}/${baseServiceWithParent.id}/${DASHBOARDS}`);
//         });

//         it('should handle changing the inherent risk score when the risk workflow is not started', async () => {
//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [RiskRating.HIGH];
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseServiceWithParent,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_state: ServiceAssessmentState.NOT_STARTED,
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );
//             await waitFor(() => screen.getByText('The Risk Workflow process has not been started. To begin the process, complete the Inherent Risk Questionnaire.'));
//             const selectBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(selectBox);
//             await selectEvent.select(selectBox, 'High');
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN RISK WORKFLOW' }));

//             screen.getByText(baseServiceWithParent.vendor_contacts[0].email_address!);
//             screen.getByText('Beginning the risk workflow process will make the third-party questionnaire, control assessment, and final report available for changes.');
//             await userEvent.click(screen.getByRole('button', { name: 'BEGIN' }));
//             await waitFor(() => expect(navigatorMock.navigateTo).toHaveBeenCalledTimes(1));
//             expect(navigatorMock.navigateTo).toHaveBeenLastCalledWith(`/${TPRM}/${THIRD_PARTIES}/${baseServiceWithParent.vendor_id}/${SERVICES}/${baseServiceWithParent.id}/${DASHBOARDS}`);
//         });

//         it('should handle changing the inherent risk score when the risk workflow is in progress', async () => {
//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [RiskRating.HIGH];
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseService,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseServiceWithParent.id,
//                         third_party_id: baseServiceWithParent.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => screen.getByText(`${baseServiceWithParent.vendor_name} - ${baseServiceWithParent.name}`));
//             const selectBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(selectBox);
//             await selectEvent.select(selectBox, 'High');
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             await waitFor(() => getByBrokenUpText('The third-party questionnaire will be regenerated based on your current configuration for third-party services with High inherent risk. Existing third-party responses and control assessments will be carried forward, except for questions whose configuration has changed.'));
//             screen.getByText(baseServiceWithParent.vendor_contacts[0].email_address!);
//         });

//         it('should not show a confirmation modal when the inherent risk score remains the same for an in-progress workflow', async () => {
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseService,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseService.id,
//                         third_party_id: baseService.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                         data_classification: DataClassification.PUBLIC,
//                         data_storage_location: DataStorageLocation.ON_PREMISES,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => screen.getByText(`${baseService.vendor_name} - ${baseService.name}`));
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             const request: SetInherentRiskEvidenceRequest = {
//                 customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                 data_classification: DataClassification.PUBLIC,
//                 data_storage_location: DataStorageLocation.ON_PREMISES,
//                 inherent_risk_score: RiskRating.LOW,
//                 file_updates: {
//                     existing_files_to_delete: [],
//                     new_files: [],
//                 },
//                 third_party_contacts: [], // The confirmation modal where Third-Party Service contacts are selected only appears if the IRQ rating changes.
//             };
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', request);
//         });

//         it('should provide the user with the option not to notify the third-party service contact when a new questionnaire is generated', async () => {
//             testFreeFormQuestionConfiguration.mapped_risk_ratings = [RiskRating.HIGH];
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseService,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                         vendor_contacts: [
//                             // Leave the existing Third-Party Service contact intact.
//                             {
//                                 name: baseService.vendor_contacts[0].name,
//                                 email_address: baseService.vendor_contacts[0].email_address,
//                                 phone_number: baseService.vendor_contacts[0].phone_number,
//                             },
//                             // Add a couple new Third-Party Service contacts, with varying contact details.
//                             {
//                                 name: 'Bruce Wayne',
//                                 phone_number: '123-456-7890',
//                             },
//                             {
//                                 email_address: 'customer_support@somecompany.com',
//                             },
//                         ],
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseService.id,
//                         third_party_id: baseService.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                         data_classification: DataClassification.PUBLIC,
//                         data_storage_location: DataStorageLocation.ON_PREMISES,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => screen.getByText(`${baseService.vendor_name} - ${baseService.name}`));
//             const riskBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(riskBox);
//             await selectEvent.select(riskBox, 'High');
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             await waitFor(() => getByBrokenUpText('The third-party questionnaire will be regenerated based on your current configuration for third-party services with High inherent risk. Existing third-party responses and control assessments will be carried forward, except for questions whose configuration has changed.'));

//             screen.getByText('Select third-party service contacts to notify via email that the new third-party questionnaire is ready. Only these individuals will be able to register and complete the third-party questionnaire. This will not reset credentials of any third-party service contacts who have already registered.');

//             // Verify the first and third Third-Party Service contacts are listed (since they have valid email addresses).
//             screen.getByText(baseService.vendor_contacts[0].email_address!);
//             screen.getByText('customer_support@somecompany.com');

//             // Verify the second Third-Party Service contact (who does not have a valid email address) is not listed by counting that there are only 2 checkboxes.
//             expect(screen.getAllByRole('checkbox')).toHaveLength(2);

//             // Uncheck the first Third-Party Service contact.
//             await userEvent.click(screen.getAllByRole('checkbox')[0]);

//             const request: SetInherentRiskEvidenceRequest = {
//                 customer_details_annual_volume: CustomerDetailsAnnualVolume.LOW,
//                 data_classification: DataClassification.PUBLIC,
//                 data_storage_location: DataStorageLocation.ON_PREMISES,
//                 inherent_risk_score: RiskRating.HIGH,
//                 file_updates: {
//                     existing_files_to_delete: [],
//                     new_files: [],
//                 },
//                 third_party_contacts: [{ name: undefined, email_address: 'customer_support@somecompany.com', phone_number: undefined }], // Verify the first Third-Party Service contact is not included in the request, but the third is.
//             };
//             await userEvent.click(screen.getByRole('button', { name: 'OVERWRITE' }));
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', request);
//         });

//         it('should warn of an invalid DDQ and not notifying the third-party service contact', async (): Promise<void> => {
//             tprmApiMock.getServiceDetails.mockReturnValueOnce(
//                 Promise.resolve({
//                     data: {
//                         ...baseService,
//                         inherent_risk_score: RiskRating.LOW,
//                         assessment_workflow_data: {
//                             state: ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
//                             final_review_documents: [],
//                         },
//                     },
//                 })
//             );

//             tprmApiMock.getInherentRiskQuestionnaire.mockReturnValue(
//                 Promise.resolve({
//                     data: {
//                         service_id: baseService.id,
//                         third_party_id: baseService.vendor_id,
//                         customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                         data_classification: DataClassification.RESTRICTED,
//                         data_storage_location: DataStorageLocation.ONSHORE,
//                         files: [{ file_id: 'document-evidence-id', filename: 'A document for download', file_state: FileState.PASSED }],
//                     },
//                 })
//             );
//             render(
//                 <BrowserRouter>
//                     <CachedDataProvider clientDetailsApi={clientDetailsApiMock} usersApi={usersApiMock}>
//                         <Routes location={url}>
//                             <Route path={url} element={<InherentRiskQuestionnaire {...inherentRiskQuestionnaireProps} />} />
//                         </Routes>
//                     </CachedDataProvider>
//                 </BrowserRouter>
//             );

//             await waitFor(() => screen.getByText(`${baseService.vendor_name} - ${baseService.name}`));
//             const selectBox = screen.getByLabelText('Inherent Risk Rating *');
//             await selectEvent.openMenu(selectBox);
//             await selectEvent.select(selectBox, 'High');
//             await userEvent.click(screen.getByRole('button', { name: 'SAVE' }));
//             await waitFor(() => getByBrokenUpText('The third-party questionnaire does not have any questions configured for the selected risk rating. The third-party service contacts will not be sent a notification.'));
//             await waitFor(() => expect(screen.queryByText('Jane Doe')).toBeNull());
//             await waitFor(() => expect(screen.queryByText('jane.doe@example.com')).toBeNull());

//             await userEvent.click(screen.getByRole('button', { name: 'OVERWRITE' }));

//             const request: SetInherentRiskEvidenceRequest = {
//                 customer_details_annual_volume: CustomerDetailsAnnualVolume.HIGH,
//                 data_classification: DataClassification.RESTRICTED,
//                 data_storage_location: DataStorageLocation.ONSHORE,
//                 inherent_risk_score: RiskRating.HIGH,
//                 file_updates: {
//                     existing_files_to_delete: [],
//                     new_files: [],
//                 },
//                 third_party_contacts: [], // Don't notify Third-Party Service contacts if the configured DDQ has no questions.
//             };
//             expect(tprmApiMock.setIRQ).toBeCalledWith(':third_party_id', ':service_id', request);
//         });
//     },
//     { retry: 3 }
// );
