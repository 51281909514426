import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ActionHistoryResponse } from 'Models/Actions';

import { ActionDetailsSnapshot } from './ActionDetailsSnapshot';

export interface ActionHistoryModalProps {
    history: ActionHistoryResponse;
    documentApi: DocumentApi;
    hideModal: () => void;
}

/**
 * Renders a modal that displays a detailed historical snapshot of an action. Matches the "main" section (containing current information) of the Action Details page. Does not include information about associated risks.
 */
export const ActionHistoryModal = (props: ActionHistoryModalProps) => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.history.title} secondaryText={iso8601ToUsDateShort(props.history.last_updated)} />
                <ActionDetailsSnapshot type="historical" action={props.history} documentApi={props.documentApi} />
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
