import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { Filter, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';
import { ManageUsersSortFilterOptions } from 'Models/User';

export interface ManageUsersFilterProps {
    filterOptions: GroupedOptions[];
    filtersUpdated: (selectedFilterOptions: Filter | Filter[]) => void;
}

export const ManageUsersFilter: React.FC<ManageUsersFilterProps> = ({ filterOptions, filtersUpdated }) => {
    const handleSelectChange = (value: GroupOptionType[]): void => {
        const mfaRequiredFilterValues: (string | number)[] = [];
        const roleFilterValues: (string | number)[] = [];
        const statusFilterValues: (string | number)[] = [];
        const departmentFilterValues: (string | number)[] = [];
        value.forEach((filter) => {
            if (filter.groupId === ManageUsersSortFilterOptions.MFA_REQUIRED) {
                mfaRequiredFilterValues.push(filter.value);
            }
            if (filter.groupId === ManageUsersSortFilterOptions.ROLES) {
                roleFilterValues.push(filter.value);
            }
            if (filter.groupId === ManageUsersSortFilterOptions.STATUS) {
                statusFilterValues.push(filter.value);
            }
            if (filter.groupId === ManageUsersSortFilterOptions.DEPARTMENT) {
                departmentFilterValues.push(filter.value);
            }
        });
        const allFilters: Filter[] = [
            {
                key: ManageUsersSortFilterOptions.MFA_REQUIRED,
                value: mfaRequiredFilterValues,
            },
            {
                key: ManageUsersSortFilterOptions.ROLES,
                value: roleFilterValues,
            },
            {
                key: ManageUsersSortFilterOptions.STATUS,
                value: statusFilterValues,
            },
            {
                key: ManageUsersSortFilterOptions.DEPARTMENT,
                value: departmentFilterValues,
            },
        ];
        filtersUpdated(allFilters);
    };

    return <FormFieldMultiOptionSelect accessibilityLabel="Users Filter" handleChange={handleSelectChange} formFieldLabel="Users Filter" options={filterOptions} />;
};
