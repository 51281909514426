import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { LogoutApi } from 'Api/Auth/LogoutApi';
import { SessionApi } from 'Api/Auth/SessionApi';

import { IdleTimeoutModal, IdleTimeoutModalProps } from './IdleTimeoutModal/IdleTimeoutModal';

export interface IdleTimeoutManagerProps {
    logoutApi: LogoutApi;
    sessionApi: SessionApi;
    promptBeforeIdleCountdown: number;
    timeoutCountdown: number;
    keepAliveThrottle: number;
}

/**
 * See `Auth.md` for information on this component and related components.
 */
export const IdleTimeoutManager = (props: IdleTimeoutManagerProps): JSX.Element => {
    const [promptedTimeRemaining, setPromptedTimeRemaining] = useState<number>(0);

    const handleLogout = async (): Promise<void> => {
        try {
            props.logoutApi.logOut();
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    const onUserActivity = async (): Promise<void> => {
        try {
            // To avoid making a call on a users initial action, we will wait at least 10 seconds before making a "keepAlive" call
            if (idleTimer.isLastActiveTab() && !idleTimer.isPrompted() && idleTimer.getTotalElapsedTime() > 10 * 1000) {
                await props.sessionApi.keepAlive();
            }
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    const onUserClickSessionKeepAlive = async (): Promise<void> => {
        try {
            await props.sessionApi.keepAlive();
            idleTimer.activate();
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    const handleUserIsIdle = () => {
        try {
            props.logoutApi.timedOut();
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    const idleTimer = useIdleTimer({
        crossTab: true,
        syncTimers: 200,
        promptBeforeIdle: props.promptBeforeIdleCountdown, // Amount of time left when the user becomes prompted
        timeout: props.timeoutCountdown, // Amount of time until the user is idle.
        onIdle: handleUserIsIdle, // Function to logout user after timeout has been reached.
        throttle: props.keepAliveThrottle, // Amount of time between keep alive calls to ensure that is is not called on every single action.
        onAction: onUserActivity,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setPromptedTimeRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [idleTimer]);

    const idleTimeoutModalProps: IdleTimeoutModalProps = {
        handleLogOut: handleLogout,
        onUserClickSessionKeepAlive: onUserClickSessionKeepAlive,
        timeoutCountdown: promptedTimeRemaining,
    };

    if (idleTimer.isPrompted()) {
        return <IdleTimeoutModal {...idleTimeoutModalProps} />;
    } else {
        // ToDo: Return null once upgraded to TS 5.1
        return <></>;
    }
};
