import styles from './MetricOptionsTooltip.module.css';

export const MetricOptionsTooltip: JSX.Element = (
    <div className={styles.metricOptionsTooltip}>
        <div>On-Poll: Generate an alert every time the metric is polled if it is outside the limit.</div>
        <br />
        <div>On-Breach: Generate an alert if during the most recent poll the metric breached the limit. Will not generate further alerts if subsequent metric polls are outside the limit.</div>
        <br />
        <div>Daily: A hybrid of the &quot;On-Poll&quot; and &quot;On-Breach&quot; options. An alert will be generated when the metric is polled, if it is outside the limit, but this option will not generate more than one alert per day.</div>
    </div>
);
