import { FunctionComponent } from 'react';

import styles from './PageCell.module.css';

export interface PageCellProps {
    variant?: 'white' | 'transparentBlue';
    children?: React.ReactNode;
}

/** DEPRECATED. Do not implement this on anything new. Use the PageLayout/ components. */
export const PageCell: FunctionComponent<PageCellProps> = (props): JSX.Element => {
    return <div className={`${styles.pageCell} ${props.variant ? styles[props.variant] : styles.white}`}>{props.children}</div>;
};
