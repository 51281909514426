import { ReactNode } from 'react';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

export interface AccordionProps {
    children: ReactNode;
    defaultActiveKey?: AccordionEventKey;
}

export const Accordion = (props: AccordionProps) => {
    return <BootstrapAccordion defaultActiveKey={props.defaultActiveKey}>{props.children}</BootstrapAccordion>;
};
