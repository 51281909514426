/*
	TableRow Presentation for a control group rendered as a "list."
*/

import { Link } from 'Components/Buttons/Buttons';
import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { getHumanReadableControlIdFromControlId } from 'Helpers/ControlFormatter/ControlFormatter';
import { ControlEffectivenessProgressBarVariantAndPercentage, ControlGroupResponse, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import styles from './ControlGroupTableRow.module.css';

export interface ControlGroupTableRowProps {
    controlGroup: ControlGroupResponse;
}

export const ControlGroupTableRow = (props: ControlGroupTableRowProps) => {
    const progressBarValues = ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.controlGroup.control_group_effectiveness));

    return (
        <TableRow>
            <TableCellDefaultText>
                <Link size="lg" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${props.controlGroup.control_framework}/${GROUPS}/${props.controlGroup.control_group_id}`}>
                    {getHumanReadableControlIdFromControlId(`${props.controlGroup.control_framework}#${props.controlGroup.control_group_id}`, props.controlGroup.control_group_name)}
                </Link>
                {props.controlGroup.control_group_description && (
                    <Text color="darkGray" variant="Text3" noStyles>
                        {props.controlGroup.control_group_description}
                    </Text>
                )}
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color="darkGray" variant="Text3" noStyles>
                    {props.controlGroup.control_framework}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <div className={styles.progressBar}>
                    <ProgressBarIndicator variant={progressBarValues.variant} percent={progressBarValues.percent} size="small" />
                </div>
                <Text color="darkGray" variant="Text3" noStyles>
                    {numberAsEffectivenessString(props.controlGroup.control_group_effectiveness)}
                </Text>
            </TableCellDefaultText>
        </TableRow>
    );
};
