import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getExceptionDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { DashboardType } from 'Models/Dashboards';
import { ExceptionResponse, ThirdPartyExceptionResponse } from 'Models/Exceptions';
import { ThirdPartyIdToNameMap, ThirdPartyResponse } from 'Models/TPRM';

import styles from './TrackingExceptionsDisplayModal.module.css';

export interface OpenExceptionDisplayModalProps {
    hideModal: () => void;
    selectedException: ExceptionResponse[];
    date: string;
    dashboardType: DashboardType;
    thirdParties?: ThirdPartyResponse[];
}

// TODO: This component name does not match up with the file name (or `TrackingIssuesDisplayModal`).
/**
 * Displays a list of exceptions that are expiring in a specific month.
 */
export const OpenExceptionDisplayModal = (props: OpenExceptionDisplayModalProps): JSX.Element => {
    const thirdPartyIdToNameMap: ThirdPartyIdToNameMap = new Map(props.thirdParties?.map((thirdParty: ThirdPartyResponse) => [thirdParty.id, thirdParty.name]));

    const sortExceptionsByDateType = (exceptionA: ExceptionResponse, exceptionB: ExceptionResponse): number => {
        if (exceptionA.expiration_date > exceptionB.expiration_date) {
            return 1;
        } else if (exceptionA.expiration_date < exceptionB.expiration_date) {
            return -1;
        } else {
            return exceptionA.title.localeCompare(exceptionB.title, undefined, { numeric: true, sensitivity: 'base' });
        }
    };

    const openExceptionListing = props.selectedException.sort(sortExceptionsByDateType).map((exception) => (
        <div className={styles.detailsContainer} key={exception.created_timestamp}>
            {props.dashboardType === DashboardType.THIRD_PARTY && thirdPartyIdToNameMap.size > 0 && (
                <div className={styles.detailsThirdPartyName}>
                    <Text color="darkGray" noStyles={true}>
                        {thirdPartyIdToNameMap.get((exception as ThirdPartyExceptionResponse).impacted_vendor)}
                    </Text>
                </div>
            )}
            <Link to={getExceptionDetailsUrl(exception.id, exception.type)} size="sm" openInNewTab>
                {exception.title}
            </Link>
            <div className={styles.detailsDateContainer}>
                <Text color="darkGray" noStyles={true}>
                    {iso8601ToUsDateShort(exception.expiration_date)}
                </Text>
            </div>
        </div>
    ));

    return <CollapsibleModal displayItems={openExceptionListing} headerText={{ text: 'Open Exceptions', secondaryText: `${props.date}` }} hideModal={props.hideModal} />;
};
