import { FILE_PROCESSING, PUBLIC, UPLOAD } from 'Config/Paths';
import { SignedDownloadResponse, SignedUploadRequest, SignedUploadResponse } from 'Models/Files';
import { ResponseModel } from 'Models/ResponseModel';
import { documentUpload, publicRestApiGet } from 'PublicRestApiConnector';

import { DocumentApi } from './DocumentApi';

export class AWSPublicDocumentApi implements DocumentApi {
    uploadDocument(url: string, fields: { string: string }, file: File): Promise<any> {
        return documentUpload(url, fields, file);
    }

    downloadText(filePath: string): Promise<Blob> {
        return Promise.reject(new Error('This should not be called by public DDQs'));
    }

    // Get an S3 presigned POST (pointed at a CloudFront Signed URL) in order to create or update a file in an S3 bucket behind CloudFront.
    getSignedUpload(file_name: string, file_id?: string): Promise<ResponseModel<SignedUploadResponse>> {
        const request: SignedUploadRequest = {
            file_name: file_name,
        };
        if (file_id) {
            request['file_id'] = file_id;
        }
        return publicRestApiGet(`/${PUBLIC}/${FILE_PROCESSING}/${UPLOAD}`, request);
    }

    getSignedDownload(file_id: string): Promise<ResponseModel<SignedDownloadResponse>> {
        return Promise.reject(new Error('This should not be called by public DDQs'));
    }
}
