/*
	AWSUsersApi.ts -- AWS-specific interface to making User related network calls.
*/
import { ACTIVATE, DEACTIVATE, USERS } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { CreateUserRequest, ModifyUserRequest, UserResponse } from 'Models/User';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { UsersApi } from './UsersApi';

export class AWSUsersApi implements UsersApi {
    getUsers = (): Promise<ResponseModel<UserResponse[]>> => {
        return restApiGet(`/${USERS}`);
    };

    createUser = (createUserRequest: CreateUserRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${USERS}`, createUserRequest);
    };

    modifyUser = (cognito_subject: string, modifyUserRequest: ModifyUserRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${USERS}/${cognito_subject}`, modifyUserRequest);
    };

    deactivateUser = (cognito_subject: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${USERS}/${cognito_subject}/${DEACTIVATE}`);
    };

    reactivateUser = (cognito_subject: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${USERS}/${cognito_subject}/${ACTIVATE}`);
    };

    deleteUser = (cognito_subject: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${USERS}/${cognito_subject}`);
    };
}
