import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { NotificationType, UserNotification } from 'Models/Notification';

/**
 * Given a Notification, determine the name that should be displayed in the UI for it.
 */
export const getNotificationDisplayName = (notification: UserNotification): string => {
    switch (notification._type) {
        case NotificationType.ACTION_DUE:
            return `Action: ${notification.action.title}`;
        case NotificationType.CONTROL_ASSESSMENT:
            return `Control: ${getHumanReadableControlIdFromControl(notification.control)}`;
        case NotificationType.EXCEPTION_EXPIRY:
            return `Exception: ${notification.exception.title}`;
        case NotificationType.FOLDER_EXPIRY:
            return `Folder: ${notification.vendor.name} - ${notification.folder.name}`;
        case NotificationType.IRQ_DUE:
            return `Inherent Risk Questionnaire: ${notification.third_party_service.vendor_name} - ${notification.third_party_service.name}`;
        case NotificationType.ISSUE_DUE:
            return `Issue: ${notification.issue.title}`;
        case NotificationType.SERVICE_ASSESSMENT_DUE:
            return `Third-Party Service Assessment: ${notification.vendor.name} - ${notification.vendor_service.name}`;
    }
};
