import { MultipleMappingBase, MultipleMappingBaseProps } from 'Components/MultipleMapping/MultipleMappingBase';
import { Text } from 'Components/Text/Text';
import { AssociatedAction } from 'Models/RiskRegister';

import styles from './MultipleActionMapping.module.css';

interface ActionMappingListItemProps {
    action: AssociatedAction;
    handleSelected: (value: AssociatedAction, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    selected?: boolean;
}

const ActionMappingListItem = (props: ActionMappingListItemProps): JSX.Element => {
    return (
        <div className={`${styles.text} ${props.selected && styles.selected}`} onClick={(e) => props.handleSelected(props.action, e)}>
            <Text variant="Text2" noStyles>
                {props.action.title}
            </Text>
            <Text variant="Text4" noStyles>
                {props.action.description}
            </Text>
        </div>
    );
};

export interface MultipleActionMappingProps {
    actions: AssociatedAction[];
    handleChange: (actions: string[]) => void;
    currentlyMappedActionIds: string[];
}

export const MultipleActionMapping = (props: MultipleActionMappingProps): JSX.Element => {
    const renderMappingItems = (items: AssociatedAction[], handleSelected: (value: AssociatedAction, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, selected: AssociatedAction[]) => {
        return items.map((item, index) => <ActionMappingListItem key={index} action={item} handleSelected={handleSelected} selected={selected.includes(item)} />);
    };

    const sortActions = (actions: AssociatedAction[]) => {
        actions.sort((a, b) => (a.title > b.title ? 1 : -1));
    };

    const multipleMappingBaseProps: MultipleMappingBaseProps<AssociatedAction> = {
        mappingItems: props.actions.map((item) => ({ ...item, identifier: item.id })),
        handleChange: props.handleChange,
        sortMappingItems: sortActions,
        currentMappedIdentifiers: props.currentlyMappedActionIds,
        renderMappingItems: renderMappingItems,
    };

    if (multipleMappingBaseProps.mappingItems.length > 0) {
        return <MultipleMappingBase {...multipleMappingBaseProps} />;
    } else {
        return (
            <>
                <Text variant="Text3" noStyles>
                    No Actions have been created.
                </Text>
            </>
        );
    }
};
