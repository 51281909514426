/*
	NavLogo.js -- Component for the HPS logo in the navigation menu.
*/

import styles from './NavLogo.module.css';

export const NavLogo = () => {
    const logoUrl = new URL('/hps_white.svg', import.meta.url).href;

    return (
        <div className={styles.navLogo}>
            <img src={logoUrl} width={89} height={84} alt="High Peaks Solutions Logo" />
        </div>
    );
};
