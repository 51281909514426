import { useCallback, useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { AwsConfigIntegration, AwsConfigIntegrationSetupProgress, Metric, SetupProgressState } from 'Models/ExternalIntegrations';

import { AwsConfigSetupStepDownloadTemplate } from './AwsConfigSetupStepDownloadTemplate';
import { AwsConfigSetupStepInitiateSetup } from './AwsConfigSetupStepInitiateSetup';
import { AwsConfigSetupWizardButtonRow } from './AwsConfigSetupWizardButtonRow';

export interface AwsConfigSetupWizardProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    metrics: Metric[];
    hideModal: () => void;
}

export type AlertState =
    | {
          variant: 'success' | 'danger';
          message: string;
      }
    | undefined;

type Step = 'DOWNLOAD_TEMPLATE' | 'INITIATE_SETUP';

const getStep = (current: Step | undefined, direction: 'next' | 'previous'): Step | undefined => {
    if (current === undefined) {
        return undefined;
    }

    switch (current) {
        case 'DOWNLOAD_TEMPLATE':
            if (direction === 'next') {
                return 'INITIATE_SETUP';
            } else {
                return undefined;
            }

        case 'INITIATE_SETUP':
            if (direction === 'next') {
                return undefined;
            } else {
                return 'DOWNLOAD_TEMPLATE';
            }
    }
};

const getStepTitle = (step: Step): string => {
    switch (step) {
        case 'DOWNLOAD_TEMPLATE':
            return 'Permit SummIT Security to Access AWS';

        case 'INITIATE_SETUP':
            return 'Perform Automated Setup';
    }
};

export const AwsConfigSetupWizard = (props: AwsConfigSetupWizardProps): JSX.Element => {
    const [stepState, setStepState] = useState<Step>();
    const [alertState, setAlertState] = useState<AlertState>();
    const [integration, setIntegration] = useState<AwsConfigIntegration>();
    const [progress, setProgress] = useState<AwsConfigIntegrationSetupProgress>();

    const previousStep = getStep(stepState, 'previous');
    const nextStep = getStep(stepState, 'next');

    const goToPreviousStep = () => {
        if (previousStep) {
            setStepState(previousStep);
        }
    };

    const goToNextStep = () => {
        if (nextStep) {
            setStepState(nextStep);
        } else {
            props.hideModal();
        }
    };

    const getProgress = useCallback(async (): Promise<boolean> => {
        try {
            const setupProgress = (await props.externalIntegrationsApi.getAwsConfigIntegrationSetupProgress()).data;
            setProgress(setupProgress);

            setStepState((current) => {
                if (current === undefined) {
                    const inProgress = setupProgress.role_template_state === SetupProgressState.IN_PROGRESS || setupProgress.regional_rules_template_state === SetupProgressState.IN_PROGRESS || setupProgress.global_rules_template_state === SetupProgressState.IN_PROGRESS;
                    return inProgress ? 'INITIATE_SETUP' : 'DOWNLOAD_TEMPLATE';
                } else {
                    return current;
                }
            });

            return true;
        } catch (err) {
            setAlertState({ variant: 'danger', message: `Progress could not be refreshed. ${err.message}` });
            return false;
        }
    }, [props.externalIntegrationsApi]);

    useEffect(() => {
        const getIntegration = async (): Promise<void> => {
            try {
                const integrationAwsConfig = (await props.externalIntegrationsApi.getIntegrationAwsConfig()).data;

                if (integrationAwsConfig) {
                    setIntegration(integrationAwsConfig);
                }
            } catch (err) {
                setAlertState({ variant: 'danger', message: `AWS Config integration could not be loaded. ${err.message}` });
            }
        };

        getIntegration();
        getProgress();
    }, [props.externalIntegrationsApi, getProgress]);

    // Whenever we get an update on progress and we're still waiting for completion, refresh progress 10 seconds from now.
    useEffect(() => {
        const setupInProgress = progress !== undefined && (progress.role_template_state === SetupProgressState.IN_PROGRESS || progress.regional_rules_template_state === SetupProgressState.IN_PROGRESS || progress.global_rules_template_state === SetupProgressState.IN_PROGRESS);

        if (setupInProgress) {
            const timeout = setTimeout(getProgress, 10000);
            return () => clearTimeout(timeout);
        }
    }, [progress, getProgress]);

    const modalContent = (() => {
        if (!integration || !progress || !stepState) {
            const buttonRowProps = {
                goToNextStep: () => void 0,
                goToPreviousStep: () => void 0,
                hasNextStep: false,
                hasPreviousStep: false,
                hideModal: props.hideModal,
            };
            return (
                <>
                    <Text>Loading...</Text>
                    <AwsConfigSetupWizardButtonRow {...buttonRowProps} />
                </>
            );
        } else {
            const modalProps = {
                setAlertState: setAlertState,
                goToNextStep: goToNextStep,
                goToPreviousStep: goToPreviousStep,
                hasNextStep: nextStep !== undefined,
                hasPreviousStep: previousStep !== undefined,
                externalIntegrationsApi: props.externalIntegrationsApi,
                hideModal: props.hideModal,
            };

            const setupModalProps = {
                ...modalProps,
                integration: integration,
                progress: progress,
                refreshProgress: getProgress,
                metrics: props.metrics,
            };

            return (
                <>
                    <ModalHeader text={getStepTitle(stepState)} />
                    <div>
                        {alertState && <Alert variant={alertState.variant}>{alertState.message}</Alert>}

                        {stepState === 'DOWNLOAD_TEMPLATE' && <AwsConfigSetupStepDownloadTemplate {...modalProps} />}
                        {stepState === 'INITIATE_SETUP' && <AwsConfigSetupStepInitiateSetup {...setupModalProps} />}
                    </div>
                </>
            );
        }
    })();

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">{modalContent}</Modal.Body>
        </Modal>
    );
};
