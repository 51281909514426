import { ACTIONS, HISTORY } from 'Config/Paths';
import { Action, ActionHistoryResponse, CreateActionRequest, UpdateActionRequest } from 'Models/Actions';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { ActionsApi } from './ActionsApi';

export class AWSActionsApi implements ActionsApi {
    createAction = (createActionRequest: CreateActionRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${ACTIONS}`, createActionRequest);
    };

    getAllActions = (): Promise<ResponseModel<Action[]>> => {
        return restApiGet(`/${ACTIONS}`);
    };

    getActionDetails = (actionId: string): Promise<ResponseModel<Action>> => {
        return restApiGet(`/${ACTIONS}/${actionId}`);
    };

    updateAction = (actionId: string, request: UpdateActionRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`${ACTIONS}/${actionId}`, request);
    };

    deleteAction = (actionId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`${ACTIONS}/${actionId}`);
    };

    getActionHistory = (actionId: string): Promise<ResponseModel<ActionHistoryResponse[]>> => {
        return restApiGet(`/${ACTIONS}/${actionId}/${HISTORY}`);
    };
}
