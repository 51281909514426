import { Link } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FieldLabel } from 'Components/FieldLabel/FieldLabel';
import { Text } from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedHyperlink/UserDefinedHyperlink';
import { ISSUES_EXCEPTIONS, SERVICES, TPRM } from 'Config/Paths';
import { TPRM_THIRD_PARTY_MANAGER, TPRM_THIRD_PARTY_WEBSITE } from 'Config/Tooltips';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';

import styles from './ThirdPartySummary.module.css';

export interface ThirdPartySummaryProps {
    third_party: ThirdPartyResponseWithServices;
}

/**
 * Renders details about a third party that roughly match the details shown on the card for a third party on the Manage Third Parties page.
 * Used on the Issue Details and Exception Details pages.
 */
export const ThirdPartySummary = (props: ThirdPartySummaryProps) => {
    const cachedData = useCachedData();

    const numberOfOpenIssues = countOpenIssues(props.third_party.issues);
    const numberOfOpenExceptions = countOpenExceptions(props.third_party.exceptions);

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsGridSection}>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Third-Party Manager" tooltipText={TPRM_THIRD_PARTY_MANAGER} />
                        <Text noStyles>{getUserNameFromSubject(props.third_party.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Issues" />
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${props.third_party.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Services" />
                        <Link size="sm" to={{ pathname: `/${TPRM}/${SERVICES}`, search: `thirdPartyId=${props.third_party.id}` }}>
                            {`Services: ${props.third_party.services.length}`}
                        </Link>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Exceptions" />
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${props.third_party.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Website" tooltipText={TPRM_THIRD_PARTY_WEBSITE} />
                <UserDefinedReference reference={props.third_party.website} />
            </div>
        </div>
    );
};
