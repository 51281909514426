import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { CSSColors } from 'Components/Colors';
import { Text } from 'Components/Text/Text';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { AssessmentsResponse } from 'Models/Dashboards';

import { AssessmentControlDisplayModal, AssessmentForDisplay } from './AssessmentControlDisplayModal/AssessmentControlDisplayModal';
import styles from './AssessmentsSchedule.module.css';

enum Modals {
    NONE,
    DISPLAY_UPCOMING_ASSESSMENTS,
}

export interface AssessmentsScheduleProps {
    api: DashboardApi;
}

export const AssessmentsSchedule = (props: AssessmentsScheduleProps): JSX.Element => {
    const moderateColor = CSSColors.YELLOW;
    const blueColor = CSSColors.LIGHT_BLUE;

    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [assessments, setAssessments] = useState<AssessmentsResponse>();
    const [assessmentForDisplay, setAssessmentForDisplay] = useState<AssessmentForDisplay[]>();
    const [modalTitle, setModalTitle] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        const getAssessments = async () => {
            try {
                const assessments = await props.api.getAssessments();
                setAssessments(assessments.data);
            } catch (error) {
                setError(error.message);
            }
        };
        getAssessments();
    }, [props.api]);

    // The original intention of this component was to show overdue assessments in two separate sections ("exceedingly" and "marginally"), based on how overdue they are.
    // Until that distinction is implemented in the UI, group overdue assessments into a single array.
    const overdueAssessments = assessments === undefined ? undefined : [...assessments.overdue.marginally, ...assessments.overdue.exceedingly];

    const buildBarData = () => {
        const overdueItems = { value: overdueAssessments!.length, itemStyle: { color: moderateColor } };
        const upcomingAssessments = assessments!.upcoming_by_month.map((assessment) => assessment.assessments.length);
        return [overdueItems, ...upcomingAssessments];
    };

    const handleChartClick = (item: string) => {
        const itemClicked = item.split(' ');
        if (itemClicked[0] === 'OVERDUE') {
            overDueClick();
        } else {
            const clickedDate = moment(`${itemClicked[0]} ${itemClicked[1]}`, 'MMM YY');
            const clickedAssessments = assessments!.upcoming_by_month.find((assessment) => assessment.month.month - 1 === clickedDate.month() && assessment.month.year === clickedDate.year());

            if (clickedAssessments) {
                const assessmentForDisplay: AssessmentForDisplay[] = clickedAssessments.assessments.map((assessment) => ({
                    identifier: getOperationalControlIdentifierString(assessment.control_framework, assessment.control_group_id, assessment.control_id),
                    name: assessment.control_name,
                    date: assessment.assessment_due_date,
                }));
                setAssessmentForDisplay(assessmentForDisplay);
                setModalTitle(`Assessments - ${clickedDate.format('MMMM YYYY')}`);
                setModalState(Modals.DISPLAY_UPCOMING_ASSESSMENTS);
            }
        }
    };

    const overDueClick = () => {
        const assessmentForDisplay: AssessmentForDisplay[] = overdueAssessments!.map((assessment) => ({
            identifier: getOperationalControlIdentifierString(assessment.control_framework, assessment.control_group_id, assessment.control_id),
            name: assessment.control_name,
            date: assessment.assessment_due_date,
        }));
        setAssessmentForDisplay(assessmentForDisplay);
        setModalTitle('Overdue Assessments');
        setModalState(Modals.DISPLAY_UPCOMING_ASSESSMENTS);
    };

    const next30Click = () => {
        const assessmentForDisplay: AssessmentForDisplay[] = assessments!.due_soon.map((assessment) => ({
            identifier: getOperationalControlIdentifierString(assessment.control_framework, assessment.control_group_id, assessment.control_id),
            name: assessment.control_name,
            date: assessment.assessment_due_date,
        }));
        setAssessmentForDisplay(assessmentForDisplay);
        setModalTitle('Assessments Due in Next 30 Days');
        setModalState(Modals.DISPLAY_UPCOMING_ASSESSMENTS);
    };

    const completedRecent = () => {
        const assessmentForDisplay: AssessmentForDisplay[] = assessments!.completed_recently.map((assessment) => ({
            identifier: getOperationalControlIdentifierString(assessment.control_framework, assessment.control_group_id, assessment.control_id),
            name: assessment.control_name,
            date: assessment.timestamp,
        }));
        setAssessmentForDisplay(assessmentForDisplay);
        setModalTitle('Assessments Completed in Last 30 Days');
        setModalState(Modals.DISPLAY_UPCOMING_ASSESSMENTS);
    };

    if (error) {
        return (
            <Text variant="Header4" color="lightGray">
                {error}
            </Text>
        );
    }

    return (
        <>
            {modalState === Modals.DISPLAY_UPCOMING_ASSESSMENTS && assessmentForDisplay && modalTitle && <AssessmentControlDisplayModal hideModal={() => setModalState(Modals.NONE)} selectedAssessments={assessmentForDisplay} modalTitle={modalTitle} />}
            <div className={styles.assessmentSchedule}>
                <div className={styles.assessmentCalloutContainer}>
                    <div className={styles.assessmentCallout} onClick={overDueClick}>
                        {overdueAssessments ? (
                            <>
                                <div className={overdueAssessments.length > 0 ? styles.assessmentNumberOverdue : styles.assessmentNumber}>{overdueAssessments.length}</div>
                                <Text noStyles color={overdueAssessments.length > 0 ? 'yellow' : 'darkGray'}>
                                    OVERDUE
                                </Text>
                            </>
                        ) : (
                            <Skeleton variant="text">
                                <div className={styles.assessmentNumber}>0</div>
                                <Text noStyles color={'darkGray'}>
                                    OVERDUE
                                </Text>
                            </Skeleton>
                        )}
                    </div>
                    <div className={styles.assessmentCallout} onClick={next30Click}>
                        {assessments?.due_soon ? (
                            <>
                                <div className={styles.assessmentNumber}>{assessments.due_soon.length}</div>
                                <Text noStyles color="darkGray">
                                    DUE NEXT 30 DAYS
                                </Text>
                            </>
                        ) : (
                            <Skeleton variant="text">
                                <div className={styles.assessmentNumber}>0</div>
                                <Text noStyles color="darkGray">
                                    DUE NEXT 30 DAYS
                                </Text>
                            </Skeleton>
                        )}
                    </div>
                    <div className={styles.assessmentCallout} onClick={completedRecent}>
                        {assessments?.completed_recently ? (
                            <>
                                <div className={styles.assessmentNumber}>{assessments.completed_recently.length}</div>
                                <Text noStyles color="darkGray">
                                    COMPLETED LAST 30 DAYS
                                </Text>
                            </>
                        ) : (
                            <Skeleton variant="text">
                                <div className={styles.assessmentNumber}>0</div>
                                <Text noStyles color="darkGray">
                                    COMPLETED LAST 30 DAYS
                                </Text>
                            </Skeleton>
                        )}
                    </div>
                </div>
                <div className={styles.controlsAssessed}>{assessments ? <StackedBarAndLineChart onChartClick={handleChartClick} xAxisLabels={['OVERDUE', ...assessments.upcoming_by_month.map((assessment) => moment(`${assessment.month.month} ${assessment.month.year}`, 'MM YYYY').format('MMM YY').toString().toUpperCase())]} bars={[{ data: buildBarData(), name: 'Number of Assessments', color: blueColor }]} yAxisInfo={[{ name: 'NUMBER OF ASSESSMENTS', position: 'left', minInterval: 1 }]} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}</div>
            </div>
        </>
    );
};
