import { Button, Link } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getIssueDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlIssueResponse, IssuesExceptionsModule, ThirdPartyIssueResponse, titleCaseIssuePriority, titleCaseIssueStatus } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ColorTheme } from 'Models/Types/GlobalType';

import styles from './IssuesTableRow.module.css';

/**
 * @param colorTheme The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
 */
interface IssuesTableRowBaseProps {
    colorTheme?: ColorTheme;
}

interface IssuesTableRowControlProps extends IssuesTableRowBaseProps {
    type: IssuesExceptionsModule.CONTROLS;
    issue: ControlIssueResponse;
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
}

interface IssuesTableRowThirdPartyProps extends IssuesTableRowBaseProps {
    type: IssuesExceptionsModule.TPRM;
    issue: ThirdPartyIssueResponse;
    getThirdPartyName: (thirdPartyId: string) => string;
}

export type IssuesTableRowProps = IssuesTableRowControlProps | IssuesTableRowThirdPartyProps;

export const IssuesTableRow = (props: IssuesTableRowProps) => {
    const cachedData = useCachedData();

    const textColor = (props.colorTheme ?? 'light') === 'light' ? 'blue' : 'white';

    return (
        <TableRow>
            <TableCell className={styles.titleCell}>
                <Link size="sm" to={getIssueDetailsUrl(props.issue.id, props.issue.type)}>
                    {props.issue.title}
                </Link>
            </TableCell>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {iso8601ToUsDateShort(props.issue.due_date)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {props.issue.source}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {getUserNameFromSubject(props.issue.owner_subject, cachedData.users)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {titleCaseIssuePriority(props.issue.priority)}
                </Text>
            </TableCellDefaultText>
            {props.type === IssuesExceptionsModule.CONTROLS && (
                <TableCellDefaultText>
                    <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal(props.issue.impacted_controls)}>{`${props.issue.impacted_controls.length} ${props.issue.impacted_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
                </TableCellDefaultText>
            )}
            {props.type === IssuesExceptionsModule.TPRM && (
                <TableCellDefaultText>
                    <Text color={textColor} noStyles>
                        {props.getThirdPartyName(props.issue.impacted_vendor)}
                    </Text>
                </TableCellDefaultText>
            )}
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {titleCaseIssueStatus(props.issue.status)}
                </Text>
            </TableCellDefaultText>
        </TableRow>
    );
};
