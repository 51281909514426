import { FileToBeUploaded, FileUpdates, UploadedFile } from './Files';
import { RiskCategory, RiskControlMapping, RiskStrategy } from './RiskRegister';
import { DisplayableTag } from './Tags';
import { OptionType } from './Types/GlobalType';

export enum ActionStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export const ActionStatusOptions: OptionType[] = [
    {
        value: ActionStatus.OPEN,
        label: ActionStatus.OPEN,
    },
    {
        value: ActionStatus.CLOSED,
        label: ActionStatus.CLOSED,
    },
];

export const actionStatusComparator = (statusA: ActionStatus, statusB: ActionStatus): number => {
    if (statusA === statusB) {
        return 0;
    } else {
        return statusA === ActionStatus.CLOSED ? 1 : -1;
    }
};

export const ActionStatusFilterOptions: OptionType[] = [
    {
        value: 'ALL_ACTIONS',
        label: 'All Actions',
    },
    ...ActionStatusOptions,
];

export enum TreatmentPlanStatus {
    ACTIVE = 'Active',
    COMPLETE = 'Complete',
    MISSING = 'Missing', // Note: not intended to be displayed in the UI.
}

export interface AssociatedRisk {
    id: string;
    title: string;
    description: string;
    last_updated_by: string;
    category: RiskCategory;
    control_environment_effectiveness: number;
    treatment_plan_status: TreatmentPlanStatus;
    treatment_plan_description?: string;
    controls: RiskControlMapping[];
    scores_comment?: string;
    inherent_risk_probability?: number;
    inherent_risk_impact?: number;
    total_inherent_risk?: number;
    current_risk_probability?: number;
    current_risk_impact?: number;
    total_current_risk?: number;
    target_risk_probability?: number;
    target_risk_impact?: number;
    total_target_risk?: number;
    owner?: string;
    strategy?: RiskStrategy;
}

export interface Action {
    id: string;
    title: string;
    description: string;
    status: ActionStatus;
    owner: string;
    last_updated: string;
    last_updated_by: string;
    tags: string[];
    documents: UploadedFile[];
    due_date?: string;
    comments?: string;
    link?: string;
    associated_risks: AssociatedRisk[];

    // These attributes were added after the feature was implemented. Going forward, they should always be present, but some legacy records may not have them.
    created_by?: string;
    created_timestamp?: string;
    closed_by?: string;
    closed_timestamp?: string;
}

export interface CreateActionRequest {
    title: string;
    description: string;
    owner: string;
    tags: string[];
    documents: FileToBeUploaded[];
    associated_risks: string[];
    due_date?: string;
    comments?: string;
    link?: string;
}

export interface UpdateActionRequest {
    title: string;
    description: string;
    status: ActionStatus;
    owner: string;
    tags: string[];
    document_updates: FileUpdates;
    associated_risks: string[];
    due_date?: string;
    comments?: string;
    link?: string;
}

// As defined by the backend, action histories have all the same properties as actions, except that tags are in a displayable format.
// (Historical tags may not match up with the client's currently configured tags, so they can't be looked up in the same way as tags on "current" items can be.)
export type ActionHistoryResponse = Omit<Action, 'tags'> & { tags: DisplayableTag[] };
