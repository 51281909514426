import { OptionType } from 'Models/Types/GlobalType';

import { OperationalControl } from './OperationalControls';

// Attributes that comprise a Regulation.
export interface Regulation {
    name: string;
    version: string;
}

// Attributes that comprise a Compliance Requirement.
export interface ComplianceRequirement {
    regulation: string;
    identifier: string;
    managed: boolean;
    version: string;
    text?: string; // Optional because some CFR requirements are empty strings.
    assigned_control_sets: OperationalControl[][];
    last_updated?: string;
    parent_context?: string;
    status?: string;
}

// Render Compliance Requirements in the ComplianceRequirements page.
export interface ComplianceRequirementsForRegulationResponse {
    [key: string]: ComplianceRequirementsForRegulationRow;
}

export interface ComplianceRequirementsForRegulationRow {
    attributes: ComplianceRequirement;
    children: {
        [key: string]: ComplianceRequirementsForRegulationRow;
    };
}

export interface UpdateAssignedControlRequest {
    assigned_control_sets: string[][];
}

/**
 * A Compliance Requirement associated with a Control (matches the domain object of the API).
 * Very similar to ComplianceRequirementForControlResponse except assigned_control_sets are Control IDs instead of Control objects.
 */
export interface ComplianceRequirementForControl {
    regulation: string;
    identifier: string;
    managed: boolean;
    version: string;
    control: string;
    assigned_control_sets: string[][];
    text?: string; // Optional because some CFR requirements are empty strings.
    last_assessment?: string;
    parent_context?: string;
    status?: string | null;
    overall_status?: string;
}

// TODO: UpdateAssessmentRequest which is used in `CurrentAssessment.tsx` is using this as a request object. This will be updated in a future PR.
/**
 * A Compliance Requirement associated with a Control (matches the response DTO of the API)
 * Very similar to ComplianceRequirementForControl except assigned_control_sets are Control objects instead of Control IDs.
 */
export interface ComplianceRequirementForControlResponse {
    regulation: string;
    identifier: string;
    managed: boolean;
    version: string;
    control: string;
    assigned_control_sets: OperationalControl[][];
    text?: string; // Optional because some CFR requirements are empty strings.
    last_assessment?: string;
    parent_context?: string;
    status?: string | null;
    overall_status?: string;
}

export interface OperationalControlWithStatus {
    control: OperationalControl;
    status?: string;
}

export interface ControlSetDetails {
    control_list: OperationalControlWithStatus[];
    status?: ComplianceRequirementStatusValues;
}

/**
 * While other Compliance Requirement domain objects and DTOs show the status of a Compliance Requirement in a singular view, this contains all statuses that have been set via Control Assessments for a particular Compliance Requirement.
 */
export interface ComplianceRequirementWithAllControlStatusesResponse {
    regulation: string;
    identifier: string;
    text?: string; // Optional because some CFR requirements are empty strings.
    last_updated?: string;
    parent_context?: string;
    overall_status?: ComplianceRequirementStatusValues;
    assigned_control_sets: ControlSetDetails[];
}

// List of possible values for the Compliance Requirement "status" attribute.
export enum ComplianceRequirementStatusValues {
    MET = 'MET',
    MET_PARTIAL = 'MET_PARTIAL', // Partially met. This should only be used in the Compliance Requirements page, not the Control Details page. The reason for that is only "unmanaged" Compliance Requirements can have this status and unmanaged Compliance Requirements cannot be mapped to a Control.
    NOT_MET = 'NOT_MET',
    // The following are unique to the unmanaged requirements in FFIEC CAT.
    BASELINE = 'Baseline',
    EVOLVING = 'Evolving',
    INTERMEDIATE = 'Intermediate',
    ADVANCED = 'Advanced',
    INNOVATIVE = 'Innovative',
}

export const titleCaseRequirementStatus = (status: ComplianceRequirementStatusValues): string | undefined => {
    switch (status) {
        case ComplianceRequirementStatusValues.MET:
            return 'Met';
        case ComplianceRequirementStatusValues.MET_PARTIAL:
            return 'Partially Met';
        case ComplianceRequirementStatusValues.NOT_MET:
            return 'Not Met';
        case ComplianceRequirementStatusValues.BASELINE:
            return 'Baseline';
        case ComplianceRequirementStatusValues.EVOLVING:
            return 'Evolving';
        case ComplianceRequirementStatusValues.INTERMEDIATE:
            return 'Intermediate';
        case ComplianceRequirementStatusValues.ADVANCED:
            return 'Advanced';
        case ComplianceRequirementStatusValues.INNOVATIVE:
            return 'Innovative';
    }
};

// List of possible values for the Compliance Requirement "status" drop-down menu (<FormFieldSelect />).
export const complianceRequirementStatusOptions: OptionType[] = [
    {
        value: ComplianceRequirementStatusValues.MET,
        label: 'Met',
    },
    {
        value: ComplianceRequirementStatusValues.NOT_MET,
        label: 'Not Met',
    },
];
