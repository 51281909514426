/*
	ControlText.tsx -- Presentation for rendering the full control text, which can be a somewhat complicated JSON object (looking at you, NIST 800-53), in a somewhat aesthetically pleasing way.
*/

import { useState } from 'react';

import { Button } from 'Components/Buttons/Buttons';
import { Colors } from 'Components/Colors';
import { Text, TextVariant } from 'Components/Text/Text';
import { ControlText as ControlTextModel } from 'Models/OperationalControls';

import styles from './ControlText.module.css';

/**
 * @param alwaysShowFullText If true, the full text will always be shown. If false, the text will be truncated with an option to expand. Used when the parent component should dictate whether the text is truncated or not. Defaults to false.
 * @param textVariant Corresponds to the 'variant' prop in the Text component. Defaults to 'Header2'.
 */
export interface ControlTextProps {
    alwaysShowFullControlText?: boolean;
    color?: Colors;
    controlText: ControlTextModel[];
    textVariant?: TextVariant;
}

export const ControlText = (props: ControlTextProps) => {
    const controlTextHasNesting = props.controlText[0].children.length > 0; // Only "nested" Control text will have the ability to expand/collapse.
    const [showFullControlText, setShowFullControlText] = useState(!controlTextHasNesting); // For Controls with nested text, this determines whether the text is currently expanded or truncated.

    /**
     * @param isNested Used to add padding when a Control has a nested structure (bullets/numbers). "isNested" can be thought of as "this level" of the Control text is nested.
     */
    const renderControlText = (controlTextArray: ControlTextModel[], isNested: boolean): JSX.Element => {
        return (
            <>
                {controlTextArray.map((controlText, index) => {
                    return isNested === false ? (
                        // This renders Control text that does not have nesting, or the top level of nested Control text.
                        <div key={index} className={props.alwaysShowFullControlText || showFullControlText ? undefined : styles.controlTextEllipsis}>
                            <Text color={props.color ? props.color : 'darkGray'} noStyles={true} variant={props.textVariant ? props.textVariant : 'Header2'}>
                                {controlText.text}
                            </Text>
                            {controlText.children !== undefined && renderControlText(controlText.children, true)}
                        </div>
                    ) : (
                        // This renders nested Control text.
                        <div key={index} className={styles.childStyle}>
                            <Text color={props.color ? props.color : 'darkGray'} noStyles={true} variant={props.textVariant ? props.textVariant : 'Header2'}>
                                {controlText.text}
                            </Text>
                            {controlText.children && renderControlText(controlText.children, true)}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <>
            {renderControlText(props.controlText, false)}
            {props.controlText && !props.alwaysShowFullControlText && controlTextHasNesting && (
                <Button variant="linkText" size="sm" onClick={() => setShowFullControlText(!showFullControlText)}>
                    {!showFullControlText ? 'Show Full Description' : 'Collapse Description'}
                </Button>
            )}
        </>
    );
};
