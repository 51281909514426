import { FunctionComponent } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

export interface HorseshoeProgressIndicatorProps {
    /**
     * Fill percentage 0-100
     */
    fill: number;
    children?: React.ReactNode;
}

export const HorseshoeProgressIndicator: FunctionComponent<HorseshoeProgressIndicatorProps> = (props): JSX.Element => {
    return (
        <CircularProgressbarWithChildren
            value={props.fill}
            styles={buildStyles({
                rotation: 0.625,
                strokeLinecap: 'butt',
                pathColor: 'var(--hps-gray)',
                trailColor: 'var(--hps-dark-blue)',
            })}
            circleRatio={0.75}
        >
            {props.children}
        </CircularProgressbarWithChildren>
    );
};
