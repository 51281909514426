import { NOTIFICATIONS } from 'Config/Paths';
import { UserNotification } from 'Models/Notification';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiGet } from 'RestApiConnector';

import { NotificationsApi } from './NotificationsApi';

export class AWSNotificationsApi implements NotificationsApi {
    getNotifications = (): Promise<ResponseModel<UserNotification[]>> => {
        return restApiGet(`/${NOTIFICATIONS}`);
    };
}
