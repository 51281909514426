import { controlComparator as controlComparatorNew } from 'Helpers/Compare';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';

import { ControlText, OperationalControl } from './OperationalControls';

export interface ControlHierarchyFramework {
    control_framework: string;
    is_custom: boolean;
    enabled: boolean;
    control_groups: ControlHierarchyControlGroup[];
}

export interface ControlHierarchyControlGroup {
    control_group_id: string;
    control_group_name: string;
    is_custom: boolean;
    enabled: boolean;
    controls: OperationalControl[];
}

export interface SortableFramework {
    control_framework: string;
    is_custom: boolean;
    control_groups: SortableControlGroup[];
}

export interface SortableControlGroup {
    control_group_id: string;
    control_group_name: string;
    is_custom: boolean;
    controls?: SortableControl[]; // Controls are not part of the TPRM Control Group response object. They are retrieved independently.
}

export interface SortableControl {
    control_id: string;
    control_text: ControlText[];
    is_custom: boolean;
}

export const sortControlHierarchy = (controlHierarchy: ControlHierarchyFramework[]): void => {
    for (let framework = 0; framework < controlHierarchy.length; framework++) {
        sortControlGroups(controlHierarchy[framework].control_groups);
    }

    controlHierarchy.sort((frameworkA, frameworkB) => {
        if (frameworkA.is_custom === true && frameworkB.is_custom === false) {
            return 1;
        } else if (frameworkA.is_custom === false && frameworkB.is_custom === true) {
            return -1;
        } else {
            return frameworkA.control_framework.localeCompare(frameworkB.control_framework, undefined, { numeric: true, sensitivity: 'base' });
        }
    });
};

const sortControlGroups = (controlGroups: ControlHierarchyControlGroup[]): void => {
    for (let i = 0; i < controlGroups.length; i++) {
        if (controlGroups[i].controls) controlGroups[i].controls!.sort(controlComparatorNew);
    }

    controlGroups.sort((controlGroupA, controlGroupB) => {
        if (controlGroupA.is_custom === true && controlGroupB.is_custom === false) {
            return 1;
        } else if (controlGroupA.is_custom === false && controlGroupB.is_custom === true) {
            return -1;
        } else if (controlGroupA.is_custom === false && controlGroupB.is_custom === false) {
            return controlGroupA.control_group_id.localeCompare(controlGroupB.control_group_id, undefined, { numeric: true, sensitivity: 'base' });
        } else {
            if (controlGroupA.control_group_name && controlGroupB.control_group_name) {
                return controlGroupA.control_group_name.localeCompare(controlGroupB.control_group_name, undefined, { numeric: true, sensitivity: 'base' });
            } else if (controlGroupA.control_group_name && !controlGroupB.control_group_name) {
                return 1;
            } else if (!controlGroupA.control_group_name && controlGroupB.control_group_name) {
                return -1;
            } else {
                return 0;
            }
        }
    });
};

/**
 * DEPRECATED.
 * Sorts a hierarchy of control frameworks, control groups, and controls. Does not sort controls correctly. Currently in use only by DDQ-related code.
 * The `SortableControlGroup`s within the `controlHierarchy` list must have `controls`, or else this function will throw an exception. See `SortableControlGroup` above.
 */
export const sortGenericControlHierarchy = (controlHierarchy: SortableFramework[]): void => {
    for (let framework = 0; framework < controlHierarchy.length; framework++) {
        sortGenericControlGroups(controlHierarchy[framework].control_groups);
    }

    controlHierarchy.sort((frameworkA, frameworkB) => {
        if (frameworkA.is_custom === true && frameworkB.is_custom === false) {
            return 1;
        } else if (frameworkA.is_custom === false && frameworkB.is_custom === true) {
            return -1;
        } else {
            return frameworkA.control_framework.localeCompare(frameworkB.control_framework, undefined, { numeric: true, sensitivity: 'base' });
        }
    });
};

/**
 * DEPRECATED.
 * See `sortGenericControlHierarchy` above.
 */
const sortGenericControlGroups = (controlGroups: SortableControlGroup[]): void => {
    for (let i = 0; i < controlGroups.length; i++) {
        if (controlGroups[i].controls) controlGroups[i].controls!.sort();
    }

    controlGroups.sort((controlGroupA, controlGroupB) => {
        if (controlGroupA.is_custom === true && controlGroupB.is_custom === false) {
            return 1;
        } else if (controlGroupA.is_custom === false && controlGroupB.is_custom === true) {
            return -1;
        } else if (controlGroupA.is_custom === false && controlGroupB.is_custom === false) {
            return controlGroupA.control_group_id.localeCompare(controlGroupB.control_group_id, undefined, { numeric: true, sensitivity: 'base' });
        } else {
            if (controlGroupA.control_group_name && controlGroupB.control_group_name) {
                return controlGroupA.control_group_name.localeCompare(controlGroupB.control_group_name, undefined, { numeric: true, sensitivity: 'base' });
            } else if (controlGroupA.control_group_name && !controlGroupB.control_group_name) {
                return 1;
            } else if (!controlGroupA.control_group_name && controlGroupB.control_group_name) {
                return -1;
            } else {
                return 0;
            }
        }
    });
};

/**
 * DEPRECATED.
 * We're moving away from using multiple "views" of things (e.g., SortableControl to represent a Control), so in the future if you need to implement/change sorting for Controls, use src/Helpers/Compare.ts/controlComparator() instead.
 * It uses OperationalControl, which is our current standard representation of a Control.
 */
export const controlComparator = (controlA: SortableControl, controlB: SortableControl): number => {
    if (controlA === undefined && controlB !== undefined) {
        return 1;
    } else if (controlA !== undefined && controlB === undefined) {
        return -1;
    } else if (controlA === undefined && controlB === undefined) {
        return 0;
    }

    if (controlA.is_custom === true && controlB.is_custom === false) {
        return 1;
    } else if (controlA.is_custom === false && controlB.is_custom === true) {
        return -1;
    } else if (controlA.is_custom === false && controlB.is_custom === false) {
        return controlA.control_id.localeCompare(controlB.control_id, undefined, { numeric: true, sensitivity: 'base' });
    } else {
        const controlTextA = controlTextToString(controlA.control_text);
        const controlTextB = controlTextToString(controlB.control_text);
        if (controlTextA && controlTextB) {
            return controlTextA.localeCompare(controlTextB, undefined, { numeric: true, sensitivity: 'base' });
        } else if (controlTextA && !controlTextB) {
            return 1;
        } else if (!controlTextA && controlTextB) {
            return -1;
        } else {
            return 0;
        }
    }
};
