import { Radio } from '@mui/material';

import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';

import styles from './RadioButtonGroup.module.css';
import { FormLabel } from '../FormLabel/FormLabel';

type RadioButtonOptionTypes = string | number | Array<string | number> | undefined;
export interface RadioButtonGroupProps {
    formFieldId: string;
    formFieldLabel?: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, formFieldId: string) => void;
    tooltip?: string;
    options: RadioButtonOptionType[];
    selectedOption?: RadioButtonOptionTypes;
    disabled?: boolean;
}

export interface RadioButtonOptionType {
    value: string | number;
    label: string;
    tooltip?: string;
    [key: string]: any;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ formFieldId, formFieldLabel, handleChange, tooltip, options, selectedOption, disabled = false }) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleChange(event, formFieldId);
    };

    return (
        <>
            {formFieldLabel && <FormLabel tooltip={tooltip ? <FormFieldTooltip text={tooltip} /> : undefined}>{formFieldLabel}</FormLabel>}
            {options.map((option, index) => (
                <div className={styles.radioContainer} key={index}>
                    <div>
                        <Radio
                            disabled={disabled}
                            checked={selectedOption === option.value}
                            onChange={handleRadioChange}
                            value={option.value}
                            disableRipple
                            color="default"
                            sx={{
                                '&.Mui-checked': {
                                    color: 'var(--hps-blue)',
                                },
                            }}
                        />
                    </div>
                    <div className={styles.answerText}>
                        <Text noStyles>{option.label}</Text>
                    </div>
                    {option.tooltip && (
                        <div className={styles.toolTip}>
                            <GenericTooltip text={option.tooltip} />
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};
