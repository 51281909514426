import { Skeleton } from '@mui/material';

import { AdornedDonutChart } from 'Components/BaseCharts/AdornedDonutChart';
import { Text } from 'Components/Text/Text';

import { RiskRatingsData } from '../TPRMDashboard.helpers';
import styles from '../TPRMDashboard.module.css';

/**
 * @param count - The number to display in the callout.
 * @param countHighlight - Whether to highlight the count.
 * @param onclick - The function to call when the callout is clicked.
 */
export interface TPRMDonutChartsProps {
    chartName: 'Inherent Risk' | 'Control Effectiveness' | 'Residual Risk';
    chartData?: RiskRatingsData;
    onClick: (item: string) => void;
}

export const TPRMDonutCharts = (props: TPRMDonutChartsProps): JSX.Element => {
    return (
        /** This top-level div may seem pointless, but the content overflows out of its parent if a fragment is used instead. */
        <div>
            {props.chartData ? (
                <AdornedDonutChart name={props.chartName} data={props.chartData.data} adornment={{ body: { value: props.chartData.average, fontSize: 60 }, subtitle: { value: props.chartData.averageLabel, fontSize: 20 } }} onChartClick={props.onClick} />
            ) : (
                <div className={styles.services}>
                    <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                </div>
            )}
            <div className={styles.services}>
                {props.chartData ? (
                    <Text variant="Header4" color="lightGray">
                        {props.chartData.numberOfScoredServices} of {props.chartData.numberOfServices} Services {props.chartName === 'Control Effectiveness' ? 'Assessed' : 'Scored'}
                    </Text>
                ) : (
                    <Skeleton>
                        <Text variant="Header4" color="lightGray">
                            --- of --- Services
                        </Text>
                    </Skeleton>
                )}
            </div>
        </div>
    );
};
