import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { IssuesApi } from 'Api/Issues/IssuesApi';
import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { CSSColors } from 'Components/Colors';
import { ChangeEventType, FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import { Text } from 'Components/Text/Text';
import { DashboardType, DateOptionSelectOptions, DefaultDateRangeSelectOptions, isValidDateOption } from 'Models/Dashboards';
import { IssuePriority, IssuePriorityFilter, IssuePriorityFilterOptions, IssueResponse, IssueStatus, IssuesExceptionsModule, isValidIssuePriorityFilter } from 'Models/Issues';
import { ThirdPartyResponse } from 'Models/TPRM';
import { CustomDateRange, CustomDateRangeModal, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';
import { countClosedIssuesByMonth, countExceptionsOrIssuesByMonth, countTotalIssuesByMonth, getClosedIssuesPerMonth } from 'Pages/Dashboards/OperationalControlsDashboard/OperationalControlsDashboard.helpers';
import { getMonths } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.helpers';
import { getDatesFromPreset } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.hooks';

import styles from './DashboardsHistoricalIssues.module.css';
import { ClosedIssueModalDisplayType, ClosedIssuesDisplayModal } from './HistoricalIssuesDisplayModal/HistoricalIssuesDisplayModal';

export interface DashboardClosedIssuesProps {
    issuesApi: IssuesApi;
    dashboardType: DashboardType;
    thirdParties?: ThirdPartyResponse[];
    thirdPartyError?: string;
}

enum Modals {
    NONE,
    DISPLAY_CLOSED_ISSUES, // TODO: rename this. It's not just for closed issues.
    CUSTOM_DATE,
}

/**
 * Renders a bar-and-line chart that displays, over a given time period, and grouped by month:
 * * The number of issues opened
 * * The number of issues closed
 * * The total number of issues in the system
 *
 * TODO: rename this. It's not just for closed issues.
 */
export const DashboardClosedIssues = (props: DashboardClosedIssuesProps): JSX.Element => {
    const [dashboardIssues, setDashboardIssues] = useState<IssueResponse[]>();
    const [closedIssues, setClosedIssues] = useState<number[]>();
    const [totalOpenIssues, setTotalOpenIssues] = useState<number[]>();
    const [openedIssues, setOpenedIssues] = useState<number[]>();
    const [issuesError, setIssuesError] = useState<string>();
    const [monthLabel, setMonthLabel] = useState<string[]>();
    const [clickedIssues, setClickedIssue] = useState<IssueResponse[]>();
    const [selectDateOption, setSelectDateOption] = useState<DateOptionSelectOptions>(DateOptionSelectOptions.PREVIOUS_QUARTER);
    const [selectedDates, setSelectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.PREVIOUS_QUARTER));
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [clickedDate, setClickedDate] = useState<string>();
    const [modalDisplayType, setModalDisplayType] = useState<ClosedIssueModalDisplayType>();
    const [issueFilterType, setIssueFilterType] = useState<IssuePriorityFilter>(IssuePriorityFilter.ALL);

    const moderateColor = CSSColors.YELLOW;
    const lineColor = CSSColors.LIGHT_BLUE;
    const gray = CSSColors.GRAY;

    useEffect(() => {
        const getIssues = async () => {
            try {
                const issuesResponse = await props.issuesApi.getAllIssues();

                if (props.dashboardType === DashboardType.OPERATIONAL_CONTROLS) {
                    const allOCIssues = issuesResponse.data.filter((issue) => issue.type === IssuesExceptionsModule.CONTROLS && issue.status !== IssueStatus.DRAFT_OPEN);
                    setDashboardIssues(allOCIssues);
                } else {
                    const allTPRMIssues = issuesResponse.data.filter((issue) => issue.type === IssuesExceptionsModule.TPRM && issue.status !== IssueStatus.DRAFT_OPEN);
                    setDashboardIssues(allTPRMIssues);
                }
            } catch (err) {
                setIssuesError(err.message);
            }
        };
        getIssues();
    }, [props.dashboardType, props.issuesApi]);

    useEffect(() => {
        const issuesPriorityFilter = (issue: IssueResponse): boolean => {
            if (issueFilterType === IssuePriorityFilter.ALL) {
                return true;
            } else if (issueFilterType === IssuePriorityFilter.HIGH) {
                return issue.priority === IssuePriority.HIGH;
            } else if (issueFilterType === IssuePriorityFilter.MEDIUM) {
                return issue.priority === IssuePriority.MEDIUM;
            } else if (issueFilterType === IssuePriorityFilter.LOW) {
                return issue.priority === IssuePriority.LOW;
            } else {
                return true;
            }
        };
        if (dashboardIssues) {
            const dashboardIssuesByPriority = dashboardIssues.filter(issuesPriorityFilter);
            const allClosedIssues = dashboardIssuesByPriority.filter((issue) => issue.status === IssueStatus.CLOSED);

            const months = getMonths(selectedDates.start, selectedDates.end);

            const openedIssues = countExceptionsOrIssuesByMonth(dashboardIssuesByPriority, months);
            const issuesClosedByMonth = countClosedIssuesByMonth(allClosedIssues, months);
            const totalIssues = countTotalIssuesByMonth(dashboardIssuesByPriority, months);

            setClosedIssues(issuesClosedByMonth);
            setOpenedIssues(openedIssues);
            setMonthLabel(months);
            setTotalOpenIssues(totalIssues);
        }
    }, [dashboardIssues, issueFilterType, selectedDates.end, selectedDates.start]);

    const handleChartClick = (item: string) => {
        const clickedItem = item.split(' | ');
        const currentFilteredIssues = dashboardIssues?.filter((issue) => {
            if (issueFilterType === IssuePriorityFilter.ALL) {
                return true;
            } else if (issueFilterType === IssuePriorityFilter.HIGH) {
                return issue.priority === IssuePriority.HIGH;
            } else if (issueFilterType === IssuePriorityFilter.MEDIUM) {
                return issue.priority === IssuePriority.MEDIUM;
            } else if (issueFilterType === IssuePriorityFilter.LOW) {
                return issue.priority === IssuePriority.LOW;
            } else {
                return true;
            }
        });
        if (clickedItem[1] === 'Total Closed' && currentFilteredIssues) {
            const allClosedIssues = currentFilteredIssues.filter((issue) => issue.status === IssueStatus.CLOSED);
            setClickedIssue(getClosedIssuesPerMonth(allClosedIssues, clickedItem[0]));
            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_CLOSED_ISSUES);
            setModalDisplayType(ClosedIssueModalDisplayType.CLOSED);
        } else if (clickedItem[1] === 'Total Opened' && currentFilteredIssues) {
            const issues = currentFilteredIssues.filter((issue) => moment(issue.created_timestamp).format('MMM YY') === clickedItem[0]);
            setClickedIssue(issues);
            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_CLOSED_ISSUES);
            setModalDisplayType(ClosedIssueModalDisplayType.OPEN);
        }
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                setSelectDateOption(value);
                setSelectedDates(getDatesFromPreset(value));
            }
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setSelectedDates({ start: customDateRange.startDate, end: customDateRange.endDate });
        setSelectDateOption(DateOptionSelectOptions.CUSTOM);
    };

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (isValidIssuePriorityFilter(value)) {
            setIssueFilterType(value as IssuePriorityFilter);
        }
    };

    if (issuesError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Issue History
                    </Text>
                </div>
                <Text color="white" variant="Header4">
                    {issuesError}
                </Text>
            </>
        );
    }

    if (props.thirdPartyError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Issue History
                    </Text>
                </div>
                <Text color="white" variant="Header4">
                    {props.thirdPartyError}
                </Text>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="pastDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_CLOSED_ISSUES && clickedDate && clickedIssues && modalDisplayType !== undefined && <ClosedIssuesDisplayModal selectedIssue={clickedIssues} date={clickedDate} hideModal={() => setModalState(Modals.NONE)} modalType={modalDisplayType} dashboardType={props.dashboardType} thirdParties={props.thirdParties} />}
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Issue History
                </Text>
                <div className={styles.filters}>
                    <FormFieldSelectDark formFieldId="issuePriority" formFieldLabel="Priority..." options={IssuePriorityFilterOptions} invalidMessage="A priority must be provided." selectedOption={issueFilterType} handleChange={handleRiskTypeChange} />
                    <FormFieldDateOptionSelect formFieldId="date" options={DefaultDateRangeSelectOptions} selectedOption={selectDateOption} handleChange={handleDateFilterChange} />
                </div>
            </div>
            {closedIssues && monthLabel && totalOpenIssues && openedIssues ? (
                <StackedBarAndLineChart
                    onChartClick={handleChartClick}
                    xAxisLabels={monthLabel}
                    line={{ name: 'Total Issues', color: moderateColor, data: totalOpenIssues }}
                    bars={[
                        { name: 'Total Opened', color: lineColor, data: openedIssues },
                        { name: 'Total Closed', color: gray, data: closedIssues },
                    ]}
                    yAxisInfo={[
                        { name: 'TOTAL CLOSED/OPENED', position: 'left', minInterval: 1 },
                        { name: 'TOTAL ISSUES', position: 'right', minInterval: 1 },
                    ]}
                    group={true}
                />
            ) : (
                <Skeleton variant="rounded" width={'100%'} height={300} />
            )}
        </>
    );
};
