import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useRef } from 'react';

import { ChartDataItem, chartFont } from './charts.common';

export interface AdornedDonutChartDataAdornment {
    body: { value: string; fontSize: number };
    subtitle: { value: string; fontSize: number };
}

export interface AdornedDonutChartProps {
    name: string;
    data: ChartDataItem[];
    adornment: AdornedDonutChartDataAdornment;
    onChartClick: (item: string) => void;
}

export const AdornedDonutChart = (props: AdornedDonutChartProps): JSX.Element => {
    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);

    const chartRef = useRef() as React.MutableRefObject<ReactECharts>;

    // This ensures that the chart does not re-render when a new onclick is created in the prop
    useEffect(() => {
        const instance = chartRef.current.getEchartsInstance();
        instance.on('click', (params) => {
            props.onChartClick(params.name);
        });
    }, [props]);

    const getOption = (info: Omit<AdornedDonutChartProps, 'onChartClick'>, primaryFont: string): EChartsOption => ({
        aria: {
            show: true,
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: false,
        },
        textStyle: {
            fontFamily: primaryFont,
        },
        grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        },
        series: [
            {
                name: info.name,
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    focus: 'item',
                },
                data: info.data.map(({ name, value, color }) => ({
                    name,
                    value,
                    itemStyle: { color },
                })),
            },
        ],
        graphic: {
            elements: [
                {
                    type: 'group',
                    left: 'center',
                    top: 'middle',
                    children: [
                        {
                            type: 'text',
                            bottom: 0,
                            left: 'center',
                            style: {
                                text: info.adornment.body.value,
                                fontSize: info.adornment.body.fontSize,
                                fontWeight: 'bold',
                                fill: 'white',
                                fontFamily: primaryFont,
                            },
                        },
                        {
                            type: 'text',
                            top: 0,
                            left: 'center',
                            style: {
                                text: info.adornment.subtitle.value,
                                fontSize: info.adornment.subtitle.fontSize,
                                fontWeight: 'bold',
                                fill: 'white',
                                fontFamily: primaryFont,
                            },
                        },
                    ],
                },
            ],
        },
    });

    return (
        <ReactECharts
            option={getOption(props, primaryFont)}
            style={{
                height: '100%',
                width: '100%',
                minHeight: '250px',
            }}
            ref={chartRef}
        />
    );
};
