import { Chip } from '@mui/material';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { DisplayableTag } from 'Models/Tags';

export interface TagProps {
    tag: DisplayableTag; // TODO: show category title on hover.
}

export const Tag = (props: TagProps): JSX.Element => {
    const isColorTooDarkForBlackText = (color: string): boolean => {
        const hex = color.replace('#', '');
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        // YIQ is a calculation to represent the intensity of a given color. It shows the overall brightness. 128 is halfway between the max and minimum value.
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    };
    return (
        <OverlayTrigger placement="auto" delay={{ show: 100, hide: 200 }} overlay={<Tooltip id={`Category for ${props.tag.title}`}>{`Category: ${props.tag.category_title}`}</Tooltip>}>
            <Chip label={props.tag.title} sx={{ lineHeight: '1.5em', backgroundColor: props.tag.category_color, color: isColorTooDarkForBlackText(props.tag.category_color) ? '#FFF' : '#000' }} />
        </OverlayTrigger>
    );
};
