/*
    FormFieldTooltip.tsx -- A mouse-over "help" popup for providing explanations for form fields. For general-purpose mouse-over popups, use <GenericTooltip />.
*/

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './Tooltips.module.css';

interface FormFieldTooltipProps {
    text: string | JSX.Element;
}

export const FormFieldTooltip = (props: FormFieldTooltipProps) => {
    const logoUrl = new URL('/i_toolTip.svg', import.meta.url).href;

    return (
        <div className={styles.positioning}>
            <OverlayTrigger placement="auto" delay={{ show: 100, hide: 200 }} overlay={<Tooltip id="information">{props.text}</Tooltip>}>
                <img src={logoUrl} width={15} height={15} alt="tooltip" />
            </OverlayTrigger>
        </div>
    );
};
