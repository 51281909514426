import { getNotificationDisplayName } from 'Helpers/NotificationFormatter/NotificationFormatter';
import { ComplianceRequirementForControlResponse } from 'Models/ComplianceRequirements';
import { UserNotification } from 'Models/Notification';
import { ControlGroupResponse, OperationalControl } from 'Models/OperationalControls';

import { getHumanReadableControlIdFromControl } from './ControlFormatter/ControlFormatter';

/**
 * Checks if both itemA and itemB have values, and if so, executes the passed in comparator.
 *
 * Otherwise returns 1 if itemA has a value and itemB does not.
 * -1 if itemB has a value and itemA does not.
 * 0 if both itemA and itemB are undefined.
 *
 * @param itemA A potentially undefined item for comparison
 * @param itemB A potentially undefined item for comparison
 * @param comparator A comparison function that is run if both itemA and itemB are undefined
 * @returns number
 */
export const undefinedComparator = <ItemType>(itemA: ItemType | undefined, itemB: ItemType | undefined, comparator: (itemA: ItemType, itemB: ItemType) => number): number => {
    if (itemA !== undefined && itemB !== undefined) {
        return comparator(itemA, itemB);
    } else if (itemA !== undefined && itemB === undefined) {
        return 1;
    } else if (itemA === undefined && itemB !== undefined) {
        return -1;
    } else {
        return 0;
    }
};

/**
 * Checks if both arrayA and arrayB contain values, and if so, executes the passed in comparator.
 *
 * Otherwise returns 1 if arrayA has values and arrayB does not.
 * -1 if arrayB has values and arrayA does not.
 * 0 if both arrayA and arrayB do not have values.
 *
 * @param arrayA A potentially empty list for comparison
 * @param arrayB A potentially empty list for comparison
 * @param comparator A comparison function that is run if both arrayA and arrayB contain values
 * @returns number
 */
export const emptyArrayComparator = <ItemType>(arrayA: ItemType[], arrayB: ItemType[], comparator: (arrayA: ItemType[], arrayB: ItemType[]) => number): number => {
    if (arrayA.length > 0 && arrayB.length <= 0) {
        return 1;
    } else if (arrayA.length <= 0 && arrayB.length > 0) {
        return -1;
    } else if (arrayA.length <= 0 && arrayB.length <= 0) {
        return 0;
    }

    return comparator(arrayA, arrayB);
};

/**
 * Used for sorting Controls.
 */
export const controlComparator = (controlA: OperationalControl, controlB: OperationalControl): number => {
    if (controlA === undefined && controlB !== undefined) {
        return 1;
    } else if (controlA !== undefined && controlB === undefined) {
        return -1;
    } else if (controlA === undefined && controlB === undefined) {
        return 0;
    }

    const controlADisplayName = getHumanReadableControlIdFromControl(controlA);
    const controlBDisplayName = getHumanReadableControlIdFromControl(controlB);

    if (controlA.metadata.is_custom === true && controlB.metadata.is_custom === false) {
        return 1;
    } else if (controlA.metadata.is_custom === false && controlB.metadata.is_custom === true) {
        return -1;
    } else if (controlA.metadata.is_custom === false && controlB.metadata.is_custom === false) {
        return controlADisplayName.localeCompare(controlBDisplayName, undefined, { numeric: true, sensitivity: 'base' });
    } else {
        return controlA.metadata.control_name!.localeCompare(controlB.metadata.control_name!, undefined, { numeric: true, sensitivity: 'base' });
    }
};

export const controlGroupComparator = (groupA: ControlGroupResponse, groupB: ControlGroupResponse): number => {
    if (groupA.is_custom === true && groupB.is_custom === false) {
        // Standard Groups come before Custom Groups.
        return 1;
    } else if (groupA.is_custom === false && groupB.is_custom === true) {
        // Custom Groups come after Standard Groups.
        return -1;
    } else if (groupA.is_custom === false && groupB.is_custom === false) {
        // If both are Standard Groups, sort by ID.
        return groupA.control_group_id.localeCompare(groupB.control_group_id, undefined, { numeric: true, sensitivity: 'base' });
    } else {
        // If both are Custom Groups, sort by name.
        return groupA.control_group_name.localeCompare(groupB.control_group_name, undefined, { numeric: true, sensitivity: 'base' });
    }
};

export const sortComplianceRequirementForControl = (requirements: ComplianceRequirementForControlResponse[]): ComplianceRequirementForControlResponse[] => {
    return [...requirements].sort((complianceReqA, complianceReqB) => {
        if (complianceReqA.regulation > complianceReqB.regulation) {
            return 1;
        } else if (complianceReqA.regulation < complianceReqB.regulation) {
            return -1;
        } else {
            // If the Regulations are the same, sort by Requirement ID.
            if (complianceReqA.identifier > complianceReqB.identifier) {
                return 1;
            } else if (complianceReqA.identifier < complianceReqB.identifier) {
                return -1;
            } else {
                return 0;
            }
        }
    });
};

/**
 * Sort Notifications by due date first, then by display name if the due dates are the same.
 */
export const sortNotifications = (notifications: UserNotification[]): UserNotification[] => {
    return [...notifications].sort((notificationA, notificationB) => {
        if (notificationA.due_date > notificationB.due_date) {
            return 1;
        } else if (notificationB.due_date > notificationA.due_date) {
            return -1;
        } else {
            const notificationADisplayName = getNotificationDisplayName(notificationA);
            const notificationBDisplayName = getNotificationDisplayName(notificationB);
            if (notificationADisplayName > notificationBDisplayName) {
                return 1;
            } else {
                return -1;
            }
        }
    });
};
