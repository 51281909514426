/**
 * Removes disabled Frameworks/Groups/Controls from a generic "Control Hierarchy" structure. Can be used by any object as long as the hierarchy is maintained (control_frameworks[] --> control_groups[] --> controls[]) and the expected properties exist at each level.
 * Currently used by the `useControlMappingItems` hook.
 */

interface GenericFramework {
    enabled: boolean;
    control_groups: GenericGroup[];
}
interface GenericGroup {
    enabled: boolean;
    controls: GenericControl[];
}
interface GenericControl {
    configuration: { enabled: boolean };
}

export const discardDisabledControls = (controlHierarchy: GenericFramework[]): any => {
    let frameworkIndex = controlHierarchy.length;
    while (frameworkIndex--) {
        if (controlHierarchy[frameworkIndex].enabled === false) {
            controlHierarchy.splice(frameworkIndex, 1);
        } else {
            let groupIndex = controlHierarchy[frameworkIndex].control_groups.length;
            while (groupIndex--) {
                if (controlHierarchy[frameworkIndex].control_groups[groupIndex].enabled === false) {
                    controlHierarchy[frameworkIndex].control_groups.splice(groupIndex, 1);
                } else {
                    let controlIndex = controlHierarchy[frameworkIndex].control_groups[groupIndex].controls.length;
                    while (controlIndex--) {
                        if (controlHierarchy[frameworkIndex].control_groups[groupIndex].controls[controlIndex].configuration.enabled === false) {
                            controlHierarchy[frameworkIndex].control_groups[groupIndex].controls.splice(controlIndex, 1);
                        }
                    }
                }
            }
        }
    }
    return controlHierarchy;
};
