import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getIssueDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { DashboardType } from 'Models/Dashboards';
import { IssueResponse, IssuesExceptionsModule } from 'Models/Issues';
import { ThirdPartyIdToNameMap, ThirdPartyResponse } from 'Models/TPRM';

import styles from './TrackingIssuesDisplayModal.module.css';

export interface TrackingIssuesDisplayModalProps {
    hideModal: () => void;
    selectedIssue: IssueResponse[];
    date: string;
    dashboardType: DashboardType;
    thirdParties?: ThirdPartyResponse[];
}

/**
 * Displays a list of issues that are coming due in a specific month.
 */
export const TrackingIssuesDisplayModal = (props: TrackingIssuesDisplayModalProps): JSX.Element => {
    const thirdPartyIdToNameMap: ThirdPartyIdToNameMap = new Map(props.thirdParties?.map((thirdParty: ThirdPartyResponse) => [thirdParty.id, thirdParty.name]));
    const sortIssuesByDateType = (issueA: IssueResponse, issueB: IssueResponse): number => {
        if (issueA.due_date > issueB.due_date) {
            return 1;
        } else if (issueA.due_date < issueB.due_date) {
            return -1;
        } else {
            return issueA.title.localeCompare(issueB.title, undefined, { numeric: true, sensitivity: 'base' });
        }
    };

    const openIssuesListing = props.selectedIssue.sort(sortIssuesByDateType).map((issue) => (
        <div className={styles.detailsContainer} key={issue.created_timestamp}>
            {props.dashboardType === DashboardType.THIRD_PARTY && issue.type === IssuesExceptionsModule.TPRM && thirdPartyIdToNameMap.size > 0 && (
                <div className={styles.detailsThirdPartyName}>
                    <Text color="darkGray" noStyles={true}>
                        {thirdPartyIdToNameMap.get(issue.impacted_vendor)}
                    </Text>
                </div>
            )}
            <Link to={getIssueDetailsUrl(issue.id, issue.type)} size="sm" openInNewTab>
                {issue.title}
            </Link>
            <div className={styles.detailsDateContainer}>
                <Text color="darkGray" noStyles={true}>
                    {iso8601ToUsDateShort(issue.due_date)}
                </Text>
            </div>
        </div>
    ));

    return <CollapsibleModal displayItems={openIssuesListing} headerText={{ text: 'Open Issues', secondaryText: `${props.date}` }} hideModal={props.hideModal} />;
};
