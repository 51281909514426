import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel as BSCarousel } from 'react-bootstrap';

import styles from './Carousel.module.css';

export interface CarouselProps {
    children: JSX.Element[];
    showControls?: boolean;
    showIndicators?: boolean;
}

export const Carousel = (props: CarouselProps): JSX.Element => {
    return (
        <BSCarousel className={styles.carousel} controls={props.showControls !== undefined ? props.showControls : false} interval={null} indicators={props.showIndicators !== undefined ? props.showIndicators : true} nextIcon={<FontAwesomeIcon icon={faChevronCircleRight} size="xl" />} prevIcon={<FontAwesomeIcon icon={faChevronCircleLeft} size="xl" />}>
            {props.children}
        </BSCarousel>
    );
};
