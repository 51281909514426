import { Checkbox } from '@mui/material';

import { RiskRating } from 'Models/TPRM';

import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export enum CheckedStatus {
    UNCHECKED,
    CHECKED,
    INDETERMINATE,
}

export type CheckedRiskRatingHandler = (checked: boolean, riskRating: RiskRating) => void;

export interface CheckboxesProps {
    low: CheckedStatus;
    lowMod: CheckedStatus;
    mod: CheckedStatus;
    modHigh: CheckedStatus;
    high: CheckedStatus;
    disabled?: boolean;
}

export interface CheckedRiskRatingHandlerProps {
    onChange: CheckedRiskRatingHandler;
}

export const Checkboxes = (props: CheckboxesProps & CheckedRiskRatingHandlerProps) => {
    return (
        <>
            <div className={styles.checkbox}>
                <Checkbox disabled={props.disabled} indeterminate={props.low === CheckedStatus.INDETERMINATE} checked={props.low === CheckedStatus.CHECKED} color="default" onChange={(event: React.FormEvent<HTMLInputElement>) => props.onChange(event.currentTarget.checked, RiskRating.LOW)} />
            </div>
            <div className={styles.checkbox}>
                <Checkbox disabled={props.disabled} indeterminate={props.lowMod === CheckedStatus.INDETERMINATE} checked={props.lowMod === CheckedStatus.CHECKED} color="default" onChange={(event: React.FormEvent<HTMLInputElement>) => props.onChange(event.currentTarget.checked, RiskRating.LOW_MODERATE)} />
            </div>
            <div className={styles.checkbox}>
                <Checkbox disabled={props.disabled} indeterminate={props.mod === CheckedStatus.INDETERMINATE} checked={props.mod === CheckedStatus.CHECKED} color="default" onChange={(event: React.FormEvent<HTMLInputElement>) => props.onChange(event.currentTarget.checked, RiskRating.MODERATE)} />
            </div>
            <div className={styles.checkbox}>
                <Checkbox disabled={props.disabled} indeterminate={props.modHigh === CheckedStatus.INDETERMINATE} checked={props.modHigh === CheckedStatus.CHECKED} color="default" onChange={(event: React.FormEvent<HTMLInputElement>) => props.onChange(event.currentTarget.checked, RiskRating.MODERATE_HIGH)} />
            </div>
            <div className={styles.checkbox}>
                <Checkbox disabled={props.disabled} indeterminate={props.high === CheckedStatus.INDETERMINATE} checked={props.high === CheckedStatus.CHECKED} color="default" onChange={(event: React.FormEvent<HTMLInputElement>) => props.onChange(event.currentTarget.checked, RiskRating.HIGH)} />
            </div>
        </>
    );
};
