import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ComplianceRequirementForControl, complianceRequirementStatusOptions } from 'Models/ComplianceRequirements';
import { Effectiveness, EffectivenessSelectOptions } from 'Models/OperationalControls';

import styles from '../ConductAssessment.module.css';

export interface PreviousAssessmentProps {
    copyToCurrent: () => void;
    approvedTimestamp?: string;
    ownerComment?: string;
    complianceRequirements: ComplianceRequirementForControl[];
    effectiveness?: Effectiveness;
    reviewerComment?: string;
    formDisabled: boolean;
    isSaving: boolean;
    isSubmitting: boolean;
}

export const PreviousAssessment = (props: PreviousAssessmentProps): JSX.Element => {
    return (
        <div className={styles.fullWidth}>
            <Text variant="Header2">Previous Control Assessment</Text>
            <ApprovedTimestamp timestamp={props.approvedTimestamp} />
            <hr />
            <OwnerComment comment={props.ownerComment} />
            <ComplianceRequirements complianceRequirements={props.complianceRequirements} />
            <EffectivenessDropdown effectiveness={props.effectiveness} />
            {props.reviewerComment !== undefined && <ReviewerComment comment={props.reviewerComment} />}
            <div className={styles.buttonContainer}>
                <div className={styles.copyButtonTextMargin}>
                    <Text>Copy applicable comments, compliance requirement statuses, and effectiveness rating from the previous assessment to the current assessment.</Text>
                </div>
                <CopyToCurrentButton disabled={props.formDisabled || props.isSaving || props.isSubmitting} handleClick={props.copyToCurrent} />
            </div>
        </div>
    );
};

interface ApprovedTimestampProps {
    timestamp?: string;
}
const ApprovedTimestamp = (props: ApprovedTimestampProps): JSX.Element => {
    if (props.timestamp !== undefined) {
        return <Text variant="Text3">Approved: {iso8601ToUsTimestampLong(props.timestamp)}</Text>;
    } else {
        return <Text variant="Text3">Approved: N/A</Text>;
    }
};

interface OwnerCommentProps {
    comment?: string;
}
const OwnerComment = (props: OwnerCommentProps): JSX.Element => {
    return (
        <div className={styles.fieldContainer}>
            <FormFieldTextArea formFieldId="ownerComment" formFieldLabel="Owner Comment" disabled={true} value={props.comment} />
        </div>
    );
};

interface ComplianceRequirementsProps {
    complianceRequirements?: ComplianceRequirementForControl[];
}
const ComplianceRequirements = (props: ComplianceRequirementsProps): JSX.Element | null => {
    if (!props.complianceRequirements || props.complianceRequirements.length === 0) {
        return null;
    }
    return (
        <Fragment>
            <Text>Status of requirements at the time of the previous control assessment.</Text>
            <div className={styles.alignVertical}>
                {props.complianceRequirements?.map((item: ComplianceRequirementForControl, index) => (
                    <div className={styles.complianceRequirementsContainer} key={item.identifier}>
                        <div className={styles.regReqIdPadding}>
                            <Text variant="Text4">{`${item.regulation} ${item.identifier}`}</Text>
                        </div>
                        <div className={styles.regReqTextPadding}>
                            <Text variant="Text4">{item.text}</Text>
                            <div className={styles.fieldMaxWidthRequirement}>
                                <FormFieldSelect disabled={true} formFieldId={`previousAssessment${String(index)}`} formFieldLabel="Requirement Status" options={complianceRequirementStatusOptions} selectedOption={item.status} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

interface EffectivenessDropdownProps {
    effectiveness?: Effectiveness;
}
const EffectivenessDropdown = (props: EffectivenessDropdownProps): JSX.Element => {
    return (
        <div className={`${styles.fieldContainer} ${styles.fieldMaxWidthEffectiveness}`}>
            <FormFieldSelect disabled={true} formFieldId="previousAssessmentEffectiveness" formFieldLabel="Control Effectiveness" options={EffectivenessSelectOptions} selectedOption={Number(props.effectiveness)} />
        </div>
    );
};

interface ReviewerCommentProps {
    comment?: string;
}
const ReviewerComment = (props: ReviewerCommentProps): JSX.Element => {
    return (
        <div className={styles.fieldContainer}>
            <FormFieldTextArea formFieldId="reviewerComment" formFieldLabel="Reviewer Comment" disabled={true} value={props.comment} />
        </div>
    );
};

interface CopyToCurrentButtonProps {
    handleClick: () => void;
    disabled: boolean;
}
const CopyToCurrentButton = (props: CopyToCurrentButtonProps): JSX.Element | null => {
    return (
        <Button variant="secondary" disabled={props.disabled} onClick={props.handleClick} fontAwesomeImage={faCopy}>
            Copy to Current
        </Button>
    );
};
