import { useCachedData } from 'Components/Context/CachedDataContext';
import { Text } from 'Components/Text/Text';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { OperationalControl } from 'Models/OperationalControls';

export interface ControlRolesDashletProps {
    detailedControlResponse: OperationalControl;
}

export const ControlRolesDashlet = (props: ControlRolesDashletProps): JSX.Element => {
    const cachedData = useCachedData();

    return (
        <>
            <Text variant="Text4" color="darkGray" toUppercase noStyles>
                Owner:
            </Text>
            <Text color="white" variant="Text2">
                {props.detailedControlResponse.configuration.review_configuration?.owner_subject ? getUserNameFromSubject(props.detailedControlResponse.configuration.review_configuration.owner_subject, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) : '-'}
            </Text>
            <Text variant="Text4" color="darkGray" toUppercase noStyles>
                Reviewer:
            </Text>
            <Text color="white" variant="Text2">
                {props.detailedControlResponse.configuration.review_configuration?.reviewer_subject ? getUserNameFromSubject(props.detailedControlResponse.configuration.review_configuration.reviewer_subject, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) : '-'}
            </Text>
            <Text variant="Text4" color="darkGray" toUppercase noStyles>
                Delegates:
            </Text>
            <Text color="white" variant="Text2">
                {props.detailedControlResponse.configuration.review_configuration?.delegate_subjects && props.detailedControlResponse.configuration.review_configuration.delegate_subjects.length > 0 ? props.detailedControlResponse.configuration.review_configuration.delegate_subjects.map((subject) => getUserNameFromSubject(subject, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)).join(', ') : '-'}
            </Text>
        </>
    );
};
