import { useCallback, useEffect, useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { AlertResponse } from 'Models/Alerts';
import { LimitResponse } from 'Models/Limits';
import { ManageLimitsTab, ManageLimitsTabProps } from 'Pages/AlertsDashboard/ManageLimitsTab/ManageLimitsTab';

import { AlertsSummaryTab, AlertsSummaryTabProps } from './AlertsSummaryTab/AlertsSummaryTab';

export interface AlertsDashboardProps {
    alertsLimitsApi: AlertsLimitsApi;
}

export const AlertsDashboard = (props: AlertsDashboardProps) => {
    const [alerts, setAlerts] = useState<AlertResponse[]>();
    const [limits, setLimits] = useState<LimitResponse[]>();

    useEffect(() => {
        const getAlerts = async (): Promise<void> => {
            try {
                const alertResponse = await props.alertsLimitsApi.getAllAlerts();
                setAlerts(alertResponse.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getAlerts();
    }, [props.alertsLimitsApi]);

    const getLimits = useCallback(async (): Promise<void> => {
        try {
            const limitResponse = await props.alertsLimitsApi.getAllLimits();
            setLimits(limitResponse.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.alertsLimitsApi]);

    useEffect(() => {
        getLimits();
    }, [getLimits]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    if (alerts && limits) {
        const manageLimitsTabProps: ManageLimitsTabProps = {
            limitResponseList: limits,
            alertsLimitsApi: props.alertsLimitsApi,
            updateLimitListing: getLimits,
        };

        const alertSummaryTabProps: AlertsSummaryTabProps = {
            alertResponseList: alerts,
        };

        return (
            <PageLayoutDefault
                headerTitle="Alerts and Limits"
                body={[
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab="alertsSummary" removePadding>
                                <Tab eventKey="alertsSummary" title="Generated Alerts">
                                    <AlertsSummaryTab {...alertSummaryTabProps} />
                                </Tab>
                                <Tab eventKey="manageLimits" title="Manage Limits">
                                    <ManageLimitsTab {...manageLimitsTabProps} />
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        );
    }

    return <Placeholder />;
};
