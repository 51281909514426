import { Text } from 'Components/Text/Text';
import { getUserNameFromUser } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

import styles from './UserOption.module.css';

export interface UserOptionProps {
    user: UserResponse;
}

export const UserOption = (props: UserOptionProps) => {
    return (
        <div className={styles.container}>
            <Text variant="Text2" noStyles>
                {getUserNameFromUser(props.user)}
            </Text>
            <Text color="darkGray" noStyles>
                {props.user.department}
            </Text>
            <Text color="darkGray" noStyles>
                {props.user.email_address}
            </Text>
        </div>
    );
};
