import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs } from '@mui/material';
import { ReactNode } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';

import styles from './Breadcrumb.module.css';

export interface BreadcrumbProps {
    textColor?: 'white' | 'blue';
    children: ReactNode;
}
/**
 * A breadcrumb for page nesting navigation.
 * For consistent styling, use the BreadCrumbItem from the same file.
 */
export const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
    const textColor = props.textColor ? props.textColor : 'white';

    return (
        <Breadcrumbs
            separator={
                <div className={textColor === 'blue' ? styles.separatorBlue : styles.separatorWhite}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            }
            sx={{
                '& .MuiBreadcrumbs-li ': {
                    maxWidth: '100%',
                    display: 'flex',
                    wordBreak: 'break-word',
                },
            }}
        >
            {props.children}
        </Breadcrumbs>
    );
};

export interface BreadcrumbTextProps {
    children: ReactNode;
}

/**
 * A breadcrumb item for consistent styling on non-linked text.
 */
export const BreadcrumbText = (props: BreadcrumbTextProps): JSX.Element => {
    return (
        <Text variant="Text2" color="darkGray" noStyles>
            {props.children}
        </Text>
    );
};

export interface BreadcrumbLinkProps {
    link: string;
    children: string;
}

/**
 * A breadcrumb item for consistent styling on linked text.
 * @param {string} link The location in the webpage the user will navigate to on click.
 */
export const BreadcrumbLink = (props: BreadcrumbLinkProps): JSX.Element => {
    return (
        <Link to={props.link} size="lg">
            {props.children}
        </Link>
    );
};
