import { faBan, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { getUserNameFromSubject, getUserNameFromUser } from 'Helpers/UserUtils';
import { ManageUsersSortFilterOptions, Status, UserResponse } from 'Models/User';

/**
 * @param users This is not eligible for the cachedData.users context because its parent (ManageUsers) filters the users list before passing it as a prop.
 */
export interface ManageUsersTableViewProps {
    users: UserResponse[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    currentSort: string;
    currentSortDirection: SortDirection;
    userSelectedForDeletion: (selectedUserForDeletion: UserResponse) => void;
    userSelectedForDeactivation: (selectedUserForDeactivation: UserResponse) => void;
    userSelectedForReactivation: (selectedUserForDeactivation: UserResponse) => void;
    userSelectedForModification: (selectedUserForModification: UserResponse) => void;
}

export const ManageUsersTableView = (props: ManageUsersTableViewProps): JSX.Element => {
    const headerValues: HeaderData[] = [
        { dataKey: ManageUsersSortFilterOptions.LAST_NAME, label: 'NAME' },
        { dataKey: ManageUsersSortFilterOptions.EMAIL, label: 'EMAIL' },
        { dataKey: ManageUsersSortFilterOptions.DEPARTMENT, label: 'DEPARTMENT' },
        { dataKey: ManageUsersSortFilterOptions.ROLES, label: 'ROLES', disableSort: true },
        { dataKey: ManageUsersSortFilterOptions.STATUS, label: 'STATUS' },
        { dataKey: ManageUsersSortFilterOptions.MFA_REQUIRED, label: 'MFA' },
    ];

    const tableRow = (user: UserResponse, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [],
            accessibilityTitle: `${getUserNameFromUser(user)} Menu`,
        };

        if (user.status === Status.ACTIVE) {
            overflowMenuProps.overflowItems.push({
                text: 'Modify User',
                onClickAction: () => props.userSelectedForModification(props.users[index]),
                icon: faEdit,
            });
            overflowMenuProps.overflowItems.push({
                text: 'Deactivate User',
                onClickAction: () => props.userSelectedForDeactivation(props.users[index]),
                icon: faBan,
            });
        }

        if (user.status === Status.INACTIVE) {
            overflowMenuProps.overflowItems.push({
                text: 'Reactivate User',
                onClickAction: () => props.userSelectedForReactivation(props.users[index]),
                icon: faCheck,
            });

            /**
             * Deleting users is currently feature toggled off
                overflowMenuProps.overflowItems.push({
                    text: 'Delete User',
                    onClickAction: () => props.userSelectedForDeletion(props.users[index]),
                    icon: faTrash,
                });
            */
        }

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(user.cognito_subject, props.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.email_address}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.department}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.roles.sort().join(', ').toUpperCase()}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.status.toUpperCase()}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.mfa_required === true ? 'ENABLED' : 'DISABLED'}</Text>
                </TableCellDefaultText>

                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: props.applySorting,
        currentSort: props.currentSort,
        currentSortDirection: props.currentSortDirection,
        tableIncludesOverflowMenu: true,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{props.users.map(tableRow)}</TableBody>
        </Table>
    );
};
