import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, OptionProps, Props, components } from 'react-select';

import { useCachedData } from 'Components/Context/CachedDataContext';
import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { getUserNameFromUser, sortFormattedUsers } from 'Helpers/UserUtils';
import { ColorTheme } from 'Models/Types/GlobalType';
import { Status, UserResponse } from 'Models/User';

import { UserOption, UserOptionProps } from './UserOption/UserOption';

interface FormFieldUserMultiSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUsersSelected?: (value: UserResponse[] | undefined, formFieldId: string) => void;
    selectedUsers?: UserResponse[];
    disabled?: boolean;
    includeInactive?: boolean;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

class UserResponseOption {
    value: string;
    label: string;
    user: UserResponse;

    constructor(user: UserResponse) {
        this.value = user.cognito_subject;
        this.label = getUserNameFromUser(user);
        this.user = user;
    }
}

// TODO: can this be combined with FormFieldMultiOptionSelect? They share quite a bit of code.
export const FormFieldUserMultiSelect = (props: FormFieldUserMultiSelectProps) => {
    const colorTheme = props.colorTheme ?? 'light';

    const cachedData = useCachedData();

    const mapUserToUserOption = (user: UserResponse): UserResponseOption => new UserResponseOption(user);

    const filterAndFormatUsers = (): UserResponseOption[] => {
        let users = [...cachedData.users];

        if (!props.includeInactive) {
            users = users.filter((user) => user.status === Status.ACTIVE);
        }

        return sortFormattedUsers(users).map(mapUserToUserOption);
    };

    const handleChange = (value: OnChangeValue<UserResponseOption, true>): void => {
        if (props.onUsersSelected) {
            if (value && value instanceof Array) {
                props.onUsersSelected(
                    value.map((userOption) => userOption.user),
                    props.formFieldId
                );
            } else {
                props.onUsersSelected(undefined, props.formFieldId);
            }
        }
    };

    const userOption = (props: OptionProps<UserResponseOption, true>): JSX.Element => {
        const userOptionProps: UserOptionProps = {
            user: props.data.user,
        };
        return (
            <components.Option {...props}>
                <UserOption {...userOptionProps} />
            </components.Option>
        );
    };

    const selectProps: Props<UserResponseOption, true> = {
        options: filterAndFormatUsers(),
        onChange: handleChange,
        value: props.selectedUsers?.map(mapUserToUserOption),
        isDisabled: props.disabled,
        isMulti: true,
        inputId: props.formFieldId,
    };

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            {props.formFieldLabel && (
                <FormLabel color={colorTheme === 'light' ? undefined : 'white'} required={props.isRequiredField}>
                    {props.formFieldLabel}
                </FormLabel>
            )}
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <Select
                {...selectProps}
                components={{ Option: userOption }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: colorTheme === 'light' ? 'var(--hps-blue)' : 'transparent',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                        backgroundColor: colorTheme === 'light' ? base.backgroundColor : 'var(--hps-blue)',
                    }),
                    multiValue: (styles) => ({
                        ...styles,
                        backgroundColor: colorTheme === 'light' ? styles.backgroundColor : 'var(--hps-white)',
                    }),
                    multiValueRemove: (styles, _) => ({
                        ...styles,
                        color: colorTheme === 'light' ? 'var(--hps-gray)' : 'var(--hps-blue)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                        },
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
