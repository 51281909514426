import { Table } from 'react-bootstrap';

import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { TableBody } from 'Components/Table/Table/Table';
import { AlertResponse } from 'Models/Alerts';

import { AlertsTableRow, AlertsTableRowProps } from './AlertsTableRow/AlertsTableRow';

export interface AlertsTableProps {
    alertsResponseList: AlertResponse[];
}

export const AlertsTable = (props: AlertsTableProps) => {
    const tableRow = (alert: AlertResponse, index: number): JSX.Element => {
        const alertsTableRowProps: AlertsTableRowProps = {
            alert: alert,
        };
        return <AlertsTableRow key={index} {...alertsTableRowProps} />;
    };

    const headerValues: HeaderData[] = [
        { dataKey: 'TITLE', label: 'TITLE', disableSort: true },
        { dataKey: 'DATE', label: 'DATE', disableSort: true },
        { dataKey: 'ALERT LIMIT', label: 'ALERT LIMIT', disableSort: true },
        { dataKey: 'CURRENT LEVEL', label: 'CURRENT LEVEL', disableSort: true },
    ];

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: () => void 0,
        currentSort: 'TITLE',
        currentSortDirection: SortDirection.DESC,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{props.alertsResponseList.map(tableRow)}</TableBody>
        </Table>
    );
};
