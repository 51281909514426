import { ASSESSMENTS, CATEGORY, CONTROLS, EFFECTIVENESS, FRAMEWORKS, GROUPS, HISTORY, OPERATIONAL_CONTROLS, RISKS, RISK_REGISTER, RISK_SCORES, STRATEGY } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment, RiskCategoryRequest, RiskCategoryResponse, RiskRatingHistoryResponse, RiskResponse, RiskStrategy, RiskStrategyRequest, SaveRiskRequest, SubmitRiskAssessmentRequest, UpdateRiskScoresRequest } from 'Models/RiskRegister';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { RiskRegisterApi } from './RiskRegisterApi';

export class AWSRiskRegister implements RiskRegisterApi {
    getAllRisks = (): Promise<ResponseModel<RiskResponse[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${RISKS}`);
    };

    getRiskDetails = (risk_id: string): Promise<ResponseModel<RiskResponse>> => {
        return restApiGet(`/${RISK_REGISTER}/${RISKS}/${risk_id}`);
    };

    getRiskRatingHistory = (risk_id: string): Promise<ResponseModel<RiskRatingHistoryResponse[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${RISKS}/${risk_id}/${HISTORY}`);
    };

    createRisk = (createActionRequest: SaveRiskRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${RISK_REGISTER}/${RISKS}`, createActionRequest);
    };

    updateRisk = (risk_id: string, request: SaveRiskRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${RISK_REGISTER}/${RISKS}/${risk_id}`, request);
    };

    deleteRisk = (risk_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${RISK_REGISTER}/${RISKS}/${risk_id}`);
    };

    getRiskCategories = (): Promise<ResponseModel<RiskCategoryResponse[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${CATEGORY}`);
    };

    getRiskStrategies = (): Promise<ResponseModel<RiskStrategy[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${STRATEGY}`);
    };

    getAllRisksForCategory = (category: string): Promise<ResponseModel<RiskResponse[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${RISKS}?${CATEGORY}=${category}`);
    };

    getAllRisksForControl = (controlFramework: string, controlGroupId: string, controlId: string): Promise<ResponseModel<RiskResponse[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}/${RISKS}`);
    };

    updateRiskCategories = (category_set: RiskCategoryRequest[]): Promise<ResponseModel<void>> => {
        return restApiPost(`/${RISK_REGISTER}/${CATEGORY}`, category_set);
    };

    updateRiskStrategies = (strategies: RiskStrategyRequest[]): Promise<ResponseModel<void>> => {
        return restApiPost(`/${RISK_REGISTER}/${STRATEGY}`, strategies);
    };

    submitRiskAssessment = (request: SubmitRiskAssessmentRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${RISK_REGISTER}/${ASSESSMENTS}`, request);
    };

    getRiskAssessment = (timestamp: string): Promise<ResponseModel<RiskAssessment>> => {
        return restApiGet(`/${RISK_REGISTER}/${ASSESSMENTS}/${timestamp}`);
    };

    getAllRiskAssessments = (): Promise<ResponseModel<RiskAssessment[]>> => {
        return restApiGet(`/${RISK_REGISTER}/${ASSESSMENTS}`);
    };

    updateRiskScores = (risk_id: string, request: UpdateRiskScoresRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${RISK_REGISTER}/${RISKS}/${risk_id}/${RISK_SCORES}`, request);
    };

    getOverallControlEffectiveness = (): Promise<ResponseModel<number>> => {
        return restApiGet(`/${RISK_REGISTER}/${EFFECTIVENESS}`);
    };
}
