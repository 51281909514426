import { useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskAssessment, RiskHistory, formatAverageWeightedEffectiveness, formatTotalImpactOrProbability } from 'Models/RiskRegister';

import styles from './AssessmentHistoryTable.module.css';
import { AverageOrTotal } from '../AssessmentListing';

export interface AssessmentHistoryTableProps {
    riskAssessments: RiskAssessment[];
    averageOrTotal: AverageOrTotal;
}

enum AssessmentSortFilterOptions {
    DATE = 'timestamp',
    CATEGORY = 'category',
    CONTROL_ENVIRONMENT = 'average_control_environment_effectiveness',
    AVERAGE_INHERENT_RISK = 'average_inherent_risk',
    AVERAGE_CURRENT_RISK = 'average_current_risk',
    AVERAGE_TARGET_RISK = 'average_target_risk',
    TOTAL_INHERENT_RISK = 'total_inherent_risk',
    TOTAL_CURRENT_RISK = 'total_current_risk',
    TOTAL_TARGET_RISK = 'total_target_risk',
}

interface AssessmentHistoryTableState {
    currentSort: AssessmentSortFilterOptions;
    currentSortDirection: SortDirection;
}

// TODO: Either the squashed commit that includes this line should be reverted, and the backend should update its calculation logic; or the backend logic should be removed, and the frontend should always do these calculations (in a single place--this logic also exists in RiskListingCallout). Regardless, a data migration will be necessary, and the logic (wherever it lives) should be tested.
const calculateTotalTargetRisk = (risks: RiskHistory[]) => {
    return (risks.map((risk) => risk.total_target_risk ?? risk.total_current_risk).filter((value) => value !== undefined) as number[]).reduce((a, b) => a + b, 0);
};

const calculateAverageTargetRisk = (risks: RiskHistory[]) => {
    if (risks.length === 0) {
        return 0;
    }

    const total = calculateTotalTargetRisk(risks);
    return total / risks.length;
};

export const AssessmentHistoryTable = (props: AssessmentHistoryTableProps): JSX.Element => {
    const [assessmentHistoryTableState, setAssessmentHistoryTableState] = useState<AssessmentHistoryTableState>({ currentSort: AssessmentSortFilterOptions.DATE, currentSortDirection: SortDirection.ASC });

    const headerValues: HeaderData[] = [
        {
            dataKey: AssessmentSortFilterOptions.DATE,
            label: 'DATE',
        },
        {
            dataKey: AssessmentSortFilterOptions.CATEGORY,
            label: 'CATEGORY',
        },
        {
            dataKey: props.averageOrTotal === AverageOrTotal.AVERAGE ? AssessmentSortFilterOptions.AVERAGE_INHERENT_RISK : AssessmentSortFilterOptions.TOTAL_INHERENT_RISK,
            label: 'INHERENT RISK',
        },
        {
            dataKey: AssessmentSortFilterOptions.CONTROL_ENVIRONMENT,
            label: 'CONTROL ENVIRONMENT',
        },
        {
            dataKey: props.averageOrTotal === AverageOrTotal.AVERAGE ? AssessmentSortFilterOptions.AVERAGE_CURRENT_RISK : AssessmentSortFilterOptions.TOTAL_CURRENT_RISK,
            label: 'CURRENT RESIDUAL RISK',
        },
        {
            dataKey: props.averageOrTotal === AverageOrTotal.AVERAGE ? AssessmentSortFilterOptions.AVERAGE_TARGET_RISK : AssessmentSortFilterOptions.TOTAL_TARGET_RISK,
            label: 'TARGET RESIDUAL RISK',
        },
    ];

    const sortRisks = (): RiskAssessment[] => {
        let sortResult = 0;
        return props.riskAssessments.sort((assessmentA, assessmentB) => {
            switch (assessmentHistoryTableState.currentSort) {
                case AssessmentSortFilterOptions.DATE:
                    sortResult = undefinedComparator(assessmentA.timestamp, assessmentB.timestamp, (assessmentA, assessmentB) => (assessmentA > assessmentB ? -1 : 1));
                    break;
                default:
                    sortResult = assessmentA[assessmentHistoryTableState.currentSort] > assessmentB[assessmentHistoryTableState.currentSort] ? 1 : -1;
                    break;
            }

            return assessmentHistoryTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: (newSort: string, newSortDirection: SortDirection) => setAssessmentHistoryTableState({ currentSort: newSort as AssessmentSortFilterOptions, currentSortDirection: newSortDirection }),
        currentSort: assessmentHistoryTableState.currentSort,
        currentSortDirection: assessmentHistoryTableState.currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    const tableRow = (riskAssessment: RiskAssessment): JSX.Element => {
        return (
            <TableRow key={riskAssessment.timestamp}>
                <TableCellDefaultText>
                    <Link size="sm" to={`/${RISK_REGISTER}/${RISK_REVIEW}/${riskAssessment.timestamp}`}>
                        {iso8601ToUsDateShort(riskAssessment.timestamp)}
                    </Link>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{riskAssessment.category.title}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{formatTotalImpactOrProbability(props.averageOrTotal === AverageOrTotal.AVERAGE ? riskAssessment.average_inherent_risk : riskAssessment.total_inherent_risk)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <div className={styles.controlEnvironmentEffectivenessContainer}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(riskAssessment.average_control_environment_effectiveness))} />
                        <Text noStyles>{formatAverageWeightedEffectiveness(riskAssessment.average_control_environment_effectiveness)}</Text>
                    </div>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{formatTotalImpactOrProbability(props.averageOrTotal === AverageOrTotal.AVERAGE ? riskAssessment.average_current_risk : riskAssessment.total_current_risk)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{formatTotalImpactOrProbability(props.averageOrTotal === AverageOrTotal.AVERAGE ? calculateAverageTargetRisk(riskAssessment.risk_histories) : calculateTotalTargetRisk(riskAssessment.risk_histories))}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };
    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortRisks().map(tableRow)}</TableBody>
        </Table>
    );
};
