import { Link } from 'Components/Buttons/Buttons';
import { TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { AlertResponse } from 'Models/Alerts';

export interface AlertsTableRowProps {
    alert: AlertResponse;
}

export const AlertsTableRow = (props: AlertsTableRowProps) => {
    return (
        <TableRow>
            <TableCellDefaultText>
                <Link to={getFrameworkGroupControlURL(props.alert.entity_id)} size="sm">
                    {props.alert.title}
                </Link>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{iso8601ToUsTimestampLong(props.alert.created_time)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{props.alert.alert_limit}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{props.alert.value}</Text>
            </TableCellDefaultText>
        </TableRow>
    );
};
