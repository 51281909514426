import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { Button, Link } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';

export interface AssociatedControlGroup {
    controlGroupIdentifier: string; // Canonical identifier for a Control Group. Example: "CIS CSC (v8.0)#1"
    controlGroupName: string;
    isCustom: boolean;
}

export interface AssociatedControlGroupsModalProps {
    hideModal: () => void;
    associatedControlGroups: AssociatedControlGroup[];
    headerText?: { text: string; secondaryText?: string };
}

export const AssociatedControlGroupsModal = (props: AssociatedControlGroupsModalProps): JSX.Element => {
    const hideModal = (): void => {
        props.hideModal();
    };

    const sortedControlGroups = (() => {
        if (props.associatedControlGroups.length < 1) {
            return undefined;
        }

        return props.associatedControlGroups.sort((controlGroupA, controlGroupB) => {
            if (controlGroupA.isCustom === true && controlGroupB.isCustom === false) {
                return 1;
            } else if (controlGroupA.isCustom === false && controlGroupB.isCustom === true) {
                return -1;
            } else if (controlGroupA.isCustom === false && controlGroupB.isCustom === false) {
                return controlGroupA.controlGroupIdentifier.localeCompare(controlGroupB.controlGroupIdentifier, undefined, { numeric: true, sensitivity: 'base' });
            } else {
                if (controlGroupA.controlGroupName && controlGroupB.controlGroupName) {
                    return controlGroupA.controlGroupName.localeCompare(controlGroupB.controlGroupName, undefined, { numeric: true, sensitivity: 'base' });
                } else if (controlGroupA.controlGroupName && !controlGroupB.controlGroupName) {
                    return 1;
                } else if (!controlGroupA.controlGroupName && controlGroupB.controlGroupName) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
    })();

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.headerText?.text ?? 'Control Groups'} secondaryText={props.headerText?.secondaryText} />
                {sortedControlGroups?.map((controlGroup) => {
                    return (
                        <Link to={getFrameworkGroupControlURL(controlGroup.controlGroupIdentifier)} size="sm" key={controlGroup.controlGroupIdentifier} openInNewTab>
                            {controlGroup.controlGroupName}
                        </Link>
                    );
                })}
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
