import { IconDefinition, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';

interface ConfirmationModalBaseProps {
    /**
     * The nature of the operation that will be performed if the user clicks the confirmation button:
     * * `nonDestructive`: The operation is not destructive, but still has major consequences. For example: toggling controls.
     * * `destructive`: The operation is destructive, but it is not a deletion (from the user's perspective). For example: retiring a policy. Usage of this type should be rare.
     * * `delete`: The operation is a destructive action that specifically deletes one or more things. For example: deleting a custom control.
     */
    operationType: 'nonDestructive' | 'destructive' | 'delete';
    /**
     * The text displayed in the modal header. Will be rendered within `<Text variant="Header2">`.
     *
     * IMPORTANT: for consistency, this should be of the form: "<operation> <entity/entities>". For example: "Delete Risk", Retire Policy", or "Toggle Controls".
     */
    headerText: string;
    /**
     * The text displayed at the top of the modal body. Will be rendered as bold text within `<Text variant="Text2">`.
     * Reiterates the operation the user is about to take.
     *
     * IMPORTANT: for consistency:
     * * This should be of the form: "Are you sure you want to...?".
     * * Entity names should be in quotes. For example: `Are you sure you want to delete "Custom Data Backup Control"?`.
     */
    areYouSureText: string;
    /**
     * The text displayed within the modal body (directly below the `areYouSureText`).
     * Details the consequences of the operation the user is about to take.
     *
     * IMPORTANT:
     * * For consistent structure:
     * * * This should contain an unordered list if and only if there are several consequences to explain. (For example, deleting a control will also remove any mappings of the control to several other types of entities.)
     * * * Otherwise, it should be a `Text` (using the default `variant`).
     * * For consistent styling:
     * * * `Text` (using the default `variant`) should be used for all text.
     */
    children: ReactNode;
    /**
     * The operation to perform when the user clicks the confirmation button.
     * IMPORTANT: The success message should reflect the header text. For example: If the header text is "Delete Risk", the success message should be "Risk deleted.".
     *
     * @returns A message that will be displayed in a "success" banner after the operation is performed successfully. Note that if this function throws an error, the error message will be displayed in a "failure" banner.
     */
    performOperation: () => Promise<string>;
    /**
     * Called when the user clicks the "CLOSE" button or clicks outside of the modal.
     */
    hideModal: () => void;
}

interface ConfirmationModalGenericConfirmationProps extends ConfirmationModalBaseProps {
    /**
     * Used when the user needs to confirm an operation that does not delete/destroy, but still has major consequences. For example: toggling controls.
     */
    operationType: 'nonDestructive';
    /**
     * The text for the button that confirms the operation the user is about to take.
     * For consistency, this text should be UPPERCASED. For example: "TOGGLE".
     */
    buttonText: string;
    /**
     * The text for the button that displays while the operation is being performed.
     * For consistency, this text should be Capitalized. For example: "Toggling...".
     */
    buttonLoadingText: string;
    /**
     * The icon for the button that confirms the operation the user is about to take.
     * `faCheck` is a good generic option if there is not a better alternative.
     */
    buttonIcon: IconDefinition;
}

interface ConfirmationModalDestructiveOperationProps extends ConfirmationModalBaseProps {
    /**
     * Used when the user needs to confirm a destructive operation that is _not_ a deletion. For example: retiring a policy.
     * If you find yourself including "delete" in the header text and/or button text, you should probably use the 'delete' `operationType` instead.
     */
    operationType: 'destructive';
    /**
     * The text for the button that confirms the operation the user is about to take.
     * For consistency, this text should be UPPERCASED. For example: "RETIRE".
     */
    buttonText: string;
    /**
     * The text for the button that displays while the operation is being performed.
     * For consistency, this text should be Capitalized. For example: "Retiring...".
     */
    buttonLoadingText: string;
    /**
     * The icon for the button that confirms the operation the user is about to take.
     * `faTrash` is a good generic option if there is not a better alternative. But keep in mind that `faTrash` is always used for the 'delete' `operationType`.
     */
    buttonIcon: IconDefinition;
}

interface ConfirmationModalDeleteOperationProps extends ConfirmationModalBaseProps {
    /**
     * Used when the user needs to confirm a deletion. For example: deleting a custom control.
     */
    operationType: 'delete';
}

export type ConfirmationModalProps = ConfirmationModalGenericConfirmationProps | ConfirmationModalDeleteOperationProps | ConfirmationModalDestructiveOperationProps;

type OperationState =
    | {
          type: 'notPerformed';
      }
    | {
          type: 'inProgress';
      }
    | {
          type: 'performed';
          wasSuccessful: boolean;
          message: string;
      };

/**
 * Used when the user is attempting to perform an operation that should be confirmed because the operation has major consequences.
 * Displays:
 * * A header.
 * * "Are you sure...?" text.
 * * An explanation of the operation's consequences.
 * * A "CANCEL"/"CLOSE" button.
 * * A confirmation button.
 * * A success or failure banner, once the operation has been performed.
 */
export const ConfirmationModal = (props: ConfirmationModalProps): JSX.Element => {
    const [operationState, setOperationState] = useState<OperationState>({ type: 'notPerformed' });

    const [buttonText, buttonLoadingText, buttonIcon] = (() => {
        switch (props.operationType) {
            case 'nonDestructive':
            case 'destructive':
                return [props.buttonText, props.buttonLoadingText, props.buttonIcon];
            case 'delete':
                return ['DELETE', 'Deleting...', faTrash];
        }
    })();

    const onConfirmButtonClicked = async () => {
        try {
            setOperationState({ type: 'inProgress' });
            const successMessage = await props.performOperation();
            setOperationState({ type: 'performed', wasSuccessful: true, message: successMessage });
        } catch (error) {
            setOperationState({ type: 'performed', wasSuccessful: false, message: error.message });
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {operationState.type === 'performed' && <Alert variant={operationState.wasSuccessful ? 'success' : 'danger'}>{operationState.message}</Alert>}

                <ModalHeader text={props.headerText} />

                <Text>
                    <b>{props.areYouSureText}</b>
                </Text>
                {props.children}

                <div className={'modalFormButtonContainer'}>
                    <Button disabled={operationState.type === 'inProgress'} variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        {operationState.type === 'performed' && operationState.wasSuccessful ? 'CLOSE' : 'CANCEL'}
                    </Button>
                    <Button variant={props.operationType === 'nonDestructive' ? 'primary' : 'danger'} onClick={onConfirmButtonClicked} fontAwesomeImage={buttonIcon} disabled={operationState.type === 'inProgress' || (operationState.type === 'performed' && operationState.wasSuccessful)} isLoading={operationState.type === 'inProgress'} loadingText={buttonLoadingText}>
                        {buttonText}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
