import { Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { Status, UserResponse } from 'Models/User';

/**
 * Determines how User names are formatted.
 */
export enum UserNameFormat {
    FIRST_SPACE_LAST,
    LAST_COMMA_FIRST,
}

/**
 * Return the User's department when the User is provided as a subject within a list of Users.
 */
export const getDepartmentFromSubject = (subject: string, users: UserResponse[]): string | undefined => {
    const user = users.find((user) => user.cognito_subject === subject);
    if (user) {
        return user.department;
    } else {
        return undefined;
    }
};

/**
 * Return the User's name in a standard format when the User is provided as an object.
 * @param format - Use UserNameFormat.FIRST_SPACE_LAST when showing a single user. Otherwise, use the default (UserNameFormat.LAST_COMMA_FIRST) when multiple users are displayed to make sorting possible.
 */
export const getUserNameFromUser = (user: UserResponse, format: UserNameFormat = UserNameFormat.LAST_COMMA_FIRST): string => {
    return applyUserNameFormatting(user, format);
};

/**
 * Return the User's name in a standard format when the User is provided as a subject within a list of Users.
 * @param format - Use UserNameFormat.FIRST_SPACE_LAST when showing a single user. Otherwise, use the default (UserNameFormat.LAST_COMMA_FIRST) when multiple users are displayed to make sorting possible.
 */
export const getUserNameFromSubject = (subject: string, users: UserResponse[], format: UserNameFormat = UserNameFormat.LAST_COMMA_FIRST): string | undefined => {
    const user = users.find((user) => user.cognito_subject === subject);
    if (user) {
        return applyUserNameFormatting(user, format);
    } else {
        return undefined;
    }
};

/**
 * Standardize how Users are displayed throughout the application.
 * @param format - Use UserNameFormat.FIRST_SPACE_LAST when showing a single user. Otherwise, use the default (UserNameFormat.LAST_COMMA_FIRST) when multiple users are displayed to make sorting possible.
 */
const applyUserNameFormatting = (user: UserResponse, format: UserNameFormat = UserNameFormat.LAST_COMMA_FIRST): string => {
    let name = '';
    if (format === UserNameFormat.LAST_COMMA_FIRST) {
        name = `${user.last_name}, ${user.first_name}`;
    } else {
        name = `${user.first_name} ${user.last_name}`;
    }

    if (user.status === Status.INACTIVE) {
        return `${name} (Inactive)`;
    } else {
        return name;
    }
};

/**
 * Given two User subjects, compare the names of their corresponding User objects for sorting.
 */
export const compareUsersBySubjectForSorting = (subject1: string, subject2: string, users: UserResponse[], format: UserNameFormat = UserNameFormat.LAST_COMMA_FIRST): number => {
    const user_name_a = getUserNameFromSubject(subject1, users, format);
    const user_name_b = getUserNameFromSubject(subject2, users, format);

    if (user_name_a && user_name_b) {
        return user_name_a.localeCompare(user_name_b);
    }

    if (user_name_a && !user_name_b) {
        return -1;
    }

    if (!user_name_a && user_name_b) {
        return 1;
    }

    return 0;
};

/**
 * Standardizes sorting for UserResponse objects. The array of users is sorted in place and then returned.
 * By default, assumes the resulting users will be displayed in the "Last, First" format.
 */
export const sortFormattedUsers = (users: UserResponse[], format: UserNameFormat = UserNameFormat.LAST_COMMA_FIRST): UserResponse[] => {
    return users.sort((userA, userB) => {
        return compareUsersBySubjectForSorting(userA.cognito_subject, userB.cognito_subject, users, format);
    });
};

/**
 * Standard filter for only getting users who have Roles to use Risk Register and Action Planning features.
 */

export const isUserWithRiskRole = (user: UserResponse) => userHasAuthorizedRole(user.roles, [Role.ADMIN, Role.RISK_MANAGER, Role.RISK_USER]);
