/*
    Placeholder.tsx -- A placeholder for when page content is loading
*/
import Lottie from 'react-lottie-player';

import animationData from 'Lottie/loading.json';

import styles from './Placeholder.module.css';

export const Placeholder: React.FC = () => {
    return (
        <div className={styles.placeholderBackground}>
            <Lottie loop animationData={animationData} play className={styles.animation} />
        </div>
    );
};
