/*
	HeaderProfile.tsx -- The profile/avatar component of the header bar.
*/
import { useContext } from 'react';

import { DropdownItem, DropdownItemClickType, TextDropdownButton } from 'Components/Buttons/DropdownButton';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { CONFIGURATION } from 'Config/Paths';

export interface HeaderProfileProps {
    username: string;
    handleChangePasswordClick: () => void;
    handleLogout: () => void;
    handleQuickstartGuideClick: () => void;
    handleReleaseNotesClick: () => void;
    handleSupportClick: () => void;
}

export const HeaderProfile = (props: HeaderProfileProps) => {
    const rbacContext = useContext(RBACContext);

    const generateHeaderProfileDropdownItems = (): DropdownItem[] => {
        const headerProfileDropdownItems: DropdownItem[] = [];

        // Ensure only Administrators have access to settings.
        if (rbacContext && userHasAuthorizedRole(rbacContext.roles, [Role.ADMIN])) {
            headerProfileDropdownItems.push({
                _type: DropdownItemClickType.HYPERLINK,
                text: 'Settings',
                href: `/${CONFIGURATION}`,
            });
        }

        headerProfileDropdownItems.push(
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Change Password',
                onClick: () => props.handleChangePasswordClick(),
            },
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Quickstart Guide',
                onClick: () => props.handleQuickstartGuideClick(),
            },
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Release Notes',
                onClick: () => props.handleReleaseNotesClick(),
            },
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Contact Support',
                onClick: () => props.handleSupportClick(),
            },
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Log Out',
                onClick: () => props.handleLogout(),
            }
        );

        return headerProfileDropdownItems;
    };

    return <TextDropdownButton dropdownItems={generateHeaderProfileDropdownItems()} text={props.username} />;
};
