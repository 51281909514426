import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiIconButton from '@mui/material/IconButton';

import styles from './Button.module.css';

interface IconButtonProps {
    onClick: () => void; // The callback function that is to be executed when the button is clicked.
    disabled?: boolean; // Optional argument to enable or disable the button depending on the application state. Defaults to false (button is enabled) if nothing is provided.
    fontAwesomeImage?: IconProp; // An optional FontAwesome image. Either this or an image from the ./public folder must be used.
    'aria-label': string; // Sets the aria-label attribute of the rendered button element. This increases accessibility and makes the button selectable in tests.
    iconImage?: JSX.Element;
    variant?: 'blue' | 'red';
}

/**
 *
 * @param {function} onClick Action after button is clicked
 * @param {object} fontAwesomeImage Image displayed on Button using FontAwesome
 * @param {boolean} disabled Disables the button to no longer be clicked
 */
export const IconButton = (props: IconButtonProps): JSX.Element => {
    const variantColor = props.variant === 'red' ? 'var(--hps-red)' : 'var(--hps-light-blue)';
    const iconButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '50px',
        color: variantColor,
        '&:disabled': {
            color: variantColor,
            cursor: 'not-allowed',
        },
    } as const;
    if (props.fontAwesomeImage) {
        return (
            <MuiIconButton aria-label={props['aria-label']} disabled={props.disabled} onClick={props.onClick} sx={iconButtonStyle}>
                <div className={props.disabled ? styles.iconButtonIconDisabled : styles.iconButtonIcon}>
                    <FontAwesomeIcon icon={props.fontAwesomeImage} />
                </div>
            </MuiIconButton>
        );
    } else if (props.iconImage) {
        return (
            <MuiIconButton aria-label={props['aria-label']} disabled={props.disabled} onClick={props.onClick} sx={iconButtonStyle}>
                <div className={props.disabled ? styles.iconButtonIconDisabled : styles.iconButtonIcon}>{props.iconImage}</div>
            </MuiIconButton>
        );
    } else return <></>;
};
