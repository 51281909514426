import { MultipleMappingBase, MultipleMappingBaseProps } from 'Components/MultipleMapping/MultipleMappingBase';
import { Text } from 'Components/Text/Text';
import { RiskResponse } from 'Models/RiskRegister';

import { RiskMappingListItem } from './RiskMappingListItem/RiskMappingListItem';

export interface RiskMappingProps {
    riskMappingItems: RiskResponse[];
    handleRiskChange: (risks: string[]) => void;
    currentMappedRiskIdentifiers?: string[];
}

export const RiskMapping = (props: RiskMappingProps): JSX.Element => {
    const renderMappingItems = (items: RiskResponse[], handleSelected: (value: RiskResponse, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, selected: RiskResponse[]) => {
        return items.map((item, index) => <RiskMappingListItem key={index} riskMappingItem={item} handleSelected={handleSelected} selected={selected.includes(item)} />);
    };

    const sortRisksMapping = (risks: RiskResponse[]): void => {
        risks.sort((riskA, riskB) => {
            return riskA.id.localeCompare(riskB.id, undefined, { numeric: true, sensitivity: 'base' });
        });
    };

    const multipleMappingBaseProps: MultipleMappingBaseProps<RiskResponse> = {
        mappingItems: props.riskMappingItems,
        handleChange: props.handleRiskChange,
        sortMappingItems: sortRisksMapping,
        currentMappedIdentifiers: props.currentMappedRiskIdentifiers,
        renderMappingItems: renderMappingItems,
    };

    if (multipleMappingBaseProps.mappingItems.length > 0) {
        return <MultipleMappingBase {...multipleMappingBaseProps} />;
    } else {
        return (
            <>
                <Text variant="Text3" noStyles>
                    No available risks to map.
                </Text>
            </>
        );
    }
};
