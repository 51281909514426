/*
    Integration.tsx -- A component for rendering an Integration within ExternalIntegrations.
*/
import { faEdit, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';
import { IntegrationName, IntegrationResponse, Metric as MetricModel } from 'Models/ExternalIntegrations';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './Integration.module.css';
import { Modal } from '../ExternalIntegrations';
import { Metric, MetricProps } from './Metric/Metric';

export interface IntegrationProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    displayAssociatedControls: (metric: MetricModel) => void; // View the Controls that are currently mapped to a Metric.
    displayAwsConfigSetup: () => void;
    displayAwsConfigSetupStatus: () => void;
    displayControlAssociation: (controlAssociationTitleSecondary: string, controlAssociationIdentifier: any, control_mapping: OperationalControl[]) => void; // Map a Metric to Controls.
    displayIntegrationModal: (modal: Modal) => void;
    displayMetricModal: (modal: Modal, metric: MetricModel) => void;
    setSuccessFailureMessages: (newSuccessMessage: string | undefined, newFailureMessage: string | undefined) => void;
    integration: IntegrationResponse;
    awsConfigConfigurationErrorsPresent: boolean;
}

export const Integration = (props: IntegrationProps): JSX.Element => {
    const renderMetric = (metric: MetricModel): JSX.Element => {
        const metricProps: MetricProps = {
            externalIntegrationsApi: props.externalIntegrationsApi,
            displayAssociatedControls: props.displayAssociatedControls,
            displayControlAssociation: props.displayControlAssociation,
            displayMetricModal: props.displayMetricModal,
            setSuccessFailureMessages: props.setSuccessFailureMessages,
            integrationName: props.integration.integration_name,
            metric: metric,
        };
        return <Metric key={metric.metric_name} {...metricProps} />;
    };

    const handleEditClick = (): void => {
        // The modal to display for the selected integration is retrieved from the Modal enum by using the integration name string that comes from the API.
        // This causes problems for integrations with spaces in the name (e.g. "AWS Config"), as enum members cannot have spaces.
        // To allow for dynamic retrieval, we simply remove whitespace when defining the enum cases.
        const whitespace = /\s+/g;
        const integrationName = props.integration.integration_name.replace(whitespace, '') as keyof typeof Modal;

        props.displayIntegrationModal(Modal[integrationName]);
    };

    return (
        <div className={styles.integrationContainer}>
            <div className={styles.integrationRowContainer}>
                <Text variant="Header2" noStyles>
                    {props.integration.integration_name}
                </Text>
                <button type="button" onClick={handleEditClick} className={styles.integrationConfigurationIcon}>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                </button>
                {props.integration.integration_name === IntegrationName.AWS_CONFIG && (
                    <>
                        <div className={styles.setupLink}>
                            <Button variant="linkText" size="sm" onClick={props.displayAwsConfigSetup}>
                                Set Up My AWS Environment
                            </Button>
                        </div>
                        {props.awsConfigConfigurationErrorsPresent && (
                            <>
                                <div className={styles.setupLink}>
                                    <Button variant="linkText" size="sm" onClick={props.displayAwsConfigSetupStatus}>
                                        Configuration errors
                                    </Button>
                                </div>
                                <div className={styles.configurationErrorsIcon}>
                                    <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            {props.integration.metrics.map(renderMetric)}
        </div>
    );
};
