import { OptionType } from './Types/GlobalType';

export enum ScheduleFrequency {
    DAYS = 'Days',
    MONTHS = 'Months',
    YEARS = 'Years',
}

/**
 * DEPRECATED. Don't use this anymore. Mixing and matching letter case is confusing. Use ScheduleFrequency instead.
 */
export type ScheduleFrequencyKeys = keyof typeof ScheduleFrequency;

/**
 * DEPRECATED. Don't use this anymore. Mixing and matching letter case is confusing. Use ScheduleFrequencySelectOptionsV2 instead.
 */
export const ScheduleFrequencySelectOptions: OptionType[] = [
    {
        value: 'DAYS',
        label: ScheduleFrequency.DAYS,
    },
    {
        value: 'MONTHS',
        label: ScheduleFrequency.MONTHS,
    },
    {
        value: 'YEARS',
        label: ScheduleFrequency.YEARS,
    },
];

export const ScheduleFrequencySelectOptionsV2: OptionType[] = [
    {
        value: ScheduleFrequency.DAYS,
        label: ScheduleFrequency.DAYS,
    },
    {
        value: ScheduleFrequency.MONTHS,
        label: ScheduleFrequency.MONTHS,
    },
    {
        value: ScheduleFrequency.YEARS,
        label: ScheduleFrequency.YEARS,
    },
];
