import moment from 'moment';

import { ControlsSnapshot } from 'Models/Dashboards';
import { ExceptionResponse, ExceptionStatus } from 'Models/Exceptions';
import { IssueResponse, IssueStatus } from 'Models/Issues';
import { Effectiveness, effectivenessAsString } from 'Models/OperationalControls';

import { AssessmentsBarChartDataRow, getEffectivenessChartColor } from '../TPRMDashboard/TPRMDashboard.helpers';
import { ComplianceModalType } from './ComplianceRequirements/ComplianceRequirementDisplayModal/ComplianceRequirementDisplayModal';

export const buildControlOverTimeChartByMonth = (controlsHistory: ControlsSnapshot[], displayValues: Effectiveness[]): AssessmentsBarChartDataRow[] => {
    return displayValues.map((displayValue: Effectiveness) => {
        return {
            data: controlsHistory.map((control) => control.assessments.filter((assessment) => assessment.effectiveness === displayValue).length),
            name: effectivenessAsString(displayValue),
            color: getEffectivenessChartColor(displayValue),
        };
    });
};

export const stringToEffectiveness = (effectivenessText: string) => {
    switch (effectivenessText) {
        case 'Inactive':
            return Effectiveness.INACTIVE;
        case 'Fail':
            return Effectiveness.FAIL;
        case 'Weak':
            return Effectiveness.WEAK;
        case 'Moderate':
            return Effectiveness.MODERATE;
        case 'Strong':
            return Effectiveness.STRONG;
        case 'Robust':
            return Effectiveness.ROBUST;
    }
};

export const stringToComplianceModalType = (requirementString: string) => {
    switch (requirementString) {
        case 'Met':
            return ComplianceModalType.MET;

        case 'Unmet':
            return ComplianceModalType.UNMET;

        case 'Not Assessed':
            return ComplianceModalType.NOT_ASSESSED;

        case 'Not Mapped':
            return ComplianceModalType.NOT_MAPPED;
    }
};

export const countExceptionsOrIssuesByMonth = (exceptionsOrIssues: (IssueResponse | ExceptionResponse)[], months: string[]): number[] => {
    return months.map((month) => {
        const currentMonthIssues = exceptionsOrIssues.filter((exceptionOrIssue) => moment(exceptionOrIssue.created_timestamp).format('MMM YY') === month);
        return currentMonthIssues.length;
    });
};

export const countClosedIssuesByMonth = (issues: IssueResponse[], months: string[]): number[] => {
    return months.map((month) => {
        const currentMonthIssues = getClosedIssuesPerMonth(issues, month);
        return currentMonthIssues.length;
    });
};

export const countTotalIssuesByMonth = (issues: IssueResponse[], months: string[]): number[] => {
    return months.map((month) => {
        const totalOpenedBeforeEnd = getTotalIssuesPerMonth(issues, month);
        return totalOpenedBeforeEnd.length;
    });
};

export const getTotalIssuesPerMonth = (issues: IssueResponse[], month: string): IssueResponse[] => {
    return issues.filter((issue) => {
        const isOpened = moment(issue.created_timestamp).isBefore(moment(month, 'MMM YY').endOf('M'));
        if (issue.status === IssueStatus.CLOSED) {
            return isOpened && !moment(issue.closed_timestamp).isBefore(moment(month, 'MMM YY').endOf('M'));
        } else {
            return isOpened;
        }
    });
};

export const getClosedIssuesPerMonth = (issues: IssueResponse[], month: string) => {
    return issues.filter((issue) => issue.status === IssueStatus.CLOSED && moment(issue.closed_timestamp).format('MMM YY') === month);
};

export const getOpenIssuesPerMonth = (issues: IssueResponse[], month: string) => {
    return issues.filter((issue) => issue.due_date && moment(issue.due_date).format('MMM YY') === month);
};

export const getOpenExceptionsPerMonth = (exceptions: ExceptionResponse[], month: string) => {
    return exceptions.filter((exception) => exception.expiration_date && moment(exception.expiration_date).format('MMM YY') === month);
};

export const getClosedExceptionsPerMonth = (exceptions: ExceptionResponse[], month: string) => {
    return exceptions.filter((exception) => exception.status === ExceptionStatus.CLOSED && moment(exception.closed_timestamp).format('MMM YY') === month);
};

export const countClosedExceptionsByMonth = (exceptions: ExceptionResponse[], months: string[]): number[] => {
    return months.map((month) => {
        const currentMonthExceptions = getClosedExceptionsPerMonth(exceptions, month);
        return currentMonthExceptions.length;
    });
};

export const countTotalExceptionsByMonth = (exceptions: ExceptionResponse[], months: string[]): number[] => {
    return months.map((month) => {
        const totalOpenedBeforeEnd = getTotalExceptionsPerMonth(exceptions, month);
        return totalOpenedBeforeEnd.length;
    });
};

export const getTotalExceptionsPerMonth = (exceptions: ExceptionResponse[], month: string) => {
    return exceptions.filter((exception) => {
        const isOpened = moment(exception.created_timestamp).isBefore(moment(month, 'MMM YY').endOf('M'));
        if (exception.status === ExceptionStatus.CLOSED) {
            return isOpened && !moment(exception.closed_timestamp).isBefore(moment(month, 'MMM YY').endOf('M'));
        } else {
            return isOpened;
        }
    });
};
