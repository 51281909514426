import { useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody } from 'Components/Table/Table/Table';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { FolderVersion } from 'Models/TPRM';

import { FoldersTableRow, FoldersTableRowProps } from './FoldersTableRow/FoldersTableRow';

export interface FoldersTableProps {
    folders: FolderVersion[];
    documentApi: DocumentApi;
}

interface FoldersTableState {
    currentSort: string;
    currentSortDirection: SortDirection;
}

enum FoldersSortFilterOptions {
    NAME = 'name',
    TYPE = 'type',
    DOCUMENTS = 'documents',
    EFFECTIVE_DATE = 'effective_date',
    EXPIRATION_DATE = 'expiration_date',
    LAST_UPDATED_BY = 'last_updated_by',
    LAST_UPDATED = 'last_updated',
}

export const FoldersTable = (props: FoldersTableProps) => {
    const cachedData = useCachedData();

    const [foldersTableState, setFoldersTableState] = useState<FoldersTableState>({ currentSort: FoldersSortFilterOptions.NAME, currentSortDirection: SortDirection.ASC });

    const headerValues: HeaderData[] = [
        { dataKey: FoldersSortFilterOptions.NAME, label: 'FOLDER NAME' },
        { dataKey: FoldersSortFilterOptions.TYPE, label: 'FOLDER TYPE' },
        { dataKey: FoldersSortFilterOptions.EFFECTIVE_DATE, label: 'EFFECTIVE DATE' },
        { dataKey: FoldersSortFilterOptions.EXPIRATION_DATE, label: 'EXPIRATION DATE' },
        { dataKey: FoldersSortFilterOptions.DOCUMENTS, label: 'DOCUMENTS', disableSort: true },
        { dataKey: FoldersSortFilterOptions.LAST_UPDATED_BY, label: 'LAST UPDATED BY' },
        { dataKey: FoldersSortFilterOptions.LAST_UPDATED, label: 'LAST UPDATED' },
    ];

    const sortFolders = (): FolderVersion[] => {
        let sortResult = 0;

        return props.folders.sort((folderA, folderB) => {
            switch (foldersTableState.currentSort) {
                case FoldersSortFilterOptions.LAST_UPDATED:
                case FoldersSortFilterOptions.TYPE:
                case FoldersSortFilterOptions.NAME:
                    sortResult = (folderA[foldersTableState.currentSort] as string).localeCompare(folderB[foldersTableState.currentSort] as string);
                    break;
                case FoldersSortFilterOptions.EFFECTIVE_DATE:
                    sortResult = undefinedComparator(folderA.effective_date, folderB.effective_date, (effectiveDateA, effectiveDateB) => (effectiveDateA > effectiveDateB ? 1 : -1));
                    break;
                case FoldersSortFilterOptions.EXPIRATION_DATE:
                    sortResult = undefinedComparator(folderA.expiration_date, folderB.expiration_date, (expirationDateA, expirationDateB) => (expirationDateA > expirationDateB ? 1 : -1));
                    break;
                case FoldersSortFilterOptions.LAST_UPDATED_BY:
                    sortResult = compareUsersBySubjectForSorting(folderA.last_updated_by, folderB.last_updated_by, cachedData.users);
                    break;
                default:
                    sortResult = 0;
                    break;
            }

            return foldersTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setFoldersTableState({ currentSort: newSort as FoldersSortFilterOptions, currentSortDirection: newSortDirection });
    };

    const tableRow = (folder: FolderVersion): JSX.Element => {
        const foldersTableRowProps: FoldersTableRowProps = {
            folder: folder,
            documentApi: props.documentApi,
        };
        return <FoldersTableRow key={folder.id} {...foldersTableRowProps} />;
    };
    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: setCurrentSort,
        currentSort: foldersTableState.currentSort,
        currentSortDirection: foldersTableState.currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortFolders().map(tableRow)}</TableBody>
        </Table>
    );
};
