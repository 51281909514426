import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { Text } from 'Components/Text/Text';
import { ACTIONS, CREATE } from 'Config/Paths';
import { ActionStatus, ActionStatusFilterOptions, TreatmentPlanStatus } from 'Models/Actions';
import { AssociatedAction } from 'Models/RiskRegister';

import { AssociatedActionListing } from './AssociatedActionListing/AssociatedActionListing';
import styles from './TreatmentPlanListing.module.css';

export interface TreatmentPlanListingProps {
    riskId: string;
    treatmentPlanStatus: TreatmentPlanStatus;
    treatmentPlanDescription?: string;
    actions: AssociatedAction[];
}

export const TreatmentPlanListing = (props: TreatmentPlanListingProps): JSX.Element => {
    const [selectedActionStatus, setSelectedActionStatus] = useState<ActionStatus | 'ALL_ACTIONS'>(ActionStatus.OPEN);

    return (
        <div className={styles.treatmentPlanTab}>
            <div className={styles.headerContainer}>
                <Text noStyles variant="Header2">
                    Treatment Plan
                </Text>
                <Link variant="primaryButton" to={`/${ACTIONS}/${CREATE}?riskId=${props.riskId}`} fontAwesomeImage={faAdd}>
                    Create Action
                </Link>
            </div>
            <hr />
            <Text noStyles color="darkGray" variant="Header4">
                Status
            </Text>
            <div className={styles.treatmentPlanStatus} data-testid="treatmentPlanStatus">
                <Text noStyles variant="Text2" color="blue">
                    {props.treatmentPlanStatus === TreatmentPlanStatus.MISSING ? '-' : props.treatmentPlanStatus}
                </Text>
            </div>
            <Text noStyles color="darkGray" variant="Header4">
                Description
            </Text>
            <div className={styles.treatmentPlanDescription} data-testid="treatmentPlanDescription">
                <Text noStyles variant="Text2" color="blue">
                    {props.treatmentPlanDescription ?? '-'}
                </Text>
            </div>
            <div className={styles.filter}>
                <FormFieldSelect selectedOption={selectedActionStatus} formFieldLabel="Filter by Status" formFieldId="status" options={ActionStatusFilterOptions} handleChange={(value) => setSelectedActionStatus(value as ActionStatus)} />
            </div>
            <AssociatedActionListing
                actions={props.actions.filter((action) => {
                    if (action.status === selectedActionStatus) {
                        return true;
                    } else if (selectedActionStatus === 'ALL_ACTIONS') {
                        return true;
                    } else {
                        return false;
                    }
                })}
            />
        </div>
    );
};
