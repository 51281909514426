import { capitalize } from 'lodash-es';
import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlTextToString, getHumanReadableControlIdFromControlId, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { OperationalControlExportRow } from 'Models/Exports';
import { effectivenessAsString } from 'Models/OperationalControls';

export interface OperationalControlProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const OperationalControlExport = (props: OperationalControlProps): JSX.Element => {
    const [controls, setControls] = useState<OperationalControlExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getOperationalResponse = async (): Promise<void> => {
            try {
                const operationalResponse = await props.exportsApi.getOperationalControlExport();
                setControls(operationalResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getOperationalResponse();
    }, [props.exportsApi]);

    const columns: GridColumn<OperationalControlExportRow>[] = [
        { field: 'control', headerName: 'Control', width: 300, type: 'string', valueGetter: (_value, row) => getHumanReadableControlIdFromControlId(getOperationalControlIdentifierString(row.control_framework, row.control_group_id, row.control_id), row.control_name) },
        { field: 'control_group_name', headerName: 'Control Group Name', width: 300 },
        { field: 'control_group_description', headerName: 'Control Group Description', width: 300 },
        { field: 'control_text', headerName: 'Control Text', width: 300, valueGetter: (value) => controlTextToString(value) },
        { field: 'control_type_timing', headerName: 'Control Type Timing', width: 300 },
        { field: 'control_type_nature', headerName: 'Control Type Nature', width: 300 },
        { field: 'control_effectiveness', headerName: 'Control Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
        { field: 'target_effectiveness', headerName: 'Control Target Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
        dateColumn('review_schedule_start_date', 'Assessment Schedule Start Date'),
        { field: 'review_schedule_number', headerName: 'Assessment Schedule Number', width: 300 },
        { field: 'review_schedule_frequency', headerName: 'Assessment Schedule Frequency', width: 300, valueGetter: (value) => capitalize(value) },
        dateColumn('next_review_date', 'Next Assessment Date'),
        { field: 'reviewer', headerName: 'Reviewer', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'owner_department', headerName: 'Owner Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.owner, cachedData.users) },
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value, row) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        {
            field: 'delegates',
            headerName: 'Delegates',
            width: 300,
            valueGetter: (value) =>
                value
                    ?.map((userId: string) => getUserNameFromSubject(userId, cachedData.users, UserNameFormat.FIRST_SPACE_LAST))
                    .sort()
                    .join(', '),
        },
        { field: 'governance_titles', headerName: 'Active Governance', width: 300, valueGetter: (value) => value.join(', ') },
    ];

    if (controls) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Operational Controls</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Operational Controls Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(control: OperationalControlExportRow) => getOperationalControlIdentifierString(control.control_framework, control.control_group_id, control.control_id)} rows={controls} title="Operational Controls Export Preview" fileName="Operational Controls" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
