import { CSSColors } from 'Components/Colors';

export interface ChartDataItem {
    name: string;
    value: number;
    color?: CSSColors;
}

export interface ChartDimensions {
    width: number | string;
    height: number | string;
}

export interface ChartSeries {
    name: string;
    color: CSSColors;
    data: (number | { value: number; itemStyle: { color: string } })[];
    units?: string;
}

export interface ChartAxis {
    name?: string;
    color?: CSSColors;
    labelFormatter?: (item: number) => string;
    position: 'left' | 'right';
    min?: number;
    max?: number;
    minInterval?: number;
    maxInterval?: number;
    interval?: number;
}

export interface ChartClickItem {
    name: string;
    seriesName: string;
}

export const axisColor = '#6C757D';
export const chartFont = '--hps-primary-font';
export const chartColor = '--hps-light-gray';
