import { Role } from 'Components/Context/RBACContext';

import { ComplianceRequirementForControl, ComplianceRequirementForControlResponse } from './ComplianceRequirements';
import { ScheduleFrequency } from './ScheduleFrequency';
import { OptionType } from './Types/GlobalType';
import { IndicatorVariant } from '../Components/Indicator/Indicator';

export interface OperationalControl {
    identifier: string;
    metadata: ControlMetadata;
    effectiveness: ControlEffectiveness;
    configuration: ControlConfiguration;
    target_effectiveness?: Effectiveness;
}

export type ControlMetadata = {
    control_group_name: string;
    control_text: ControlText[];
    control_type_timing: 'Preventive' | 'Detective' | 'Corrective';
    control_type_nature: 'Administrative' | 'Physical' | 'Technical';
} & (
    | {
          is_custom: false;
          control_name?: never;
      }
    | {
          is_custom: true;
          control_name: string;
      }
);

export interface ControlText {
    text: string;
    children: ControlText[];
}

export type ControlEffectiveness =
    | {
          control_effectiveness: Effectiveness.INACTIVE;
          last_updated?: never;
      }
    | {
          control_effectiveness: Exclude<Effectiveness, Effectiveness.INACTIVE>;
          last_updated: string;
      };

export interface ControlConfiguration {
    enabled: boolean;
    review_configuration?: ReviewConfiguration;
}

export interface ReviewConfiguration {
    review_schedule_start_date: string;
    review_schedule_number: number;
    review_schedule_frequency: 'DAYS' | 'MONTHS' | 'YEARS';
    reviewer_subject?: string;
    owner_subject: string;
    delegate_subjects: string[];
}

/**
 * API DTOs
 */
export enum AssessmentState {
    IN_PROGRESS = 'IN_PROGRESS',
    UNDER_REVIEW = 'UNDER_REVIEW',
    APPROVED = 'APPROVED',
}

export interface UpdateAssessmentRequest {
    state: AssessmentState;
    assessment_effectiveness?: Effectiveness;
    owner_comment?: string;
    reviewer_comment?: string;
    regulatory_requirements?: ComplianceRequirementForControlResponse[];
}

export interface AssessmentDetailsResponse {
    control_identifier: string;
    assessment_due_date: string;
    assessment_state: AssessmentState;
    assessment_effectiveness?: Effectiveness;
    owner_comments?: Comment[];
    reviewer_comment?: Comment;
}

export interface AssessmentHistoryResponse {
    assessment_state: AssessmentState;
    timestamp: string;
    assessment_effectiveness?: Effectiveness;
    owner_comments?: Comment[];
    reviewer_comment?: Comment;
    attached_requirements?: ComplianceRequirementForControl[];
    [index: string]: AssessmentState | Comment[] | Comment | Effectiveness | string | ComplianceRequirementForControl[] | undefined;
}

export enum AssessmentRole {
    ADMIN,
    OWNER,
    REVIEWER,
}

/**
 * Note that the values in this enum must correspond to the property names of AssessmentHistoryResponse.
 * This is because the user sorting function uses index access based on these values.
 */
export enum AssessmentHistorySortFilterOptions {
    ASSESSMENT_STATE = 'assessment_state',
    TIMESTAMP = 'timestamp',
    OWNER_COMMENTS = 'owner_comments',
    REVIEWER_COMMENT = 'reviewer_comment',
}

export interface UncompletedAssessment {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    assessment_due_date: string;
    control_name?: string; // Only present if the Control is custom.
}

export interface CompletedAssessment {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    effectiveness: Effectiveness;
    timestamp: string;
    control_name?: string; // Only present if the Control is custom.
}

export interface AssessmentSchedule {
    coming_due: UncompletedAssessment[];
    completed_recently: CompletedAssessment[];
    overdue: UncompletedAssessment[];
}

export interface ControlFrameworkResponse {
    control_framework: string;
    control_framework_version: string;
    is_custom: boolean;
    enabled: boolean;
    [key: string]: any;
}

export interface DetailedControlFrameworkResponse {
    control_framework: string;
    control_framework_version: string;
    assessment_schedule: AssessmentSchedule;
    control_framework_effectiveness: number;
    review_schedule_start_date?: string;
    review_schedule_number?: string;
    review_schedule_frequency?: ScheduleFrequency;
    reviewer_subject?: string;
    owner_subject?: string;
    delegate_subjects?: string[];
    is_custom: boolean;
    enabled: boolean;
    target_effectiveness?: Effectiveness;

    [key: string]: any;
}

export interface ControlGroupResponse {
    control_framework: string;
    control_group_name: string;
    control_group_id: string;
    control_group_effectiveness: number;
    control_group_description?: string;
    is_custom: boolean;
    enabled: boolean;

    [key: string]: any;
}

export interface DetailedControlGroupResponse {
    control_framework: string;
    control_group_name: string;
    control_group_id: string;
    assessment_schedule: AssessmentSchedule;
    control_group_effectiveness: number;
    control_group_description?: string;
    review_schedule_start_date?: string;
    review_schedule_number?: string;
    review_schedule_frequency?: ScheduleFrequency;
    reviewer_subject?: string;
    owner_subject?: string;
    delegate_subjects?: string[];
    is_custom: boolean;
    enabled: boolean;
    target_effectiveness?: Effectiveness;

    [key: string]: any;
}

export interface Comment {
    text: string;
    user_id?: string;
    timestamp?: string;
}

export interface SaveControlGroupRequest {
    control_group_name: string;
    control_group_description: string;
}

export interface SaveControlRequest {
    control_name: string;
    control_text: ControlText[];
    control_type_nature: ControlTypeNatureValues;
    control_type_timing: ControlTypeTimingValues;
}

export interface AllCustomControlsControlFrameworkResponse {
    control_framework: string;
    control_groups: AllCustomControlsControlGroupResponse[];
}

export interface AllCustomControlsControlGroupResponse {
    control_group_id: string;
    is_custom: boolean;
    control_group_name: string;
    controls: AllCustomControlsControlResponse[];
}

export interface AllCustomControlsControlResponse {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    control_text: ControlText[];
    control_name: string;
    is_custom: boolean;
}

export enum ControlListingView {
    LIST = 'list',
    GRID = 'grid',
}

export enum ControlTypeNatureValues {
    ADMINISTRATIVE = 'Administrative',
    PHYSICAL = 'Physical',
    TECHNICAL = 'Technical',
}

export const ControlTypeNatureValuesSelectOptions: OptionType[] = [
    {
        value: ControlTypeNatureValues.ADMINISTRATIVE,
        label: 'Administrative',
    },
    {
        value: ControlTypeNatureValues.PHYSICAL,
        label: 'Physical',
    },
    {
        value: ControlTypeNatureValues.TECHNICAL,
        label: 'Technical',
    },
];

export const stringAsControlTypeNatureValue = (stringValue: string) => {
    switch (stringValue) {
        case 'Administrative':
            return ControlTypeNatureValues.ADMINISTRATIVE;
        case 'Physical':
            return ControlTypeNatureValues.PHYSICAL;
        case 'Technical':
            return ControlTypeNatureValues.TECHNICAL;
    }
};

export enum ControlTypeTimingValues {
    PREVENTIVE = 'Preventive',
    DETECTIVE = 'Detective',
    CORRECTIVE = 'Corrective',
}

export const ControlTypeTimingValuesSelectOptions: OptionType[] = [
    {
        label: 'Preventive',
        value: ControlTypeTimingValues.PREVENTIVE,
    },
    {
        label: 'Detective',
        value: ControlTypeTimingValues.DETECTIVE,
    },
    {
        label: 'Corrective',
        value: ControlTypeTimingValues.CORRECTIVE,
    },
];

export const stringAsControlTypeTimingValue = (stringValue: string) => {
    switch (stringValue) {
        case 'Preventive':
            return ControlTypeTimingValues.PREVENTIVE;
        case 'Detective':
            return ControlTypeTimingValues.DETECTIVE;
        case 'Corrective':
            return ControlTypeTimingValues.CORRECTIVE;
    }
};

export interface ProgressBarVariantPercent {
    variant: IndicatorVariant;
    percent: number;
}

export enum Effectiveness {
    INACTIVE = 0,
    FAIL,
    WEAK,
    MODERATE,
    STRONG,
    ROBUST,
}

export const ControlEffectivenessProgressBarVariantAndPercentage = (effectiveness: Effectiveness): ProgressBarVariantPercent => {
    const effectivenessVariant: IndicatorVariant = getEffectivenessVariantColor(effectiveness);
    let effectivenessPercent = 0;

    switch (effectiveness) {
        case Effectiveness.INACTIVE:
            effectivenessPercent = 0;
            break;
        case Effectiveness.FAIL:
            effectivenessPercent = 20;
            break;
        case Effectiveness.WEAK:
            effectivenessPercent = 40;
            break;
        case Effectiveness.MODERATE:
            effectivenessPercent = 60;
            break;
        case Effectiveness.STRONG:
            effectivenessPercent = 80;
            break;
        case Effectiveness.ROBUST:
            effectivenessPercent = 100;
    }

    return { variant: effectivenessVariant, percent: effectivenessPercent };
};

export const getEffectivenessVariantColor = (effectiveness: Effectiveness): IndicatorVariant => {
    switch (effectiveness) {
        case Effectiveness.INACTIVE:
            return IndicatorVariant.GRAY;
        case Effectiveness.FAIL:
            return IndicatorVariant.RED;
        case Effectiveness.WEAK:
            return IndicatorVariant.ORANGE;
        case Effectiveness.MODERATE:
            return IndicatorVariant.YELLOW;
        case Effectiveness.STRONG:
            return IndicatorVariant.LIGHT_GREEN;
        case Effectiveness.ROBUST:
            return IndicatorVariant.DARK_GREEN;
    }
};

export const numberAsEffectiveness = (effectiveness: number): Effectiveness => {
    if (effectiveness === 0) {
        return Effectiveness.INACTIVE;
    } else if (effectiveness >= 1 && effectiveness < 2) {
        return Effectiveness.FAIL;
    } else if (effectiveness >= 2 && effectiveness < 3) {
        return Effectiveness.WEAK;
    } else if (effectiveness >= 3 && effectiveness < 4) {
        return Effectiveness.MODERATE;
    } else if (effectiveness >= 4 && effectiveness < 5) {
        return Effectiveness.STRONG;
    } else if (effectiveness >= 5) {
        return Effectiveness.ROBUST;
    }

    return Effectiveness.INACTIVE;
};

export const effectivenessAsString = (effectiveness: Effectiveness): string => {
    switch (effectiveness) {
        case Effectiveness.INACTIVE:
            return 'Inactive';
        case Effectiveness.FAIL:
            return 'Fail';
        case Effectiveness.WEAK:
            return 'Weak';
        case Effectiveness.MODERATE:
            return 'Moderate';
        case Effectiveness.STRONG:
            return 'Strong';
        case Effectiveness.ROBUST:
            return 'Robust';
    }
};

export const effectivenessLabelAsNumber = (effectivenessLabel: string) => {
    switch (effectivenessLabel) {
        case 'Inactive':
            return Effectiveness.INACTIVE;
        case 'Fail':
            return Effectiveness.FAIL;
        case 'Weak':
            return Effectiveness.WEAK;
        case 'Moderate':
            return Effectiveness.MODERATE;
        case 'Strong':
            return Effectiveness.STRONG;
        case 'Robust':
            return Effectiveness.ROBUST;
    }
};

export const numberAsEffectivenessString = (effectiveness: number): string => {
    return effectivenessAsString(numberAsEffectiveness(effectiveness));
};

export const EffectivenessSelectOptions: OptionType[] = [
    {
        value: 1,
        label: 'Fail',
    },
    {
        value: 2,
        label: 'Weak',
    },
    {
        value: 3,
        label: 'Moderate',
    },
    {
        value: 4,
        label: 'Strong',
    },
    {
        value: 5,
        label: 'Robust',
    },
];

export const parseImpactedControls = (controlsList: string[]): string => {
    let impactedControls = '';
    if (controlsList) {
        let index = 0;
        for (const control of controlsList) {
            if (index === controlsList.length - 1) {
                impactedControls = impactedControls.concat(control);
            } else {
                impactedControls = impactedControls.concat(control, ',');
            }

            index++;
        }
    }
    return impactedControls;
};

export const determineUserAssessmentRoles = (userSubject?: string, userRoles?: Role[], ownerSubject?: string, reviewerSubject?: string): Set<AssessmentRole> => {
    const assessmentRoles = new Set<AssessmentRole>();
    if (!userSubject || !userRoles) {
        return assessmentRoles;
    }
    if (userSubject === ownerSubject) {
        assessmentRoles.add(AssessmentRole.OWNER);
    }

    if (userSubject === reviewerSubject) {
        assessmentRoles.add(AssessmentRole.REVIEWER);
    }

    if (userRoles.includes(Role.ADMIN)) {
        assessmentRoles.add(AssessmentRole.ADMIN);
    }

    return assessmentRoles;
};

export const assessmentStateAsString = (assessmentState: AssessmentState): string => {
    switch (assessmentState) {
        case AssessmentState.IN_PROGRESS:
            return 'In Progress';
        case AssessmentState.UNDER_REVIEW:
            return 'Under Review';
        case AssessmentState.APPROVED:
            return 'Approved';
    }
};
