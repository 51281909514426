import { v4 as uuidv4 } from 'uuid';

import { AWSAuthApi } from 'Api/Auth/AWSAuthApi';
import { AuthApi } from 'Api/Auth/AuthApi';
import { LogoutApi } from 'Api/Auth/LogoutApi';
import { Role } from 'Components/Context/RBACContext';
import { AWSConfig } from 'Config/AWSConfig';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';
import { getClaims } from 'Helpers/Auth/JwtHelper';
import { AuthNavigator } from 'Helpers/AuthNavigator';
import { LocalStorageStringRepository } from 'Helpers/LocalStorageStringRepository';
import { StringRepository } from 'Helpers/StringRepository';
import { WindowAuthNavigator } from 'Helpers/WindowAuthNavigator';
import { AuthState } from 'Models/Auth';
import { UserResponse } from 'Models/User';

const ACCESS_TOKEN = 'access_token';
const redirectUriEncoded = encodeURIComponent(AWSConfig.oidc.REDIRECT_URI!);

/**
 * See `Auth.md` for information on this component and related components.
 */
export class OktaAuthHandler implements AuthHandler, LogoutApi {
    authNavigator: AuthNavigator;
    stringRepository: StringRepository;
    authApi: AuthApi;

    constructor(authApi: AuthApi = new AWSAuthApi(), authNavigator: AuthNavigator = new WindowAuthNavigator(), stringRepository: StringRepository = new LocalStorageStringRepository()) {
        this.authNavigator = authNavigator;
        this.stringRepository = stringRepository;
        this.authApi = authApi;
    }

    // Returns a boolean indicating whether the app should be rendered.
    pageLoaded = (): boolean => {
        const isLogin = this.authNavigator.getIsLogin();
        const isLogout = this.authNavigator.getIsLogout();
        const isAuthenticatedPath = !isLogin && !isLogout;

        if (isLogin) {
            return this.processLogin();
        } else {
            const accessTokenDoesNotExist = this.getAccessToken() === null;

            if (isAuthenticatedPath && accessTokenDoesNotExist) {
                this.beginAuthorizationCodeFlow();
                return false;
            } else {
                return true;
            }
        }
    };

    processLogin = (): boolean => {
        if (this.authNavigator.getLoginError() !== null) {
            this.authNavigator.navigateToLogout(AuthState.ERROR);
            return true;
        }

        const loginResult = this.authNavigator.getLoginSuccessResult();
        const hasBeenRedirectedByAuthServer = loginResult !== undefined;

        if (hasBeenRedirectedByAuthServer) {
            const stateObject = this.stringRepository.getItem(loginResult!.stateGuid);

            if (stateObject) {
                this.authApi
                    .login(loginResult!.code)
                    .then((accessToken: string): void => {
                        this.stringRepository.removeItem(loginResult!.stateGuid);
                        this.setAccessToken(accessToken);
                        this.authNavigator.completeOidcLogin(JSON.parse(stateObject).destinationPath);
                    })
                    .catch((): void => {
                        this.authNavigator.navigateToLogout(AuthState.ERROR);
                    });
                return false;
            } else {
                this.authNavigator.navigateToLogout(AuthState.ERROR);
                return true;
            }
        } else {
            this.beginAuthorizationCodeFlow();
            return false;
        }
    };

    // TODO: do we want user name claims in the access token, or is this approach sufficient?
    getAuthenticatedUserFullName = (users: UserResponse[]): string => {
        const accessToken = this.getAccessToken();
        const claims = getClaims(accessToken!);
        const user = users.find((user) => user.cognito_subject === claims.hps_user_id);
        return `${user?.first_name} ${user?.last_name}`;
    };

    getAuthenticatedUserSubject = (): string | undefined => {
        const accessToken = this.getAccessToken();
        if (!accessToken) {
            this.authNavigator.navigateToLogout(AuthState.LOGGED_OUT);
            return;
        }
        const claims = getClaims(accessToken!);

        return claims.hps_user_id;
    };

    getUserRoles = (): Role[] => {
        const accessToken = this.getAccessToken();
        if (!accessToken) {
            this.authNavigator.navigateToLogout(AuthState.LOGGED_OUT);
            return [];
        }
        const claims = getClaims(accessToken!);
        if (!claims.hps_roles) {
            return [];
        }

        const validRoleStrings = Object.values(Role) as string[];

        return claims.hps_roles
            .map((role: string): Role | null => {
                // I have no idea why we are accommodating roles that aren't in the case (upper/lower) we have defined them in.
                // I'm almost certain we don't actually need this line, and I suspect that we weren't familiar enough with how enums work when this code was written.
                // But I'm leaving this line in for now. It's possible that there is a good reason we wrote this logic and covered it with tests.
                const lowercasedRole = role.toLowerCase();
                if (validRoleStrings.includes(lowercasedRole)) {
                    return lowercasedRole as Role;
                }

                return null;
            })
            .filter((role: Role) => role !== null);
    };

    getAccessToken = (): string | null => {
        return this.stringRepository.getItem(ACCESS_TOKEN);
    };

    setAccessToken = (newAccessToken: string): void => {
        this.stringRepository.setItem(ACCESS_TOKEN, newAccessToken);
    };

    userUnauthorized = (): void => {
        this.stringRepository.removeItem(ACCESS_TOKEN);
        this.authNavigator.navigateToLogout(AuthState.UNAUTHENTICATED);
    };

    logIn = async (): Promise<void> => {
        const accessToken = this.getAccessToken();
        if (accessToken) {
            this.authNavigator.navigateToLoginDestination();
        } else {
            this.beginAuthorizationCodeFlow();
        }
    };

    logOut = async (): Promise<void> => {
        const accessToken = this.getAccessToken();
        if (accessToken) {
            await this.authApi.logout(accessToken);
            this.stringRepository.removeItem(ACCESS_TOKEN);
        }
        this.authNavigator.navigateToLogout(AuthState.LOGGED_OUT);
    };

    timedOut = async (): Promise<void> => {
        this.stringRepository.removeItem(ACCESS_TOKEN);
        this.authNavigator.navigateToLogout(AuthState.TIMEOUT);
    };

    beginAuthorizationCodeFlow = (): void => {
        const destinationPath = this.authNavigator.getLoginDestination();
        const stateGuid = uuidv4();

        const stateObject = {
            destinationPath: destinationPath,
        };

        this.stringRepository.setItem(stateGuid, JSON.stringify(stateObject));

        this.authNavigator.initiateOidcLogin(`${AWSConfig.oidc.ORG_URL}/oauth2/default/v1/authorize?client_id=${AWSConfig.oidc.CLIENT_ID}&response_type=code&scope=openid%20offline_access&redirect_uri=${redirectUriEncoded}&state=${stateGuid}`);
    };
}
