import moment from 'moment';

import { ScheduleFrequency } from 'Models/ScheduleFrequency';

/**
 * This utility function converts a ScheduleFrequency enum value to a moment.js unit of time value.
 */
const scheduleFrequencyToMomentConversion = (frequency: ScheduleFrequency): moment.unitOfTime.DurationConstructor => {
    switch (frequency) {
        case ScheduleFrequency.DAYS:
        case ScheduleFrequency.DAYS.toUpperCase():
            return 'd';
        case ScheduleFrequency.MONTHS:
        case ScheduleFrequency.MONTHS.toUpperCase():
            return 'M';
        case ScheduleFrequency.YEARS:
        case ScheduleFrequency.YEARS.toUpperCase():
            return 'y';
    }

    return 'M';
};

/**
 * This utility function determines the next due date, given a start date and frequency (number and units).
 */
export const getNextDueDate = (reviewStartDate: Date, reviewScheduleNumber: string, reviewScheduleFrequency: ScheduleFrequency) => {
    return moment(reviewStartDate).add(+reviewScheduleNumber, scheduleFrequencyToMomentConversion(reviewScheduleFrequency)).format('MM/DD/YYYY');
};
