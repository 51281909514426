/*
    Qualys.tsx -- Modal for configuring the Qualys integration.
*/

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

export const Qualys = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [platform, setPlatform] = useState<string | undefined>(undefined);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const { name, value } = event.currentTarget;
        if (name === 'username') setUsername(value);
        if (name === 'password') setPassword(value);
        if (name === 'platform') setPlatform(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(username, password, platform);
            await props.externalIntegrationsApi.setExternalIntegration(IntegrationName.QUALYS, {
                username: username!,
                password: password!,
                platform: platform!,
            });
            setSuccessMessage('Qualys integration configured.');
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const validateForm = (username?: string, password?: string, platform?: string): void => {
        if (!username || username.length === 0) {
            throw new ValidationError('Invalid username.');
        }
        if (!password || password.length === 0) {
            throw new ValidationError('Invalid password.');
        }
        if (!platform || platform.length === 0) {
            throw new ValidationError('Invalid platform.');
        }
    };

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.QUALYS);
            setSuccessMessage('Qualys integration deleted.');
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Configure Qualys Integration" />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText formFieldId="username" formFieldLabel="Username" required={true} tooltip="The API username provided by Qualys." invalidMessage="Please enter a valid Qualys API username." handleChange={handleChange} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText formFieldId="password" formFieldLabel="Password" formFieldType="password" required={true} tooltip="The API password provided by Qualys." invalidMessage="Please enter a valid Qualys API password." handleChange={handleChange} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText formFieldId="platform" formFieldLabel="Platform" required={true} placeholder="Example: US1" tooltip="The Qualys platform your account is hosted on. See: https://www.qualys.com/platform-identification/" invalidMessage="Please enter a valid Qualys platform." handleChange={handleChange} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={faTrash}>
                            DELETE
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={faTimes}>
                                    CLOSE
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting} isLoading={isSubmitting} loadingText="Saving...">
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
