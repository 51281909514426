import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { CreateControlConfigurationRequest } from 'Models/TPRM';

import { Group, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface CreateControlModalProps extends QuestionnaireConfigurationModal {
    group: Group;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    controlToCreate?: CreateControlConfigurationRequest;
    complete: boolean;
}

export const CreateControlModal = (props: CreateControlModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<Partial<CreateControlConfigurationRequest>>({ control_name: '', control_text: [] });
    const [isValid, setIsValid] = useState<boolean>();

    useEffect(() => {
        if (formFieldsState?.control_name && formFieldsState.control_text) {
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState]);

    useEffect(() => {
        const createControl = async (request: CreateControlConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.createControlConfiguration(props.group.framework, props.group.groupId, request);
                setFormState({ isLoading: false, successMessage: 'Control created.', complete: true });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.controlToCreate && isValid) {
            createControl(formState.controlToCreate);
        }
    }, [props.tprmApi, formState.controlToCreate, isValid, props.group, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.control_name && formFieldsState.control_text) {
            const controlToCreate: CreateControlConfigurationRequest = {
                control_name: formFieldsState.control_name,
                control_text: formFieldsState.control_text,
            };
            setFormState({ isLoading: false, controlToCreate: controlToCreate, complete: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event.currentTarget.name === 'control_text') {
            setFormFieldsState({
                ...formFieldsState,
                control_text: [{ text: event.currentTarget.value, children: [] }],
            });
        } else {
            setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Create Control Configuration" />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_name} formFieldId="control_name" formFieldLabel="Name" required handleChange={handleChange} tooltip="The name used to identify this control." />
                    </div>
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_text?.[0]?.text} formFieldId="control_text" formFieldLabel="Text" required handleChange={handleChange} tooltip="A description of what is assessed by this control." />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={formState.isLoading || !isValid || formState.complete} isLoading={formState.isLoading} loadingText="Creating...">
                            CREATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
