import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { titleCaseRequirementStatus } from 'Models/ComplianceRequirements';
import { RequirementExportRow } from 'Models/Exports';
import { OperationalControl } from 'Models/OperationalControls';
import { PaginatedResponseModel } from 'Models/ResponseModel';

export interface ComplianceRequirementsProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ComplianceRequirementsExport = (props: ComplianceRequirementsProps): JSX.Element => {
    const [requirements, setRequirements] = useState<RequirementExportRow[]>();
    const [error, setError] = useState();

    useEffect(() => {
        const getComplianceRequirementsExport = async (): Promise<void> => {
            let nextPage;
            let tempRequirements: RequirementExportRow[] = [];
            try {
                do {
                    const getComplianceRequirementsExportResponse: PaginatedResponseModel<RequirementExportRow[]> = await props.exportsApi.getComplianceRequirementsExport(nextPage);
                    tempRequirements = tempRequirements.concat(getComplianceRequirementsExportResponse.data);
                    setRequirements(tempRequirements);
                    nextPage = getComplianceRequirementsExportResponse.next_page;
                } while (nextPage);
            } catch (error) {
                setError(error.message);
            }
        };

        getComplianceRequirementsExport();
    }, [props.exportsApi]);

    const columns: GridColumn<RequirementExportRow>[] = [
        { field: 'regulation', headerName: 'Regulation', width: 300 },
        { field: 'requirement_id', headerName: 'Requirement ID', width: 300 },
        { field: 'text', headerName: 'Requirement Text', width: 300 },
        {
            field: 'status',
            headerName: 'Status',
            width: 300,
            valueGetter: (value) => titleCaseRequirementStatus(value),
        },
        dateColumn('last_updated', 'Last Updated'),
        {
            field: 'controls',
            headerName: 'Controls',
            width: 300,
            valueGetter: (_value, row) =>
                row.controls
                    .sort(controlComparator)
                    .map((control: OperationalControl) => getHumanReadableControlIdFromControl(control))
                    .join(', '),
        },
    ];

    if (requirements) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Compliance Requirements</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Compliance Requirements Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(compliance_requirement: RequirementExportRow) => `${compliance_requirement.regulation}#${compliance_requirement.requirement_id}`} rows={requirements} title="Compliance Requirements Export Preview" fileName="Compliance Requirements" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
