import { Text } from 'Components/Text/Text';
import { RiskHistory, RiskResponse, formatTotalImpactOrProbability } from 'Models/RiskRegister';

import styles from './RiskListingCallout.module.css';

export interface RiskListingCalloutProps {
    calloutType: 'total_inherent_risk' | 'control_environment_effectiveness' | 'total_current_risk' | 'total_target_risk';
    completeEnvironmentEffectivenessAverage: number;
    risks: RiskResponse[] | RiskHistory[];
}

export const RiskListingCallout = (props: RiskListingCalloutProps): JSX.Element => {
    const getRiskStats = (riskType: keyof RiskResponse): { total?: number; average?: number } => {
        const definedValues = props.risks
            .map((risk) => {
                switch (riskType) {
                    case 'total_target_risk':
                        return risk.total_target_risk ?? risk.total_current_risk;
                    default:
                        return risk[riskType];
                }
            })
            .filter((value) => value !== undefined);
        const total = definedValues.length ? (definedValues as number[]).reduce((a, b) => a + b, 0) : undefined;
        const average = total !== undefined && definedValues.length ? total / definedValues.length : undefined;
        return { total: riskType === 'control_environment_effectiveness' ? props.completeEnvironmentEffectivenessAverage : total, average };
    };

    return (
        <>
            <div className={styles.innerAverageCell} data-testid={props.calloutType}>
                <div className={styles.stat}>
                    <Text noStyles variant="Header4" color="darkGray">
                        {props.calloutType === 'control_environment_effectiveness' ? 'RISK-RELATED' : 'AVERAGE'}
                    </Text>
                    <Text noStyles variant="Text1" color="white">
                        {formatTotalImpactOrProbability(getRiskStats(props.calloutType).average)}
                    </Text>
                </div>
                <div className={styles.stat}>
                    <Text noStyles variant="Header4" color="darkGray">
                        {props.calloutType === 'control_environment_effectiveness' ? 'OVERALL' : 'TOTAL'}
                    </Text>
                    <Text noStyles variant="Text1" color="white">
                        {formatTotalImpactOrProbability(getRiskStats(props.calloutType).total)}
                    </Text>
                </div>
            </div>
        </>
    );
};
