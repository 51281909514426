import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { LineAndScatterChart, LineAndScatterChartProps, LineAndScatterChartType } from 'Components/BaseCharts/LineAndScatterChart';
import { CSSColors } from 'Components/Colors';
import { FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { ChangeEventType, FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import { Text } from 'Components/Text/Text';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { DashboardRiskOptions, DateOptionSelectOptions, DefaultDateRangeSelectOptions, TprmDashboardRiskType, isValidDashboardRiskType, isValidDateOption } from 'Models/Dashboards';
import { CustomDateRange, CustomDateRangeModal, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';

import { getDatesFromPreset, useDateFilter, useRiskPortfolioHistoryQuery } from '../TPRMDashboard.hooks';
import styles from '../TPRMDashboard.module.css';

enum Modals {
    NONE,
    CUSTOM_DATE,
}

interface RiskPortfolioHistoryChartProps {
    dashboardApi: DashboardApi;
}

export const RiskPortfolioHistoryChart = (props: RiskPortfolioHistoryChartProps): JSX.Element => {
    const { riskPortfolioHistory, updateDateFilters, riskPortfolioHistoryError } = useRiskPortfolioHistoryQuery(props.dashboardApi);
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [selectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const [riskType, setRiskType] = useState<TprmDashboardRiskType>(TprmDashboardRiskType.RESIDUAL);
    const {
        dateFilter,
        updateDateFilter: { setPreset, setDateRange },
    } = useDateFilter(DateOptionSelectOptions.PREVIOUS_QUARTER);

    const chartProps = useMemo<LineAndScatterChartProps | undefined>(() => {
        if (riskPortfolioHistory) {
            const months = riskPortfolioHistory.map((summary) => summary.month.month);

            return {
                onChartClick: () => void 0,
                xAxisLabels: months.map((month) =>
                    moment()
                        .month(month - 1)
                        .format('MMM')
                        .toUpperCase()
                ),
                yAxisInfo: [{ name: `Average ${riskType}`.toUpperCase(), position: 'left', min: 0, max: 5, interval: 1 }],
                series: [
                    {
                        name: riskType,
                        color: CSSColors.LIGHT_BLUE,
                        data: riskPortfolioHistory.map((summary) => {
                            switch (riskType) {
                                case TprmDashboardRiskType.INHERENT:
                                    return Number(summary.statistics.average_inherent_risk.toFixed(2));
                                case TprmDashboardRiskType.CONTROL_EFFECTIVENESS:
                                    return Number(summary.statistics.average_control_effectiveness.toFixed(2));
                                case TprmDashboardRiskType.RESIDUAL:
                                default:
                                    return Number(summary.statistics.average_residual_risk.toFixed(2));
                            }
                        }),
                    },
                ],
                type: LineAndScatterChartType.LINE,
            };
        }
    }, [riskPortfolioHistory, riskType]);

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDashboardRiskType(value)) {
            setRiskType(value);
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setDateRange(customDateRange);
        updateDateFilters.setStartDate(jsDateToIso8601(customDateRange.startDate));
        updateDateFilters.setEndDate(jsDateToIso8601(customDateRange.endDate));
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                const presetValue = getDatesFromPreset(value);
                updateDateFilters.setStartDate(jsDateToIso8601(presetValue.start));
                updateDateFilters.setEndDate(jsDateToIso8601(presetValue.end));
            }

            setPreset(value);
        }
    };

    if (riskPortfolioHistoryError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Portfolio Risk History
                    </Text>
                </div>
                <Text color="white">{riskPortfolioHistoryError}</Text>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="pastDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Portfolio Risk History
                </Text>
                <div className={styles.filters}>
                    <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" options={DashboardRiskOptions} selectedOption={riskType} handleChange={handleRiskTypeChange} />
                    <FormFieldDateOptionSelect formFieldId="date" options={DefaultDateRangeSelectOptions} selectedOption={dateFilter.preset} handleChange={handleDateFilterChange} />
                </div>
            </div>
            {chartProps ? <LineAndScatterChart {...chartProps} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}
        </>
    );
};
