/** Permissions-related error messages. */
// A user made an API request for which they do not have the proper role.
export const API_FORBIDDEN_RESPONSE_MESSAGE = 'Unauthorized request.';
// Explains to a user with no role why they are not seeing anything.
export const NO_ROLE_MESSAGE = "You are not assigned to any role, though you may still receive notifications from SummIT Security. Contact your organization's administrator if you need to be assigned a role.";
// A user tried to mount a component for which they do not have the proper role.
export const UNAUTHORIZED_MESSAGE = 'You are not authorized to view this page.';

/** Other error messages. */
// Something unexpected happened.
export const GENERIC_ERROR_MESSAGE = 'Something went wrong. If this problem persists, please contact High Peaks Solutions at support@highpeakssolutions.com.';
// The WAF blocked a request.
export const WAF_BLOCK_MESSAGE = 'Request blocked. For assistance, contact High Peaks Solutions at support@highpeakssolutions.com.';
