import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { Button, Link } from 'Components/Buttons/Buttons';
import { DropdownItemClickType, PrimaryAndSecondaryDropdownButtonProps, SecondaryDropdownButton } from 'Components/Buttons/DropdownButton';
import { ComplianceRequirementStatus } from 'Components/ComplianceRequirements/ComplianceRequirementStatus';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { Text } from 'Components/Text/Text';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { ComplianceRequirementStatusValues, ControlSetDetails, titleCaseRequirementStatus } from 'Models/ComplianceRequirements';

import styles from './ModifyControlRow.module.css';

export const MaxControlsShown = 6; // Exported so this can be imported by tests.

export interface ModifyControlRowProps {
    mappedControlSet: ControlSetDetails;
    requirementName?: string;
    openModifyControlSetModal: (selectedControlSet: string[]) => void;
    openDeleteControlSetModal: (selectedControlSet: string[]) => void;
}

export const ModifyControlRow = (props: ModifyControlRowProps): JSX.Element => {
    const [showAllControls, setShowAllControls] = useState(false);
    const requirementControlGroupDropdownProps: PrimaryAndSecondaryDropdownButtonProps = {
        dropdownItems: [
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Modify Controls',
                onClick: () => props.openModifyControlSetModal(props.mappedControlSet.control_list.map((control) => control.control.identifier)),
                icon: faPlus,
            },
            {
                _type: DropdownItemClickType.ONCLICK,
                text: 'Delete Control Set',
                onClick: (): void => props.openDeleteControlSetModal(props.mappedControlSet.control_list.map((control) => getHumanReadableControlIdFromControl(control.control))),
                icon: faTrash,
            },
        ],
    };

    const controlSetStatusTooltip = (): JSX.Element => {
        if (props.mappedControlSet.status === ComplianceRequirementStatusValues.MET) {
            return <GenericTooltip text={`This control set has a status of MET because the assessment for at least one control has assessed ${props.requirementName} as MET.`}></GenericTooltip>;
        } else if (props.mappedControlSet.status === ComplianceRequirementStatusValues.NOT_MET) {
            return <GenericTooltip text={`This control set has a status of NOT MET because the assessment for each control has assessed ${props.requirementName} as NOT MET.`}></GenericTooltip>;
        } else {
            return <GenericTooltip text={`This control set will have a status once a control assessment assesses ${props.requirementName} as MET or each control assessment assesses ${props.requirementName} as NOT MET.`}></GenericTooltip>;
        }
    };
    return (
        <>
            <div className={styles.modifyControlRow}>
                <div className={styles.controlGroupListing}>
                    <PageCell>
                        <div className={styles.controlGroupListingContent}>
                            <div className={styles.contentContainer}>
                                <div className={styles.controlGroupListingContentHeader}>
                                    <Text variant="Header4" color="darkBlue" toUppercase>
                                        Do any of these controls meet the requirement?
                                    </Text>
                                </div>
                                <div className={styles.controlsContainer}>
                                    {props.mappedControlSet.control_list.slice(0, showAllControls ? undefined : MaxControlsShown).map((mappedControl, index) => {
                                        return (
                                            <div className={styles.controlContainer} key={index}>
                                                <div className={styles.iconContainer}>
                                                    <ComplianceRequirementStatus status={mappedControl.status} />
                                                </div>
                                                <div className={styles.dropdownButtonContainer}>
                                                    <Link size="sm" to={getFrameworkGroupControlURL(mappedControl.control.identifier)}>
                                                        {getHumanReadableControlIdFromControl(mappedControl.control)}
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {props.mappedControlSet.control_list.length > MaxControlsShown && (
                                    <div className={styles.showAllButton}>
                                        <Button variant="linkText" size="sm" onClick={() => setShowAllControls(!showAllControls)}>
                                            {!showAllControls ? 'Show All Mapped Controls' : 'Collapse Mapped Controls'}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={styles.dropdownButton}>
                                <SecondaryDropdownButton {...requirementControlGroupDropdownProps}>Modify Control Set</SecondaryDropdownButton>
                            </div>
                        </div>
                    </PageCell>
                </div>
                <div className={styles.groupOutcomeContainer}>
                    <PageCell>
                        <div className={styles.groupOutcome}>
                            <Text variant="Header4" color="darkBlue" toUppercase>
                                result
                            </Text>
                            {props.mappedControlSet.status === undefined ? (
                                <div className={styles.groupResultTextContainer}>
                                    <div className={styles.resultIconContainer}>
                                        <Text variant="Header2" color="blue" noStyles>
                                            -
                                        </Text>
                                    </div>
                                    <Text variant="Header2" color="blue" noStyles>
                                        Awaiting Assessments
                                    </Text>
                                    <div className={styles.tooltip}>{controlSetStatusTooltip()}</div>
                                </div>
                            ) : (
                                <div className={styles.groupResultTextContainer}>
                                    <div className={styles.resultIconContainer}>
                                        <ComplianceRequirementStatus status={props.mappedControlSet.status} />
                                    </div>
                                    <Text variant="Header2" color="blue" noStyles>
                                        {titleCaseRequirementStatus(props.mappedControlSet.status)}
                                    </Text>
                                    <div className={styles.tooltip}>{controlSetStatusTooltip()}</div>
                                </div>
                            )}
                        </div>
                    </PageCell>
                </div>
            </div>
        </>
    );
};
