import { faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { UserNameFormat, getUserNameFromUser } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

import styles from './ReactivateUserModal.module.css';

export interface ReactivateUserModalProps {
    hideModal: () => void;
    userSelectedForReactivation: UserResponse;
    usersApi: UsersApi;
    userReactivated: () => void;
}

export interface ReactivateUserModalState {
    successMessage?: string;
    failureMessage?: string;
    isReactivating: boolean;
}

export const ReactivateUserModal = (props: ReactivateUserModalProps): JSX.Element => {
    const [isReactivating, setIsReactivating] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);

    const reactivateUser = async (): Promise<void> => {
        setIsReactivating(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.usersApi.reactivateUser(props.userSelectedForReactivation.cognito_subject);
            setSuccessMessage('User reactivated.');
            props.userReactivated();
        } catch (error) {
            handleRequestError(error);
        } finally {
            setIsReactivating(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isReactivating) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Reactivate" />
                <div className={styles.reactivateText}>Reactivating a user will allow the user to login to SummIT and receive notifications.</div>
                <div className={styles.reactivateText}>Are you sure you want to reactivate: {getUserNameFromUser(props.userSelectedForReactivation, UserNameFormat.FIRST_SPACE_LAST)}?</div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={hideModal} disabled={isReactivating} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                    <Button variant="danger" onClick={() => reactivateUser()} fontAwesomeImage={faUser} loadingText={'Reactivating...'} isLoading={isReactivating} disabled={successMessage ? true : false}>
                        REACTIVATE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
