/*
	AWSClientDetailsApi.ts -- AWS-specific interface to retrieve Client-specific details and platform documentation.
*/

import { CLIENTS, DETAILS, DOCUMENTS } from 'Config/Paths';
import { ClientDetailsModel } from 'Models/ClientDetailsModel';
import { FileDownloadResponse } from 'Models/Files';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiGet } from 'RestApiConnector';

import { ClientDetailsApi } from './ClientDetailsApi';

export class AWSClientDetailsApi implements ClientDetailsApi {
    getClientDetails = (): Promise<ResponseModel<ClientDetailsModel>> => {
        return restApiGet(`/${CLIENTS}/${DETAILS}`, {});
    };

    getCustomerDocumentation = (fileName: string): Promise<ResponseModel<FileDownloadResponse>> => {
        return restApiGet(`/${DOCUMENTS}/${fileName}`);
    };
}
