import { IntegrationName, MetricName } from './ExternalIntegrations';
import { ControlText, Effectiveness } from './OperationalControls';
import { OptionType } from './Types/GlobalType';

export type LimitResponse = EffectivenessLimit | MetricLimit;
export interface Limit {
    id: string;
    title: string;
    enabled: boolean;
    entity_id: string;
    // This is undefined for Framework Limits, since a Framework has no description.
    // This is a string for Group Limits, since a Group has a description.
    // This is a ControlText[] for Control Limits, Control text is a nested structure.
    entity_description?: string | ControlText[];
    _type: LimitType;
}

export interface EffectivenessLimit extends Limit {
    alert_limit: Effectiveness;
}

export interface MetricLimit extends Limit {
    integration_name: IntegrationName;
    metric_name: MetricName;
    alert_limit: number;
    alert_preference: MetricAlertPreference;
}

export interface CreateEffectivenessLimitRequest {
    alert_limit: Effectiveness;
    enabled: boolean;
    entity_id: string;
    _type: LimitType;
}

export interface CreateMetricLimitRequest {
    entity_id: string;
    integration_name: IntegrationName;
    metric_name: MetricName;
    alert_limit: number;
    enabled: boolean;
    alert_preference: MetricAlertPreference;
    _type: LimitType;
}
export interface ModifyLimitRequest {
    enabled?: boolean;
    alert_limit?: Effectiveness | number;
    alert_preference?: MetricAlertPreference;
}

export enum LimitType {
    EFFECTIVENESS = 'EFFECTIVENESS',
    METRIC = 'METRIC_VALUE',
}

export enum MetricAlertPreference {
    WHEN_POLLED = 'WHEN_POLLED',
    WHEN_BREACHED = 'WHEN_BREACHED',
    ONCE_PER_DAY = 'ONCE_PER_DAY',
}

// Keys must match the keys of MetricName defined in src\Models\ExternalIntegrations.ts.
// Limit value enforcement is defined in src\Helpers\Limits.ts.
export enum MetricLimitDescription {
    ACTIVE_VULNERABILITIES = 'Generate an alert if the number of active vulnerabilities exceeds the limit value. Valid limit values: 0+', // Rapid7 Insight Platform
    AGGREGATED_RISK_SCORE = "Generate an alert if the summed risk score for all your organization's assets exceeds the limit value. Valid limit values: 0+", // Rapid7 Insight Platform
    AVERAGE_THIRD_PARTY_SCORE = 'Generate an alert if the average score of your third parties falls below the limit value. Valid limit values: 0.01 - 10', // RiskRecon
    CRITICAL_THIRD_PARTY_SECURITY_RATINGS = "Generate an alert if any of your critical third parties' security rating falls below the limit value. Valid limit values: 250 - 900", // BitSight
    CLOUD_TRAIL_ENABLED = 'Generate an alert if the number of AWS accounts that do not have CloudTrail enabled exceeds the limit value. Valid limit values: 0+', // AWS Config
    DATA_LOSS_EVENTS = 'Generate an alert if the number of data loss events for your organization exceeds the limit value. Valid limit values: 0+', // RiskRecon
    DEVICE_COUNT = 'Generate an alert if the device count from any source falls below the limit value. Valid limit: 0+', // Microsoft
    DYNAMODB_PITR_ENABLED = 'Generates an alert if the number of DynamoDB tables with point-in-time-recovery enabled exceeds the limit value. Valid limit values: 0+', // AWS Config
    ENDPOINT_ENCRYPTION = 'Generate an alert if the number of endpoints with encryption disabled exceeds the limit value. Valid limit values: 0+', // Sophos
    ENDPOINT_HEALTH = 'Generate an alert if the number of endpoints that have Sophos products in a degraded state exceeds the limit value. Valid limit values: 0+', // Sophos
    EXPOSURE_SCORE = "Generate an alert if your organization's overall exposure score exceeds the limit value. Valid limit values: 0+", // Microsoft
    LAMBDA_FUNCTION_PUBLIC_ACCESS_PROHIBITED = 'Generate an alert if the number of publicly accessible Lambda functions exceeds the limit value. Valid limit values: 0+', // AWS Config
    MALICIOUS_EMAILS = 'Generate an alert if the number of malicious emails delivered exceeds the limit value. Valid limit values: 0+', // Proofpoint Targeted Attack Protection
    MALWARE_FAMILIES = 'Generate an alert if the number of unique malware families observed exceeds the limit value. Valid limit values: 0+', // Proofpoint Targeted Attack Protection
    MY_OVERALL_SCORE = "Generate an alert if your organization's overall score falls below the limit value. Valid limit values: 0.01 - 10", // RiskRecon
    MY_SECURITY_RATING = "Generate an alert if your organization's security rating falls below the limit value. Valid limit values: 250 - 900", // BitSight
    NONCOMPLIANT_DEVICES = 'Generate an alert if the number of noncompliant devices exceeds the limit value. Valid limit values: 0+', // Microsoft
    OUTDATED_SCANS = 'Generate an alert if the number of assets that were last scanned for vulnerabilities over 30 days ago exceeds the limit value. Valid limit values: 0+', // Rapid7 Insight Platform
    OVERDUE_ACCESS_REVIEWS = 'Generate an alert if the number of overdue access reviews exceeds the limit value. Valid limit values: 0+', // Microsoft
    OVERDUE_SECURITY_TRAINING = 'Generate an alert if the number of overdue security trainings exceeds the limit value. Valid limit values: 0+', // Microsoft
    OVERDUE_VULNERABILITIES = 'Generate an alert if the number of overdue vulnerabilities exceeds the limit value. Valid limit values: 0+', // Microsoft, Qualys, Tenable
    PEER_SECURITY_RATINGS = "Generate an alert if any of your peers' security ratings falls below the limit value. Valid limit values: 250 - 900", // BitSight
    PERCENT_THREATS_BLOCKED = 'Generate an alert if the percent of email threats blocked by Proofpoint Targeted Attack Protection falls below the limit value. Valid limit values: 0.01 - 100', // Proofpoint Targeted Attack Protection
    PHISHING_CLICKS = 'Generate an alert if the number of clicks on phishing emails exceeds the limit value. Valid limit values: 0+', // Proofpoint Targeted Attack Protection
    RISKY_DEVICES = 'Generate an alert if the number of risky devices exceeds the limit value. Valid limit values: 0+', // Microsoft
    RISKY_PORTS = 'Generate an alert if the number of "Unsafe Network Services" exceeds the limit value. Valid limit values: 0+', // RiskRecon
    RISKY_SIGN_IN_EVENTS = 'Generate an alert if the number of risky sign-in events exceeds the limit value. Valid limit values: 0+', // Microsoft
    S3_ACCOUNT_LEVEL_PUBLIC_ACCESS_BLOCKS_PERIODIC = 'Generate an alert if the number of AWS accounts that do not block public access to S3 buckets exceeds the limit value. Valid limit values: 0+', // AWS Config
    S3_BUCKET_SERVER_SIDE_ENCRYPTION_ENABLED = 'Generate an alert if the number of S3 buckets without server-side encryption exceeds the limit value. Valid limit values: 0+', // AWS Config
    SECURE_SCORE = "Generate an alert if your organization's secure score falls below the limit value. Valid limit values: 0+", // Microsoft
    SIMULATED_PHISHING_CAMPAIGN_RESULTS = 'Generate an alert if the percent of users who click on a simulated phishing email in a campaign exceeds the limit value. Valid limit values: 0 - 100', // Proofpoint Security Awareness Training
    SIMULATED_PHISHING_RESULTS = 'Generate an alert if the number of clicks on simulated phishing emails exceeds the limit value. Valid limit values: 0+', // Proofpoint Security Awareness Training
    UNRESOLVED_ALERTS = 'Generate an alert if the number of unresolved alerts exceeds the limit value. Valid limit values: 0+', // Microsoft
    // THIRD_PARTY_MONITORING is not defined because it cannot be mapped to Controls.
    VERY_ATTACKED_PEOPLE_SUMMARY = 'Generate an alert if the attack index exceeds the limit value. Valid limit values: 0+', // Proofpoint Targeted Attack Protection
    WEB_ENCRYPTION_FINDINGS = 'Generate an alert if the number of "Web Encryption" issues exceeds the limit value. Valid limit values: 0+', // RiskRecon
}

export const MetricAlertPreferenceSelectOptions: OptionType[] = [
    {
        value: MetricAlertPreference.ONCE_PER_DAY,
        label: 'Daily',
    },
    {
        value: MetricAlertPreference.WHEN_BREACHED,
        label: 'On-Breach',
    },
    {
        value: MetricAlertPreference.WHEN_POLLED,
        label: 'On-Poll',
    },
];

export const EffectivenessLimitsSelectOptions: OptionType[] = [
    {
        value: 2,
        label: 'Weak',
    },
    {
        value: 3,
        label: 'Moderate',
    },
    {
        value: 4,
        label: 'Strong',
    },
    {
        value: 5,
        label: 'Robust',
    },
];
