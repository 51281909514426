/**
 * WARNING: ControlsApi.getControlsHierarchy() MUST be mocked when testing components that call useControlMappingItems() or the tests will fail with a generic "Cannot read property 'data' of undefined" error.
 */
import { useEffect, useState } from 'react';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { discardDisabledControls } from 'Helpers/DiscardDisabledControls';
import { ControlHierarchyFramework } from 'Models/ControlHierarchy';
import { OperationalControl } from 'Models/OperationalControls';
import { ResponseModel } from 'Models/ResponseModel';

export const useControlMappingItems = (api: ControlsApi, currentlyAssociatedControlIdentifier?: string): [OperationalControl[] | undefined, Error | undefined, OperationalControl | undefined] => {
    const [controlMappingItems, setControlMappingItems] = useState<OperationalControl[]>();
    const [error, setError] = useState<Error>();
    const [currentlyAssociatedControl, setCurrentlyAssociatedControl] = useState<OperationalControl>();

    useEffect(() => {
        const getControlHierarchy = async (): Promise<void> => {
            try {
                const response: ResponseModel<ControlHierarchyFramework[]> = await api.getControlsHierarchy();
                const controlList: OperationalControl[] = [];

                let controlHierarchy: ControlHierarchyFramework[] = discardDisabledControls(response.data);
                controlHierarchy = discardEmpty(controlHierarchy);

                controlHierarchy.forEach((framework) => {
                    framework.control_groups.forEach((controlGroup) => {
                        controlGroup.controls.forEach((control) => {
                            if (control.configuration.enabled === true) {
                                controlList.push(control);
                            }
                            if (control.identifier === currentlyAssociatedControlIdentifier) {
                                setCurrentlyAssociatedControl(control);
                            }
                        });
                    });
                });

                setControlMappingItems(controlList);
            } catch (error) {
                setError(error);
            }
        };

        getControlHierarchy();
    }, [api, currentlyAssociatedControlIdentifier]);

    const discardEmpty = (controlHierarchy: ControlHierarchyFramework[]): ControlHierarchyFramework[] => {
        let frameworkIndex = controlHierarchy.length;
        while (frameworkIndex--) {
            let groupIndex = controlHierarchy[frameworkIndex]['control_groups'].length;
            while (groupIndex--) {
                if (controlHierarchy[frameworkIndex]['control_groups'][groupIndex]['controls'].length === 0) {
                    controlHierarchy[frameworkIndex].control_groups.splice(groupIndex, 1);
                }
            }
            if (controlHierarchy[frameworkIndex]['control_groups'].length === 0) {
                controlHierarchy.splice(frameworkIndex, 1);
            }
        }
        return controlHierarchy;
    };

    return [controlMappingItems, error, currentlyAssociatedControl];
};
