/*
    ComplianceRequirementStatus.tsx -- Used to render an icon depending on the status of a Compliance Requirement. This is defined in the shared Components/ folder because it's used in multiple pages (ComplianceRequirements and ControlDetails).
*/

import { faCheck, faMinusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Text } from 'Components/Text/Text';
import { GenericTooltip, IconColor } from 'Components/Tooltips/GenericTooltip';
import { ComplianceRequirementStatusValues } from 'Models/ComplianceRequirements';

export interface ComplianceRequirementStatusProps {
    status?: string | null;
}

export const ComplianceRequirementStatus = (props: ComplianceRequirementStatusProps): JSX.Element => {
    const renderIcon = (): JSX.Element => {
        switch (props.status) {
            case ComplianceRequirementStatusValues.MET:
                return <GenericTooltip text="Requirement Met" fontAwesomeIcon={faCheck} iconColor={IconColor.GREEN} />;
            case ComplianceRequirementStatusValues.MET_PARTIAL:
                return <GenericTooltip text="Requirement Partially Met" fontAwesomeIcon={faMinusCircle} iconColor={IconColor.YELLOW} />;
            case ComplianceRequirementStatusValues.NOT_MET:
                return <GenericTooltip text="Requirement Not Met" fontAwesomeIcon={faTimes} iconColor={IconColor.RED} />;
            case ComplianceRequirementStatusValues.BASELINE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.BASELINE}
                    </Text>
                );
            case ComplianceRequirementStatusValues.EVOLVING:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.EVOLVING}
                    </Text>
                );
            case ComplianceRequirementStatusValues.INTERMEDIATE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.INTERMEDIATE}
                    </Text>
                );
            case ComplianceRequirementStatusValues.ADVANCED:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.ADVANCED}
                    </Text>
                );
            case ComplianceRequirementStatusValues.INNOVATIVE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.INNOVATIVE}
                    </Text>
                );
            case null:
                return (
                    <Text variant="Text3" noStyles>
                        -
                    </Text>
                );
            default:
                return (
                    <Text variant="Text3" noStyles>
                        -
                    </Text>
                );
        }
    };

    return renderIcon();
};
