import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Text } from 'Components/Text/Text';
import { EffectivenessLimit, Limit, LimitResponse, LimitType, MetricLimit } from 'Models/Limits';

import styles from './LimitsTables.module.css';
import { EffectivenessLimitsTable, EffectivenessLimitsTableProps } from '../Effectiveness/EffectivenessLimitsTable/EffectivenessLimitsTable';
import { MetricLimitsTable, MetricLimitsTableProps } from '../Metric/MetricLimitsTable/MetricLimitsTable';

export interface LimitsTablesProps {
    limitResponseList: LimitResponse[];
    displayDeleteLimitModal: (deleteLimitId: string, deleteLimitTitle: string) => void;
    displayModifyLimitModal: (modifyLimit: Limit) => void;
    alertsLimitsApi: AlertsLimitsApi;
    addLimitsButton?: JSX.Element;
}

export const LimitsTables = (props: LimitsTablesProps) => {
    const effectivenessLimits = props.limitResponseList.filter((limit) => limit._type === LimitType.EFFECTIVENESS) as EffectivenessLimit[];
    const metricLimits = props.limitResponseList.filter((limit) => limit._type === LimitType.METRIC) as MetricLimit[];
    const limitsTableProps: EffectivenessLimitsTableProps = {
        limitResponseList: effectivenessLimits,
        displayDeleteLimitModal: props.displayDeleteLimitModal,
        displayModifyLimitModal: props.displayModifyLimitModal,
        alertsLimitsApi: props.alertsLimitsApi,
    };
    const metricLimitsProps: MetricLimitsTableProps = {
        limitResponseList: metricLimits,
        displayDeleteLimitModal: props.displayDeleteLimitModal,
        displayModifyLimitModal: props.displayModifyLimitModal,
        alertsLimitsApi: props.alertsLimitsApi,
    };

    if (effectivenessLimits.length > 0) {
        limitsTableProps.addLimitButton = props.addLimitsButton;
    } else if (metricLimits.length > 0) {
        metricLimitsProps.addLimitsButton = props.addLimitsButton;
    }

    return (
        <div className={styles.limitContainer}>
            {effectivenessLimits.length > 0 && <EffectivenessLimitsTable {...limitsTableProps} />}
            {metricLimits.length > 0 && <MetricLimitsTable {...metricLimitsProps} />}
            {metricLimits.length === 0 && effectivenessLimits.length === 0 && (
                <div className={styles.pageSectionHeader}>
                    <Text>There are no limits created.</Text>
                    {props.addLimitsButton}
                </div>
            )}
        </div>
    );
};
