/*
	index.js -- Initializes the SummIT Security application and injects it into the browser DOM.
*/

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'App';
import { ErrorBoundary } from 'Components/ErrorBoundary/ErrorBoundary';
import { ScrollToTopWhenPathChanges } from 'Components/ScrollToTopWhenPathChanges';

import * as serviceWorker from './serviceWorker';

import './index.css';

createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <ErrorBoundary>
            <ScrollToTopWhenPathChanges />
            <App />
        </ErrorBoundary>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
