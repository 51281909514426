import { TAGS } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { TagCategoryResponse, UpdateTagCategoryRequest } from 'Models/Tags';
import { restApiGet, restApiPost } from 'RestApiConnector';

import { TagsApi } from './TagsApi';

export class AWSTagsApi implements TagsApi {
    getTags = (): Promise<ResponseModel<TagCategoryResponse>> => {
        return restApiGet(`/${TAGS}`);
    };

    updateTags = (updateTagsRequest: UpdateTagCategoryRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TAGS}`, updateTagsRequest);
    };
}
