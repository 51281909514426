import { FormFieldUserMultiSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import { ColorTheme, Filter } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

/**
 * @param colorTheme - The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
 */
export interface UserFilterProps {
    filterLabel: string;
    tooltip?: string;
    onUsersSelected: (filter: Filter) => void;
    userFilterId: string;
    formFieldId: string;
    colorTheme?: ColorTheme;
}

export const UserFilter: React.FC<UserFilterProps> = ({ filterLabel, tooltip, onUsersSelected, userFilterId, formFieldId, colorTheme = 'light' }) => {
    const handleSelectUserChange = (selectedUsers: UserResponse[] | undefined, _: string): void => {
        const userFilterList: string[] = [];
        if (selectedUsers) {
            selectedUsers.forEach((user) => {
                userFilterList.push(user.cognito_subject);
            });
        }
        const userFilter: Filter = {
            key: userFilterId,
            value: userFilterList,
        };

        onUsersSelected(userFilter);
    };

    return <FormFieldUserMultiSelect colorTheme={colorTheme} onUsersSelected={handleSelectUserChange} formFieldId={formFieldId} formFieldLabel={filterLabel} tooltip={tooltip} />;
};
