import { faCheck, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';

import styles from './IdleTimeoutModal.module.css';

export interface IdleTimeoutModalProps {
    handleLogOut: () => void;
    onUserClickSessionKeepAlive: () => Promise<void>;
    timeoutCountdown: number;
}

export const IdleTimeoutModal = (props: IdleTimeoutModalProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const onUserContinueWorking = async () => {
        try {
            setIsLoading(true);
            await props.onUserClickSessionKeepAlive();
            setIsLoading(false);
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text="Session Timeout" />
                <div className={styles.contentContainer}>
                    <Text variant="Text2">Your session will end in: {props.timeoutCountdown}</Text>
                </div>
                <div className={styles.contentContainer}>
                    <Button variant="primary" onClick={onUserContinueWorking} fontAwesomeImage={faCheck} isLoading={isLoading} loadingText="Loading...">
                        Keep Working
                    </Button>
                    <Button variant="danger" onClick={props.handleLogOut} fontAwesomeImage={faSignOutAlt} disabled={isLoading}>
                        Log Out
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
