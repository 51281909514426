import { FormControl, FormGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

interface FormFieldDatePickerProps {
    formFieldId?: string;
    formFieldLabel: string;
    tooltip?: string;
    dateFormat?: string;
    placeholder?: string;
    invalidMessage?: string;
    selected?: any;
    handleChange?: any;
    disabled?: boolean;
    required?: boolean;
    datesRestriction?: 'pastDatesOnly' | 'futureDatesOnly';
    isClearable?: boolean;
}

export const FormFieldDatePicker = (props: FormFieldDatePickerProps) => {
    const [minDate, maxDate] = (() => {
        switch (props.datesRestriction) {
            case 'pastDatesOnly':
                return [undefined, new Date()];
            case 'futureDatesOnly':
                return [new Date(), undefined];
            default:
                return [undefined, undefined];
        }
    })();

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <FormLabel required={props.required}>{props.formFieldLabel}</FormLabel>
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <div className={styles.datePickerOverride}>
                <DatePicker autoComplete="off" name={props.formFieldId} id={props.formFieldId} className={`${styles.customFormControl}`} dateFormat={props.dateFormat} selected={props.selected} placeholderText={props.placeholder} onChange={props.handleChange} disabled={props.disabled} minDate={minDate} maxDate={maxDate} />
            </div>
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
