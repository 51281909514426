import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { UploadedFile } from 'Models/Files';
import { Service } from 'Models/TPRM';

export interface DeleteServiceAssessmentDocumentModalProps {
    tprmApi: TPRMApi;
    service: Service;
    document: UploadedFile;
    hideModal: () => void;
    onDocumentDeleted: () => void;
}

export const DeleteServiceAssessmentDocumentModal = (props: DeleteServiceAssessmentDocumentModalProps): JSX.Element => {
    const deleteDocument = async (): Promise<string> => {
        await props.tprmApi.deleteServiceAssessmentDocument(props.service.vendor_id, props.service.id, props.document.file_id);
        props.onDocumentDeleted();
        return 'Document deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Document" areYouSureText="Are you sure you want to delete the following document?" performOperation={deleteDocument} hideModal={props.hideModal}>
            <Text>{props.document.filename}</Text>
        </ConfirmationModal>
    );
};
