import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { FileToBeUploaded, FileUpdates, UploadedFile } from 'Models/Files';
import { OperationalControl } from 'Models/OperationalControls';
import { thirdPartyNameComparator } from 'Models/TPRM';
import { GroupOptionType, OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import { IssuesExceptionsModule } from './Issues';

export enum ExceptionsSortFilterOptions {
    TITLE = 'title',
    EXPIRATION = 'expiration_date',
    DESCRIPTION = 'description',
    OWNER = 'owner_subject',
    REVIEWER = 'reviewer_subject',
    STATUS = 'status',
    RISK_SCORE = 'risk_score',
    CONTROLS = 'controls',
    THIRD_PARTY = 'impacted_vendor',
}

export enum ExceptionStatus {
    DRAFT_OPEN = 'DRAFT_OPEN',
    APPROVED = 'APPROVED',
    DRAFT_CLOSE = 'DRAFT_CLOSE',
    CLOSED = 'CLOSED',
}

const statusAsNumber = (status: ExceptionStatus): number => {
    switch (status) {
        case ExceptionStatus.DRAFT_OPEN:
            return 0;
        case ExceptionStatus.APPROVED:
            return 1;
        case ExceptionStatus.DRAFT_CLOSE:
            return 2;
        case ExceptionStatus.CLOSED:
            return 3;
    }
};

export const statusComparator = (statusA: ExceptionStatus, statusB: ExceptionStatus): number => {
    if (statusA === statusB) {
        return 0;
    } else {
        const valueA = statusAsNumber(statusA);
        const valueB = statusAsNumber(statusB);
        return valueA < valueB ? -1 : 1;
    }
};

export const ExceptionStatusFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ExceptionsSortFilterOptions.STATUS,
        label: 'Draft Open',
        value: ExceptionStatus.DRAFT_OPEN,
    },
    {
        groupId: ExceptionsSortFilterOptions.STATUS,
        label: 'Approved',
        value: ExceptionStatus.APPROVED,
    },
    {
        groupId: ExceptionsSortFilterOptions.STATUS,
        label: 'Draft Close',
        value: ExceptionStatus.DRAFT_CLOSE,
    },
    {
        groupId: ExceptionsSortFilterOptions.STATUS,
        label: 'Closed',
        value: ExceptionStatus.CLOSED,
    },
];

export const titleCaseExceptionStatus = (status: ExceptionStatus): string => {
    switch (status) {
        case ExceptionStatus.DRAFT_OPEN:
            return 'Draft Open';
        case ExceptionStatus.APPROVED:
            return 'Approved';
        case ExceptionStatus.DRAFT_CLOSE:
            return 'Draft Close';
        case ExceptionStatus.CLOSED:
            return 'Closed';
    }
};

export enum ExceptionLikelihood {
    RARE = 'RARE',
    UNLIKELY = 'UNLIKELY',
    POSSIBLE = 'POSSIBLE',
    LIKELY = 'LIKELY',
    ALMOST_CERTAIN = 'ALMOST_CERTAIN',
}

export const titleCaseLikelihood = (likelihood: ExceptionLikelihood): string => {
    switch (likelihood) {
        case ExceptionLikelihood.RARE:
            return 'Rare';
        case ExceptionLikelihood.UNLIKELY:
            return 'Unlikely';
        case ExceptionLikelihood.POSSIBLE:
            return 'Possible';
        case ExceptionLikelihood.LIKELY:
            return 'Likely';
        case ExceptionLikelihood.ALMOST_CERTAIN:
            return 'Almost Certain';
    }
};

export const ExceptionLikelihoodOptions: OptionType[] = [
    {
        value: ExceptionLikelihood.RARE,
        label: titleCaseLikelihood(ExceptionLikelihood.RARE),
    },
    {
        value: ExceptionLikelihood.UNLIKELY,
        label: titleCaseLikelihood(ExceptionLikelihood.UNLIKELY),
    },
    {
        value: ExceptionLikelihood.POSSIBLE,
        label: titleCaseLikelihood(ExceptionLikelihood.POSSIBLE),
    },
    {
        value: ExceptionLikelihood.LIKELY,
        label: titleCaseLikelihood(ExceptionLikelihood.LIKELY),
    },
    {
        value: ExceptionLikelihood.ALMOST_CERTAIN,
        label: titleCaseLikelihood(ExceptionLikelihood.ALMOST_CERTAIN),
    },
];

export enum ExceptionImpact {
    LOW = 'LOW',
    LOW_MODERATE = 'LOW_MODERATE',
    MODERATE = 'MODERATE',
    MODERATE_HIGH = 'MODERATE_HIGH',
    HIGH = 'HIGH',
}

export const isValidExceptionImpact = (value: unknown): value is ExceptionImpact => Object.values(ExceptionImpact).includes(value as ExceptionImpact);

export const titleCaseImpact = (impact: ExceptionImpact): string => {
    switch (impact) {
        case ExceptionImpact.LOW:
            return 'Low';
        case ExceptionImpact.LOW_MODERATE:
            return 'Low/Moderate';
        case ExceptionImpact.MODERATE:
            return 'Moderate';
        case ExceptionImpact.MODERATE_HIGH:
            return 'Moderate/High';
        case ExceptionImpact.HIGH:
            return 'High';
    }
};

export const ExceptionImpactOptions: OptionType[] = [
    {
        value: ExceptionImpact.LOW,
        label: titleCaseImpact(ExceptionImpact.LOW),
    },
    {
        value: ExceptionImpact.LOW_MODERATE,
        label: titleCaseImpact(ExceptionImpact.LOW_MODERATE),
    },
    {
        value: ExceptionImpact.MODERATE,
        label: titleCaseImpact(ExceptionImpact.MODERATE),
    },
    {
        value: ExceptionImpact.MODERATE_HIGH,
        label: titleCaseImpact(ExceptionImpact.MODERATE_HIGH),
    },
    {
        value: ExceptionImpact.HIGH,
        label: titleCaseImpact(ExceptionImpact.HIGH),
    },
];

export const ExceptionImpactFilterOptions: OptionType[] = [
    {
        value: 'ALL',
        label: 'All Impacts',
    },
    ...ExceptionImpactOptions,
];

export enum ExceptionRiskScore {
    LOW = 'LOW',
    LOW_MODERATE = 'LOW_MODERATE',
    MODERATE = 'MODERATE',
    MODERATE_HIGH = 'MODERATE_HIGH',
    HIGH = 'HIGH',
}

export const ExceptionRiskScoreFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ExceptionsSortFilterOptions.RISK_SCORE,
        label: 'Low',
        value: ExceptionRiskScore.LOW,
    },
    {
        groupId: ExceptionsSortFilterOptions.RISK_SCORE,
        label: 'Low/Moderate',
        value: ExceptionRiskScore.LOW_MODERATE,
    },
    {
        groupId: ExceptionsSortFilterOptions.RISK_SCORE,
        label: 'Moderate',
        value: ExceptionRiskScore.MODERATE,
    },
    {
        groupId: ExceptionsSortFilterOptions.RISK_SCORE,
        label: 'Moderate/High',
        value: ExceptionRiskScore.MODERATE_HIGH,
    },
    {
        groupId: ExceptionsSortFilterOptions.RISK_SCORE,
        label: 'High',
        value: ExceptionRiskScore.HIGH,
    },
];

const riskScoreAsNumber = (score: ExceptionRiskScore): number => {
    switch (score) {
        case ExceptionRiskScore.LOW:
            return 0;
        case ExceptionRiskScore.LOW_MODERATE:
            return 1;
        case ExceptionRiskScore.MODERATE:
            return 2;
        case ExceptionRiskScore.MODERATE_HIGH:
            return 3;
        case ExceptionRiskScore.HIGH:
            return 4;
    }
};

export const riskScoreComparator = (scoreA: ExceptionRiskScore, scoreB: ExceptionRiskScore): number => {
    if (scoreA === scoreB) {
        return 0;
    } else {
        const valueA = riskScoreAsNumber(scoreA);
        const valueB = riskScoreAsNumber(scoreB);
        return valueA < valueB ? 1 : -1;
    }
};

export const titleCaseRiskScore = (riskScore: ExceptionRiskScore): string => {
    switch (riskScore) {
        case ExceptionRiskScore.LOW:
            return 'Low';
        case ExceptionRiskScore.LOW_MODERATE:
            return 'Low/Moderate';
        case ExceptionRiskScore.MODERATE:
            return 'Moderate';
        case ExceptionRiskScore.MODERATE_HIGH:
            return 'Moderate/High';
        case ExceptionRiskScore.HIGH:
            return 'High';
    }
};

export enum ExceptionRiskScoreFilter {
    ALL = 'ALL',
    LOW = 'LOW',
    LOW_MODERATE = 'LOW_MODERATE',
    MODERATE = 'MODERATE',
    MODERATE_HIGH = 'MODERATE_HIGH',
    HIGH = 'HIGH',
}

export const isValidExceptionRiskScoreFilter = (value: unknown): value is ExceptionRiskScoreFilter => typeof value === 'string' && Object.values(ExceptionRiskScoreFilter).includes(value as ExceptionRiskScoreFilter);

export const titleCaseExceptionRiskScoreFilter = (riskScore: ExceptionRiskScoreFilter): string => {
    switch (riskScore) {
        case ExceptionRiskScoreFilter.ALL:
            return 'All Risk Scores';
        case ExceptionRiskScoreFilter.LOW:
            return 'Low';
        case ExceptionRiskScoreFilter.LOW_MODERATE:
            return 'Low/Moderate';
        case ExceptionRiskScoreFilter.MODERATE:
            return 'Moderate';
        case ExceptionRiskScoreFilter.MODERATE_HIGH:
            return 'Moderate/High';
        case ExceptionRiskScoreFilter.HIGH:
            return 'High';
    }
};

export const ExceptionRiskScoreFilterOptions: OptionType[] = [
    {
        value: ExceptionRiskScoreFilter.ALL,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.ALL),
    },
    {
        value: ExceptionRiskScoreFilter.LOW,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.LOW),
    },
    {
        value: ExceptionRiskScoreFilter.LOW_MODERATE,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.LOW_MODERATE),
    },
    {
        value: ExceptionRiskScoreFilter.MODERATE,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.MODERATE),
    },
    {
        value: ExceptionRiskScoreFilter.MODERATE_HIGH,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.MODERATE_HIGH),
    },
    {
        value: ExceptionRiskScoreFilter.HIGH,
        label: titleCaseExceptionRiskScoreFilter(ExceptionRiskScoreFilter.HIGH),
    },
];

interface BaseException {
    id: string;
    status: ExceptionStatus;
    risk_score: ExceptionRiskScore;
    created_timestamp: string;
    created_by: string;
    last_updated_by: string;
    last_updated_timestamp: string;
    title: string;
    expiration_date: string;
    description: string;
    likelihood: ExceptionLikelihood;
    impact: ExceptionImpact;
    remediation_plan: string;
    reviewer_subject: string;
    owner_subject: string;
    risk_assessment: string;
    delegates: string[];
    reference?: string;
    compensating_controls?: string;
    files: UploadedFile[];
}

export interface OpenException extends BaseException {
    status: ExceptionStatus.DRAFT_OPEN | ExceptionStatus.APPROVED;
}

export interface DraftCloseException extends BaseException {
    status: ExceptionStatus.DRAFT_CLOSE;
    closure_statement: string;
}

export interface ClosedException extends BaseException {
    status: ExceptionStatus.CLOSED;
    closure_statement: string;
    closed_timestamp: string;
    closed_by: string;
}

type ExceptionWithStatusSpecifics = OpenException | DraftCloseException | ClosedException;

/**
 * Matches the backend's domain object. Used directly only for Notifications.
 * TODO: Can this be reworked? Why aren't we using DTOs for notifications?
 */
export type ExceptionEntity = ExceptionWithStatusSpecifics & {
    impacted_controls: string[];
    impacted_vendor?: string;
};

export type ControlExceptionResponse = ExceptionWithStatusSpecifics & { type: IssuesExceptionsModule.CONTROLS; impacted_controls: OperationalControl[] };
export type ThirdPartyExceptionResponse = ExceptionWithStatusSpecifics & { type: IssuesExceptionsModule.TPRM; impacted_vendor: string };
export type ExceptionResponse = ControlExceptionResponse | ThirdPartyExceptionResponse;

export interface CreateExceptionRequest {
    title: string;
    expiration_date: string;
    description: string;
    likelihood: ExceptionLikelihood;
    impact: ExceptionImpact;
    remediation_plan: string;
    reviewer_subject: string;
    owner_subject: string;
    risk_assessment: string;
    delegates: string[];
    reference?: string;
    impacted_controls: string[];
    impacted_third_party?: string;
    compensating_controls?: string;
    files: FileToBeUploaded[];
}

interface BaseUpdateExceptionRequest {
    title: string;
    expiration_date: string;
    description: string;
    likelihood: ExceptionLikelihood;
    impact: ExceptionImpact;
    remediation_plan: string;
    reviewer_subject: string;
    owner_subject: string;
    risk_assessment: string;
    delegates: string[];
    reference?: string;
    impacted_controls: string[];
    impacted_third_party?: string;
    compensating_controls?: string;
    file_updates: FileUpdates;
}

interface UpdateExceptionWithoutClosingRequest extends BaseUpdateExceptionRequest {
    status: ExceptionStatus.DRAFT_OPEN | ExceptionStatus.APPROVED;
}

interface CloseExceptionRequest extends BaseUpdateExceptionRequest {
    status: ExceptionStatus.DRAFT_CLOSE | ExceptionStatus.CLOSED;
    closure_statement: string;
}

export type UpdateExceptionRequest = UpdateExceptionWithoutClosingRequest | CloseExceptionRequest;

export interface ControlExceptionHistoryResponse {
    timestamp: string;
    exception: ControlExceptionResponse;
}

export interface ThirdPartyExceptionHistoryResponse {
    timestamp: string;
    exception: ThirdPartyExceptionResponse;
}

export type ExceptionHistoryResponse = ControlExceptionHistoryResponse | ThirdPartyExceptionHistoryResponse;

export const sortExceptions = (exceptions: ExceptionResponse[], users: UserResponse[], sortBy: ExceptionsSortFilterOptions, sortDirection: SortDirection, thirdPartyIdToNameMap: Map<string, string> = new Map([])): void => {
    exceptions.sort((exceptionA, exceptionB) => {
        let sortResult = 0;

        switch (sortBy) {
            case ExceptionsSortFilterOptions.OWNER:
                sortResult = compareUsersBySubjectForSorting(exceptionA.owner_subject, exceptionB.owner_subject, users);
                break;
            case ExceptionsSortFilterOptions.REVIEWER:
                sortResult = compareUsersBySubjectForSorting(exceptionA.reviewer_subject, exceptionB.reviewer_subject, users);
                break;
            case ExceptionsSortFilterOptions.EXPIRATION:
                sortResult = exceptionA.expiration_date > exceptionB.expiration_date ? 1 : -1;
                break;
            case ExceptionsSortFilterOptions.STATUS:
                sortResult = statusComparator(exceptionA.status, exceptionB.status);
                break;
            case ExceptionsSortFilterOptions.THIRD_PARTY:
                if (exceptionA.type === IssuesExceptionsModule.TPRM && exceptionB.type === IssuesExceptionsModule.TPRM) {
                    sortResult = thirdPartyNameComparator(exceptionA.impacted_vendor, exceptionB.impacted_vendor, thirdPartyIdToNameMap);
                } else {
                    throw new Error('Cannot sort Operational Controls exceptions by third party.');
                }
                break;
            case ExceptionsSortFilterOptions.CONTROLS:
                if (exceptionA.type === IssuesExceptionsModule.CONTROLS && exceptionB.type === IssuesExceptionsModule.CONTROLS) {
                    sortResult = exceptionA.impacted_controls.length - exceptionB.impacted_controls.length;
                } else {
                    throw new Error('Cannot sort TPRM exceptions by control.');
                }
                break;
            case ExceptionsSortFilterOptions.RISK_SCORE:
                sortResult = riskScoreComparator(exceptionA.risk_score, exceptionB.risk_score);
                break;
            default:
                sortResult = exceptionA[sortBy].localeCompare(exceptionB[sortBy]);
                break;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
    });
};

/**
 * This relatively simple function exists because we have previously had inconsistencies in whether drafts are considered "open".
 * @returns The number of issues in the provided list that are considered "open".
 */
export const countOpenExceptions = (exceptions: ExceptionResponse[]): number => exceptions.filter((exception) => exception.status === ExceptionStatus.APPROVED || exception.status === ExceptionStatus.DRAFT_CLOSE).length;
