import { ProgressBar } from 'react-bootstrap';

import { IndicatorVariant } from './Indicator';
import styles from './ProgressBarIndicator.module.css';

export interface ProgressBarIndicatorProps {
    size: 'large' | 'small';
    percent: number;
    variant: IndicatorVariant;
}

export const ProgressBarIndicator: React.FC<ProgressBarIndicatorProps> = ({ size, percent, variant }) => {
    const applyVariantColor = (): string => {
        switch (variant) {
            case IndicatorVariant.RED:
                return styles.red;
            case IndicatorVariant.ORANGE:
                return styles.orange;
            case IndicatorVariant.YELLOW:
                return styles.yellow;
            case IndicatorVariant.LIGHT_GREEN:
                return styles.lightgreen;
            case IndicatorVariant.DARK_GREEN:
                return styles.darkgreen;
            case IndicatorVariant.BLUE:
                return styles.blue;
            case IndicatorVariant.GRAY:
                return styles.gray;
            case IndicatorVariant.LIGHTBLUE:
                return styles.lightblue;
            default:
                return '';
        }
    };

    if (size === 'large') {
        return <ProgressBar className={`${styles.large} ${applyVariantColor()}`} now={percent} />;
    } else {
        return <ProgressBar className={`${styles.small} ${applyVariantColor()}`} now={percent} />;
    }
};
